import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TableCell = ({ content, columnIndex, rowIndex, groupIndex, moveCell, isDraggable, cellKey }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      type: 'TABLE_CELL',
      item: { content, columnIndex, rowIndex, groupIndex, isDraggable },
      canDrag: () => isDraggable,
      options: {
        previewOptions: {
          captureDraggingState: true,
        },
      },
    });
  
    const [, drop] = useDrop({
      accept: 'TABLE_CELL',
      canDrop: () => isDraggable,
      hover:(draggedItem, monitor) => {

      },
      drop: (draggedItem, monitor) => {
        if (
          draggedItem.rowIndex !== rowIndex ||
          draggedItem.columnIndex !== columnIndex ||
          draggedItem.groupIndex !== groupIndex
        ) {
          moveCell(draggedItem.columnIndex, draggedItem.rowIndex, columnIndex, rowIndex, groupIndex);
          draggedItem.rowIndex = rowIndex;
          draggedItem.columnIndex = columnIndex;
          draggedItem.groupIndex = groupIndex;
        }
      },
    });
  
    return (
        <>
        {cellKey === 'ind_as_ref' || cellKey === 'check_list_ref' ?
        <td>
            <input type="checkbox" checked={content}/>
        </td>
         :
         <td
        ref={(node) => {
          preview(drop(node)); // Pass preview ref to drop
          drag(node); // Attach drag ref
        }}
        style={{
          padding: '8px',
          border: '1px solid #ccc',
          margin: '15px',
          opacity: isDragging ? 0.5 : 1,
          transition: 'opacity 0.3s ease',
          cursor: isDraggable ? 'grab' : 'default', // Set cursor style based on draggability
        }}
      >
        {content}
      </td>
         }
        
        </>
      
    );
};
  
const TableRow = ({ cells, rowIndex, groupIndex, moveCell, isDraggable }) => {
return (
    <tr>
        <td><input type="checkbox" /></td>

    {Object.keys(cells).filter(item => !['refData','notes_index', 'headings'].includes(item)).map((content, columnIndex) => (
        <TableCell
        key={columnIndex}
        content={cells[content]}
        cellKey={content}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        groupIndex={groupIndex}
        moveCell={moveCell}
        isDraggable={isDraggable && columnIndex === 0}
        />
    ))}
    </tr>
);
};
  
const Table = () => {
const [headers, setHeaders] = useState(['Particulars', 'Notes', 'As at 07 February 2024', 'As at 07 February 2023', 'Reference', 'IND As Reference', 'checklist reference']);
const [groups, setGroups] = useState([
    "Financial assets",
    "Non-financial Assets",
    "Financial liabilities",
    "Non-financial liabilities",
    // "Profit before tax",
    "Equity",
    // "Profit for the period from continuing operations",
]);
const [rows, setRows] = useState(
    {
        "Financial assets": [
            {
                "notes_grp": "Trade receivables",
                "notes_no": "6",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(h)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(h)",
                    "indReference": false,
                    "checkListReference": true
                },
                "notes_index": 3
            },
            {
                "notes_grp": "Loans",
                "notes_no": "8",
                "cy": 120557163013,
                "py": 117414632041,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "Ind AS 107",
                    "name2": "Ind AS 107",
                    "refNo2": "107.8(c)",
                    "refNo": "1.54(d)",
                    "refNo1": "107.8(f)",
                    "reference": "Ind AS 1.54(d), Ind AS 107.8(f), Ind AS 107.8(c)",
                    "indReference": true,
                    "checkListReference": false
                },
                "notes_index": 4
            },
            {
                "notes_grp": "Cash and cash equivalents",
                "notes_no": "3",
                "cy": 7229377310,
                "py": 8064110195,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(i)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(i)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 5
            },
            {
                "notes_grp": "Derivative financial instruments",
                "notes_no": "5",
                "cy": 911173,
                "py": 999022,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "Ind AS 107",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(d)",
                    "refNo1": "107.8(a)",
                    "reference": "Ind AS 1.54(d), Ind AS 107.8(a)",
                    "indReference": true,
                    "checkListReference": true
                },
                "notes_index": 6
            },
            {
                "notes_grp": "Other Receivables",
                "notes_no": "7",
                "cy": 2806470,
                "py": 6238622,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(h)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(h)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 7
            },
            {
                "notes_grp": "Bank balance other than cash and cash equivalents",
                "notes_no": "4",
                "cy": 15084377,
                "py": 16500515,
                "refData": {
                    "name": "Ind AS 107",
                    "name1": "Ind AS 107",
                    "name2": "Ind AS 107",
                    "refNo2": "107.8(c)",
                    "refNo": "107.8(f)",
                    "refNo1": "107.8(c)",
                    "reference": "Ind AS 107.8(f), Ind AS 107.8(c), Ind AS 107.8(c)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 8
            },
            {
                "notes_grp": "Investments",
                "notes_no": "9",
                "cy": 220000000,
                "py": 220000000,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(d)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(d)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 9
            },
            {
                "notes_grp": "Other financial Assets",
                "notes_no": "10",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(d)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(d)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 10
            }
        ],
        "Non-financial Assets": [
            {
                "notes_grp": "Deferred tax assets (net)",
                "notes_no": "13",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "Ind AS 1",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(o)",
                    "refNo1": "1.56",
                    "reference": "Ind AS 1.54(o), Ind AS 1.56",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 11
            },
            {
                "notes_grp": "Current tax assets (net)",
                "notes_no": "12",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(n)",
                    "refNo1": "",
                    "reference": "Ind As 1.54(n)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 12
            },
            {
                "notes_grp": "Contract assets",
                "notes_no": "11",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 115",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "115.105",
                    "refNo1": "",
                    "reference": "Ind AS 115.105",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 13
            },
            {
                "notes_grp": "Property, plant and equipment",
                "notes_no": "14",
                "cy": 236186480,
                "py": 226028527,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(a)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(a)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 14
            },
            {
                "notes_grp": "Goodwill",
                "notes_no": "16",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(c)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(c)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 15
            },
            {
                "notes_grp": "Right Of Use Assets",
                "notes_no": "15",
                "cy": 133511684,
                "py": 129195688,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(a)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(a)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 16
            },
            {
                "notes_grp": "Capital work in progress",
                "notes_no": "17",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(c)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(c)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 17
            },
            {
                "notes_grp": "Other intangible assets",
                "notes_no": "18",
                "cy": 94458299,
                "py": 91889220,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(c)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(c)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 18
            },
            {
                "notes_grp": "Other non-financial assets",
                "notes_no": "19",
                "cy": 110307602,
                "py": 104539734,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "refNo": "1.54(d)",
                    "refNo1": "",
                    "name2": "",
                    "refNo2": "",
                    "reference": "Ind AS 1.54(d)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 19
            },
            {
                "notes_grp": "Non-current assets and disposal group held for sale",
                "notes_no": "20",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "",
                    "refNo1": "",
                    "reference": "",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 20
            },
            {
                "notes_grp": "Investments",
                "notes_no": "21",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "",
                    "refNo1": "",
                    "reference": "",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 21
            }
        ],
        "Financial liabilities": [
            {
                "notes_grp": "Derivative financial instruments",
                "notes_no": "22",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "Ind AS 107",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(m)",
                    "refNo1": "107.8(e)",
                    "reference": "Ind AS 1.54(m), Ind AS 107.8(e)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 22
            },
            {
                "notes_grp": "Trade payables",
                "notes_no": "23",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(k)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(k)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 23
            },
            {
                "notes_grp": "Other payables",
                "notes_no": "24",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(k)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(k)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 24
            },
            {
                "notes_grp": "Debt securities",
                "notes_no": "25",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "Ind AS 107",
                    "name2": "Ind AS 107",
                    "refNo2": "107.8(f)",
                    "refNo": "1.54(m)",
                    "refNo1": "107.8(g)",
                    "reference": "Ind AS 1.54(m), Ind AS 107.8(g), Ind AS 107.8(f)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 25
            },
            {
                "notes_grp": "Borrowings (other than debt securities)",
                "notes_no": "26",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "Ind AS 107",
                    "name2": "Ind AS 107",
                    "refNo2": "107.8(f)",
                    "refNo": "1.54(m)",
                    "refNo1": "107.8(g)",
                    "reference": "Ind AS 1.54(m), Ind AS 107.8(g), Ind AS 107.8(f)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 26
            },
            {
                "notes_grp": "Deposits",
                "notes_no": "27",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "Ind AS 107",
                    "name2": "Ind AS 107",
                    "refNo2": "107.8(f)",
                    "refNo": "1.54(m)",
                    "refNo1": "107.8(g)",
                    "reference": "Ind AS 1.54(m), Ind AS 107.8(g), Ind AS 107.8(f)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 27
            },
            {
                "notes_grp": "Subordinated liabilities",
                "notes_no": "28",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.55",
                    "refNo1": "",
                    "reference": "Ind AS 1.55",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 28
            },
            {
                "notes_grp": "Lease Liabilities",
                "notes_no": "29",
                "cy": 0,
                "py": 0,
                "notes_index": 29
            },
            {
                "notes_grp": "Other financial liabilities",
                "notes_no": "30",
                "cy": 33479645,
                "py": 71980942,
                "notes_index": 30
            }
        ],
        "Non-financial liabilities": [
            {
                "notes_grp": "Contract liability",
                "notes_no": "31",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 115",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "115.105",
                    "refNo1": "",
                    "reference": "Ind AS 115.105",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 31
            },
            {
                "notes_grp": "Current tax liabilities (Net)",
                "notes_no": "32",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(n)",
                    "refNo1": "",
                    "reference": "Ind As 1.54(n)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 32
            },
            {
                "notes_grp": "Provisions",
                "notes_no": "33",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(l)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(l)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 33
            },
            {
                "notes_grp": "Other non-financial liabilities",
                "notes_no": "34",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(l)",
                    "refNo1": "",
                    "reference": "Ind AS 1.54(l)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 34
            },
            {
                "notes_grp": "Deferred tax liabilities (net)",
                "notes_no": "35",
                "cy": 396558537,
                "py": 358261986,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "Ind AS 1",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.56",
                    "refNo1": "1.54(O)",
                    "reference": "Ind AS 1.56, Ind AS 1.54(O)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 35
            },
            {
                "notes_grp": "Non-current liability and disposal groups held for sale",
                "notes_no": "36",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "",
                    "name1": "",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "",
                    "refNo1": "",
                    "reference": "",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 36
            }
        ],
        "Equity": [
            {
                "notes_grp": "Equity share capital",
                "notes_no": "37",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "1.54(r)",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "Ind AS 1",
                    "refNo1": "1.78(e)",
                    "reference": "Ind AS 1.54(r) , Ind AS 1.78(e)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 37
            },
            {
                "notes_grp": "Entirely equity in nature",
                "notes_no": "38",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "1.54(r)",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "Ind AS 1",
                    "refNo1": "1.78(e)",
                    "reference": "Ind AS 1.54(r) , Ind AS 1.78(e)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 38
            },
            {
                "notes_grp": "Share application money pending allotment",
                "notes_no": "39",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "1.54(r)",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "Ind AS 1",
                    "refNo1": "1.78(e)",
                    "reference": "Ind AS 1.54(r) , Ind AS 1.78(e)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 39
            },
            {
                "notes_grp": "Other equity",
                "notes_no": "40",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1",
                    "name1": "Ind AS 1",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1.54(r)",
                    "refNo1": "1.78(e)",
                    "reference": "Ind AS 1.54(r), Ind AS 1.78(e)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 40
            },
            {
                "notes_grp": "Non-controlling interest",
                "notes_no": "41",
                "cy": 0,
                "py": 0,
                "refData": {
                    "name": "Ind AS 1110",
                    "name1": "Ind AS 1",
                    "name2": "",
                    "refNo2": "",
                    "refNo": "1110 B94",
                    "refNo1": "1.54(q)",
                    "reference": "Ind AS 1110 B94, Ind AS 1.54(q)",
                    "indReference": false,
                    "checkListReference": false
                },
                "notes_index": 41
            }
        ]
    }
  );

const moveCell = (startColumnIndex, startRowIndex, endColumnIndex, endRowIndex, groupIndex) => {
    console.log("hiiii")
    const updatedRows = { ...rows };
    const draggedCell = updatedRows[groups[groupIndex]][startRowIndex]
    const endDraggedCell = updatedRows[groups[groupIndex]][endRowIndex]
    updatedRows[groups[groupIndex]][startRowIndex] = {
    ...updatedRows[groups[groupIndex]][startRowIndex],
    notes_grp: endDraggedCell.notes_grp,
    cy: endDraggedCell.cy,
    py: endDraggedCell.py,
    refData: endDraggedCell.refData
    }
    
    updatedRows[groups[groupIndex]][endRowIndex] = { 
    ...endDraggedCell, 
    notes_grp: draggedCell.notes_grp,
    cy: draggedCell.cy,
    py: draggedCell.py,
    refData: draggedCell.refData
};
    setRows(updatedRows);
};

return (
    <DndProvider backend={HTML5Backend}>
    <div>
        <table style={{ width: '100%' }}>
        <thead>
            <tr>
            <th>Soft Delete</th>
            {headers.map((header, index) => (
                <th
                key={index}
                style={{
                    padding: '8px',
                    border: '1px solid #ccc',
                    margin: '15px', // Set cursor style based on draggability
                }}
                >
                {header}
                </th>
            ))}
            </tr>
        </thead>
        <tbody>
            {groups.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
                <tr>
                    <td></td>
                    <td style={{
                        padding: '8px',
                        border: '1px solid #ccc',
                        margin: '15px', // Set cursor style based on draggability
                        fontWeight:'bold',
                        backgroundColor:'cyan'
                        }}
                    >
                        {group}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {rows[group].map((cells, rowIndex) => (
                    <>
                    <TableRow
                    key={rowIndex}
                    cells={{...cells, reference: cells?.refData?.reference, ind_as_ref: cells?.refData?.indReference, check_list_ref: cells?.refData?.checkListReference}}
                    rowIndex={rowIndex}
                    groupIndex={groupIndex}
                    moveCell={moveCell}
                    isDraggable={true}
                />
                </>
                
                ))}
            </React.Fragment>
            ))}
        </tbody>
        </table>
    </div>
    </DndProvider>
);
};

const TestExcel = () => {
    const data = [
        {
            "columns": [
                {
                    "title": "Trade & Other Receivables"
                }
            ],
            "data": [
                [
                    {
                        "value": ""
                    }
                ]
            ]
        },
        {
            "columns": [
                {
                    "title": "Trade & Other Receivables"
                }
            ],
            "data": [
                [
                    {
                        "value": "Provision matrix for Trade & Other Receivable"
                    }
                ]
            ]
        },
        {
            "columns": [
                {
                    "title": "Particulars"
                },
                {
                    "title": "Trade & Other receivable days past due"
                },
                {
                    "title": "0-90 days"
                },
                {
                    "title": "91-180 days"
                },
                {
                    "title": "181-360 days"
                },
                {
                    "title": "more than 360 days"
                },
                {
                    "title": "Total"
                }
            ],
            "data": [
                [
                    {
                        "value": "ECL Rate"
                    },
                    {
                        "value": ""
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    }
                ],
                [
                    {
                        "value": "As at 01 March 2023"
                    },
                    {
                        "value": "Estimated total gross carrying amount at default"
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    }
                ],
                [
                    {
                        "value": "As at 01 March 2023"
                    },
                    {
                        "value": "ECL Provision"
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    }
                ],
                [
                    {
                        "value": "As at 01 March 2023"
                    },
                    {
                        "value": "Net Carrying Amount"
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    }
                ],
                [
                    {
                        "value": "ECL Rate"
                    },
                    {
                        "value": ""
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    }
                ],
                [
                    {
                        "value": "As at 30 March 2022"
                    },
                    {
                        "value": "Estimated total gross carrying amount at default"
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    }
                ],
                [
                    {
                        "value": "As at 30 March 2022"
                    },
                    {
                        "value": "ECL Provision"
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    }
                ],
                [
                    {
                        "value": "As at 30 March 2022"
                    },
                    {
                        "value": "Net Carrying Amount"
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    },
                    {
                        "value": 0
                    }
                ]
            ]
        }
    ]

    return (
        <ExcelFile 
            filename="Test Excel"
            element={<button>download</button>}
        >
            <ExcelSheet dataSet={data} name="disclosure"/>
        </ExcelFile>
    )
}



export default TestExcel;