import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../../helper/DarkModeContext";
import { setNoteNumber } from "../../helper/calculation";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import {BSTableDND} from "./TableDND/Table";

const BalanceSheet = (props) => {
  const { projectData, setTempValue, hideData, hideChildData, setAddNoteGrp } = props;
  const [no, setNo] = useState([]);
  const { isSuccess, response, error_message, loading } = useSelector((state) => state.TempTabBSReducer)
  const rows = response?.balance_sheet ?? [];
  const fsRows = Object.keys(rows);


  useEffect(() => {
    setNo(setNoteNumber(fsRows, rows));
  }, []);

  const getIndexNumber = (line) => {
    try{
      let index = no.findIndex((note) => note?.notes_grp.toLowerCase() === line?.notes_grp.toLowerCase());
      return index += 2

    }catch(err){
      console.log("error", err.message)
      return "00"
    }
    
  }

  function handleClick(key) {
    setTempValue(key);
  }

  const TotalNoteGroupsValue = (group, type) => {
    try{
      if(!["cy", "py"].includes(type))return 0;
      return response.balance_sheet[group].reduce((num, acc)=> num + acc[type], 0);
    }catch(err){
      console.log("err total notegrp", err);
      return 0
    }
   
  }

  return (
    <>
    {loading ? (
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="grow" size="sm" />
        <Spinner animation="grow" style={{ margin: "1rem" }} />
        <Spinner animation="grow" size="sm" />
      </div>
    ) : isSuccess ? (
    <>
     <>
      <div
        className="tableSheet"
      >
        <BSTableDND 
          headerData={['Particulars', 'Notes', `As at ${moment(projectData.current_year).format("DD MMMM YYYY")}`, `As at ${moment(projectData.previous_year).format("DD MMMM YYYY")}`]}
          groupData = {fsRows}
          tableData={rows}
          handleClick={handleClick}
          TotalNoteGroupsValue={TotalNoteGroupsValue}
          companyType={response.company_type}
          setAddNoteGrp={setAddNoteGrp}
        />
      </div>
    </>
    </>
    ) :
    (
      <p className="text-danger">
        {error_message || "Something went wrong!!!"}
      </p>
    )
    }
    </>
  );
};

export default BalanceSheet;
