import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Sidbarv2 from "../../Workspace-v2/Sidbarv2";
import Navbarv3 from "../../Workspace-v2/Navbarv3";
import "../../Workspace-v2/sidebarv2.css";
import { Navbar } from "../../../Components/Navbar";
import { useLocation } from "react-router";
import "./../../../WorkSpace/font.css";

const WorkspacePreset = () => {
  const location = useLocation();
  let a = location.pathname;
  let w_id = a.split("/");
  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>
        <div className="d-flex">
          <div>
            <Sidbarv2 w_id={w_id} />
          </div>
          <div class="container wpcontent">
            <p className="dm-sans wp-h">Workspace presets</p>
            <div class="row wprow">
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }} className="wpcard-pad">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text-dark"
                      height="90px"
                      gutterBottom
                    >
                      <div>
                        <h6 className="dm-sans wp-sechead">
                          Accounting_FS_ver_2_2020
                        </h6>
                        <h6
                          // className="text-secondary font-weight-bold "
                          // style={{ fontSize: "12px" }}
                          className="fs-inter wp-card-content"
                        >
                          CREATED ON 31 APR 2021
                        </h6>
                        <p className="wp-content wpTypography">
                          Contains Mapping, Adjustments, General Disclosures,
                          Balance Sheet Notes, PNL Notes, General Notes, SOCIE,
                          Cashflow
                        </p>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }} className="wpcard-pad">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text-dark"
                      height="90px"
                      gutterBottom
                    >
                      <div>
                        <h6 className="dm-sans wp-sechead">
                          Accounting_FS_ver_2_2020
                        </h6>
                        <small className="fs-inter wp-card-content">
                          CREATED ON 31 APR 2021
                        </small>
                        <br />
                        <p className="wp-content wpTypography">
                          Contains Mapping ,Adjustments,General
                          Disclosures,BalanceSheetNotes,PNL Notes, General
                          Notes,SOCIE,cashFlow
                        </p>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }} className="wpcard-pad">
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text-dark"
                      height="90px"
                      gutterBottom
                    >
                      <div>
                        <h6 className="dm-sans wp-sechead">
                          Accounting_FS_ver_2_2020
                        </h6>
                        <small className="fs-inter wp-card-content">
                          CREATED ON 31 APR 2021
                        </small>
                        <br />
                        <small className="wp-content wpTypography">
                          Contains Mapping ,Adjustments,General
                          Disclosures,BalanceSheetNotes,PNL Notes, General
                          Notes,SOCIE,cashFlow
                        </small>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>

            <div class="row wpsecondrow">
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.light"
                      gutterBottom
                    >
                      <div>
                        <h6 className="dm-sans wp-sechead">
                          Accounting_FS_ver_2_2020
                        </h6>
                        <small className="fs-inter wp-card-content">
                          CREATED ON 31 APR 2021
                        </small>
                        <h6 className="wp-content wpTypography">
                          Contains Mapping ,Adjustments,General
                          Disclosures,BalanceSheetNotes,PNL Notes, General
                          Notes,SOCIE,cashFlow
                        </h6>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.light"
                      gutterBottom
                    >
                      <div>
                        <h6 className="dm-sans wp-sechead">
                          Accounting_FS_ver_2_2020
                        </h6>
                        <small className="fs-inter wp-card-content">
                          CREATED ON 31 APR 2021
                        </small>
                        <h6 className="wp-content wpTypography">
                          Contains Mapping ,Adjustments,General
                          Disclosures,BalanceSheetNotes,PNL Notes, General
                          Notes,SOCIE,cashFlow
                        </h6>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.light"
                      gutterBottom
                    >
                      <div>
                        <h6 className="dm-sans wp-sechead">
                          Accounting_FS_ver_2_2020
                        </h6>
                        <small className="fs-inter wp-card-content">
                          CREATED ON 31 APR 2021
                        </small>
                        <h6 className="wp-content wpTypography">
                          Contains Mapping ,Adjustments,General
                          Disclosures,BalanceSheetNotes,PNL Notes, General
                          Notes,SOCIE,cashFlow
                        </h6>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* <div class="row" style={{ marginTop: "20px" }}>
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Word of the Day
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Word of the Day
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Word of the Day
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div> */}
            <div class="row wpsecondrow">
              <div className="col-md-4">
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.light"
                      gutterBottom
                    >
                      <div>
                        <h6 className="dm-sans wp-sechead">
                          Accounting_FS_ver_2_2020
                        </h6>
                        <small className="fs-inter wp-card-content">
                          CREATED ON 31 APR 2021
                        </small>
                        <h6 className="wp-content wpTypography">
                          Contains Mapping ,Adjustments,General
                          Disclosures,BalanceSheetNotes,PNL Notes, General
                          Notes,SOCIE,cashFlow
                        </h6>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspacePreset;
