import { React, useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { Navbar } from "../../../Components/Navbar";
import { Button } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { AllWorkspace, GetUser, cities, editCompanyData, findCompanyData, getChildUser, getCompany, getCompanyByApproverId, getWorkApproverByModulename, state, getAllWorkspacebyUserid, getApprover, fetchDraftCompany, updateDraftCompany, getRequest, createRequest, getDeleteWorkspace, addDeleteDraftWorkspace } from "../ApiServices/apiHandler";
import { Autocomplete, MenuItem, Modal, Select, TextField, FormControl } from "@mui/material";
import swal from "sweetalert";
import { useHistory } from "react-router";
import CancelDeleteWorkspace from "../Modal/cancelDeleteWorkspace";
import { dataRegisterLabels, notificationCenterLabels } from "./notificationData";
import DataRegisterPanel from "./components/DataRegisterPanel";
import { socket } from '../../../services/socket/socket';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

const Notification = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'));
  const [value, setValue] = useState(0);
  const [approverTabValue, setApproverTabValue] = useState(0);
  const [dataRegisterTab, setDataRegisterTab] = useState(0);
  const [salesValue, setSalesValue] = useState(0);
  const [purchasesValue, setPurchasesValue] = useState(0);
  const [leasesValue, setLeasesValue] = useState(0);
  const [loansValue, setLoansValue] = useState(0);
  const [investmentsValue, setInvestmentsValue] = useState(0);
  const [fixedassetsValue, setFixedAssetsValue] = useState(0);
  const [receivablesValue, setReceivablesValue] = useState(0);
  const [payablesValue, setPayablesValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openCancelDeleteModal, setOpenCancelDeletModal] = useState(false);
  const [reason, setReason] = useState('');
  const [id, setId] = useState('');
  const [companyName, setCompanyName] = useState({});
  const [workspaceAll, setWorkspaceAll] = useState([]);
  const [workspaceDelete, setWorkspaceDelete] = useState([]);
  const [workspace, setWorkspace] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [company, setAllCompany] = useState([]);
  const [approverId, setApproverId] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [deleteWorkspace, setDeleteWorkspace] = useState([]);
  const [companies, setCompany] = useState([]);
  const [deleteCompanyId, setDeleteCompanyId] = useState('');
  const [singleCompany, setSingleCompany] = useState({
    org_name: "",
    ci_no: "",
    pan_no: "",
    nature_of_company: "",
    functional: "",
    city: "",
    state: "",
    address: "",
    approver_id: ""
  });

  const [preparerId,setPreparerId]=useState('');
  const [sendUser,setSendUser]=useState('');
  
  const [Open, setOpen] = useState(false);
  const [allcities, setCitiesData] = useState([]);
  const [allstate, setStateData] = useState([]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal1 = () => setOpenModal1(true);
  const handleCloseModal1 = () => setOpenModal1(false);
  const handleOpenModal2 = () => setOpenModal2(true);
  const handleCloseModal2 = () => setOpenModal2(false);
  const handleOpenCancelDeleteModal = () => setOpenCancelDeletModal(true);
  const handleCloseCancelDeleteModal = () => setOpenCancelDeletModal(false);


  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    GetAllUser();
    GetAllworkspace();
    allCompany();
    allCompanyByApprovalId();
    citiesData();
    stateData();
    getApproverIds();
    fetchCompany();
    getAllWorkspaceByUserid();
    GetRequests();
    getdeleteDraftWorkspaces();
  }, []);

  const handleChnageEdit = (value, field) => {
    setSingleCompany({ ...singleCompany, [field]: value });
  }


  const getAllWorkspaceByUserid = async () => {
    try {
      const response = await getAllWorkspacebyUserid();
      setWorkspaceAll(response.data.getworkspace);
    } catch (error) {
      console.error('Error fetching workspace', error);
    }
  }

  const userName = (u_id) => {
    try {
      const nameUser = allUser.filter((adj) => adj._id === u_id);
      const result = nameUser[0]?.name
        ? nameUser[0]?.name
        : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  const WorkspaceName = (w_id) => {
    const workspaceData = workspace.find((adj) => adj._id === w_id);
    return workspaceData?.workspace_name || 'Unknown';
  };

  const getCompanyName = (c_id) => {
    const companyData = company.find((adj) => adj._id === c_id);
    return companyData?.company_name || 'Unknown';
  };

  const GetAllUser = async () => {
    const res = await GetUser();
    if (res.status === 200) {
      setAllUser(res.data.fetchUser);
    }
  };

  const GetAllworkspace = async () => {
    const res = await AllWorkspace();
    if (res.status === 200) {
      setWorkspace(res.data.workspace);
    }
  };

  const GetRequests = async () => {
    const res = await getRequest();
    if (res.status === 200) {
      setWorkspaceDelete(res.data.response);
    }
  };

  const handleClick = (status, w_id, c_id) => {
    if (c_id && status === "approved") {
      history.push(`/financialstatement/${w_id}`);
    } else if (status !== "approved") {
      history.push(`/financialstatement/${w_id}`);
    } else {
      swal("Error", "The workspace has already been deleted", "error");
    }
  };

  const allCompany = async () => {
    const res = await getCompany();
    setAllCompany(res.data.getCompany);
  };

  const allCompanyByApprovalId = async () => {
    const res = await getCompanyByApproverId();
    setCompany(res.data.getCompany);
  };

  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };

  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };

  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };

  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };

  const fetchCompany = async (c_id) => {
    try {
      const res = await fetchDraftCompany(c_id);
      if (res.status === 200) {
        setSingleCompany(res?.data?.getOneCompany[0]);
      }
    } catch (error) {
      console.error('Error fetching company:', error);
    }
  };

  const handleApprove = async (c_id, company) => {
    const data = {
      companyName: company.company_name,
      companyCode: company.company_code,
      ci_no: company.ci_no,
      pan_no: company.pan_no,
      nature_of_company: company.nature_of_company,
      functional: company.functional,
      city: company.city,
      state: company.state,
      address: company.address,
      approver_id: company.approver_id,
      has_published: "published",
      status: "approved",
      cancel_reason: ""
    };
    const result = await updateDraftCompany(c_id, data);
    if (result.status === 200) {
    setOpen(false);
    allCompanyByApprovalId();
    swal("Success", "Company Data Approved", "success");
    socket.emit("add-notification", {
      receiver_id: company.user_id,
      description: "Company",
      type:"approved approval",
      path:"/Notification"
    });
    socket.emit('get-notification');
    }
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  }

  const handleCancel = async (c_id, company) => {
    const data = {
      companyName: company.company_name,
      companyCode: company.company_code,
      ci_no: company.ci_no,
      pan_no: company.pan_no,
      nature_of_company: company.nature_of_company,
      functional: company.functional,
      city: company.city,
      state: company.state,
      address: company.address,
      approver_id: company.approver_id,
      has_published: "draft",
      status: "cancelled",
      cancel_reason: reason
    };
    const result = await updateDraftCompany(c_id, data);
    if (result.status === 200) {
    setOpen(false);
    setOpenModal(false);
    setReason('');
    allCompanyByApprovalId();
    swal("Success", "Company data Cancelled", "success");
    socket.emit("add-notification", {
      receiver_id: company.user_id,
      description: "Company",
      type:"cancelled approval",
      path:"/Notification"
    });
    socket.emit('get-notification');
    }
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  }

  const submitEdit = async (company_id) => {
    let company_code =
      singleCompany.company_name.slice(0, 3) + Math.floor(1000 + Math.random() * 9000);

    const data = {
      companyName: singleCompany.company_name,
      companyCode: company_code,
      ci_no: singleCompany.ci_no,
      pan_no: singleCompany.pan_no,
      nature_of_company: singleCompany.nature_of_company,
      functional: singleCompany.functional,
      city: singleCompany.city,
      state: singleCompany.state,
      address: singleCompany.address,
      approver_id: singleCompany.approver_id,
      has_published: "draft",
      status: "waiting",
      cancel_reason: ""
    };
    const result = await updateDraftCompany(company_id, data);
    if (result.status === 200) {
      handleClose();
      allCompany();
      allCompanyByApprovalId();
      swal("Success", "Company data Updated", "success");
      socket.emit("add-notification", {
        receiver_id: singleCompany.user_id,
        description: "Updation of company",
        type:"cancelled approval",
        path:"/Notification"
      });
      socket.emit('get-notification');
    }
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  }

  const getApproverIds = async () => {
    const parent_id = user.parent_id;
    getApprover(parent_id)
      .then(response => {
        setApproverId(response.data.result);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const acceptRequestForAccess=async(r_id,preparer_id)=>{
    const data={
      request_id:r_id,
      status:"approved",
      cancel_reason:""
    }
    createRequest(data)
      .then(response => {
        GetRequests();
        swal("Success", "Request Accepted Successfully", "success");
        socket.emit("add-notification", {
          receiver_id: preparer_id,
          description: "workspace deletion",
          type:"Request Accepted",
          path:"/Notification"
        });
        socket.emit('get-notification');
        })
        .catch(error => {
          console.error('Error:', error);
        });
  };

  const getdeleteDraftWorkspaces = async()=>{
    getDeleteWorkspace()
      .then(response => {
        setDeleteWorkspace(response.data.response);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const acceptApprovalDeleteWorkspace=async(d_id,preparer_id)=>{
    const data={
      draft_id:d_id,
      status:"approved",
      cancel_reason:"",
      approver_id:user._id
    }
    addDeleteDraftWorkspace(data)
      .then(response => {
        getdeleteDraftWorkspaces();
        swal("Success", "Approved Successfully", "success");
        socket.emit("add-notification", {
          receiver_id: preparer_id,
          description: "workspace deletion",
          type:"approved approval",
          path:"/Notification"
        });
        socket.emit('get-notification');
        })
        .catch(error => {
          console.error('Error:', error);
        });
  };

  return (
    <>
      <Navbar />
      <div>
        <Card>
          <CardContent>
            <Box>
              <Tabs
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                aria-label="basic tabs example"
                variant="fullWidth"
                sx={{
                  "& button": {
                    borderRadius: 1,
                    color: "black",
                    fontWeight: "500",
                    opacity: "1",
                  },
                  "& button.Mui-selected": {
                    backgroundColor: "#03565A",
                    color: "white",
                    opacity: "1",
                  },
                }}
              >
                {user.responsibilities === "approver" &&
                  <Tab
                    className="font-sans"
                    sx={{
                      textTransform: "none",
                      fontWeight: "400",
                    }}
                    label="Approver"
                    {...a11yProps(0)}
                  />
                }
                {user.responsibilities === "preparer" &&
                  <Tab
                    className="font-sans"
                    sx={{
                      textTransform: "none",
                      fontWeight: "400",
                    }}
                    label="Preparer"
                    {...a11yProps(0)}
                  />
                }
              </Tabs>
            </Box>
          </CardContent>
        </Card>

        <CustomTabPanel value={value} index={0}>
          <Card className="my-2">
            <span>{user.responsibilities === "approver" ? "Request for approval :" : "Sent for approval :"}</span>
          </Card>
          <div>
            <Card>
              <CardContent>
                <Box>
                  <Tabs
                    value={approverTabValue}
                    onChange={(event, newValue) =>
                      setApproverTabValue(newValue)
                    }
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    sx={{
                      "& button": {
                        borderRadius: 1,
                        color: "black",
                        fontWeight: "500",
                        opacity: "1",
                      },
                      "& button.Mui-selected": {
                        backgroundColor: "#03565A",
                        color: "white",
                        opacity: "1",
                      },
                    }}
                  >
                    {
                      notificationCenterLabels.map(label => (
                        <Tab
                          className="font-sans"
                          sx={{
                            textTransform: "none",
                            fontWeight: "400",
                          }}
                          label={label.label}
                          {...a11yProps(0)}
                        />
                      ))
                    }
                  </Tabs>
                </Box>
              </CardContent>
            </Card>
            <CustomTabPanel value={approverTabValue} index={0}>
              {companies && companies.length > 0 && companies.map((data) =>
                <div className="d-flex flex-row justify-content-between mt-4">
                  <div className="col-md-4">
                    <Typography>{data.company_name}</Typography>
                    <Typography>{userName(data.user_id)}</Typography>
                  </div>
                  <div className="col-md-4">
                    <Button
                      style={{
                        backgroundColor: "#021076",
                        color: "white",
                        width: "150px",
                      }}
                      onClick={() => {
                        setOpen(true);
                        fetchCompany(data._id)
                      }}
                    >
                      View Details
                    </Button>
                  </div>
                  {
                    user.responsibilities === "approver" ? (
                      <div
                        className="col-md-4 ">
                        {data.status === "approved" ? "" :
                          <Button
                            style={{
                              backgroundColor: "#ECECEC",
                              color: "#3f3f3f",
                              marginRight: "10px",
                              borderColor: "black",
                              width: "150px",
                            }}
                            disabled={data.status === "cancelled"}
                            onClick={() => {
                              handleOpenModal();
                              setId(data._id);
                              setCompanyName(data)
                            }}
                          >
                            {data.status === "cancelled" ? "Returned" : "Return"}
                            ({data.action})
                          </Button>}
                        {data.status === "cancelled" ? "" :
                          <Button
                            style={{
                              backgroundColor: "#cefad0",
                              color: "#008631",
                              borderColor: "black",
                              width: "150px",
                            }}
                            disabled={data.status === "approved"}
                            onClick={() => {
                              handleApprove(data._id, data)
                            }}
                          >
                            {data.status === "approved" ? "Approved" : "Approve"}
                            ({data.action})
                          </Button>}
                      </div>
                    ) : (
                      <div
                        className="col-md-4 d-flex flex-row justify-content-between"
                      >
                        <Button style={{ color: "#3f3f3f", backgroundColor: "#ECECEC", borderColor: "black", width: "300px", marginRight: "10px" }}>
                          {data.status === "waiting" ? "Sent For Approval" : data.status === "approved" ? "Approved" : "Task Returned"}
                          ({data.action})
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#021076",
                            color: "white",
                            marginRight: "10px",
                            width: "150px",
                          }}
                        >
                          Send remainder
                        </Button>
                      </div>
                    )
                  }
                </div>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={approverTabValue} index={1}>
              {workspaceAll && workspaceAll.length && workspaceAll.map((data) =>
                <div className="d-flex flex-row justify-content-between m-4">
                  <div className="col-md-4">
                    <Typography>{data.workspace_name ? data.workspace_name : data?.data?.workspace_name}</Typography>
                    <Typography>{userName(data.user_id)}</Typography>
                  </div>
                  <div className="col-md-4">
                    <Button
                      style={{
                        backgroundColor: "#021076",
                        color: "white",
                        width: "150px",
                      }}
                      onClick={() => { data.status === "approved" ? history.push(`/financialstatement/${data._id}`) : data.current_status ? history.push(`/workspacedeatils/${data.workspace_id}`) : history.push(`/workspacelayout/${data._id}`) }}
                    >
                      View Details
                    </Button>
                  </div>
                  {
                    user.responsibilities === "approver" ? (
                      <Button
                        style={{
                          backgroundColor: "#cefad0",
                          color: "#008631",
                          borderColor: "black",
                          width: "150px",
                        }}
                      >
                        {data.status === "approved" ? "Approved" : data.status === "cancelled" ? "Cancelled" : "Approve"}
                      </Button>
                    ) : (
                      <div className="col-md-4 d-flex flex-row justify-content-between">
                        <Button style={{ color: "#3f3f3f", backgroundColor: "#ECECEC", borderColor: "black", width: "300px", marginRight: "10px" }}>
                          {data.status === "start" ? "Send for Approval" : (data.status === "waiting" || data.current_status === "draft" ? "Sent For Approval" : data.status === "approved" || data.current_status === "published" ? "Approved" : "Cancelled")}
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#021076",
                            color: "white",
                            marginRight: "10px",
                            width: "150px",
                          }}
                        >
                          Send remainder
                        </Button>
                      </div>
                    )
                  }

                </div>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={approverTabValue} index={2}>
              {user.responsibilities === "approver" ?
                deleteWorkspace && deleteWorkspace.length && deleteWorkspace.map((data) =>
                  <div className="d-flex flex-row justify-content-between mt-4">
                    <div className="col-md-4">
                      <Typography>{data.workspace_name}</Typography>
                      <Typography>{userName(data.user_id)}</Typography>
                    </div>
                    <div className="col-md-4">
                      <Button
                        style={{
                          backgroundColor: "#021076",
                          color: "white",
                          marginRight: "10px",
                          width: "150px",
                        }}
                        onClick={() => handleClick(data.status, data.workspace_id)}
                      >
                        View Details
                      </Button>
                    </div>
                    <div className="col-md-4">
                      {data.status === "cancelled" ? <></> :
                        <Button
                          style={{
                            backgroundColor: "#cefad0",
                            color: "#008631",
                            borderColor: "black",
                            width: "150px",
                          }}
                          onClick={() => acceptApprovalDeleteWorkspace(data._id,data.preparer_id)}
                        >
                          {data.status === "approved" ? `Approved by ${userName(data.approver_id)}` : "Approve"}
                        </Button>}
                      &nbsp;
                      {data.status === "approved" ? <></> :
                        <Button
                          style={{
                            backgroundColor: "#cefad0",
                            color: "#008631",
                            borderColor: "black",
                            width: "150px",
                          }}
                          onClick={() => {
                            handleOpenCancelDeleteModal()
                            setRequestId(data._id);
                            setSendUser(data.user_id);
                            setPreparerId(data.preparer_id);
                          }
                          }
                        >
                          {data.status === "cancelled" ? `Cancelled by ${userName(data.approver_id)}` : "Cancel"}
                        </Button>}
                    </div>
                  </div>
                ) :
                workspaceDelete && workspaceDelete.length && workspaceDelete.map((data) =>
                  <div className="d-flex flex-row justify-content-between m-4">
                    <div className="col-md-4">
                      <Typography>{data.workspace_name}</Typography>
                      <Typography>{userName(data.user_id)}</Typography>
                    </div>
                    <div className="col-md-4">
                      <Button
                        style={{
                          backgroundColor: "#021076",
                          color: "white",
                          width: "300px",
                        }}
                        onClick={() => handleClick(data.status, data.workspace_id, data.company_id)}
                      >
                        {data.company_id ? `Delete request for ${getCompanyName(data.company_id)}` : "View Details"}

                      </Button>
                    </div>
                    <div className="col-md-4 d-flex flex-row justify-content-between">
                      {data.preparer_id !== user._id ?
                        <>
                          <Button style={{ color: "#3f3f3f", backgroundColor: "#ECECEC", borderColor: "black", width: "300px", marginRight: "10px" }}>
                            {data.status === "waiting" ? "Request Sent For Acceptance" : data.status === "approved" ? "Request Approved" : "Request Declined"}
                          </Button>
                          <Button
                            style={{
                              backgroundColor: "#021076",
                              color: "white",
                              marginRight: "10px",
                              width: "150px",
                            }}
                          >
                            Send remainder
                          </Button>
                        </>
                        : !data.company_id ?
                          <>
                            <Button style={{ color: "#3f3f3f", backgroundColor: "#ECECEC", borderColor: "black", width: "300px", marginRight: "10px" }}>
                              {data.status === "waiting" ? "Send for Approval" : data.status === "approved" ? `Approved by ${userName(data.approver_id)}` : `Task Returned by ${userName(data.approver_id)}`}
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "#021076",
                                color: "white",
                                marginRight: "10px",
                                width: "150px",
                              }}
                            >
                              Send remainder
                            </Button>
                          </> :
                          <>
                            {data.status === "declined" ? <></> :
                              <Button
                                style={{
                                  backgroundColor: "#cefad0",
                                  color: "#008631",
                                  borderColor: "black",
                                  width: "200px",
                                }}
                                disabled={data.status === "approved"}
                                onClick={() => acceptRequestForAccess(data._id,data.user_id)}
                              >
                                {data.status === "approved" ? "Request Accepted" : "Request Accept"}
                              </Button>}
                            {data.status === "approved" ? <></> :
                              <Button
                                style={{
                                  backgroundColor: "#cefad0",
                                  color: "#008631",
                                  borderColor: "black",
                                  width: "200px",
                                  marginRight: "10px"
                                }}
                                disabled={data.status === "declined"}
                                onClick={() => {
                                  handleOpenCancelDeleteModal()
                                  setRequestId(data._id);
                                  setDeleteCompanyId(data.company_id);
                                  setSendUser(data.user_id);
                                  setPreparerId(data.preparer_id);
                                }
                                }
                              >
                                {data.status === "declined" ? "Request Declined" : "Request Decline"}
                              </Button>}
                          </>
                      }
                    </div>
                  </div>
                )
              }
            </CustomTabPanel>
            <CustomTabPanel value={approverTabValue} index={3}>
              <Card className="py-2">
                Data set related approvals :
              </Card>
              <Card>
                <CardContent>
                  <Box>
                    <Tabs
                      value={dataRegisterTab}
                      onChange={(event, newValue) => setDataRegisterTab(newValue)}
                      aria-label="basic tabs example"
                      variant="fullWidth"
                      sx={{
                        "& button": {
                          borderRadius: 1,
                          color: "black",
                          fontWeight: "500",
                          opacity: "1",
                        },
                        "& button.Mui-selected": {
                          backgroundColor: "#03565A",
                          color: "white",
                          opacity: "1",
                        },
                      }}
                    >
                      {
                        dataRegisterLabels.map((label, index) => (
                          <Tab
                            key={index}
                            className="font-sans"
                            label={label.label}
                            {...a11yProps(index)}
                          />
                        ))
                      }
                    </Tabs>
                  </Box>
                </CardContent>
              </Card>
              {
                dataRegisterLabels.map((label, index) => (
                  <CustomTabPanel value={dataRegisterTab} index={index}>
                    <DataRegisterPanel
                      label={label}
                      responsibilities={user.responsibilities}
                    />
                  </CustomTabPanel>
                ))
              }
            </CustomTabPanel>
          </div>
        </CustomTabPanel>
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <label>Cancel Reason:</label>
            <textarea
              className="w-100"
              value={reason}
              onChange={(event) => setReason(event.target.value)}
            />
          </div>
          <div className="d-flex  justify-content-center mt-3 ">
            <Button
              style={{
                backgroundColor: "#03565a",
                color: "white",
                textTransform: "none",
                width: "50%",
                marginTop: "10px"
              }}
              onClick={() => handleCancel(id, companyName)}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={Open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="wp-modal-box wp-modal-box-add-company-form">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h5 class="dbwcardhead">Create new company</h5>
          </Typography>
          <div style={{ marginTop: "30px" }}>
            <label>Enter your organization name</label>
            <TextField
              className="wp-add-comp-modal-input"
              value={singleCompany.company_name}
              disabled={singleCompany.status === "approved" || user.responsibilities === "approver" || singleCompany.status === "waiting" || singleCompany.action === "Delete"}
              onChange={(e) =>
                handleChnageEdit(e.target.value, "company_name")
              }
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>CIN No.</label>
            <TextField
              className="wp-add-comp-modal-input"
              value={singleCompany.ci_no}
              disabled={singleCompany.status === "approved" || user.responsibilities === "approver" || singleCompany.status === "waiting" || singleCompany.action === "Delete"}
              onChange={(e) =>
                handleChnageEdit(e.target.value, "ci_no")
              }
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <label>PAN/TAN No.</label>
            <TextField
              className="wp-add-comp-modal-input"
              value={singleCompany.pan_no}
              disabled={singleCompany.status === "approved" || user.responsibilities === "approver" || singleCompany.status === "waiting" || singleCompany.action === "Delete"}
              onChange={(e) =>
                handleChnageEdit(e.target.value, "pan_no")
              }
            />
          </div>
          <div className="row">
            <div className="col-12" style={{ marginTop: "20px" }}>
              <label>Nature of company</label>

              <select
                className="wp-add-comp-modal-input"

                style={{
                  height: "60px",
                  borderRadius: "6px"
                }}
                disabled={singleCompany.status === "approved" || user.responsibilities === "approver" || singleCompany.status === "waiting" || singleCompany.action === "Delete"}
                onChange={(e) =>
                  handleChnageEdit(e.target.value, "nature_of_company")
                }
              >
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Select..."}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={singleCompany?.nature_of_company === 'Manufacturing'}>
                  {"Manufacturing"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={singleCompany?.nature_of_company === 'NBFC'}>
                  {"NBFC"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={singleCompany?.nature_of_company === 'Banking'}>
                  {"Banking"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={singleCompany?.nature_of_company === 'Trading'}>
                  {"Trading"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={singleCompany?.nature_of_company === 'Other Specify'}>
                  {"Other Specify"}
                </option>
              </select>
            </div>
            <div className="col-12" style={{ marginTop: "20px" }}>
              <label>Functional currency</label>
              <select
                className="wp-add-comp-modal-input"

                style={{
                  height: "60px",
                  borderRadius: "6px"
                }}
                disabled={singleCompany.status === "approved" || user.responsibilities === "approver" || singleCompany.status === "waiting" || singleCompany.action === "Delete"}
                onChange={(e) =>
                  handleChnageEdit(e.target.value, "functional")
                }
              >
                <option style={{ color: "black", backgroundColor: "white" }}>
                  {"Select..."}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={singleCompany?.functional === 'INR'}>
                  {"INR"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={singleCompany?.functional === 'USD'}>
                  {"USD"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={singleCompany?.functional === 'LKR'}>
                  {"LKR"}
                </option>
                <option style={{ color: "black", backgroundColor: "white" }} selected={singleCompany?.functional === 'AUD'}>
                  {"AUD"}
                </option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-6" style={{ marginTop: "20px" }}>
              <label>Select City</label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={singleCompany?.city}
                disabled={singleCompany.status === "approved" || user.responsibilities === "approver" || singleCompany.status === "waiting" || singleCompany.action === "Delete"}
                options={!allcities ? [] : allcities}
                onInputChange={onInputChangecity}
                onChange={(e, value) => handleChnageEdit(value, "city")}
                sx={{ width: 300, backgroundColor: "white" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=<LocationOnIcon />
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="col-6" style={{ marginTop: "20px" }}>
              <label>Select State</label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={singleCompany.state}
                disabled={singleCompany.status === "approved" || user.responsibilities === "approver" || singleCompany.status === "waiting" || singleCompany.action === "Delete"}
                options={!allstate ? [] : allstate}
                onChange={(e, value) => handleChnageEdit(value, "state")}
                onInputChange={onInputChange}
                className="wp-add-comp-modal-input"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=<LocationOnIcon />
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="blankspace30"></div>
          {user.responsibilities === "approver" ?
            <>
              {singleCompany.status === "cancelled" ? "" :
                <Button className="wp-btn-fill"
                  disabled={singleCompany.status === "approved"}
                  onClick={() => {
                    handleApprove(singleCompany._id, singleCompany)
                  }}
                >{singleCompany.status === "approved" ? "Approved" : "Approve"}
                </Button>}
              &nbsp; &nbsp;
              {singleCompany.status === "approved" ? "" :
                <Button className="wp-btn-no-fill"
                  disabled={singleCompany.status === "cancelled"}
                  onClick={() => {
                    handleOpenModal();
                    setCompanyName(singleCompany)
                    setId(singleCompany._id);
                  }} >
                  {singleCompany.status === "cancelled" ? "Returned" : "Return"}
                </Button>}
            </> :
            <>
              {singleCompany.status === "cancelled" ?
                <>
                  <Button className="wp-btn-fill"
                    disabled={singleCompany.action === "Delete"}
                    onClick={() => {
                      handleOpenModal1();
                      setId(singleCompany._id);
                    }}>Update</Button>
                  &nbsp; &nbsp;
                  <Button className="wp-btn-no-fill" onClick={handleClose}>
                    Cancel
                  </Button>
                  &nbsp; &nbsp;
                  <Button className="wp-btn-no-fill" onClick={handleOpenModal2}>
                    Remark
                  </Button>
                </> : (
                  <>
                    <Button className="wp-btn-fill" >
                      {singleCompany.status === "approved" ? "Approved" : " Sent for Approval"}
                    </Button>
                    &nbsp; &nbsp;
                    <Button className="wp-btn-no-fill" onClick={handleClose}>
                      Cancel
                    </Button>
                  </>
                )}
            </>
          }
        </Box>
      </Modal>
      <Modal
        open={openModal1}
        onClose={handleCloseModal1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => handleChnageEdit(e.target.value, "approver_id")}
              value={singleCompany?.approver_id || ""}
            >
              {approverId && approverId.length > 0 && approverId.map((data) =>
                <MenuItem key={data._id} value={data._id}>{data.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <div className="d-flex flex-row justify-content-center mt-3 ">
            <Button style={{
              backgroundColor: "#03565a",
              color: "white",
              textTransform: "none",
              margin: "15px",
              width: "50%"
            }}
              onClick={handleCloseModal1}
            >
              Return
            </Button>
            <Button
              className="mt-3"
              style={{
                backgroundColor: "#03565a",
                color: "white",
                textTransform: "none",
                width: "80%",
                height: "48px",
              }}
              onClick={() => {
                submitEdit(id);
                setOpenModal1(false);
              }}
            >
              Send for approval
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openModal2}
        onClose={handleCloseModal2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ maxHeight: "150px", overflowY: "auto" }}>
            <p>{singleCompany.cancel_reason}</p>
          </div>
          <div className="d-flex  justify-content-center mt-3 ">
            <Button
              style={{
                backgroundColor: "#03565a",
                color: "white",
                textTransform: "none",
                width: "50%",
                marginTop: "10px"
              }}
              onClick={handleCloseModal2}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
      <CancelDeleteWorkspace
        openCancelDeleteModal={openCancelDeleteModal}
        handleCloseCancelDeleteModal={handleCloseCancelDeleteModal}
        GetRequests={GetRequests}
        requestId={requestId}
        getdeleteDraftWorkspaces={getdeleteDraftWorkspaces}
        deleteCompanyId={deleteCompanyId}
        preparer_id={preparerId}
        sendUser={sendUser}
      />
    </>
  );
};

export default Notification;
