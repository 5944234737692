import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Button } from "react-bootstrap";
import axios from "axios";
import * as actionTypes from "../../redux/actionTypes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { socket } from "../../services/socket/socket";
import folder from "../../assets/folder.svg";
import {
  SplitPane,
  SplitPaneProps,
  ResizerOptions,
  CollapseOptions,
  SplitPaneHooks,
} from "react-collapse-pane";

import { Navbar } from "../../Components/Navbar";
// import './conventional.css'
import switchModeSvg from "../../assets/switchMode.svg";
import filterSvg from "../../assets/filter.svg";
import trashSvg from "../../assets/trash.svg";
import shareSvg from "../../assets/share.svg";
import adjustmentSvg from "../../assets/adjustment.svg";
import plusSvg from "../../assets/plus.svg";

import { Submit } from "../../Components/Button";
import Filter from "../../Components/Filter";
import BasicTable from "../../Components/Table/BasicTable";
import DeleteSelected from "../../Components/deleteSelected/DeleteSelected";
import TemplateTabs from "../../Components/templateTabs/TemplateTabs";
import DeletedFile from "../../Components/deletedItems/DeletedFile";
import PopulateFs from "../../Components/populateFs/PopulateFs";
import ExpandLedger from "../../Components/expandLedger/ExpandLedger";
import AddLedger from "../../Components/addLedger/AddLedger";
import AddGrouping from "../../Components/AddGrouping/AddGrouping";
import Confirmation from "../../Components/confirmationMenu/confirmationMenu";
import ShareScreen from "../../Components/shareScreen/ShareScreen";
import SwitchMode from "../../Components/switchMode/SwitchMode";
import "./template.css";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import swal from "sweetalert";
import { Alert, Snackbar, Checkbox } from "@mui/material";
import { AccessProjectContext } from "../../helper/DarkModeContext";
import DeleteDuplicate from "../../Components/deletedItems/DeleteDuplicate";
import { red } from "@mui/material/colors";
import AssignValuesData from "../setupProject/AssignValuesData";
import {
  ModalStyle,
  headStyle,
  headerStyle,
  centerStyle,
  inputFiles,
  stickyStyle,
} from "../../Components/Styles/templateStyle";
import {
  deleteConventionalLineItems,
  getAllProjects,
  getProfile,
} from "../../services/api/apiHandler";
import {
  deleteLineItems,
  fetchConventionalMode,
} from "../../redux/actions/TemplateTabs/actionCM";
import {
  fetchTTGroupData,
  socketTTGroupUpdate,
} from "../../redux/actions/TemplateTabs/actionGrouping";
import AddNoteGroup from "../../Components/AddGrouping/AddNotes";
import { toggleCheckYearly } from "../../redux/actions/TemplateTabs/actionDragSelector";
import { useLocation } from "react-router";
import { getAllWorkspace } from "../../WorkSpace/Components/ApiServices/apiHandler";
import CommonProgressBar from "../../Components/Common/ProgressBar";

const NewTemplate = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const tableData = useSelector(
    (initialState) => initialState.reducer.tableData
  );
  const filterData = useSelector((initialState) => initialState.reducer.filter);
  const grouping = useSelector((initialState) => initialState.reducer.grouping);
  const groupData = useSelector((state) => state.TTGroupingReducer);
  const conventionMode = useSelector((state) => state.ConventionModeReducer);
  const { multiSelectedRows, checkYearly } = useSelector(
    (state) => state.selectedDragReducer
  );
  const { loading, error_message, response } = conventionMode;
  const [filterRow, setFilterRow] = useState([]);
  const [totalRow, setTotalRow] = useState([]);
  const [totalFilter, setTotalFilter] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [filterEnable, setFilterEnable] = useState(false);
  const [object, setObject] = useState({});
  const [select, setSelect] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletedMenu, setDeleteMenu] = useState(false);
  const [populateFs, setPopulateFs] = useState(false);
  const [otherGrouping, setOtherGrouping] = useState(grouping);
  const [addLedger, setAddLedger] = useState(false);
  const [expandLedger, setExpandLedger] = useState(false);
  const [addLedgerAbove, setAddLedgerAbove] = useState(false);
  const [addLedgerBelow, setAddLedgerBelow] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [addGrouping, setAddGrouping] = useState(false);
  const [addNoteGrp, setAddNoteGrp] = useState(false);
  const [confirmationMenu, setConfirmationMenu] = useState();

  const [showSwitchMode, setSwitchMode] = useState(false);
  const [render, setRender] = useState("");

  const [confirmfilClose, SetConfirmFilClose] = useState(false);
  const [newSg, SetNewSg] = useState();
  const [hideItems, SetHideItems] = useState(false);
  const [shareScreen, SetShareScreen] = useState(false);
  //   const [loading, setLoading] = useState(false);
  const R_tb_id = useSelector((initialState) => initialState.reducer.tb_id);
  const LOCAL_tb_id = localStorage.getItem("tb_id");
  let tb_id = R_tb_id ? R_tb_id : LOCAL_tb_id;

  const [hiddenFilter, setHiddenFilter] = useState([]);

  const [updateRows, setUpdateRows] = useState(false);
  const [tb_upload, uploadTb] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const { access, setAccess } = useContext(AccessProjectContext);
  const [rowData, setRowData] = useState({});
  const [countdata, getDataCount] = useState("");
  const [updateTabs, setUpdateTabs] = useState(false);
  const [duplicateItems, setDuplicateItems] = useState([]);
  const [openDuplicateModal, setDuplicateModal] = useState(false);
  const [showAssignValues, setAssignValues] = useState(false);
  const [info, setinfo] = useState([]);
  const [index, setIndex] = useState("");
  const [headerdata, setHeaders] = useState([]);
  const [tempID, setTempID] = useState("");
  const [type, setType] = useState("");
  const [allProject, setAllProject] = useState([]);
  const [allWorkspace, setAllWorkspace] = useState([]);

  let init = {
    LedgerCode: "",
    currentBalance: "",
    ledgerName: "",
    previousBalance: "",
    tbAmount: "",
  };
  const [Name, setName] = useState(init);

  const project_id = localStorage.getItem("project_id");
  const auth = localStorage.getItem("auth_token");
  let headers = {
    "x-auth-token": auth,
  };

  let vertical = "bottom";
  let horizontal = "right";

  const projectId = useParams();

  useEffect(() => {
    (async () => {
      await fetchUserProfile();
      await fetchConvential();
      await fetchGroupData();
    })();
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;
    const pathParts = pathname.split('/');
    if(pathParts.includes('templateV2')){
      localStorage.setItem("templateAction", 'templateV2');
    }
  }, [])

  const fetchUserProfile = async () => {
    try {
      const response = await getProfile();
      const { access_projects } = response.data.user.data;
      if (
        access_projects.length &&
        access_projects.find((project) => project.project_id === projectId.pid)
      ) {
        setAccess(
          access_projects.find(
            (project) => project.project_id === projectId.pid
          )
        );
      } else {
        setAccess({ access: "All", project_id: "" });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchConvential = async () => {
    try {
      if (
        !conventionMode.isSuccess ||
        conventionMode.set_payload !== JSON.stringify({ project_id, tb_id })
      ) {
        dispatch(fetchConventionalMode(project_id, tb_id));
      } else if (
        conventionMode.isSuccess &&
        conventionMode.response.length > 0
      ) {
        const { response } = conventionMode;
        const unique = [];

        const duplicates = response.data.data.line_items.filter((o) => {
          if (unique.find((i) => i.ledger_code === o.ledger_code)) {
            return true;
          }
          unique.push(o);
          return false;
        });
        setDuplicateItems(duplicates);
        setTotalRow(response.data.data.line_items);
        setDeleteRow(false);
        setUpdateRows(false);
      }
    } catch (error) {
      console.log("error fetchConvention", error.message);
    }
  };

  const fetchGroupData = async () => {
    if (
      !groupData.isSuccess ||
      groupData.set_payload !== JSON.stringify({ project_id, tb_id })
    ) {
      dispatch(fetchTTGroupData(project_id, tb_id));
    }
  };

  function handleApplyFilter() {
    setRender(Math.random());

    if (filterData.length > 0) {
      filterData.map((filter, i) => {
        console.log(filter, "filterfilter", i);

        if (i === 0) {
          if (filter.column === "" && filter.change === "") {
            return;
          }
          if (
            filter.column !== "ledger_name" &&
            filter.column !== "ledger_code" &&
            filter.select === "Less than"
          ) {
            const res = tableData.filter((line) => {
              console.log(line);
              if (line[`${filter.column}`] < filter.startAmount) {
                return line;
              }
            });
            setTotalFilter(res);
            return;
          }

          if (
            filter.column !== "ledger_name" &&
            filter.column !== "ledger_code" &&
            filter.select === "Greater than"
          ) {
            const res = tableData.filter((line) => {
              if (line[`${filter.column}`] > Number(filter.startAmount)) {
                return line;
              }
            });
            setTotalFilter(res);
            return;
          }

          if (
            filter.column !== "ledger_name" &&
            filter.column !== "ledger_code" &&
            filter.select === "Equal to"
          ) {
            const res = tableData.filter((line) => {
              if (line[`${filter.column}`] === Number(filter.startAmount)) {
                return line;
              }
            });
            setTotalFilter(res);
            return;
          }

          const res = tableData.filter((line) => {
            if (
              line[`${filter.column}`]
                .toLowerCase()
                .includes(`${filter.change}`.toLowerCase())
            ) {
              return line;
            }
          });
          setTotalFilter(res);
          return;
        }
      });
    }
  }
  const handleFS = () => {
    history.push(`/preview`);
  };
  function handleDrop(e) {
    setConfirmDelete(!confirmDelete);
  }

  useEffect(() => {
    setRender(Math.random());
  }, [grouping]);

  useEffect(() => {
    handleApplyFilter();
    findProject();
    findWorkspace();
  }, [filterData]);

  const hiddenFileInput = React.useRef(null);

  const existFileInput = React.useRef(null);

  const handleChange = (e, type) => {
    e.preventDefault();
    setType(type);
    const fileListAsArray = e.target.files;
    if (
      (fileListAsArray[0].type === "application/vnd.ms-excel") |
      (fileListAsArray[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      const auth = localStorage.getItem("auth_token");
      let headers = {
        "x-auth-token": auth,
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
      };
      const project_id = localStorage.getItem("project_id");
      const data = new FormData();
      data.append("file", fileListAsArray[0]);

      axios
        .post(`/api/v1/uploads/upload-headers/${project_id}`, data, {
          headers,
        })
        .then((response) => {
          setHeaders(Object.keys(response.data.result.data[0].segregatedJson));
          setTempID(response.data.tmp_id);
          setAssignValues(true);
          uploadTb(false);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  const handleOpen = (type) => {
    if (type === "new") {
      hiddenFileInput.current.click();
    } else {
      existFileInput.current.click();
    }
  };

  let total_cy = 0;
  let total_adjusted = 0;
  let someData = "";
  if (tableData.length > 0) {
    someData = tableData;
    total_cy = someData.reduce((totalCy, i) => totalCy + Number(i.cy), 0);
    const total_py = someData.reduce((totalPy, i) => totalPy + Number(i.py), 0);
    total_adjusted = someData.reduce(
      (totalPy, i) => Number(totalPy) + Number(i.adjusted_amount),
      0
    );
    const total_amnt = total_cy - total_adjusted;
  }

  const calculateCY = () => {
    let total = 0;

    tableData.forEach((element) => {
      if (element.cy !== 0) {
        total = total + Number(element.cy);
      }
    });

    return total;
  };

  const calculatePY = () => {
    let total = 0;

    tableData.forEach((element) => {
      if (element.py !== 0) {
        total = total + Number(element.py);
      }
    });
    return total;
  };

  function handleSelect(type) {
    if (type === "Delete Record") {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will be able to view deleted logs in the deleted items menu",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const project_id = localStorage.getItem("project_id");
          let deletedData = {
            row: multiSelectedRows,
          };
          deleteConventionalLineItems(project_id, deletedData)
            .then((res) => {
              dispatch(fetchConventionalMode(project_id, tb_id));
              dispatch(deleteLineItems(multiSelectedRows, project_id, tb_id));
              swal("Ledger has been deleted!", {
                icon: "success",
              });
            })
            .catch((err) => {
              console.log("err", err.message);
              swal("somthing went wrong", {
                icon: "danger",
              });
            });
        } else {
          swal("Ledger not deleted!");
        }
      });
    }

    setConfirmDelete(false);
  }

  const handleSubmit = () => {
    console.log("hello");
    // console.log(Name);
    // console.log(tempID);
    let data = {
      ledger_name: Name.ledgerName,
      ledger_code: Name.LedgerCode,
      branch: Name.branchName,
      py: Name.previousBalance,
      cy: Name.currentBalance,
    };

    axios
      .post(
        `/api/v1/tb-mapping/heading-classification/${project_id}/${tempID}`,
        data,
        { headers }
      )
      .then((response) => {
        console.log(response, "response");
        if (type === "new") {
          const auth = localStorage.getItem("auth_token");
          let headers = {
            "x-auth-token": auth,
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
          };
          const project_id = localStorage.getItem("project_id");
          axios
            .post(`/api/v1/uploads/existing-tb/${project_id}/${tempID}`, data, {
              headers,
            })
            .then((response) => {
              console.log("hello new tb");
              console.log(response, "data");
              // eliminateDupe(response.data.data.line_items);
              // setProjectData(data);
              // saveProjectData(data);
              dispatch({
                type: actionTypes.SET_TABLE_DATA,
                payload: response.data.data.line_items,
              });
              setAssignValues(false);
              setUpdateRows(true);
              // setLoading(true);
            })
            .catch((error) => {
              console.log(error.data, "error");
              console.log(error.response, "error");
              console.log(error, "error");
            });
        } else {
          // setData((Data) => [...Data, fileListAsArray]);
          // setConfirm((confirm) => [...confirm, false]);
          const auth = localStorage.getItem("auth_token");
          let headers = {
            "x-auth-token": auth,
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
          };
          const project_id = localStorage.getItem("project_id");
          axios
            .post(`/api/v1/uploads/merge-tb/${project_id}/${tempID}`, data, {
              headers,
            })
            .then((response) => {
              console.log("hello multi");
              console.log(response, "data");
              // eliminateDupe(response.data.data.line_items);
              // dispatch({
              //   type: actionTypes.SET_TABLE_DATA,
              //   payload: response.data.data.line_items,
              // });
              setAssignValues(false);
              setUpdateRows(true);
            })
            .catch((error) => {
              console.log(error.data, "error");
              console.log(error.response, "error");
              console.log(error, "error");
            });
        }
      })
      .catch((error) => {
        console.log(error.response, "error");
      });
  };

  const findProject = async () => {
    const res = await getAllProjects();
    if (res.status === 200) {
      setAllProject(res.data.project_list);
    }
  };
  const findWorkspace = async () => {
    const res = await getAllWorkspace();
    if (res.status === 200) {
      setAllWorkspace(res.data.getWorkspace);
    }
  };

  const AccessProject = () => {
    const nameOfWs = allProject.filter((adj) => adj._id === project_id);
    const result = nameOfWs[0]?.workspace_id ? nameOfWs[0]?.workspace_id : "";
    const accessData = allWorkspace.filter((adj) => adj._id === result);
    const resultWorkspace = accessData[0]?.access_status
      ? accessData[0]?.access_status
      : "";
    return resultWorkspace;
  };
  return (
    <div style={{ maxHeight: "100vh" }}>
      {shareScreen ? <ShareScreen SetShareScreen={SetShareScreen} /> : null}
      {addGrouping ? (
        <AddGrouping
          setOtherGrouping={setOtherGrouping}
          newSg={newSg}
          setAddGrouping={setAddGrouping}
          setRowData={setRowData}
          rowData={rowData}
        />
      ) : null}
      {addNoteGrp ? (
        <AddNoteGroup
          setAddNoteGrp={setAddNoteGrp}
          setRowData={setRowData}
          rowData={rowData}
        />
      ) : null}
      {populateFs ? <PopulateFs setPopulateFs={setPopulateFs} /> : null}
      {deletedMenu ? (
        <DeletedFile
          setDeleteMenu={setDeleteMenu}
          select={select}
          setUpdateRows={setUpdateRows}
        />
      ) : null}
      {addLedger ? (
        <AddLedger close={setAddLedger} name="Create a new Ledger Item" />
      ) : null}
      {showSwitchMode ? (
        <SwitchMode
          close={setSwitchMode}
          name="Choose a Mapping Mode"
          currentMode="2"
        />
      ) : null}
      {expandLedger ? (
        <ExpandLedger
          close={setExpandLedger}
          name="Ledger Detail"
          select={select}
        />
      ) : null}
      {addLedgerAbove ? (
        <AddLedger
          close={setAddLedgerAbove}
          name="Add Ledger Above"
          select={select}
        />
      ) : null}
      {addLedgerBelow ? (
        <AddLedger
          close={setAddLedgerBelow}
          name="Add Ledger Below"
          select={select}
        />
      ) : null}
      {confirmation ? (
        <Confirmation
          setConfirmation={setConfirmation}
          confirmationMenu={confirmationMenu}
          select={select}
        />
      ) : null}

      {showAssignValues ? (
        <AssignValuesData
          show={showAssignValues}
          setShow={setAssignValues}
          info={info}
          index={index}
          setName={setName}
          Name={Name}
          handleSubmit={handleSubmit}
          headers={headerdata}
        />
      ) : null}
      <div style={{ maxHeight: "77vh", overflowY: "hidden" }}>
        <Navbar text="Financial Year: 2021 - 2022" />
        <div className="conventionalMenu" style={{ zIndex: "9" }}>
          {multiSelectedRows.length < 1 ? null : (
            <div
              className="delete"
              onClick={() => {
                handleSelect("Delete Record");
              }}
            >
              <img src={trashSvg} alt="trash" />
              <p>Delete record</p>
            </div>
          )}
          <div
            className="switchMode"
            onClick={() => {
              setSwitchMode(!showSwitchMode);
            }}
          >
            <img src={switchModeSvg} />
            <p>Switch Mode</p>
          </div>
          <div
            className="filter"
            onClick={() => {
              setFilterShow(!filterShow);
            }}
          >
            <img src={filterSvg} />
            <p>Filter</p>
          </div>
          <div className="hideItem" onClick={() => SetHideItems(!hideItems)}>
            <i class={hideItems ? "fas fa-eye" : "far fa-eye-slash"}></i>
            <p>{hideItems ? "Show grouped items" : "Hide grouped items"}</p>
          </div>
          {AccessProject() === "view" ? (
            <div className="deletedItem" style={{ cursor: "pointer" }}>
              <img src={trashSvg} />
              <p>Deleted items</p>
            </div>
          ) : (
            <div
              className="deletedItem"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setDeleteMenu(true);
              }}
            >
              <img src={trashSvg} />
              <p>Deleted items</p>
            </div>
          )}
          {AccessProject() === "view" ? (
            <div className="adjustment">
              <img src={adjustmentSvg} />
              <p>Adjustment</p>
            </div>
          ) : (
            <div
              className="adjustment"
              onClick={() => {
                history.push("/adjustments");
              }}
            >
              <img src={adjustmentSvg} />
              <p>Adjustment</p>
            </div>
          )}
          {/* {AccessProject() === "view" ? (
            <div className="share">
              <img src={shareSvg} />
              <p>Share</p>
            </div>
          ) : (
            <div
              className="share"
              onClick={() => {
                SetShareScreen(true);
              }}
            >
              <img src={shareSvg} />
              <p>Share</p>
            </div>
          )} */}

          <div className="checkYearly" >
            <Checkbox
              label={"Split mapping"}
              sx={{
                '&.Mui-checked': {
                  color: '#03565a', 
                }
              }}
              checked={checkYearly}
              onChange={(e) => {
                console.log("checkbox", e.target.checked);
                dispatch(toggleCheckYearly(e.target.checked));
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <p>Split mapping</p>
          </div>
          <div className="uploadConventional">
            <div className="addTb" onClick={() => uploadTb(true)}>
              <img src={plusSvg} />
              <h4>Upload trial balance</h4>
            </div>
            <div className="populateTemplate" onClick={() => handleFS()}>
              <Submit sm value="Populate FS Template" />
            </div>
          </div>
        </div>

        <Filter
          show={filterShow}
          handleApplyFilter={handleApplyFilter}
          setFilterShow={setFilterShow}
          setObject={setObject}
          setFilterRow={setFilterRow}
          filterRow={filterRow}
          SetConfirmFilClose={SetConfirmFilClose}
          filterEnable={filterEnable}
          filterHandler={setFilterEnable}
        />
        {confirmDelete ? (
          <DeleteSelected
            setDeleteRow={setDeleteRow}
            fetchConvential={fetchConvential}
            setConfirmDelete={setConfirmDelete}
            select={select}
            setConfirmation={setConfirmation}
            setConfirmationMenu={setConfirmationMenu}
            setExpandLedger={setExpandLedger}
            setAddLedgerBelow={setAddLedgerBelow}
            setAddLedgerAbove={setAddLedgerAbove}
          />
        ) : null}
        <div>
          {loading && (
            <div
              style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="grow" size="sm" />
              <Spinner animation="grow" style={{ margin: "1rem" }} />
              <Spinner animation="grow" size="sm" />
            </div>
          )}
          <div
            className="tabsinside"
            style={{ width: "100%", maxHeight: "77vh", overflow: "hidden" }}
          >
            <SplitPane
              minSizes={[600, 300]}
              initialSizes={[60, 40]}
              split="vertical"
              collapseOptions={{
                beforeToggleButton: <Button>⬅</Button>,
                afterToggleButton: <Button>➡</Button>,
                overlayCss: { backgroundColor: "black" },
                buttonTransition: "zoom",
                buttonPositionOffset: -20,
                collapsedSize: 50,
                collapseTransitionTimeout: 350,
                // minSizes:[2,1],
              }}
              resizerOptions={{
                css: {
                  width: "5px",
                  background: "#03565ade",
                },
                hoverCss: {
                  width: "5px",
                  background: "4px solid rgba(0, 0, 0)",
                },
                grabberSize: "5px",
              }}
            >
              <div style={{ height: "100%", display:"flex", flexDirection:"column" }}>
                <div style={{overflow:"scroll", height:"96%"}}>
                  {!loading && (
                    <BasicTable
                      template={true}
                      SetNewSg={SetNewSg}
                      setAddGrouping={setAddGrouping}
                      totalFilter={totalFilter}
                      hideItems={hideItems}
                      setTotalRow={setTotalRow}
                      tableData={someData}
                      hiddenFilter={hiddenFilter}
                      fetchConvential={setUpdateRows}
                      setRowData={setRowData}
                      rowData={rowData}
                      setUpdateTabs={setUpdateTabs}
                      getdata={getDataCount}
                      filterEnable={filterEnable}
                    />
                  )}
                </div>
                <CommonProgressBar />
              </div>

              <div style={{ maxHeight: "77vh", overflowY: "scroll" }}>
                <div
                  style={{ minHeight: "77vh"}}
                  onDragEnter={() => {
                    // setHighlighted('#e9ebf0')
                  }}
                  onDragLeave={() => {
                    // setHighlighted('')
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                >
                  <TemplateTabs
                    SetNewSg={SetNewSg}
                    setAddGrouping={setAddGrouping}
                    updateTabs={updateTabs}
                    setAddNoteGrp={setAddNoteGrp}
                  />
                </div>
              </div>
            </SplitPane>
          </div>
        </div>
        
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={response?.cy_balance !== 0 || response?.py_balance !== 0 || duplicateItems.length > 0}
        >
          <Alert
            severity="warning"
            sx={{ cursor: "pointer", padding: "6px 0px 6px 14px" }}
            onClick={() => setDuplicateModal(true)}
          ></Alert>
        </Snackbar>
      </div>

      {openDuplicateModal && (
        <DeleteDuplicate
          CMResponse={response}
          duplicateItems={duplicateItems}
          openDuplicateModal={openDuplicateModal}
          setDuplicateModal={setDuplicateModal}
          fetchConvential={setUpdateRows}
        />
      )}

      <Modal
        open={tb_upload}
        onClose={() => uploadTb(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div style={centerStyle}>
              <h3 style={headerStyle}>Choose One</h3>
            </div>
            <div style={headStyle}>
              <div style={inputFiles} onClick={() => handleOpen("new")}>
                <img src={folder} alt="folder" style={{ marginLeft: "56px" }} />
                <input
                className="font-sans"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ref={hiddenFileInput}
                  onChange={(e) => handleChange(e, "new")}
                  style={{ display: "none" }}
                />
                Existing TB upload
              </div>

              <div style={inputFiles} onClick={() => handleOpen("exist")}>
                <img src={folder} alt="folder" style={{ marginLeft: "40px" }} />
                <input
                  className="font-sans"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ref={existFileInput}
                  onChange={(e) => handleChange(e, "exist")}
                  style={{ display: "none" }}
                />
                New TB upload
              </div>
            </div>
            <div style={{ display: "flex", padding: "0 1rem" }}>
              <p
              className="font-sans"
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "var(--clr-accent)",
                  textAlign: "center",
                }}
              >
                {" "}
                <i
                  class="fas fa-info-circle"
                  style={{ color: "var(--clr-accent)" }}
                ></i>{" "}
                Make sure only one sheet is present if one file is uploaded at a
                time
              </p>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
export default NewTemplate;
