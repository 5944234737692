import React, { useEffect, useState, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../redux/actionTypes";
import { Spinner } from "react-bootstrap";
import { ThemeContext } from "../../helper/DarkModeContext";
import { getAllProjects, getGroupingData } from "../../services/api/apiHandler";
import { calSubGrpHeading, getCyPyData, getGroupName } from "../../helper/calculation";
import { fetchTTGroupData } from "../../redux/actions/TemplateTabs/actionGrouping";
import { batchUpdateConventionalMode, updateConventionalMode } from "../../redux/actions/TemplateTabs/actionCM";
import { formatIndianCurrency } from "../../helper/ConvertValue";
import moment from "moment";

const total = {
  lineHeight: "22px",
  letterSpacing: "0px",
  fontSize: "18px",
  fontWeight: "600",
  backgroundColor: "#e0e0e0",
  borderBottom: "1px solid #cfcccc",
};

const level1 = {
  lineHeight: "22px",
  letterSpacing: "0px",
  fontSize: "18px",
  fontWeight: "600",
  backgroundColor: "#f2e2b6",
  borderBottom: "1px solid #cfcccc",
};
const Pattern2 = (props) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const Note = useSelector(
    (initialState) => initialState.reducerTemplate.note3
  );

  const group = useSelector((initialState) => initialState.reducer.grouping);
  const conventionModeData_ = useSelector(
    (state) => state.ConventionModeReducer
  );
  const reduxProjectData = useSelector((initialState) => initialState.PresetReducer.projectData);

  const conventionModeData = useMemo(()=> conventionModeData_, [conventionModeData_]);

  const {checkYearly} = useSelector((state)=> state.selectedDragReducer);
  const {selectedRow} = useSelector((state) => state.selectedDragRow);
  // const selectedRow = null;
  const noteNumber = props.note_no;
  const [note, setNote] = useState(Note);
  const [grouping, setGrouping] = useState(group);
  const [nid, setNld] = useState("");
  const [highlight, setHighlight] = useState("");
  const [projectGroupData_, setProjectGroupData] = useState([]);

  const projectGroupData = useMemo(()=> projectGroupData_, [projectGroupData_])

  const [groupName, setGroupName] = useState([]);

  const R_tb_id = useSelector((initialState) => initialState.reducer.tb_id);
  const LOCAL_tb_id = localStorage.getItem("tb_id");
  let tb_id = R_tb_id ? R_tb_id : LOCAL_tb_id;

  const TTGrouping_ = useSelector((state) => state.TTGroupingReducer);
  const TTGrouping = useMemo(() => TTGrouping_, [TTGrouping_]);
  const auth = localStorage.getItem("auth_token");
  const project_id = localStorage.getItem("project_id");

  useEffect(() => {
    (async () => {
      if (!TTGrouping.isSuccess || TTGrouping.set_payload !== JSON.stringify({project_id, tb_id})) {
        dispatch(fetchTTGroupData(project_id, tb_id));
      } else {
        const result = getCyPyData(TTGrouping.response.fs_grp, noteNumber);
        if(!projectGroupData.length){
          setProjectGroupData(result);
        }
      }
    })();
  }, [TTGrouping.response]);

  useEffect(() => {
    setGroupName([...getGroupName(TTGrouping.response.fs_grp, noteNumber)])
    if(Object.keys(reduxProjectData).length === 0){
      getAllProjects()
      .then((response) => {
        const projectFind = response.data.project_list.find(
          (i) => i._id == project_id
        );
        if(projectFind){
          dispatch({ type: "PROJECT_DATA", payload: projectFind });
        }
      })
      .catch((error) => {
        console.log(error.response, "error");
      });
    }
  },[]);

  function brackets(val) {
    if (val < 0) {
      return "(" + (-1 * val).toLocaleString("en-IN") + ")";
    }
    return val > 0 ? val.toLocaleString("en-IN") : val;
  }

  function handleAdd(payload) {
    props.setAddGrouping(true);
    props.SetNewSg(payload);
  }

  const totalCy = () => {
    let result =  projectGroupData.map((data)=> data.cy_amt);
    const res = result.reduce((data, acc)=> data + acc, 0);
    if(res === 0){
      return "-"
    }
    return brackets(res)
  } 

  const totalPy = () => {
    let result =  projectGroupData.map((data)=> data.cy_amt);
    const res = result.reduce((data, acc)=> data + acc, 0)
    if(res === 0){
      return "-"
    }
    return brackets(res)
  }

  const handleDropping = (group_data, type) => {
    const mapData = {
      fs_grp: group_data.fs_grp,
      note_grp: group_data.notes_grp,
      note_no: group_data.notes_no,
      sub_grp: group_data.group,
    }

    if(selectedRow?.length > 0){
      // ? MULTI SELECT DRAG AND DROP
      const updatedData = [...selectedRow].map((data)=>{
        return {
          ...data,
          fs_grp: group_data.fs_grp,
          note_grp: group_data.notes_grp,
          note_no: group_data.notes_no,
          sub_grp: group_data.group
        }
      });
      dispatch(batchUpdateConventionalMode(conventionModeData.set_payload, updatedData));

    }else{
      let updatedData = {
        ...selectedRow,
      }

      if(checkYearly){
        updatedData = {...updatedData, map_data: {
          ...updatedData.map_data,
          [type+'_map']: {...mapData}
        }}
      }else{
        updatedData = {...updatedData, map_data: {
          ...updatedData.map_data,
          cy_map: {...mapData},
          py_map: {...mapData}
        }}
      }
      dispatch(updateConventionalMode(conventionModeData.set_payload, updatedData));
    }
  }


  if (TTGrouping.loading) {
    return (
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="grow" size="sm" />
        <Spinner animation="grow" style={{ margin: "1rem" }} />
        <Spinner animation="grow" size="sm" />
      </div>
    );
  }

  return (
    <div>
      <table>
        <thead>
          <tr style={{ backgroundColor: "#A2C0C2" }}>
            <th
              style={{
                width: "50%",
                height: "50px",
                textAlign: "left",
                paddingLeft: "1rem",
              }}
            >
              Particulars ({props.note_name})
            </th>
            <th style={{ width: "25%" }}>
              As at {reduxProjectData?.current_year && moment(reduxProjectData?.current_year).format("MMMM Do, YYYY")}
            </th>
            <th style={{ Width: "25%" }}>
              As at {reduxProjectData?.previous_year && moment(reduxProjectData?.previous_year).format("MMMM Do, YYYY")}
            </th>
          </tr>
        </thead>
        <tbody
          style={{
            background: `${theme ? "" : "#363535"}`,
            color: `${theme ? "" : "#fff"}`,
          }}
        >
          {projectGroupData.length > 0 && projectGroupData.map((fsGroup, idx)=>(
            <React.Fragment>
              <tr key={idx} style={level1}>
              <td  style={{
                      textAlign: "left",
                      paddingLeft: "1rem",
                    }}>{groupName[idx]}</td>
              <td ></td>
              <td></td>
             </tr>
            {fsGroup.map((group_data, index)=>(
              <>
              {group_data.hasOwnProperty('headings')
              && group_data?.headings.map((heading) => (
              <>
                {!heading.hasOwnProperty('type') && heading?.sub_grp_index === index && 
                <tr style={total}>
                  <td
                    style={{
                      width: "50%",
                      textAlign: "left",
                      paddingLeft: "1rem",
                      color: "black",
                    }}
                  >
                    {heading.heading}
                  </td>
                  {heading.hasOwnProperty("calculation") 
                  ?
                  (
                    <>
                    <td style={{
                      textAlign: "right",
                      paddingRight: "1rem",
                    }}>{
                      formatIndianCurrency(
                        calSubGrpHeading(fsGroup, heading.calculation.from, heading.calculation.to, 'cy_amt', heading.calculation.action)
                      )
                    }</td>
                    <td style={{
                      textAlign: "right",
                      paddingRight: "1rem",
                    }}>{
                      formatIndianCurrency(
                        calSubGrpHeading(fsGroup, heading.calculation.from, heading.calculation.to, 'py_amt', heading.calculation.action)
                      )
                    }</td>
                    </>
                  ):(
                    <>
                    <td></td>
                    <td></td>
                    </>
                  )
                  }
                </tr>
              }
              </>
              ))
              }
              <tr key={index}
                className={
                  highlight.row === index
                    ? "dragtemplate"
                    : ""
                }
                onDragEnter={() => {
                  setHighlight({ row: index });
                }}
            >
              <td
                  style={{
                    width: "50%",
                    textAlign: "left",
                    paddingLeft: "1rem",
                    color: "black",
                  }}
                >
                  {group_data.group}
                </td>
                <td style={{
                      textAlign: "right",
                      paddingRight: "1rem",
                      width: "25%"
                    }}
                  onDrop={() => {
                    handleDropping(group_data, 'cy');
                  }}
                >
                  {formatIndianCurrency(
                    group_data.cy_amt ? group_data.cy_amt : "-"
                  )}
                </td>
                <td style={{
                      textAlign: "right",
                      paddingRight: "1rem",
                      width: "25%"
                    }}
                  onDrop={() => {
                    handleDropping(group_data, 'py');
                  }}
                >
                  {formatIndianCurrency(
                    group_data.py_amt ? group_data.py_amt : "-"
                  )}
                </td>
             </tr>
             {group_data.hasOwnProperty('headings')
              && group_data?.headings.map((heading) => (
              <>
                {heading.hasOwnProperty('type') && 
                heading.type === 'greater_than' && heading?.sub_grp_index === index + 1 ?
                <tr style={total}>
                  <td
                    style={{
                      width: "50%",
                      textAlign: "left",
                      paddingLeft: "1rem",
                      color: "black",
                    }}
                  >
                    {heading.heading}
                  </td>
                  {heading.hasOwnProperty("calculation") 
                  ?
                  (
                    <>
                    <td style={{
                      textAlign: "right",
                      paddingRight: "1rem",
                    }}>{
                      formatIndianCurrency(
                        calSubGrpHeading(fsGroup, heading.calculation.from, heading.calculation.to, 'cy_amt', heading.calculation.action)
                      )
                    }</td>
                    <td style={{
                      textAlign: "right",
                      paddingRight: "1rem",
                    }}>{
                      formatIndianCurrency(
                        calSubGrpHeading(fsGroup, heading.calculation.from, heading.calculation.to, 'py_amt', heading.calculation.action)
                      )
                    }</td>
                    </>
                  ):(
                    <>
                    <td></td>
                    <td></td>
                    </>
                  )
                  }
                </tr>
                : ''
              }
              </>
              ))
              }
              </>
            ))}
            <tr style={total}>
              <td className="font-inter" style={{
                      textAlign: "left",
                      paddingLeft: "1rem",
                    }}>TOTAL</td>
              <td style={{
                      textAlign: "right",
                      paddingRight: "1rem",
                    }}>
                { formatIndianCurrency(fsGroup.reduce((num, acc)=> num + acc.cy_amt, 0)) }
              </td>
              <td  style={{
                      textAlign: "right",
                      paddingRight: "1rem",
                    }}>
                { formatIndianCurrency(fsGroup.reduce((num, acc)=> num + acc.py_amt, 0)) }
              </td>
            </tr>
            <tr>
              <td
                onClick={()=>handleAdd({
                  note_grp: fsGroup[idx]?.notes_grp || props.note_name,
                  fs_grp: groupName[idx],
                  notes_no: noteNumber
                })}
                style={{
                  width: "50%",
                  textAlign: "left",
                  fontWeight: "600",
                  paddingLeft: "1rem",
                  color: "var(--clr-accent)",
                  cursor: "pointer",
                }}
              >
                + Add sub group
              </td>
              <td style={{ width: "25%" }}></td>
              <td style={{ width: "25%" }}></td>
            </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Pattern2;
