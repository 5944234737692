
import React from 'react';
import { MatrixNav } from './MatrixNav';


const Matrix = () => {



    const styles = {
        header: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#005f56',
            color: 'white',
            padding: '10px'
        },
        headerLink: {
            color: 'white',
            textDecoration: 'none',
            marginRight: '10px'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            // marginTop: '20px'
        },
        thTd: {
            border: '1px solid #ddd',
            padding: '8px',
            textAlign: 'center'
        },
        th: {
            backgroundColor: '#f2f2f2'
        },
        viewTask: {
            color: '#008cba',
            cursor: 'pointer'
        }
    };

    return (
        <>
            <div>
                <MatrixNav />
                <div>
                    <table className="table table-striped" style={styles.table}>
                        <thead>
                            <tr style={{backgroundColor:"white"}}>
                                <th scope="col" className='text-left text-uppercase text-secondary font-size-16 custom-font-500 font-inter'  >S NO</th>
                                <th scope="col" className='text-left text-uppercase text-secondary font-size-16 custom-font-500 font-inter'  >Ledger Code</th>
                                <th scope="col" className='text-left text-uppercase text-secondary font-size-16 custom-font-500 font-inter'  >Ledger Name</th>
                                <th scope="col" className='text-left text-uppercase text-secondary font-size-16 custom-font-500 font-inter'  >Amount</th>
                                <th scope="col" className='text-left text-uppercase text-secondary font-size-16 custom-font-500 font-inter'  >Module 1</th>
                                <th scope="col"  colSpan={4} className='text-uppercase text-secondary font-size-16 custom-font-500 font-inter' >Module 2</th>
                                <th scope="col" className='text-left text-uppercase text-secondary font-size-16 custom-font-500 font-inter'  >Final ledger amount</th>
                            </tr>
                            <tr style={{backgroundColor:"#F8F9F9"}}>
                                <th colSpan={5}></th>
                                <th scope="col"  className=' text-uppercase text-secondary font-size-16 custom-font-500 font-inter' >Task 1</th>
                                <th scope="col" className=' text-uppercase text-secondary font-size-16 custom-font-500 font-inter'>Task 2</th>
                                <th scope="col" className=' text-uppercase text-secondary font-size-16 custom-font-500 font-inter'>Task 3</th>
                                <th scope="col" className=' text-uppercase text-secondary font-size-16 custom-font-500 font-inter'>Subtotal</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                           

                            <tr style={{backgroundColor:"white"}}>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>1</td>
                                <td style={styles.thTd}className=' font-size-16 custom-font-500'>A1</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>Petty Cash</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>₹ 1,600</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                     Demo Task<br />
                                    <span style={styles.viewTask} className='custom-common-theme-text-color font-size-16 custom-font-600'>View Task</span>
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                    Subtotal: F=C+D=E
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>A + Sum of all modules</td>
                            </tr>
                            <tr style={{backgroundColor:"white"}}>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>1</td>
                                <td style={styles.thTd}className=' font-size-16 custom-font-500'>A1</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>Petty Cash</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>₹ 1,600</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                     Demo Task<br />
                                    <span style={styles.viewTask} className='custom-common-theme-text-color font-size-16 custom-font-600'>View Task</span>
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                    Subtotal: F=C+D=E
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>A + Sum of all modules</td>
                            </tr>
                            <tr style={{backgroundColor:"white"}}>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>1</td>
                                <td style={styles.thTd}className=' font-size-16 custom-font-500'>A1</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>Petty Cash</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>₹ 1,600</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                     Demo Task<br />
                                    <span style={styles.viewTask} className='custom-common-theme-text-color font-size-16 custom-font-600'>View Task</span>
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                    Subtotal: F=C+D=E
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>A + Sum of all modules</td>
                            </tr>
                            <tr style={{backgroundColor:"white"}}>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>1</td>
                                <td style={styles.thTd}className=' font-size-16 custom-font-500'>A1</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>Petty Cash</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>₹ 1,600</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                     Demo Task<br />
                                    <span style={styles.viewTask} className='custom-common-theme-text-color font-size-16 custom-font-600'>View Task</span>
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                    Subtotal: F=C+D=E
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>A + Sum of all modules</td>
                            </tr>
                            <tr style={{backgroundColor:"white"}}>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>1</td>
                                <td style={styles.thTd}className=' font-size-16 custom-font-500'>A1</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>Petty Cash</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>₹ 1,600</td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                     Demo Task<br />
                                    <span style={styles.viewTask} className='custom-common-theme-text-color font-size-16 custom-font-600'>View Task</span>
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'></td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>
                                    Subtotal: F=C+D=E
                                </td>
                                <td style={styles.thTd} className=' font-size-16 custom-font-500'>A + Sum of all modules</td>
                            </tr>
                            
                          
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Matrix;

