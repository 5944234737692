import React, { useState, useEffect, useContext } from "react";
// import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Button } from "react-bootstrap";
import axios from "axios";
import { useHistory } from 'react-router-dom';

// import * as actionTypes from "../../redux/actionTypes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
// import { socket } from "../../services/socket/socket";
// import folder from "../../assets/folder.svg";
import {
  SplitPane,
  SplitPaneProps,
  ResizerOptions,
  CollapseOptions,
  SplitPaneHooks,
} from "react-collapse-pane";

// import { Navbar } from "../Components/Navbar";
import { Navbar } from "../../Components/Navbar";
// import './conventional.css'
// import switchModeSvg from "../../assets/switchMode.svg";
// import filterSvg from "../../assets/filter.svg";
// import trashSvg from "../../assets/trash.svg";
// import shareSvg from "../../assets/share.svg";
// import adjustmentSvg from "../../assets/adjustment.svg";
// import plusSvg from "../../assets/plus.svg";
// import { Submit } from "../../../Components/Button";

// import BasicTable from "../../Components/Table/BasicTable";
// import BasicTable from "../../../Components/Table/BasicTable";

// import DeleteSelected from "../../../Components/deleteSelected/DeleteSelected";

// import TemplateTabs from "../../../Components/templateTabs/TemplateTabs";
// import DeletedFile from "../../../Components/deletedItems/DeletedFile";
// import PopulateFs from "../../../Components/populateFs/PopulateFs";
// import ExpandLedger from "../../Components/expandLedger/ExpandLedger";
// import AddLedger from "../../Components/addLedger/AddLedger";
// import AddGrouping from "../../../Components/AddGrouping/AddGrouping";
// import Confirmation from "../../../Components/confirmationMenu/confirmationMenu";
// import ShareScreen from "../../../Components/shareScreen/ShareScreen";
// import SwitchMode from "../../../Components/switchMode/SwitchMode";
// import "./template.css";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import swal from "sweetalert";
import { Alert, Snackbar, Checkbox } from "@mui/material";
// import { AccessProjectContext } from "../../../helper/DarkModeContext";
// import DeleteDuplicate from "../../../Components/deletedItems/DeleteDuplicate";
import { red } from "@mui/material/colors";
// import AssignValuesData from "../../../PrivatePages/setupProject/AssignValuesData";
// import ClassificationPreview from "./ClassificationPreview";
// import {
//   ModalStyle, headStyle,
//   headerStyle,
//   centerStyle,
//   inputFiles,
//   stickyStyle,
// } from "../../../Components/Styles/templateStyle";

// import {
//   getProfile, deleteConventionalLineItems,
//   getAllProjects,
// } from "../../../services/api/apiHandler";
// import { deleteLineItems,fetchConventionalMode, } from "../../../redux/actions/TemplateTabs/actionCM";
// import { fetchTTGroupData , socketTTGroupUpdate,} from "../../../redux/actions/TemplateTabs/actionGrouping";

// import { toggleCheckYearly } from "../../../redux/actions/TemplateTabs/actionDragSelector";
import { useLocation } from "react-router";
// import { getAllWorkspace } from "../ApiServices/apiHandler";
// import CommonProgressBar from "../../Components/Common/ProgressBar";
// import CommonProgressBar from "../../../Components/Common/ProgressBar";
import ClassificationPreview from "./ClassificationPreview";

const Tbc = () => {

  const history = useHistory(); 
  return (
    <div style={{ maxHeight: "100vh" }}>
     {/* <ShareScreen  /> */}
   
        {/* <AddGrouping
          // setOtherGrouping={setOtherGrouping}
          // newSg={newSg}
          // setAddGrouping={setAddGrouping}
          // setRowData={setRowData}
          // rowData={rowData}
        /> */}
      
      
        {/* <AddNoteGroup
          // setAddNoteGrp={setAddNoteGrp}
          // setRowData={setRowData}
          // rowData={rowData}
        /> */}
   
       {/* <PopulateFs  />  */}
    
        {/* <DeletedFile
          // setDeleteMenu={setDeleteMenu}
          // select={select}
          // setUpdateRows={setUpdateRows}
        /> */}
  
      {/* {addLedger ? (
        <AddLedger close={setAddLedger} name="Create a new Ledger Item" />
      ) : null} */}
   
        {/* <SwitchMode
          // close={setSwitchMode}
          name="Choose a Mapping Mode"
          currentMode="2"
        /> */}
    
      {/* {expandLedger ? (
        <ExpandLedger
          close={setExpandLedger}
          name="Ledger Detail"
          select={select}
        />
      ) : null} */}
      {/* {addLedgerAbove ? (
        <AddLedger
          close={setAddLedgerAbove}
          name="Add Ledger Above"
          select={select}
        />
      ) : null} */}
      {/* {addLedgerBelow ? (
        <AddLedger
          close={setAddLedgerBelow}
          name="Add Ledger Below"
          select={select}
        />
      ) : null} */}
      {/* {confirmation ? (
        <Confirmation
          setConfirmation={setConfirmation}
          confirmationMenu={confirmationMenu}
          select={select}
        />
      ) : null} */}

    
        {/* <AssignValuesData
          // show={showAssignValues}
          // setShow={setAssignValues}
          // info={info}
          // index={index}
          // setName={setName}
          // Name={Name}
          // handleSubmit={handleSubmit}
          // headers={headerdata}
        /> */}
     
      <div style={{ maxHeight: "77vh", overflowY: "hidden" }}>
        <Navbar text="Financial Year: 2021 - 2022" />
        <div className="conventionalMenu" style={{ zIndex: "9" }}>
          
            <div
              className="delete"
              onClick={() => {
                // handleSelect("Delete Record");
              }}
            >
              {/* <img src={trashSvg} alt="trash" /> */}
              <p>Delete record</p>
            </div>
         
          <div
            className="switchMode"
            onClick={() => {
              // setSwitchMode(!showSwitchMode);
            }}
          >
            {/* <img src={switchModeSvg} /> */}
            <p>Switch Mode</p>
          </div>
          <div
            className="filter"
            onClick={() => {
              // setFilterShow(!filterShow);
            }}
          >
            {/* <img src={filterSvg} /> */}
            <p>Filter</p>
          </div>
          <div className="hideItem">
            {/* <i class={hideItems ? "fas fa-eye" : "far fa-eye-slash"}></i> */}
            {/* <p>{hideItems ? "Show grouped items" : "Hide grouped items"}</p> */}
          </div>
         
            <div className="deletedItem" style={{ cursor: "pointer" }}>
              {/* <img src={trashSvg} /> */}
              <p>Deleted items</p>
            </div>
         
            <div
              className="deletedItem"
              style={{ cursor: "pointer" }}
              onClick={() => {
                // setDeleteMenu(true);
              }}
            >
              {/* <img src={trashSvg} /> */}
              <p>Deleted items</p>
            </div>
        
         
            <div className="adjustment">
              {/* <img src={adjustmentSvg} /> */}
              <p>Adjustment</p>
            </div>
          
            <div
              className="adjustment"
              onClick={() => {
                // history.push("/adjustments");
              }}
            >
              {/* <img src={adjustmentSvg} /> */}
              <p>Adjustment</p>
            </div>
       
          {/* {AccessProject() === "view" ? (
            <div className="share">
              <img src={shareSvg} />
              <p>Share</p>
            </div>
          ) : (
            <div
              className="share"
              onClick={() => {
                SetShareScreen(true);
              }}
            >
              <img src={shareSvg} />
              <p>Share</p>
            </div>
          )} */}

          <div className="checkYearly" >
            <Checkbox
              label={"Split mapping"}
              sx={{
                '&.Mui-checked': {
                  color: '#03565a',
                }
              }}
              // checked={checkYearly}
              onChange={(e) => {
                console.log("checkbox", e.target.checked);
                // dispatch(toggleCheckYearly(e.target.checked));
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <p>Split mapping</p>
          </div>
          <div className="uploadConventional">
            <div className="addTb" >
              {/* <img src={plusSvg} /> */}
              <h4>Upload trial balance</h4>
            </div>
            {/* <div className="populateTemplate">
              <Submit sm value="Populate FS Template" onClick={() => history.push('/classification-preview')} />
            </div> */}
            <div>
            <Button className="custom-common-theme-bg-color text-white font-size-16 custom-font-600 font-sans" variant="contained" onClick={() => history.push('/classification-preview')} style={{width:"200px",borderRadius:"5px"}}>Confirm Grouping</Button>

            </div>
          </div>
        </div>

       
       
        <div>
          
          <div
            className="tabsinside"
            style={{ width: "100%", maxHeight: "77vh", overflow: "hidden" }}
          >
            <SplitPane
              minSizes={[600, 300]}
              initialSizes={[60, 40]}
              split="vertical"
              collapseOptions={{
                beforeToggleButton: <Button>⬅</Button>,
                afterToggleButton: <Button>➡</Button>,
                overlayCss: { backgroundColor: "black" },
                buttonTransition: "zoom",
                buttonPositionOffset: -20,
                collapsedSize: 50,
                collapseTransitionTimeout: 350,
                // minSizes:[2,1],
              }}
              resizerOptions={{
                css: {
                  width: "5px",
                  background: "#03565ade",
                },
                hoverCss: {
                  width: "5px",
                  background: "4px solid rgba(0, 0, 0)",
                },
                grabberSize: "5px",
              }}
            >
              <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ overflow: "scroll", height: "96%" }}>
                
                    
                
                </div>
                {/* <CommonProgressBar /> */}
              </div>

              <div style={{ maxHeight: "77vh", overflowY: "scroll" }}>
                <div
                  style={{ minHeight: "77vh" }}
                  onDragEnter={() => {
                    // setHighlighted('#e9ebf0')
                  }}
                  onDragLeave={() => {
                    // setHighlighted('')
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                >
                  {/* <TemplateTabs
                    // SetNewSg={SetNewSg}
                    // setAddGrouping={setAddGrouping}
                    // updateTabs={updateTabs}
                    // setAddNoteGrp={setAddNoteGrp}
                  /> */}
                </div>
              </div>
            </SplitPane>
          </div>
        </div>

        {/* <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={response?.cy_balance !== 0 || response?.py_balance !== 0 || duplicateItems.length > 0}
        > */}
          <Alert
            severity="warning"
            sx={{ cursor: "pointer", padding: "6px 0px 6px 14px" }}
            // onClick={() => setDuplicateModal(true)}
          ></Alert>
        {/* </Snackbar> */}
      </div> 
    </div>
  );
};
export default Tbc;
