import React from "react";
import { Navbar } from "../../../../Components/Navbar";
import Button from "@mui/material/Button";
import "./AddYourTeam.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SidebarTeam";
import SidebarTeam from "./SidebarTeam";
import Container from "./Container";
import { Card } from "@mui/material";
const AddYourTeam = () => {
  return (
    <>
      <Navbar/>
      <div className="d-flex">
        <div>
          <SidebarTeam />
        </div>
        <Card className="w-100 ml-3 mt-2 ">
          <div>
            <Container />
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddYourTeam;
