/* eslint-disable no-lone-blocks */
/*
  This is being used in Populate FS
*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { motion } from "framer-motion";
import "./balanceSheet.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { fetchTTBSData } from "../../redux/actions/TemplateTabs/actionBS";
import Loading from "../Common/Loader/Loading";
import { BSTableDND } from "../TableDND/Table";
import { bsPnlReferenceCheck } from "../../services/api/apiHandler";
import swal from 'sweetalert';

const BalanceSheet = ({ projectData, fromScoie, scrollToTop }) => {
  const dispatch = useDispatch();

  const [footerData, setFooterData] = useState("");

  const tb_id = localStorage.getItem("tb_id");
  const project_id = localStorage.getItem("project_id");

  const { loading, isSuccess, response, error_message, set_payload } = useSelector((state) => state.TempTabBSReducer)
  const rows = response?.financial_statement ?? [];
  const fsRows = Object.keys(rows);

  const fetchBalanceSheetData = async () => {
    if (!isSuccess || set_payload !== JSON.stringify({ project_id, tb_id })) {
      dispatch(fetchTTBSData(project_id, tb_id))
    }
  }

  useEffect(() => {
    (async () => {
      await fetchBalanceSheetData();
    })()
  }, [])

  // const [clicked, setClicked] = useState(false);

  const handleFilterNote = (noteNo) => {
    dispatch({ type: "NOTE_KEY", payload: 5 });
    dispatch({ type: "NOTE_BS_NUMBER", payload: noteNo });
    scrollToTop();
    if (noteNo === 1) {
      window.scrollTo(5000, 50000);
    }
  }



  function handleFooter(e, editor) {
    const data = editor.getData();
    saveFooter(data);
  }

  const saveFooter = (val) => {
    const auth = localStorage.getItem("auth_token");
    const project_id = localStorage.getItem("project_id");
    let headers = {
      "x-auth-token": auth,
    };
    let data = {
      name: "balancesheet",
      footer_name: val,
    };
    axios
      .post(`/api/v1/pnl/save-footer/${project_id}`, data, { headers })
      .then((response) => {

      })
      .catch((error) => {
        console.log(error.response, "error");
      });
  };


  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  const TotalNoteGroupsValue = (group, type) => {
    try {
      if (!["cy", "py"].includes(type)) return 0;
      return response.financial_statement[group].reduce((num, acc) => num + acc[type], 0);
    } catch (err) {
      console.log("err total notegrp", err);
      return 0
    }

  }

  const HandleReference = (event, actionKey, key, index) => {
    const data = {
      value : event.target.value,
      actionKey,
      key,
      index
    }
    bsPnlReferenceCheck(project_id, tb_id, data).then(res => {
      dispatch({
        type: "TT_BS_FETCH_DATA_FINANCIAL_STATEMENT",
        payload: {data: res.data, set_payload: JSON.stringify({project_id, tb_id})}
      })
    }).catch(error => {
      swal("Error", error.response?.data?.error || "Balance Sheet calculation failed !" , "error");
    })
  }

  return (
    <>
      {
        loading ? <Loading /> : (
          <>
            <motion.div
              className="tableSheet"
              initial={{ y: "-5vh" }}
              animate={{ y: 0 }}
            >
              <BSTableDND 
                headerData={['','Particulars', 'Notes', `As at ${moment(projectData.current_year).format("DD MMMM YYYY")}`, `As at ${moment(projectData.previous_year).format("DD MMMM YYYY")}`, 'References', 'IND AS References', 'Checklist References']}
                groupData = {fsRows}
                tableData={rows}
                handleClick={handleFilterNote}
                TotalNoteGroupsValue={TotalNoteGroupsValue}
                companyType={response.company_type}
                HandleReference={HandleReference}
              />
            </motion.div>
            <div style={{ marginTop: "20px" }}>
              <CKEditor
                style={{ minHeight: "70vh", zIndex: "0" }}
                editor={ClassicEditor}
                onReady={(editor) => { }}
                onChange={(e, editor) => {
                  handleFooter(e, editor);
                }}
              />
              <h4 style={{ marginLeft: "2rem" }}>
                <strong>
                  {removeTags(footerData)}
                </strong>
              </h4>
            </div>
          </>
        )
      }
    </>
  );
};

export default BalanceSheet;