import React from 'react'
import CommonInputCell from '../Common/CommonInputCell';
import { formatIndianCurrency } from '../../helper/ConvertValue';
import ErrorIcon from "@mui/icons-material/Error";
import CommonToolTip from '../Common/CommonToolTip';
import { useSelector } from 'react-redux';

const SocieTableBody = ({type, data, HandleChange, HandleReference, SwitchReferenceTab}) => {
    const { isSuccess, response, error_message, loading, set_payload } = useSelector((state) => state.TempTabPNLReducer);

    let name = "Current Period"
    let balanceBeginning = {}
    if(type === "previous_row_header"){
        balanceBeginning = data[9]
        name = "Previous Period"
        data = data.slice(0, 9);
    }

    const addition = (data) => Object.values(data).reduce((acc, val) => typeof val === 'number' && isFinite(val) ? acc + val : acc, 0);

    const calculationByIndex = (data, start, end) => {
        const slicedArray = data.slice(start, end);
    
        const totals = slicedArray.reduce((acc, obj) => {
            if (obj && typeof obj === 'object') {
                for (const key in obj) {
                    if (key !== "row_header" && typeof obj[key] === 'number') {
                        acc[key] = (acc[key] || 0) + obj[key];
                    }
                }
            }
            return acc;
        }, {});
    
        return totals;
    };

    const grandTotal = (data, start, end) => {
        const slicedArray = data.slice(start, end);
        let total = 0;
        slicedArray.forEach((row) => {
            if (row && typeof row === 'object') {
                Object.values(row).forEach((value) => {
                    if (typeof value === 'number' && !isNaN(value)) {
                        total += value;
                    }
                });
            }
        });
        return formatIndianCurrency(total);
    };

    const checkCondition = (data, key) => {
        const slicedArray = data.filter((ele, i) => ![2,3].includes(i));
        const totals = slicedArray.reduce((acc, obj) => {
            if (obj && typeof obj === 'object') {
                for (const key in obj) {
                    if (key !== "row_header" && typeof obj[key] === 'number') {
                        acc[key] = (acc[key] || 0) + obj[key];
                    }
                }
            }
            return acc;
        }, {});

        return (totals[key] !== (type === "previous_row_header" ? balanceBeginning[key] : data[0][key])) && 
        <CommonToolTip
            variant="primary"
            title={"The total value is not matching with the 3rd level mapping value." }
            placement="top"
        >
            <ErrorIcon
                style={{
                    color: "darkorange",
                    cursor: "pointer",
                }}
                className="financialInstrument-hoverIcon"
            />
        </CommonToolTip> 
    }

    const checkConditionOtherCompressiveIncome = (total) => {
        const pnl_type = type === "current_row_header" ? "cy" : "py"
        const otherCompressiveIncome = response?.pnl_statement?.["Other Comprehensive Income"]?.reduce((num, acc) => num + acc[pnl_type], 0) ?? "";

        return total !== otherCompressiveIncome && 
        <div className='d-flex align-items-center justify-content-center'>
            <CommonToolTip
                variant="primary"
                title={"The total value is not matching with the 3rd level mapping value." }
                placement="top"
            >
                <ErrorIcon
                    style={{
                        color: "darkorange",
                        cursor: "pointer",
                    }}
                    className="financialInstrument-hoverIcon"
                />
            </CommonToolTip>
        </div>
    }

    return (
        <>
            <p className='pl-3 custom-common-theme-text-color font-size-16 custom-font-600 font-sans'>{name}</p>
            <tbody>
                {
                    data?.map((element,ix, originalArray) => (
                        <React.Fragment key={ix}>
                            <tr key={ix} >
                                <td className='text-left pl-3 font-size-16 custom-font-500 font-sans'> {element?.row_header} </td>
                                <td>
                                    <CommonInputCell 
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Share application money pending allotment"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Share application money pending allotment"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell 
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Equity component of compounded financial instruments"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Equity component of compounded financial instruments"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Capital reserve"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Capital reserve"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Securities premium"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Securities premium"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell 
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Other reserves"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Other reserves"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell 
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2].includes(ix) && "#ffddf6"}`}}
                                        name={"Retained Earnings"}
                                        type={"text"}
                                        readOnly={[2].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Retained Earnings"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell 
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Debt instruments through Other Comprehensive Income"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Debt instruments through Other Comprehensive Income"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Equity Instruments through Other Comprehensive Income"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Equity Instruments through Other Comprehensive Income"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell 
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Effective portion of Cash Flow Hedges"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Effective portion of Cash Flow Hedges"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Revaluation Surplus"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Revaluation Surplus"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Exchange differences on translating the financial statements of a foreign operation"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Exchange differences on translating the financial statements of a foreign operation"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td>
                                    <CommonInputCell
                                        className={"m-0 rounded-0 text-right pr-3"}
                                        style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}}
                                        name={"Money received against share warrants"}
                                        type={"text"}
                                        readOnly={[2, 3].includes(ix) || (type !== "previous_row_header" && ix === 0)}
                                        value={formatIndianCurrency(element["Money received against share warrants"])}
                                        onChange={(event) => HandleChange(event, type, ix)}
                                    />
                                </td>
                                <td style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}} className='text-right pr-3'> 
                                    {formatIndianCurrency(addition(element))}
                                    {
                                        ix === 3 && checkConditionOtherCompressiveIncome(addition(element))
                                    }
                                </td>
                                <td 
                                    style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}} 
                                    className={element?.refData?.isReference ? "hyper-link-ref cursor-pointer" : ""}
                                    onClick={() => SwitchReferenceTab(element)}
                                >
                                    {element?.refData?.reference}
                                </td>
                                <td style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}} >
                                    <input 
                                        type="checkbox" 
                                        value={"ind_as_reference"}
                                        disabled={!element?.refData?.reference}
                                        onChange={(e) => HandleReference(e, type, ix)}
                                        checked={element?.refData?.isReference === "ind_as_reference"}
                                    />
                                </td>
                                <td style={{backgroundColor:`${[2,3].includes(ix) && "#ffddf6"}`}} >
                                    <input
                                        type="checkbox" 
                                        value={"checklist_reference"}
                                        disabled={!element?.refData?.reference}
                                        onChange={(e) => HandleReference(e, type, ix)}
                                        checked={element?.refData?.isReference === "checklist_reference"}
                                    /> 
                                </td>
                            </tr>
                            {
                                [1,3,8].includes(ix) && 
                                <>
                                <tr style={{backgroundColor:"#c8ffdb"}}>
                                    <td className='text-left pl-3 font-size-16 custom-font-600 bg-white font-sans'>
                                        {
                                            ix === 1 && `Restated balance at the beginning of the ${type === "previous_row_header" ? "previous" : "current"} reporting period`
                                        }
                                        {
                                            ix === 3 && `Total Comprehensive Income for the ${type === "previous_row_header" ? "previous" : "current"} year`
                                        }
                                        {
                                            ix === 8 && "Total"
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Share application money pending allotment"])
                                        }
                                        {
                                            ix === 3 && "-"
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Share application money pending allotment"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Equity component of compounded financial instruments"])
                                        }
                                        {
                                            ix === 3 && "-"
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Equity component of compounded financial instruments"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Capital reserve"])
                                        }
                                        {
                                            ix === 3 && "-"
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Capital reserve"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Securities premium"])
                                        }
                                        {
                                            ix === 3 && "-"
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Securities premium"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Other reserves"])
                                        }
                                        {
                                            ix === 3 && "-"
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Other reserves"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Retained Earnings"])
                                        }
                                        {
                                            ix === 3 && formatIndianCurrency(calculationByIndex(originalArray, 2, 4)["Retained Earnings"])
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Retained Earnings"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Debt instruments through Other Comprehensive Income"])
                                        }
                                        {
                                            ix === 3 && formatIndianCurrency(calculationByIndex(originalArray, 2, 4)["Debt instruments through Other Comprehensive Income"])
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Debt instruments through Other Comprehensive Income"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Equity Instruments through Other Comprehensive Income"])
                                        }
                                        {
                                            ix === 3 && formatIndianCurrency(calculationByIndex(originalArray, 2, 4)["Equity Instruments through Other Comprehensive Income"])
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Equity Instruments through Other Comprehensive Income"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Effective portion of Cash Flow Hedges"])
                                        }
                                        {
                                            ix === 3 && formatIndianCurrency(calculationByIndex(originalArray, 2, 4)["Effective portion of Cash Flow Hedges"])
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Effective portion of Cash Flow Hedges"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Revaluation Surplus"])
                                        }
                                        {
                                            ix === 3 && formatIndianCurrency(calculationByIndex(originalArray, 2, 4)["Revaluation Surplus"])
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Revaluation Surplus"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Exchange differences on translating the financial statements of a foreign operation"])
                                        }
                                        {
                                            ix === 3 && formatIndianCurrency(calculationByIndex(originalArray, 2, 4)["Exchange differences on translating the financial statements of a foreign operation"])
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Exchange differences on translating the financial statements of a foreign operation"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && formatIndianCurrency(calculationByIndex(originalArray, 0, 2)["Money received against share warrants"])
                                        }
                                        {
                                            ix === 3 && formatIndianCurrency(calculationByIndex(originalArray, 2, 4)["Money received against share warrants"])
                                        }
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 4, 9)["Money received against share warrants"])
                                        }
                                    </td>
                                    <td className='text-right pr-3'>
                                        {
                                            ix === 1 && grandTotal(originalArray, 0, 2)
                                        }
                                        {
                                            ix === 3 && grandTotal(originalArray, 2, 4)
                                        }
                                        {
                                            ix === 8 && grandTotal(originalArray, 4, 9)
                                        }
                                    </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                { ix === 8 &&
                                    <tr style={{backgroundColor:"#ddddff"}}>
                                        <td className='text-left pl-3 font-size-16 custom-font-600 bg-white font-sans'>
                                            { `Balance at the end of the ${type === "previous_row_header" ? "previous" : "current"} reporting period `}
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Share application money pending allotment"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Equity component of compounded financial instruments"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Capital reserve"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Securities premium"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Other reserves"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Retained Earnings"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Debt instruments through Other Comprehensive Income"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Equity Instruments through Other Comprehensive Income"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Effective portion of Cash Flow Hedges"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Revaluation Surplus"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Exchange differences on translating the financial statements of a foreign operation"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && formatIndianCurrency(calculationByIndex(originalArray, 0, 10)["Money received against share warrants"])
                                        }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {
                                            ix === 8 && grandTotal(originalArray, 0, 10)
                                        }
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }
                                { ix === 8 &&
                                    <tr>
                                        <td></td>
                                        <td className='text-right pr-3'> 
                                        {checkCondition(originalArray, "Share application money pending allotment") } 
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Equity component of compounded financial instruments") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Capital reserve") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Securities premium") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Other reserves") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Retained Earnings") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Debt instruments through Other Comprehensive Income") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Equity Instruments through Other Comprehensive Income") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Effective portion of Cash Flow Hedges") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Revaluation Surplus") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Exchange differences on translating the financial statements of a foreign operation") }
                                        </td>
                                        <td className='text-right pr-3'>
                                        {checkCondition(originalArray, "Money received against share warrants") }
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }
                                </>
                            }
                        </React.Fragment>
                    ))
                }
            </tbody>
        </>
    )
}

export default SocieTableBody