import axios from 'axios';
const auth = localStorage.getItem("auth_token");

const headers = {
    "x-auth-token": auth,
};

export const getAllProjects = async () => {
    return axios.get(`/api/v1/tb-onboarding/get-all-projects`,{
        headers
    })
}

export const getProfile = async () => {
    return axios.get(`/api/v1/auth/get-middleware-user`, {
        headers
    })
}

export const getBalanceSheet = async (project_id, tb_id) => {
    return axios.get(`/api/v1/bs/generate-bs/${project_id}/${tb_id}`,{
        headers
    })
}

export const getPNLData = async (project_id, tb_id) => {
    return axios.get(`/api/v1/pnl/generate-pnl/${project_id}/${tb_id}`,{
        headers
    })
}

export const getConventionalMode = async (project_id, tb_id) => {
    return axios.get(`/api/v1/conventional-mode/fetch/${project_id}/${tb_id}`,{
        headers
    })
}

export const mapConventionalMode = async (project_id, tb_id, updateData) => {
    headers["Content-Type"] = "application/json";
    return axios.put(`/api/v1/conventional-mode/map-update/${project_id}/${tb_id}`, updateData, {
        headers
    })
}

export const mapBatchConventionalMode = async (project_id, tb_id, updateData) => {
    headers["Content-Type"] = "application/json";
    return axios.put(`/api/v1/conventional-mode/map-batch-update/${project_id}/${tb_id}`, updateData, {
        headers
    })
}

export const deleteConventionalLineItems = async (project_id, deletedItems) => {
    headers["Content-Type"] = "application/json";
    return axios.delete(`/api/v1/conventional-mode/delete/${project_id}`, {
        headers: headers,
        data: deletedItems
    })
}

export const postSaveGroup = async (project_id, group) => {
    const formData = new FormData();
    formData.append("grp", JSON.stringify(group.grp));
    return axios.post(`/api/v1/grouping/save-grp/${project_id}`, formData ,{
        headers
    })
}


export const uploadColumnName = async (project_id, temp_id) => {
    return axios.get(`/api/v1/tb-mapping/upload-column-name/${project_id}/${temp_id}`,{
        headers
    })
}

export const getGroupingData = async (project_id) => {
    return axios.get(`/api/v1/grouping/fs/${project_id}`, {headers})
}

export const getMapTBGroupingData = async (project_id, tb_id) => {
    return axios.get(`/api/v1/grouping/map-tb-grouping/${project_id}/${tb_id}`, {headers})
}

export const getMapTBBalanceSheet = async (project_id, tb_id, bsQuery) => {
    return axios.get(`/api/v1/bs/balance-sheet/${project_id}/${tb_id}?bs=${bsQuery}`, {headers})
}

export const getMapTbPNLStatement = async (project_id, tb_id) => {
    return axios.get(`/api/v1/pnl/pnl-statement/${project_id}/${tb_id}`, {headers})
}

export const fetchDeletedLineItems = async (project_id, tb_id) => {
    return await axios.get(`/api/v1/conventional-mode/fetch-deleted-items/${project_id}/${tb_id}`, {
        headers
    })
}

export const restoreDeletedLineItems = async (project_id, row) => {
    return await axios.get(`api/v1/conventional-mode/recover-line-item/${project_id}/${row.tb_id}/${row.lid}`, {
        headers
    })
}

export const restoreDeletedAllLineItems = async (project_id, tb_id, data) => {
    return await axios.put(`api/v1/conventional-mode/recover-all-line-item/${project_id}/${tb_id}`, data, { headers })
}

export const addNoteGroup = async (project_id, newNoteData) => {
    headers["Content-Type"] = "application/json";
    return await axios.post(`/api/v1/grouping/add-note-grp/${project_id}/`, newNoteData, {
        headers
    })
}

export const getNotesIndex = async (project_id) => {
    return await axios.get(`/api/v1/grouping/fetch-notes-no/${project_id}/`,{
        headers
    })
}

export const getNotesData = async (project_id, tb_id, params_data) => {
    const { fs_grp, notes_no } = params_data;
    return await axios.get(`/api/v1/notes/get-notes-data/${project_id}/${tb_id}?fs_grp=${fs_grp}&notes_no=${notes_no}`,{
        headers
    })
}

export const updateDisclosure = async (notes_id, sub_grp_id, did, cy_amt, py_amt, fs_grp) => {
    let data = JSON.stringify({
        "targetId": notes_id,
        "subGroupId": sub_grp_id,
        "disclosureDidToUpdate": did,
        "cyAmtToUpdate": cy_amt,
        "pyAmtToUpdate": py_amt,
        "fs_grp": fs_grp
    });
    headers["Content-Type"] = "application/json";
    return await axios.put(`/api/v1/notes/update-disclosure/`, data, {
        headers,
    })
}

export const userInvestAccess = async (data) => {
    headers["Content-Type"] = "application/json";
    return await axios.post(`api/v1/auth/userAcccessIr/`, data, {
        headers
    })
}

export const createAllBalanceNote = async (project_id, tb_id) => {
    return await axios.post(`api/v1/notes/create-all-bs-notes/${project_id}/${tb_id}`,{}, { headers })
}

export const getSocieData = async (project_id, tb_id) => {
    return axios.get(`/api/v1/socie/fetch-socie/${project_id}/${tb_id}`,{
        headers
    })
}

export const updateSocieReference = async (project_id, tb_id, data) => {
    return axios.put(`/api/v1/socie/reference-mapping/${project_id}/${tb_id}`, data, {
        headers
    })
}

export const getDisclosureData = async (project_id, tb_id) => {
    return axios.get(`/api/v1/disclosure/${project_id}/${tb_id}`,{
        headers
    })
}

export const getBSNotesData = async (pid, tb_id) => {
    return axios.get(`/api/v1/notes/get-bs-notes-data/${pid}/${tb_id}`, {
        headers
    })
}

export const getPNLNotesData = async (pid, tb_id) => {
    return axios.get(`/api/v1/notes/get-pnl-notes-data/${pid}/${tb_id}`, {
        headers
    })
}
// ? cashflow APIS
export const getCashflowData = async (pid, tb_id) => {
    return axios.get(`/api/v1/cashflow/${pid}/${tb_id}`, {
        headers
    })
}

export const cashflowAddNewLineItem = async (data) => {
    return await axios.put(`api/v1/cashflow/new-line-item`, data, { headers })
}

export const getLinkRegister = async (project_id, tb_id) => {
    return axios.get(`/api/v1/link-register/${project_id}/${tb_id}`,{
        headers
    })
}
export const createNewTableRowLinkRegister = async (project_id, tb_id, data) => {
    return await axios.post(`api/v1/link-register/new-table-row/${project_id}/${tb_id}`, data, { headers })
}
export const dropValueMappingLinkRegister = async (project_id, tb_id, data) => {
    return await axios.post(`api/v1/link-register/drop-value-mapping/${project_id}/${tb_id}`, data, { headers })
}
export const onchangeMappingLinkRegister = async (project_id, tb_id, data) => {
    return await axios.put(`api/v1/link-register/onchange-mapping/${project_id}/${tb_id}`, data, { headers })
}
export const mappingLinkRegister = async (project_id, tb_id, index, key) => {
    return await axios.get(`api/v1/link-register/complete-mapping/${project_id}/${tb_id}?index=${index}&key=${key}`, { headers })
}

export const getDisclosureChecklist = async (project_id, tb_id) => {
    return axios.get(`api/v1/disclouser-checklist/${project_id}/${tb_id}`,{
        headers
    })
}

export const getGeneralNotes = async (project_id) => {
    return axios.get(`api/v1/general-notes/${project_id}`, {headers})
}

export const getLeaseTagAssets = async (project_id, tb_id, index) => {
    return await axios.get(`api/v1/link-register/get-lease-assets/${project_id}/${tb_id}?leaseIndex=${index}`, { headers })
}

export const addLeaseTagAssets = async (project_id, tb_id, index) => {
    return await axios.get(`api/v1/link-register/add-lease-asset/${project_id}/${tb_id}?leaseIndex=${index}`, { headers })
}

export const dropLeaseTagAssets = async (project_id, tb_id, data) => {
    return await axios.post(`api/v1/link-register/drop-lease-asset/${project_id}/${tb_id}`, data, { headers })
}
export const removeLeaseTagAssetItem = async (project_id, tb_id, data) => {
    return await axios.put(`api/v1/link-register/remove-lease-asset-item/${project_id}/${tb_id}`, data, { headers })
}

export const deleteLeaseTagAssets = async (project_id, tb_id, index) => {
    return await axios.get(`api/v1/link-register/delete-lease-asset/${project_id}/${tb_id}?leaseIndex=${index}`, { headers })
}

export const bsPnlReferenceCheck = async (project_id, tb_id, data) => {
    return await axios.put(`api/v1/bs/reference-mapping/${project_id}/${tb_id}`, data, { headers })
}

// ? @adjustment Apis
export const getAllAdjustments = async (project_id, tb_id) => {
    return await axios.get(`api/v1/adjustments/fetch-adjustments/${project_id}/${tb_id}`, { headers })
}
export const getLedger = async (project_id, tb_id) => {
    return await axios.get(`api/v1/adjustments/fetch-ledger/${project_id}/${tb_id}`, { headers });
}
export const deleteAdjustment = async (project_id, tb_id, data) => {
    return await axios.put(`api/v1/adjustments/delete-adjustment-line-item/${project_id}/${tb_id}`, data, { headers });
}
export const getDeletedAdjustments = async (project_id, tb_id) => {
    return await axios.get(`api/v1/adjustments/fetch-deleted-adjustments/${project_id}/${tb_id}`, { headers })
}
export const restoreAdjustment = async (project_id, tb_id, data) => {
    return await axios.put(`api/v1/adjustments/restore-delete-adjustment/${project_id}/${tb_id}`, data, { headers });
}
export const getAdjustmentLogs = async (project_id, tb_id) => {
    return await axios.get(`api/v1/adjustments/fetch-adjustment-logs/${project_id}/${tb_id}`, { headers });
}

// ? @payable and @receivable Apis
export const getPayable = async () => {
    return await axios.get(`api/v1/link-register/get-payable`, { headers })
}
export const getReceivable = async () => {
    return await axios.get(`api/v1/link-register/get-receivable`, { headers });
}

export const softDeleteNotesData = async (project_id, data) => {
    return await axios.post(`api/v1/grouping/soft-delete/${project_id}`, data, { headers })
}

export const getSoftDeletedNotes = async (project_id) => {
    return await axios.get(`api/v1/grouping/soft-delete/${project_id}`, { headers })
}

export const restoreSoftDeletedNotes = async (project_id, data) => {
    return await axios.post(`api/v1/grouping/restore-soft-delete/${project_id}`, data, { headers })
}

// ? super admin ticket
export const ticketImageUpload = async ( data ) => {
    return await axios.patch(`api/v1/superadmin-ticket/upload-more-image`, data, { headers })
}
export const removeSupportImage = async ( data ) => {
    return await axios.patch(`api/v1/superadmin-ticket/remove-ticket-image`, data, { headers })
}
export const updateSupportTicket = async ( data ) => {
    return await axios.patch(`api/v1/superadmin-ticket/update-ticket`, data, { headers })
}