import React from 'react';
import BookcluosureNav from './BookcluosureNav';
import Button from '@mui/material/Button';



const BookClosureEntries = () => {

    return (
        <>
            <div>
                <BookcluosureNav />
                <div>
                    <table>
                        <thead style={{ backgroundColor: "#E5E7E9" }}>
                            <tr>
                                <th className='text-right text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>S NO</th>
                                <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Ledger Code</th>
                                <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Ledger Name</th>
                                <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Amount</th>
                                <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Preparer</th>
                                <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Approver</th>
                                <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>remarks</th>
                                <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Modules</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={2}>1</td>
                                <td className='text-left'><div className='ml-3 font-size-14 'style={{fontWeight:"400px"}}>10012ASA </div></td>
                                <td className='text-left'><div className='ml-3 font-size-14 'style={{fontWeight:"400px"}}>Trade Receivable</div></td>
                                <td className='text-left'><div className='ml-3 font-size-14 'style={{fontWeight:"400px"}}>45,000</div></td>
                                <td className='text-left'><div className='ml-3 font-size-14 'style={{fontWeight:"400px"}}>Krishna</div></td>
                                <td className='text-left' > <div className='ml-3 font-size-14 'style={{fontWeight:"400px"}}>Mohan</div></td>
                                <td className='text-left'><div className='ml-3 font-size-14 'style={{fontWeight:"400px"}}></div></td>
                                <td rowSpan={2} className="text-center align-middle">
                                    <div className="d-flex p-3 justify-content-center align-items-center  w-100">
                                        <p className="mt-0 mb-0 font-size-16 " style={{fontWeight:"400px"}}>Fixed assets</p>
                                        <p className="mt-0 mb-0 ml-2 font-size-16 custom-font-600 custom-common-theme-text-color">View</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-left' ><div className='ml-3  font-size-14' style={{fontWeight:"400px"}}></div></td>
                                <td className='text-left' ><div className='ml-3  font-size-14' style={{fontWeight:"400px"}}>Select ledger name</div></td>
                                <td className='text-left' ><div className='ml-3  font-size-14' style={{fontWeight:"400px"}}></div></td>
                                <td className='text-left' ><div className='ml-3  font-size-14' style={{fontWeight:"400px"}}></div></td>
                                <td className='text-left' ><div className='ml-3  font-size-14' style={{fontWeight:"400px"}}></div></td>
                                <td className='text-left' ><div className='ml-3  font-size-14' style={{fontWeight:"400px"}}></div></td>
                            </tr>
                            <tr>
                                <td colSpan={8} style={{ backgroundColor: "#F1F2F5" }}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='d-flex justify-content-center align-items-center' style={{height:"60vh"}}>
                    <Button variant="contained" className='custom-common-theme-bg-color text-capitalize font-size-16 custom-font-600 font-sans text-white' style={{width:"200px",height:"55px",borderRadius:"7px"}}>Save as preset</Button>
                </div>
            </div>
        </>
    );
}

export default BookClosureEntries;
