import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { getCompany } from "../ApiServices/apiHandler";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";

const EditInvestmentRegister = (props) => {
  const {
    registerdata,
    hidediv,
    name,
    totaldata,
    holding,
    interest,
    notInterest,
    natureinvest,
    browsproject,
    handleOpen1,
    handleSubmit,
    entryOpen,
    browseFs,
    hideDiv,
    handleChange,
    path,
  } = props;
  const [allCompany, setAllCompany] = useState([]);
  useEffect(() => {
    GetCompany();
  }, []);

  const GetCompany = async () => {
    const res = await getCompany();
    if (res.status === 200) {
      console.log(res.data.getCompany);
      setAllCompany(res.data.getCompany);
    }
  };

  const companyCode =(c_id)=>{
    const nameOfType = allCompany.filter((adj) => adj._id === c_id);
    console.log(nameOfType,"nameOfType");
    const result = nameOfType[0]?.company_code
      ? nameOfType[0]?.company_code
      : "NA";
    return result;
  }
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
          style={{marginLeft: "22px"}}
          >
            <small className="fs-inter ir-con-head">
              TRANCH <b>1</b> {registerdata.date_Of_investment}
            </small>
            <br />
            <p className="dm-sans ir-heading2">
              Investment value:{registerdata.value_of_investment}
            </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {hidediv && (
            <Typography>
              <div className="row">
                <div className="col-md-6">
                  <label className="ircon-head">
                    investee company name
                    <select
                      style={{
                        width: "336px",
                        marginTop: "12px",
                        borderRadius: "5px",
                        height: "59px",
                      }}
                      onChange={(e) => handleChange("company", e)}
                    >
                      <option>Select...</option>
                      {allCompany.length > 0 &&
                        allCompany.map((det) => {
                          return (
                            <>
                              <option value={det._id}
                              selected={registerdata.company === det._id}
                              >
                                {det.company_name}
                              </option>
                            </>
                          );
                        })}
                    </select>
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="ircon-head">
                    Company code
                    <input type="text" value={companyCode(registerdata.company)}/>
                  </label>
                </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-12">
                    <label className="ircon-head">
                      Date of investment
                      <input
                        onChange={(e) => handleChange(e, "doi")}
                        type="date"
                        className="form-control"
                        defaultValue={registerdata.date_Of_investment}
                      />
                    </label>
                  </div>
                </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="ircon-head">Value of investment</label>
                  <input
                    type="text"
                    defaultValue={registerdata.value_of_investment}
                    onChange={(e) => handleChange(e, "voi")}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <label className="ircon-head">
                    No of shares brought
                    <input
                      type="text"
                      defaultValue={registerdata?.no_of_shares}
                      onChange={(e) => handleChange(e, "no_of_bought")}
                    />
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="ircon-head">
                    Total no shares in the company
                    <input
                      type="text"
                      defaultValue={registerdata.total_no_shares}
                      onChange={(e) => handleChange(e, "total_share")}
                    />
                  </label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <label className="ircon-head">
                    Percentage holding
                    <input
                      type="text"
                      value={totaldata ? totaldata : registerdata.holdings}
                      onChange={(e) => handleChange(e, "holding")}
                      disabled
                    />
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="ircon-head">
                    Non-controling interest Percentage
                    <input
                      type="text"
                      value={interest ? interest : notInterest}
                      disabled
                    />
                  </label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <label className="text-secondary">
                    Nature of relationship
                  </label>

                  <select
                    style={{
                      width: "100%",
                      height: "59px",
                      borderRadius: "5px",
                    }}
                    defaultValue={natureinvest}
                    onChange={(e) => handleChange(e, "nature_of_relation")}
                  >
                    <option>Select</option>
                    <option
                      value="Investment"
                      selected={natureinvest === "Investment"}
                    >
                      Investment
                    </option>
                    <option
                      value="Associate Company"
                      selected={natureinvest === "Associate Company"}
                    >
                      Associate Company
                    </option>
                    <option
                      value="Subsidiary Company"
                      selected={natureinvest === "Subsidiary Company"}
                    >
                      Subsidiary Company
                    </option>
                    <option
                      value="Joint Venture"
                      selected={natureinvest === "Joint Venture"}
                    >
                      Joint Venture
                    </option>
                  </select>
                </div>
              </div>
              {/* modal  */}

              {holding > 50 && (!totaldata || totaldata > 50) ? (
                <div className="row mt-3">
                  <div className="col-md-6" onClick={() => entryOpen()}>
                    <input
                      type="button"
                      className="btn btn-primary1 w-100"
                      value="Create consol entry"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="button"
                      className="btn btn-primary1 w-100"
                      value="Browse control date FS"
                      onClick={() => browseFs()}
                    />
                    <small>{browsproject ? browsproject : ""}</small>
                  </div>
                </div>
              ) : path[1] === "edit-register" && totaldata && totaldata > 50 ? (
                <div className="row mt-3">
                  <div className="col-md-6" onClick={() => entryOpen()}>
                    <input
                      type="button"
                      className="btn btn-primary1 w-100"
                      value="Create consol entry"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="button"
                      className="btn btn-primary1 w-100"
                      value="Browse control date FS"
                      onClick={() => browseFs()}
                    />
                    <small>{browsproject ? browsproject : ""}</small>
                  </div>
                </div>
              ) : (
                ""
              )}
    
              {holding > 50 && (!totaldata || totaldata > 50) ? (
                <div className="row  mt-3">
                  <div className="col-md-12 ">
                    <label className="text-secondary">
                      Details of networth on the date of control
                    </label>
                    <input
                      defaultValue={registerdata.details_networth}
                      onChange={(e) => handleChange(e, "doi")}
                      type="text"
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="text-secondary">
                      Add other details &nbsp; &nbsp;
                    </label>
                    <AddIcon
                      onClick={handleOpen1}
                      data-bs-toggle="modal"
                      data-bs-target="#Intangible"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              ) : path[1] === "edit-register" && totaldata && totaldata > 50 ? (
                <div className="row  mt-3">
                  <div className="col-md-12 ">
                    <label className="text-secondary">
                      Details of networth on the date of control
                    </label>
                    <input
                      defaultValue={registerdata.details_networth}
                      onChange={(e) => handleChange(e, "doi")}
                      type="text"
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="text-secondary">
                      Add other details &nbsp; &nbsp;
                    </label>
                    <AddIcon
                      onClick={handleOpen1}
                      data-bs-toggle="modal"
                      data-bs-target="#Intangible"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {holding > 50 && (!totaldata || totaldata > 50) ? (
                <div className="row mt-2">
                  <div className="col-md-12 ">
                    <label className="text-secondary">
                      Whether transaction is covered as common control
                      transaction
                    </label>
                    <select
                      defaultValue={registerdata.transaction_is_covered}
                      onChange={(e) => handleChange(e, "transaction")}
                      style={{
                        width: "691px",
                        marginTop: "12px",
                        borderRadius: "5px",
                        height: "59px",
                      }}
                    >
                      <option>Select....</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div class="table-responsive table-bordered">
                      <table class="table" style={{ marginBottom: "0" }}>
                        <tbody>
                          {registerdata.other_items.length > 0 &&
                            registerdata.other_items.map((item) => (
                              <>
                                <tr>
                                  <td>{item.column}</td>
                                  <td>{item.select}</td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : path[1] === "edit-register" && totaldata && totaldata > 50 ? (
                <div className="row mt-2">
                  <div className="col-md-12 ">
                    <label className="text-secondary">
                      Whether transaction is covered as common control
                      transaction
                    </label>
                    <select
                      defaultValue={registerdata.transaction_is_covered}
                      onChange={(e) => handleChange(e, "transaction")}
                      style={{
                        width: "691px",
                        marginTop: "12px",
                        borderRadius: "5px",
                        height: "59px",
                      }}
                    >
                      <option>Select....</option>
                      <option
                        value="yes"
                        selected={registerdata.transaction_is_covered === "yes"}
                      >
                        Yes
                      </option>
                      <option
                        value="no"
                        selected={registerdata.transaction_is_covered === "no"}
                      >
                        No
                      </option>
                    </select>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div class="table-responsive table-bordered">
                      <table class="table" style={{ marginBottom: "0" }}>
                        <tbody>
                          {registerdata.other_items.length > 0 &&
                            registerdata.other_items.map((item) => (
                              <>
                                <tr>
                                  <td>{item.column}</td>
                                  <td>{item.select}</td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default EditInvestmentRegister;
