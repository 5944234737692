import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoopIcon from '@mui/icons-material/Loop';
import { pyTransformBSData, pyTransformCashFlow, pyTransformCashflowWorking, pyTransformDisclosureCheckList, pyTransformGeneralNotes, pyTransformPNLData, pyTransformSocieData, pyTtransformNotesData } from '../../Components/excelExport/dataTransform';
import { gzip } from 'pako';  // pako is a zlib port to the web
import { fetchTTPNLData } from '../../redux/actions/TemplateTabs/actionPNL';
import { fetchTTBSData } from '../../redux/actions/TemplateTabs/actionBS';
import { fetchCashFlowData } from '../../redux/actions/PopulateFs/actionCashflow';
import { getBSNotesData, getDisclosureChecklist, getDisclosureData, getGeneralNotes, getPNLNotesData, getSocieData } from '../../services/api/apiHandler';
import { pyTransformDisclosure48, pyTransformEPS, pyTransformRBP, pyTransformdis52, pyTransformdis53, pyTransformdis54, pyTransformdis55, pyTransformdis56, pyTransformdis58, pyTransformdis59, pyTransformdis60, pyTransformdis62, pyTransformdis63, pyTransformdis64, pyTransformdis65, pyTransformdis66, pyTransformdis67, pyTransformdis68, pyTransformdis69, pyTransformdis70, pyTransformdis71, pyTransformdis72, pyTransformdis73 } from '../../Components/excelExport/disclosureTransform';


const ExportExcel = () => {
    const tb_id = localStorage.getItem("tb_id");
    const project_id = localStorage.getItem("project_id");

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);

    const [bsData, setBsData] = useState([]); 
    const [pnlData, setPnlData] = useState([]);
    const [cashFlow, setCashFlow] = useState([]);
    const [cashFlowWorking, setCashFlowWorking] = useState([]);
    const [disclosureChecklist, setDisclosuresChecklist] = useState([])
    const [generalNotes, setGeneralNotes] = useState({
        loading: false,
        isSuccess: false,
        response: [],
        error_message: null
    })
    const [socieData, setSocieData] = useState({
        loading: false,
        isSuccess: false,
        response: [],
        error_message: null
    });

    const [bsNotesData, setBsNotesData] = useState({
        loading: false,
        isSuccess: false,
        response: [],
        error_message: null
    });

    const [pnlNotesData, setPnlNotesData] = useState({
        loading: false,
        isSuccess: false,
        response: [],
        error_message: null
    });

    const [disclosure48, setDisclosure48] = useState([]);
    const [disclosure49, setDisclosure49] = useState([]);
    const [disclosure50, setDisclosure50] = useState([]);
    const [disclosure52, setDisclosure52] = useState([]);
    const [disclosure53, setDisclosure53] = useState([]);
    const [disclosure54, setDisclosure54] = useState([]);
    const [disclosure55, setDisclosure55] = useState([]);
    const [disclosure56, setDisclosure56] = useState([]);
    const [disclosure58, setDisclosure58] = useState([]);
    const [disclosure59, setDisclosure59] = useState([]);
    const [disclosure60, setDisclosure60] = useState([]);
    const [disclosure62, setDisclosure62] = useState([]);
    const [disclosure63, setDisclosure63] = useState([]);
    const [disclosure64, setDisclosure64] = useState([]);
    const [disclosure65, setDisclosure65] = useState([]);
    const [disclosure66, setDisclosure66] = useState([]);
    const [disclosure67, setDisclosure67] = useState([]);
    const [disclosure68, setDisclosure68] = useState([]);
    const [disclosure69, setDisclosure69] = useState([]);
    const [disclosure70, setDisclosure70] = useState([]);
    const [disclosure71, setDisclosure71] = useState([]);
    const [disclosure72, setDisclosure72] = useState([]);
    const [disclosure73, setDisclosure73] = useState([]);


    const balanceSheet = useSelector(
        (state) => state.TempTabBSReducer
    );

    const pnlSheet = useSelector(
        (state) => state.TempTabPNLReducer
    );

    const cashflowData = useSelector((state)=>state.PFSCashFlow);

    const disChecklist = useSelector((state) => state.DisclosureChecklistReducer.disclosureChecklistData);

    const fetchPNLSheetData = async () => {
        if(!pnlData.isSuccess || pnlData.set_payload !== JSON.stringify({project_id, tb_id})){
          dispatch(fetchTTPNLData(project_id, tb_id));
        }
    }

    const fetchBalanceSheetData = async () => {
        if(!bsData.isSuccess || bsData.set_payload !== JSON.stringify({project_id, tb_id})){
            dispatch(fetchTTBSData(project_id, tb_id));
        }
    }

    const getCashflowData = async () => {
        dispatch(fetchCashFlowData(project_id, tb_id));
    }

    const fetchDisclosureChecklist = async () => {
        const response = await getDisclosureChecklist(project_id, tb_id);
        dispatch({ type: "DISCLOSURE_CHECKLIST_DATA", payload: response?.data?.data });
    }


    useEffect(()=>{
        const BalanceSheetData = pyTransformBSData(balanceSheet?.response?.balance_sheet, balanceSheet.response.company_type);
        setBsData(BalanceSheetData);

        const PnlSheetData = pyTransformPNLData(pnlSheet?.response?.pnl_statement, pnlSheet?.response?.company_type);
        setPnlData(PnlSheetData);

    }, [balanceSheet?.response, pnlSheet?.response])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if(cashflowData.isSuccess){
            setCashFlow(pyTransformCashFlow(cashflowData.response.data));
            setCashFlowWorking(pyTransformCashflowWorking(cashflowData.response.working));
        }
        if(disChecklist){
            setDisclosuresChecklist(pyTransformDisclosureCheckList(disChecklist));
        }
    }, [cashflowData.isSuccess, cashflowData.response.data, cashflowData.response.working, disChecklist])

    useEffect(()=>{
        (async () => {
          await fetchPNLSheetData();
          await fetchBalanceSheetData();
          await getCashflowData();
          await fetchDisclosureChecklist();
        })()
    },[])


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async ()=>{
        (async() => {
            try {
                setGeneralNotes((res)=>{
                    return {
                        ...res,
                        loading: true
                    }
                });
                const response = await getGeneralNotes(project_id, tb_id);
                setGeneralNotes((res)=>{
                    return {
                        ...res,
                        loading: false,
                        isSuccess: true,
                        response: pyTransformGeneralNotes(response.data.data)
                    }
                });
            } catch (error) {
                setGeneralNotes((res)=>{
                    return {
                        ...res,
                        loading: false,
                        isSuccess: false,
                        error_message: error.message
                    }
                });
            }
        })()
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async ()=>{
        (async() => {
            try {
                setSocieData((res)=>{
                    return {
                        ...res,
                        loading: true
                    }
                });
                const response = await getSocieData(project_id, tb_id);
                setSocieData((res)=>{
                    return {
                        ...res,
                        loading: false,
                        isSuccess: true,
                        response: pyTransformSocieData(response.data.socie_list[0])
                    }
                });
            } catch (error) {
                setSocieData((res)=>{
                    return {
                        ...res,
                        loading: false,
                        isSuccess: false,
                        error_message: error.message
                    }
                });
            }
            
        })()
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async ()=>{
        (async() => {
            try {
                setBsNotesData((res)=>{
                    return {
                        ...res,
                        loading: true
                    }
                });
                const response = await getBSNotesData(project_id, tb_id);
                setBsNotesData((res)=>{
                    return {
                        ...res,
                        loading: false,
                        isSuccess: true,
                        response: pyTtransformNotesData(response.data.result.filter(data => data.status===true).map((data)=>data.result))
                    }
                });
            } catch (error) {
                setBsNotesData((res)=>{
                    return {
                        ...res,
                        loading: false,
                        isSuccess: false,
                        error_message: error.message
                    }
                });
            }
            
        })()
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async ()=>{
        (async() => {
            try {
                setPnlNotesData((res)=>{
                    return {
                        ...res,
                        loading: true
                    }
                });
                const response = await getPNLNotesData(project_id, tb_id);
                setPnlNotesData((res)=>{
                    return {
                        ...res,
                        loading: false,
                        isSuccess: true,
                        response: pyTtransformNotesData(response.data.result.filter(data => data.status===true).map((data)=>data.result).filter(data => data !== undefined))
                    }
                });
            } catch (error) {
                setPnlNotesData((res)=>{
                    return {
                        ...res,
                        loading: false,
                        isSuccess: false,
                        error_message: error.message
                    }
                });
            }
            
        })()
    }, [])

    useEffect(()=> {
        (async () => {
        try {
            const response = await getDisclosureData(project_id, tb_id);
            const disclosureData = response.data.data;
            const dis48 = pyTransformDisclosure48(disclosureData.find(dis => dis.disclosureId === 48));
            const dis49 = pyTransformEPS(disclosureData.find(dis => dis.disclosureId === 49));
            const dis50 = pyTransformRBP(disclosureData.find(dis => dis.disclosureId === 50));
            const dis52 = pyTransformdis52(disclosureData.find(dis => dis.disclosureId === 52));
            const dis53 = pyTransformdis53(disclosureData.find(dis => dis.disclosureId === 53));
            const dis54 = pyTransformdis54(disclosureData.find(dis => dis.disclosureId === 54));
            const dis55 = pyTransformdis55(disclosureData.find(dis => dis.disclosureId === 55));
            const dis56 = pyTransformdis56(disclosureData.find(dis => dis.disclosureId === 56));
            const dis58 = pyTransformdis58(disclosureData.find(dis => dis.disclosureId === 58));
            const dis59 = pyTransformdis59(disclosureData.find(dis => dis.disclosureId === 59));
            const dis60 = pyTransformdis60(disclosureData.find(dis => dis.disclosureId === 60));
            const dis62 = pyTransformdis62(disclosureData.find(dis => dis.disclosureId === 62));
            const dis63 = pyTransformdis63(disclosureData.find(dis => dis.disclosureId === 63));
            const dis64 = pyTransformdis64(disclosureData.find(dis => dis.disclosureId === 64));
            const dis65 = pyTransformdis65(disclosureData.find(dis => dis.disclosureId === 65));
            const dis66 = pyTransformdis66(disclosureData.find(dis => dis.disclosureId === 66));
            const dis67 = pyTransformdis67(disclosureData.find(dis => dis.disclosureId === 67));
            const dis68 = pyTransformdis68(disclosureData.find(dis => dis.disclosureId === 68));
            const dis69 = pyTransformdis69(disclosureData.find(dis => dis.disclosureId === 69));
            const dis70 = pyTransformdis70(disclosureData.find(dis => dis.disclosureId === 70));
            const dis71 = pyTransformdis71(disclosureData.find(dis => dis.disclosureId === 71));
            const dis72 = pyTransformdis72(disclosureData.find(dis => dis.disclosureId === 72));
            const dis73 = pyTransformdis73(disclosureData.find(dis => dis.disclosureId === 73));

            setDisclosure48([...dis48]);
            setDisclosure49([...dis49]);
            setDisclosure50([...dis50]);
            setDisclosure52([...dis52]);
            setDisclosure53([...dis53]);
            setDisclosure54([...dis54]);
            setDisclosure55([...dis55]);
            setDisclosure56([...dis56]);
            setDisclosure58([...dis58]);
            setDisclosure59([...dis59]);
            setDisclosure60([...dis60]);
            setDisclosure62([...dis62]);
            setDisclosure63([...dis63]);
            setDisclosure64([...dis64]);
            setDisclosure65([...dis65]);
            setDisclosure66([...dis66]);
            setDisclosure67([...dis67]);
            setDisclosure68([...dis68]);
            setDisclosure69([...dis69]);
            setDisclosure70([...dis70]);
            setDisclosure71([...dis71]);
            setDisclosure72([...dis72]);
            setDisclosure73([...dis73]);
        } catch (error) {
            console.log("err", error.message)
        }
        })()
    }, [])

    const handleData = async () => {
        if(bsData.length > 0 && pnlData.length > 0 && cashFlow.length > 0 && generalNotes.isSuccess && socieData.isSuccess && bsNotesData.isSuccess && pnlNotesData.isSuccess && disclosure48 && disclosure53 && disclosure58){
            setLoader(true);
            const dataSet = {
                balanceSheet: bsData,
                pnlSheet: pnlData,
                cashFlow: cashFlow,
                cashFlowWorking: cashFlowWorking,
                disclosureChecklist: disclosureChecklist,
                generalNotes: generalNotes.response,
                socieData: socieData.response,
                balanceSheetNotes: bsNotesData.response,
                pnlSheetNotes: pnlNotesData.response,
                disclosure48: disclosure48,
                disclosure49: disclosure49,
                disclosure50: disclosure50,
                disclosure52: disclosure52,
                disclosure53: disclosure53,
                disclosure54: disclosure54,
                disclosure55: disclosure55,
                disclosure56: disclosure56,
                disclosure58: disclosure58,
                disclosure59: disclosure59,
                disclosure60: disclosure60,
                disclosure62: disclosure62,
                disclosure63: disclosure63,
                disclosure64: disclosure64,
                disclosure65: disclosure65,
                disclosure66: disclosure66,
                disclosure67: disclosure67,
                disclosure68: disclosure68,
                disclosure69: disclosure69,
                disclosure70: disclosure70,
                disclosure71: disclosure71,
                disclosure72: disclosure72,
                disclosure73: disclosure73,
            }
            const compressedData = gzip(JSON.stringify(dataSet));
            const url = process.env.REACT_APP_PYTHON_URL + 'get-excel';
            try {
                const response = await axios.post(url, compressedData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Content-Encoding': 'gzip'
                    }
                });
                const fileUrl = response.data.file_url;
                setLoader(false)
                window.location.href = `${process.env.REACT_APP_PYTHON_URL}${fileUrl}`;
            } catch (error) {
                setLoader(false)
                alert("failed", error.message)
                console.error('Error:', error);
            }
        }else{
            alert("We are processing the data for the export, please try after few seconds!");
        }
    }

    return (
        <div className='d-flex align-items-center' onClick={handleData} style={{cursor: 'pointer'}}>
            <span>
                {!loader ? 
                <ExitToAppIcon className='mt-3' style={{ width: "24px", height: "24px" }} />
                :
                <LoopIcon className='mt-3 rotate' style={{ width: "24px", height: "24px", animation: 'rotate 2s linear infinite' }} />
                }
                <style>
                    {`
                    @keyframes rotate {
                        0% {
                        transform: rotate(0deg);
                        }
                        100% {
                        transform: rotate(360deg);
                        }
                    }
                    `}
                </style>
            </span>
            <p className="mb-0 ml-2 font-size-16" style={{ color: "#343434" }}>Export as Excel</p>
        </div>
    )
}

export default ExportExcel;