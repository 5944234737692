import * as React from "react";

import Sidbarv2 from "../../Workspace-v2/Sidbarv2";
import Navbarv3 from "../../Workspace-v2/Navbarv3";
import Button from "@mui/material/Button";
import "../../Workspace-v2/sidebarv2.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import Badge from "@mui/material/Badge";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { Navbar } from "../../../Components/Navbar";
import { GetTeamAccess, addworkspaceteamMember, deleteworkspaceteamMember, getMeber, getMemberbyPreparerId, getworkspaceteamMember,getPermission } from "../ApiServices/apiHandler";
import { useState ,useEffect} from "react";
import { useHistory, useLocation } from "react-router";
import { FormControl, MenuItem, Select } from "@mui/material";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import swal from "sweetalert";
// import SearchIcon from '@mui/icons-material/Search';

const TeamMemebers = (props) => {
  const users = JSON.parse(localStorage.getItem('user'));
  const [acessTeam, setAccess] = useState([]);
  const [permission,setPermission]=useState([]);
  const [allmember, setAllmember] = useState([]);
  const [userId, setUserId] = useState({});
  const [teamMember,setTeamMember]=useState({});
  const location = useLocation();
  let history = useHistory();
  let a = location.pathname;
  let w_id = a.split("/");
  useEffect(() => {
    teamAccess();
    usersData();
    getworkspaceteamMembers();
    getPermissionOfWorkspace();
    if(w_id.includes('TeamMemebers')){
      localStorage.setItem("modifyAccess", 'TeamMemebers');
    }
  }, [])
  

  const handleChange = (event,value) => {
    const newValue = event.target.value;
    setUserId(newValue);
  }

  const teamAccess = async () => {
    const res = await GetTeamAccess();
    if (res.status === 200) {
      console.log(res.data.getAccess, "12321");
      setAccess(res.data.getAccess)
    }
  }
  const getPermissionOfWorkspace = async () => {
    const workspace_id = w_id[2];
    const res = await getPermission(workspace_id);
    if (res.status === 200) {
      setPermission(res.data.response.workspace_access)
    }
  }
  const usersData = async () => {
    if (users.responsibilities==="preparer"){
      const parent_id = users.parent_id;
      const res = await getMemberbyPreparerId(parent_id);
      setAllmember(res.data.mergedData);
    }else{
    const res = await getMeber();
    setAllmember(res.data.fetchMember);
    }
  };
  const getworkspaceteamMembers = () => {
    const workspace_id = w_id[2];
    getworkspaceteamMember(workspace_id)
      .then((result) => {
        if (result.status === 200) {
          setTeamMember(result.data.response?.user_ids);
        }
      })
      .catch(error => {
        swal("Error",  "Get Workspace Team Failed", "error")
      });
  };

  const handleAdd = () => {
    const datas = {
      workspace_id:w_id[2],
      user_id:userId
      };
      addworkspaceteamMember(datas).then(res => { 
      setUserId('');
      getworkspaceteamMembers();
      if(res.data.response.user_exists===true){
        swal("ERROR","Team member Already exists","error");
      }else{
        swal("Success","Team Added Sucessfully","success");
      }
    }).catch(error => {
      swal("Error", error.response.data.error, "error")
    });
  };

  const handleRemove = (u_id) => {
    const datas = {
      workspace_id:w_id[2],
      user_id:u_id
      };
      deleteworkspaceteamMember(datas).then(res => { 
      swal("Success","Team member Removed Sucessfully","success");
      getworkspaceteamMembers();
    }).catch(error => {
      swal("Error", error.response.data.error, "error")
    });
  };
  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>
        <div className="d-flex">
          <div>
            <Sidbarv2
              w_id={w_id}
            />
          </div>
          {/* <div class="px-4 w-100 mt-3">
            <div className="d-flex justify-content-between mt-4">
            <p className="dm-sans fs-head">Team Members</p>
            <Button
            className="font-sans"
                  style={{
                    height: "40px",
                    width: "250px",
                    border: "2px solid #03565A",
                    color: "#03565A",
                    textTransform: "none",
                    borderRadius: "5px",
                    fontWeight: "normal",
                  }}
                >
                  <span style={{ marginRight: "8px" }}>
                    <AddIcon />
                  </span>
                  Invite Team Member
                </Button>
            </div>
            <TableContainer component={Paper} className="wpTableContainer">
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                <div
                    style={{
                      width: "100%",
                      height: "50px",
                      marginLeft: "15px",
                    }}
                  >
                      <div
                        className="d-flex align-items-center"
                        style={{
                          border: "1px solid gray",
                          borderRadius: "7px",
                          marginTop:"5px"
                        }}
                      >
                        <SearchIcon style={{
                            paddingLeft: "5px",
                          }}/>
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            marginTop: "0px",
                            padding:"10px",
                            borderRadius: "7px",
                            width:"100%"
                          }}
                          type="search"
                          name="search"
                          placeholder="Search for a team member"
                        />
                      </div>
                  </div>
                  <TableRow>
                    <TableCell className="font-inter">TEAM MEMBER</TableCell>
                    <TableCell className="font-inter" align="right">ACCESSTYPE</TableCell>
                    <TableCell className="font-inter" align="right">INVITE STATUS</TableCell>
                    <TableCell className="font-inter" align="right">ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acessTeam.length > 0 && acessTeam.map((dat) => {
                    return (<>
                      <TableRow>
                      <TableCell
                      component="th"
                      scope="row"
                      style={{ backgroundColor: "white" }}
                      className="font-sans"
                    >
                      {dat?.email}
                    </TableCell>
                    
                        <TableCell align="right" className="font-sans">{dat?.access}</TableCell>
                        <TableCell align="right">
                          <Badge className="badgePending font-sans">pending</Badge>
                        </TableCell>
                        <TableCell align="right">
                          <MoreVertIcon />
                        </TableCell>
                      </TableRow>
                    </>)
                  })}</TableBody>
              </Table>
            </TableContainer>
          </div> */}
            <div className="d-flex flex-column w-100">
              <div className="container">
                  <div className="row m-5 justify-content-between">
                      <div className="col">
                        <p className="font-sans text-center">User ID:</p>
                      </div>
                      <div className="col">
                        <FormControl fullWidth>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={userId|| ''}
                            onChange={handleChange}
                            disabled={permission && permission.length > 0 && permission[0].addteam_member===false || users.responsibilities==="approver"}
                            >
                              {allmember && allmember.length>0 && allmember.map((data)=>
                              <MenuItem
                              key={data._id} 
                              value={data._id}
                              >
                                {data.email}({data.responsibilities?data.responsibilities: "admin"})
                              </MenuItem>
                            )}
                            </Select>
                        </FormControl>
                      </div>
                      <div className="col">
                        <Button
                            style={{
                              color: "black",
                              textTransform: "none",
                              marginTop: "17px",
                            }}
                            onClick={handleAdd}
                            disabled={permission && permission.length > 0 && permission[0].addteam_member===false || users.responsibilities==="approver"}
                          >
                            <AddIcon />
                            <span className="font-sans">Add into Workspace</span>
                        </Button>
                      </div>
                  </div>
              </div>
              <div className="mt-4">
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#b5b3b3" }}>
                        <TableCell style={{border:"none"}}align="center" className="font-sans">S.No</TableCell>
                        <TableCell style={{border:"none"}} align="center"className="font-sans">Name</TableCell>
                        <TableCell style={{border:"none"}} align="center"className="font-sans">Company</TableCell>
                        <TableCell style={{border:"none"}} align="center"className="font-sans">Actions</TableCell>
                        <TableCell style={{border:"none"}} align="center"className="font-sans">ID</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {teamMember && teamMember.length>0  && teamMember.map((data,index)=>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{border:"none"}}>{index+1}</TableCell>
                        <TableCell align="center" style={{border:"none"}}className="font-sans">{data.name?data.name:"Unknown"}</TableCell>
                        <TableCell align="center" style={{border:"none"}}>Not Listed</TableCell>
                        <TableCell align="center" style={{border:"none"}}>
                          <div className="d-flex flex-row justify-content-center">
                          <div style={{color:"#03565a" ,cursor:"pointer"}} 
                          onClick={
                            permission && permission.length > 0 && permission[0].addteam_member===false? null: data.responsibilities === "approver" ? () => history.push(`/functionalityacess/${data._id}/${w_id[2]}`) : () => history.push(`/modifyacess/${data._id}/${w_id[2]}`)
                          }>
                          <MiscellaneousServicesIcon/> Modify access
                          </div>
                          <div style={{color:"#03565a",cursor:"pointer"}} className="ml-3"  onClick={
                            users.responsibilities === "approver" ? null:permission && permission.length > 0 && permission[0].addteam_member===false? null:
                            () => handleRemove(data._id)}>
                          <DoDisturbOnIcon/>Remove user
                          </div>
                          </div>
                        </TableCell>
                        <TableCell align="center" style={{border:"none"}}className="font-sans">{data.email}</TableCell>
                      </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};
export default TeamMemebers;
