import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'



export function NBFC_32_32p5({ data, subIndex, HandleChange, dis_index_id, calculateTotal }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});


    // const calculateTotal = (key) => {
    //     const copy = [...data];
    //     return copy.reduce((total, item) => {
    //       const value = item[key];
    //       if (typeof value === 'number') {
    //         return total + value;
    //       }
    //       return total;
    //     }, 0);
    //   };

    return (
        <>
            <thead>
                {
                    (dis_index_id === 2 || dis_index_id === 3) && (
                        <>
                            <tr>
                                <th className='text-left w-50 pl-3 border-top-0 border-bottom-0' rowSpan={3}>Particulars</th>
                                <th className='border-top-0 border-bottom-0' colSpan={5}>
                                    {`As at ${dis_index_id === 2 ? moment(reduxProjectData.current_year).format("DD MMMM YYYY") : moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}`}
                                </th>
                            </tr>
                            <tr>
                                <th className='w-auto font-sans border-top-0 border-bottom-0' rowSpan={2}>Carrying Value</th>
                                <th className='w-auto font-sans border-top-0 border-bottom-0' rowSpan={2}>Fair Value</th>
                                <th className='font-sans border-top-0 border-bottom-0' colSpan={3}>Fair Value</th>
                            </tr>
                            <tr>
                                <th className='w-auto font-sans border-top-0 border-bottom-0'>Level 1</th>
                                <th className='w-auto font-sans border-top-0 border-bottom-0'>Level 2</th>
                                <th className='w-auto font-sans border-top-0 border-bottom-0'>Level 3</th>
                            </tr>
                        </>
                    )
                }
                {
                    dis_index_id === 4 && (
                        <tr>
                            <th className='w-25 font-sans border-top-0 border-bottom-0'>Each class of financial assets/liabilities</th>
                            <th className='w-75 font-sans border-top-0 border-bottom-0'>Name of Related Party</th>
                        </tr>
                    )
                }
                {
                    dis_index_id === 6 && (
                        <tr>
                            <th style={{ width: "80%" }} className=' font-sans border-top-0 border-bottom-0'>Nature of Transactions  </th>
                            <th className='w-auto font-sans border-top-0 border-bottom-0' > For the year ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                            <th className='w-auto font-sans border-top-0 border-bottom-0' > For the year ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                        </tr>
                    )
                }
            </thead>

            <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            {
                                (dis_index_id === 2 || dis_index_id === 3) && (
                                    <>
                                        <tr>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input
                                                    style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"} font-sans `}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>

                                            <td className='border-top-0 border-bottom-0'>
                                                <input
                                                    style={{ backgroundColor: "inherit" }}
                                                    className="w-100 border-0 pr-3 text-right font-sans"
                                                    type={"text"}
                                                    readOnly={typeof subDis?.carrying_value !== "number"}
                                                    value={formatIndianCurrency(subDis?.carrying_value)}
                                                    name="carrying_value"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input
                                                    style={{ backgroundColor: "inherit" }}
                                                    className="w-100 border-0 pr-3 text-right font-sans"
                                                    type={"text"}
                                                    readOnly={typeof subDis?.fair_value !== "number"}
                                                    value={formatIndianCurrency(subDis?.fair_value)}
                                                    name="fair_value"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input
                                                    style={{ backgroundColor: "inherit" }}
                                                    className="w-100 border-0 pr-3 text-right font-sans"
                                                    type={"text"}
                                                    readOnly={typeof subDis?.fair_value_lv1 !== "number"}
                                                    value={formatIndianCurrency(subDis?.fair_value_lv1)}
                                                    name="fair_value_lv1"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input
                                                    style={{ backgroundColor: "inherit" }}
                                                    className="w-100 border-0 pr-3 text-right font-sans"
                                                    type={"text"}
                                                    readOnly={typeof subDis?.fair_value_lv2 !== "number"}
                                                    value={formatIndianCurrency(subDis?.fair_value_lv2)}
                                                    name="fair_value_lv2"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input
                                                    style={{ backgroundColor: "inherit" }}
                                                    className="w-100 border-0 pr-3 text-right font-sans"
                                                    type={"text"}
                                                    readOnly={typeof subDis?.fair_value_lv3 !== "number"}
                                                    value={formatIndianCurrency(subDis?.fair_value_lv3)}
                                                    name="fair_value_lv3"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                        </tr>
                                        {
                                            (dis_index_id === 2 && subDis.sub_dis_index_id === 8) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark font-sans custom-bg-gray-200 font-inter'>TOTAL</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "carrying_value", { start: 1, end: 8 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value", { start: 1, end: 8 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv1", { start: 1, end: 8 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv2", { start: 1, end: 8 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv3", { start: 1, end: 8 })} </td>
                                                </tr>
                                            )
                                        }
                                        {
                                            (dis_index_id === 2 && subDis.sub_dis_index_id === 11) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200 font-inter'>TOTAL</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "carrying_value", { start: 9, end: 11 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value", { start: 9, end: 11 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv1", { start: 9, end: 11 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv2", { start: 9, end: 11 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv3", { start: 9, end: 11 })} </td>
                                                </tr>
                                            )
                                        }
                                        {
                                            (dis_index_id === 2 && subDis.sub_dis_index_id === 13) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200 font-inter'>TOTAL</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "carrying_value", { start: 12, end: 13 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value", { start: 12, end: 13 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv1", { start: 12, end: 13 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv2", { start: 12, end: 13 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark font-sans custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv3", { start: 12, end: 13 })} </td>
                                                </tr>
                                            )
                                        }
                                        {
                                            (dis_index_id === 2 && subDis.sub_dis_index_id === 19) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200 font-inter'>TOTAL</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 font-sans '> {calculateTotal(data, "carrying_value", { start: 14, end: 19 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 font-sans '> {calculateTotal(data, "fair_value", { start: 14, end: 19 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 font-sans '> {calculateTotal(data, "fair_value_lv1", { start: 14, end: 19 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 font-sans '> {calculateTotal(data, "fair_value_lv2", { start: 14, end: 19 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 font-sans '> {calculateTotal(data, "fair_value_lv3", { start: 14, end: 19 })} </td>
                                                </tr>
                                            )
                                        }
                                        {
                                            (dis_index_id === 2 && subDis.sub_dis_index_id === 21) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark font-inter custom-bg-gray-200'>TOTAL</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "carrying_value", { start: 20, end: 21 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value", { start: 20, end: 21 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv1", { start: 20, end: 21 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv2", { start: 20, end: 21 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv3", { start: 20, end: 21 })} </td>
                                                </tr>
                                            )
                                        }

                                        {
                                            (dis_index_id === 3 && subDis.sub_dis_index_id === 8) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark font-inter custom-bg-gray-200'>TOTAL</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "carrying_value", { start: 1, end: 8 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value", { start: 1, end: 8 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv1", { start: 1, end: 8 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv2", { start: 1, end: 8 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv3", { start: 1, end: 8 })} </td>
                                                </tr>
                                            )
                                        }

                                        {
                                            (dis_index_id === 3 && subDis.sub_dis_index_id === 10) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200 font-inter'>TOTAL</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "carrying_value", { start: 9, end: 11 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value", { start: 9, end: 11 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv1", { start: 9, end: 11 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv2", { start: 9, end: 11 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv3", { start: 9, end: 11 })} </td>
                                                </tr>
                                            )
                                        }

                                        {
                                            (dis_index_id === 3 && subDis.sub_dis_index_id === 16) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200 font-inter'>TOTAL</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "carrying_value", { start: 11, end: 16 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value", { start: 11, end: 16 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv1", { start: 11, end: 16 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv2", { start: 11, end: 16 })} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data, "fair_value_lv3", { start: 11, end: 16 })} </td>
                                                </tr>
                                            )
                                        }

                                    </>
                                )
                            }

                            {
                                dis_index_id === 4 && (
                                    <tr >
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }} className="p-0 m-0 w-100 border-0 pl-3 text-left"
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>

                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }} className="p-0 m-0 w-100 border-0 pl-3 text-left"
                                                type={"text"}
                                                value={subDis?.name_of_related_party}
                                                name="name_of_related_party"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                dis_index_id === 6 && (
                                    <tr >
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }} className="p-0 m-0 w-100 border-0 pl-3 text-left"
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>

                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }} className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.cy !== "number"}
                                                value={formatIndianCurrency(subDis?.cy)}
                                                name="cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }} className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.py !== "number"}
                                                value={formatIndianCurrency(subDis?.py)}
                                                name="py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </>
                    ))
                }
            </tbody>
        </>

    );
}