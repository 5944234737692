// import {
//     CurrentProjectCy,
//     CurrentProjectPy,
//   } from "../../helper/pnlCalculation";
import styled from "styled-components";
import {
    AddNewKeystoSocie,
  calCulateProfitBefore,
  calProfitBeforeTax,
  calProfitContinuing,
  calculateGroupSum,
  calculateTotalSub,
  convertHtmlToString,
} from "./utils";

export const pyTransformBSData = (data, company_type) => {
  try {
    let result = [
      {
        Particular: {
          value: "Particulars",
          style: {
            bold: true,
            size: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            width: 40,
          },
        },
        "Notes No": {
          value: "Notes No",
          style: {
            bold: true,
            fill_color: "808080",
            width: 20,
            horizontal_align: "center",
            vertical_align: "center",
          },
        },
        CY: {
          value: "As at 10 November 2023",
          style: {
            bold: true,
            fill_color: "808080",
            width: 20,
            wrap_text: true,
            horizontal_align: "center",
            vertical_align: "center",
          },
        },
        PY: {
          value: "As at 10 November 2022",
          style: {
            bold: true,
            fill_color: "808080",
            width: 20,
            wrap_text: true,
            horizontal_align: "center",
            vertical_align: "center",
          },
        },
      },
    ];
    let index = 0;
    for (const key in data) {
      const groupTitle = key;
      result.push({
        Particular: {
          value: groupTitle,
          style: {
            bold: true,
            size: 10,
            fill_color: "F0DBC8",
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
        "Notes No": { value: null, style: { fill_color: "F0DBC8" } },
        CY: { value: null, style: { fill_color: "F0DBC8" } },
        PY: { value: null, style: { fill_color: "F0DBC8" } },
      });

      const groupData = data[key];
      const totalCy = groupData.reduce((acc, curr) => acc + curr.cy, 0);
      const totalPy = groupData.reduce((acc, curr) => acc + curr.py, 0);

      groupData.forEach((item) => {
        result.push({
          Particular: {
            value: item["notes_grp"],
            style: {
              size: 10,
              horizontal_align: "left",
              vertical_align: "top",
              wrap_text: true,
              fill_color: "FFFFFF",
              width: 45
            },
          },
          "Notes No": {
            value: item["notes_index"],
            style: {
              size: 10,
              horizontal_align: "center",
              vertical_align: "top",
              fill_color: "FFFFFF",
              width: 15
            },
          },
          CY: {
            value: item["cy"],
            style: {
              size: 10,
              horizontal_align: "right",
              vertical_align: "top",
              fill_color: "FFFFFF",
              width: 15
            },
          },
          PY: {
            value: item["py"],
            style: {
              size: 10,
              horizontal_align: "right",
              vertical_align: "top",
              fill_color: "FFFFFF",
              width: 15
            },
          },
        });
      });
      result.push({
        Particular: {
          value: `Total ${groupTitle}`,
          style: {
            bold: true,
            size: 10,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
            width: 45,
          },
        },
        "Notes No": { value: null, style: { width: 15, fill_color: "FFFFFF" } },
        CY: {
          value: totalCy,
          style: {
            size: 10,
            bold: true,
            horizontal_align: "right",
            vertical_align: "top",
            fill_color: "FFFFFF",
            width: 15,
          },
        },
        PY: {
          value: totalPy,
          style: {
            size: 10,
            bold: true,
            horizontal_align: "right",
            vertical_align: "top",
            fill_color: "FFFFFF",
            width: 15,
          },
        },
      });
      if (index === 1) {
        if (company_type === "NBFC") {
          const f_assets = calculateGroupSum("Financial assets", data);
          const nf_assets = calculateGroupSum("Non-financial Assets", data);
          result.push({
            Particular: {
              value: `Total Financial and Non Financial Assets`,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "left",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 45
              },
            },
            "Notes No": { value: null, style: { width: 15, fill_color: "FFFFFF" } },
            CY: {
              value: f_assets.totalCy + nf_assets.totalCy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
            PY: {
              value: f_assets.totalPy + nf_assets.totalPy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
          });
        } else {
          const c_assets = calculateGroupSum("Current Assets", data);
          const nc_assets = calculateGroupSum("Non Current Assets", data);
          result.push({
            Particular: {
              value: `Total Current and Non Current Assets`,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "left",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 45
              },
            },
            "Notes No": { value: null, style: { width: 15, fill_color: "FFFFFF", } },
            CY: {
              value: c_assets.totalCy + nc_assets.totalCy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
            PY: {
              value: c_assets.totalPy + nc_assets.totalPy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
          });
        }
      } else if (index === 4) {
        if (company_type === "NBFC") {
          const f_liabilities = calculateGroupSum(
            "Financial liabilities",
            data
          );
          const nf_liabilities = calculateGroupSum(
            "Non-financial liabilities",
            data
          );
          const equity = calculateGroupSum("Equity", data);
          result.push({
            Particular: {
              value: `Total Financial and Non-Financial liabilities`,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "left",
                vertical_align: "top",
                fill_color: "FFFFFF",
                wrap_text: true,
                width: 45
              },
            },
            "Notes No": { value: null, style: { width: 15, fill_color: "FFFFFF" } },
            CY: {
              value: f_liabilities.totalCy + nf_liabilities.totalCy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
            PY: {
              value: f_liabilities.totalPy + nf_liabilities.totalPy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
          });

          result.push({
            Particular: {
              value: `Total Equity & Liabilities`,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "left",
                vertical_align: "top",
                fill_color: "FFFFFF",
                wrap_text: true,
                width: 45
              },
            },
            "Notes No": { value: null, style: { width: 15, fill_color: "FFFFFF" } },
            CY: {
              value:
                f_liabilities.totalCy + nf_liabilities.totalCy + equity.totalCy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
            PY: {
              value:
                f_liabilities.totalPy + nf_liabilities.totalPy + equity.totalPy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
          });
        } else {
          const c_liabilities = calculateGroupSum("Current Liabilities", data);
          const nc_liabilities = calculateGroupSum(
            "Non Current Liabilities",
            data
          );
          result.push({
            Particular: {
              value: `Total Current and Non-Current Liabilities`,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "left",
                vertical_align: "top",
                fill_color: "FFFFFF",
                wrap_text: true,
                width: 45
              },
            },
            "Notes No": { value: null, style: { width: 15, fill_color: "FFFFFF" } },
            CY: {
              value: c_liabilities.totalCy + nc_liabilities.totalCy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
            PY: {
              value: c_liabilities.totalPy + nc_liabilities.totalPy,
              style: {
                size: 10,
                bold: true,
                horizontal_align: "right",
                vertical_align: "top",
                fill_color: "FFFFFF",
                width: 15
              },
            },
          });
        }
      }
      index += 1;
    }
    return result;
  } catch (err) {
    return [
      {
        Error: "Error",
        Description: err.message,
      },
    ];
  }
};

export const pyTransformPNLData = (data, company_type) => {
  try {
    let result = [
      {
        Particular: {
          value: "Particulars",
          style: {
            bold: true,
            size: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            width: 40,
          },
        },
        "Notes No": {
          value: "Notes No",
          style: {
            bold: true,
            fill_color: "808080",
            width: 20,
            horizontal_align: "center",
            vertical_align: "center",
          },
        },
        CY: {
          value: "As at 10 November 2023",
          style: {
            bold: true,
            fill_color: "808080",
            width: 20,
            wrap_text: true,
            horizontal_align: "center",
            vertical_align: "center",
          },
        },
        PY: {
          value: "As at 10 November 2022",
          style: {
            bold: true,
            fill_color: "808080",
            width: 20,
            wrap_text: true,
            horizontal_align: "center",
            vertical_align: "center",
          },
        },
      },
    ];
    let index = 0;
    for (const key in data) {
      const groupTitle = key;
      const { pb_cy, pb_py } = calCulateProfitBefore(data, company_type);
      const { pbt_cy, pbt_py } = calProfitBeforeTax(data, company_type);
      const { pc_cy, pc_py } = calProfitContinuing(data, company_type);
      if ([2, 3, 5].includes(index)) {
        result.push({
          Particular: {
            value: groupTitle,
            style: {
              bold: true,
              size: 10,
              fill_color: "F0DBC8",
              horizontal_align: "left",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          "Notes No": { value: null, style: { fill_color: "F0DBC8" } },
          CY: {
            value:
              index === 2
                ? pb_cy
                : index === 3
                ? pbt_cy
                : index === 5
                ? pc_cy
                : 0,
            style: { fill_color: "F0DBC8" },
          },
          PY: {
            value:
              index === 2
                ? pb_py
                : index === 3
                ? pbt_py
                : index === 5
                ? pc_py
                : 0,
            style: { fill_color: "F0DBC8" },
          },
        });
      } else {
        result.push({
          Particular: {
            value: groupTitle,
            style: {
              bold: true,
              size: 10,
              fill_color: "F0DBC8",
              horizontal_align: "left",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          "Notes No": { value: null, style: { fill_color: "F0DBC8" } },
          CY: { value: null, style: { fill_color: "F0DBC8" } },
          PY: { value: null, style: { fill_color: "F0DBC8" } },
        });
      }

      const groupData = data[key];
      const totalCy = groupData.reduce((acc, curr) => acc + curr.cy, 0);
      const totalPy = groupData.reduce((acc, curr) => acc + curr.py, 0);

      groupData.forEach((item) => {
        result.push({
          Particular: {
            value: item["notes_grp"],
            style: {
              size: 10,
              horizontal_align: "left",
              vertical_align: "top",
              wrap_text: true,
              fill_color: "FFFFFF",
            },
          },
          "Notes No": {
            value: item["notes_index"],
            style: {
              size: 10,
              horizontal_align: "center",
              vertical_align: "top",
              fill_color: "FFFFFF",
            },
          },
          CY: {
            value: item["cy"],
            style: {
              size: 10,
              horizontal_align: "right",
              vertical_align: "top",
              fill_color: "FFFFFF",
            },
          },
          PY: {
            value: item["py"],
            style: {
              size: 10,
              horizontal_align: "right",
              vertical_align: "top",
              fill_color: "FFFFFF",
            },
          },
        });
      });
      if (![2, 3, 5].includes(index)) {
        result.push({
          Particular: {
            value: `Total ${groupTitle}`,
            style: {
              bold: true,
              size: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "top",
              wrap_text: true,
              width: 45,
            },
          },
          "Notes No": { value: null, style: { width: 15, fill_color: "FFFFFF" } },
          CY: {
            value: totalCy,
            style: {
              size: 10,
              bold: true,
              horizontal_align: "right",
              vertical_align: "top",
              fill_color: "FFFFFF",
              width: 15,
            },
          },
          PY: {
            value: totalPy,
            style: {
              size: 10,
              bold: true,
              horizontal_align: "right",
              vertical_align: "top",
              fill_color: "FFFFFF",
              width: 15,
            },
          },
        });
      }
      index += 1;
    }
    return result;
  } catch (err) {
    return [
      {
        Error: "Error",
        Description: err.message,
      },
    ];
  }
};


export const pyTransformCashFlow = (input) => {
    try{
    const result = [{
        Particular: {
          value: "Particulars",
          style: {
            bold: true,
            size: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            width: 40,
          },
        },
        CY: {
          value: "As at 10 November 2023",
          style: {
            bold: true,
            fill_color: "808080",
            width: 20,
            wrap_text: true,
            horizontal_align: "center",
            vertical_align: "center",
          },
        },
        PY: {
          value: "As at 10 November 2022",
          style: {
            bold: true,
            fill_color: "808080",
            width: 20,
            wrap_text: true,
            horizontal_align: "center",
            vertical_align: "center",
          },
        },
      }];
    input.forEach((rowData)=> {
      rowData.faceValueData.forEach((row)=>{
        result.push({
            Particular: {
              value: row.particular,
              style: {
                bold: row.hasOwnProperty("isHeading") && row?.isHeading === true
                ? true
                : false,
                size: 10,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
                width: 40,
              },
            },
            CY: {
              value: row.cy || '-',
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
            PY: {
              value: row.py || '-',
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
        })
      });
      if(rowData.faceValueId === 2){
        result.push({
            Particular: {
              value: 'Operating profit before working capital changes',
              style: {
                bold: true,
                size: 10,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
                width: 40,
              },
            },
            CY: {
              value: calculateTotalSub(input, [1,2,3], "cy"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
            PY: {
              value: calculateTotalSub(input, [1,2], "py"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
        })
      }
      if(rowData.faceValueId === 3){
        result.push({
            Particular: {
              value: 'Operating profit after working capital changes',
              style: {
                bold: true,
                size: 10,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
                width: 40,
              },
            },
            CY: {
              value: calculateTotalSub(input, [1,2,3], "cy"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
            PY: {
              value: calculateTotalSub(input, [1,2,3], "py"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
        })
      }
      if(rowData.faceValueId === 4){
        result.push({
            Particular: {
              value: 'Net cash flow from / (used) in operating activities (A)',
              style: {
                bold: true,
                size: 10,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
                width: 40,
              },
            },
            CY: {
              value: calculateTotalSub(input, [1,2,3,4], "cy"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
            PY: {
              value: calculateTotalSub(input, [1,2,3,4], "py"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
        })
      }
      if(rowData.faceValueId === 5){
        result.push({
            Particular: {
              value: 'Net cash  flow from / (used) in investing activities (B)',
              style: {
                bold: true,
                size: 10,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
                width: 40,
              },
            },
            CY: {
              value: calculateTotalSub(input, [5], "cy"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
            PY: {
              value: calculateTotalSub(input, [5], "py"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
        })
      }
      if(rowData.faceValueId === 6){
        result.push({
            Particular: {
              value: 'Net Cash flow from / (used) in financing activities (C)',
              style: {
                bold: true,
                size: 10,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
                width: 40,
              },
            },
            CY: {
              value: calculateTotalSub(input, [6], "cy"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
            PY: {
              value: calculateTotalSub(input, [6], "py"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
        })
      }
      if(rowData.faceValueId === 8){
        result.push({
            Particular: {
              value: 'Total Cash and Cash Equivalents',
              style: {
                bold: true,
                size: 10,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
                width: 40,
              },
            },
            CY: {
              value: calculateTotalSub(input, [8], "cy"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
            PY: {
              value: calculateTotalSub(input, [8], "py"),
              style: {
                fill_color: "FFFFFF",
                width: 20,
                wrap_text: true,
                horizontal_align: "right",
                vertical_align: "center",
              },
            },
        })
      }
    })
  
    return result;
  }catch(err){
    return [
        {
          Error: "Error",
          Description: err.message,
        },
    ];
  }
};
 
export function pyTransformCashflowWorking(input) {
    let output = [];
    
    input.forEach(item => {
        let result = []
        // Pushing category name
        result.push({
            sno: {value: item.name, style: {width: 10, size: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            adjustment: {value: null, style: {width: 20, size: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            cy: {value: null, style: {width: 20, size: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            py: {value: null, style: {width: 20, size: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            cs: {value: null, style: {width: 20, size: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            cy_reflect: {value: null, style: {width: 20, size: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            py_reflect: {value: null, style: {width: 20, size: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            name: {value: null, style: {width: 20, size: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            remark: {value: null, style: {width: 20, size: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}}
        })
        // Pushing adjustments data
        result.push({
            sno: {value: "S.no", style: {width: 10, size: 10, bold: true, fill_color: 'f2e2b5', horizontal_align: 'left', vertical_align: 'center'}},
            adjustment: {value: "Adjustments Name", style: {width: 20, size: 10, bold: true, fill_color: 'f2e2b5', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            cy: {value: "Current Year", style: {width: 20, size: 10, bold: true, fill_color: 'f2e2b5', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            py: {value: "Previous Year", style: {width: 20, size: 10, bold: true, fill_color: 'f2e2b5', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            cs: {value: "Adjustment in Cash flow statement", style: {width: 20, size: 10, bold: true, fill_color: 'f2e2b5', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            cy_reflect: {value: "Amount to be reflected in Cash Flow (CY)", style: {width: 20, size: 10, bold: true, fill_color: 'f2e2b5', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            py_reflect: {value: "Amount to be reflected in Cash Flow (PY)", style: {width: 20, size: 10, bold: true, fill_color: 'f2e2b5', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            name: {value: "Name of the item in cash flow statement", style: {width: 20, size: 10, bold: true, fill_color: 'f2e2b5', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            remark: {value: "Remarks", style: {width: 20, size: 10, bold: true, fill_color: 'f2e2b5', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}}
        })
        item.data.forEach((adjustment, index)=>{
            result.push({
                sno: {value: index + 1, style: {width: 10, size: 10, fill_color: adjustment.isColored ? 'e0e0e0' : 'FFFFFF', horizontal_align: 'left', vertical_align: 'center'}},
                adjustment: {value: adjustment.adjustmentsName, style: {width: 20, size: 10, fill_color: adjustment.isColored ? 'e0e0e0' : 'FFFFFF', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
                cy: {value: adjustment.cy_amount, style: {width: 20, size: 10, fill_color: adjustment.isColored ? 'e0e0e0' : 'FFFFFF', horizontal_align: 'right', vertical_align: 'center', wrap_text: true}},
                py: {value: adjustment.py_amount, style: {width: 20, size: 10, fill_color: adjustment.isColored ? 'e0e0e0' : 'FFFFFF', horizontal_align: 'right', vertical_align: 'center', wrap_text: true}},
                cs: {value: adjustment.adjustmentCashFlowStatement, style: {width: 20, size: 10, fill_color: adjustment.isColored ? 'e0e0e0' : 'FFFFFF', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
                cy_reflect: {value: adjustment.cy, style: {width: 20, size: 10, fill_color: adjustment.isColored ? 'e0e0e0' : 'FFFFFF', horizontal_align: 'right', vertical_align: 'center', wrap_text: true}},
                py_reflect: {value: adjustment.py, style: {width: 20, size: 10, fill_color: adjustment.isColored ? 'e0e0e0' : 'FFFFFF', horizontal_align: 'right', vertical_align: 'center', wrap_text: true}},
                name: {value: adjustment.nameOfItemCashFlowStatement, style: {width: 20, size: 10, fill_color: adjustment.isColored ? 'e0e0e0' : 'FFFFFF', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
                remark: {value: adjustment.remarks, style: {width: 20, size: 10, fill_color: adjustment.isColored ? 'e0e0e0' : 'FFFFFF', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}}
            })
        })
        output.push(result)
    });
  
    return output;
}

export function pyTransformDisclosureCheckList(input) {
    try{
      let output = [{
        name: {value: 'Name', style: {width: 14, bold: true, size: 10, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
        paraRef: {value: 'Paragraph reference', style: {width: 14, bold: true, size: 10, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        disclosure: {value: 'Disclosure', style: {width: 45, bold: true, size: 10, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
        yes: {value: 'Yes', style: {width: 12, bold: true, size: 10, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
        no: {value: 'No', style: {width: 12, bold: true, size: 10, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
        na: {value: 'NA', style: {width: 12, bold: true, size: 10, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
        remark: {value: 'Remark', style: {width: 20, bold: true, size: 10, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
      }];

    
      input.forEach(item => {
          item.disclosure.forEach(dis => {
                output.push({
                    name: {value: item.name, style: {size: 10, color: '64789F', width: 14, horizontal_align: 'left', vertical_align: 'center'}},
                    paraRef: {value: dis?.reference || "NA", style: {size: 10, color: '03565A', width: 14, horizontal_align: 'left', vertical_align: 'center'}},
                    disclosure: {value: dis?.notes || "NA", style: {size: 10, width: 45, horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
                    yes: {value: dis?.value === "yes" ? "\u2713" : "\u2717", style: {size: 10, width: 10, bold: true, horizontal_align: 'center', vertical_align: 'center', color: dis?.value === "yes" ? "008000" : "FF0000"}},
                    no: {value: dis?.value === "no" ? "\u2713" : "\u2717", style: {size: 10, width: 10, bold: true, horizontal_align: 'center', vertical_align: 'center', color: dis?.value === "yes" ? "008000" : "FF0000"}},
                    na: {value: dis?.value === "na" ? "\u2713" : "\u2717", style: {size: 10, width: 10, bold: true, horizontal_align: 'center', vertical_align: 'center', color: dis?.value === "yes" ? "008000" : "FF0000"}},
                    remark: {value: dis?.remark || "NA", style: {size: 10, width: 20, horizontal_align: 'left', vertical_align: 'center', wrap_text: true}}
                })
          });
      });
  
      return output
    }catch(err) {
      return [
        {
          Error: "Error",
          Description: err.message,
        },
    ];
    }
  }



export function pyTransformGeneralNotes(input) {
try{
    let output = [];
    
    // Processing data from 'data' array
    let dataOutput = [{
        note: {value: 'Notes', style: {size: 10, bold: true, width: 10, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
        message: {value: 'Message', style: {size: 10, bold: true, width: 25, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
        label: {value: 'Label', style: {size: 10, bold: true, width: 20, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
        value: {value: 'Value', style: {size: 10, bold: true, width: 30, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
        disclosure: {value: 'Disclosure', style: {size: 10, bold: true, width: 20, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}}
    }];
    input.data.forEach(item => {
        dataOutput.push({
            note: {value: item.name, style: {size: 10, bold: true, width: 10, color: '64789F', horizontal_align: 'left', vertical_align: 'center'}},
            message: {value: convertHtmlToString(item.message), style: {size: 10, width: 25, color: '03565A', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            label: {value: item.label, style: {size: 10, width: 20, horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            value: {value: item.value, style: {size: 10, width: 30, horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            disclosure: {value: item.disclosure || 'NA', style: {size: 10, width: 20, horizontal_align: 'left', vertical_align: 'center', wrap_text: true}}
        })
    });
    output.push(dataOutput);
    // Processing data from 'noteOne' array
    let noteOneOutput = [
        {
            note: {value: 'Notes', style: {size: 10, bold: true, width: 10, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            noteIndex: {value: 'Note Index', style: {size: 10, bold: true, width: 25, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            message: {value: 'Message', style: {size: 10, bold: true, width: 20, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            text1: {value: 'Text1', style: {size: 10, bold: true, width: 30, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            text2: {value: 'Text2', style: {size: 10, bold: true, width: 20, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}}
        }
    ];
    input.noteOne.forEach(item => {
        noteOneOutput.push({
            note: {value: item.name, style: {size: 10, bold: true, width: 10, color: '64789F', horizontal_align: 'left', vertical_align: 'center'}},
            noteIndex: {value: item.nodeIndex, style: {size: 10, width: 25, color: '03565A', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            message: {value: convertHtmlToString(item.message), style: {size: 10, width: 20, horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            text1: {value: item.text1 || "NA", style: {size: 10, width: 30, horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            text2: {value: item.text2 || "NA", style: {size: 10, width: 20, horizontal_align: 'left', vertical_align: 'center', wrap_text: true}}
        })
    });
    
    if(noteOneOutput.length > 1) {
        output.push(noteOneOutput)
    }
    return output;
} catch(err) {
    return [
        {
          Error: "Error",
          Description: err.message,
        },
    ]
}
}



export const pyTransformSocieData = (input) => {
    try {
      const output = [];
      for (const key in input) {
        if (input.hasOwnProperty(key)) {
          // Empty row for spacing
          let newData = AddNewKeystoSocie(`Restated balance at the beginning of the ${key.split("_")[0]} reporting period`, 0, 1, input[key]);
          input[key].splice(2, 0, newData)
          let newData2 = AddNewKeystoSocie(`Total Comprehensive Income for the ${key.split("_")[0]} year`, 3, 4, input[key]);
          input[key].splice(5, 0, newData2)
          let newData3 = AddNewKeystoSocie(`Total`, 0, 1, [newData, newData2]);
          input[key].push(newData3);
          let newData4 = AddNewKeystoSocie(`Balance at the end of the ${key.split("_")[0]} reporting period`, 0, 1, [newData, newData2]);
          input[key].push(newData4);
          // eslint-disable-next-line no-loop-func
          const rows = input[key].map((row, sIndex) => {
            const rowData = [];
            let sum = 0;
            let totalRow = (key === 'previous_row_header') ? 12 : 11;
            let balanceAttheEnd = (key === 'previous_row_header') ? 13 : 12;
            let obj = {}
            for (const colKey in row) {
              if (colKey === "row_header") {
                obj[colKey] = {value: row[colKey], style: {size: 10, width: 20, bold: [2, 5, totalRow, balanceAttheEnd].includes(sIndex) ? true : false, horizontal_align: 'left', vertical_align: 'center', wrap_text: true, fill_color: 'FFFFFF'}}
                // rowData.push({
                //     [colKey]: {value: row[colKey], style: {size: 10, bold: [2, 5, totalRow, balanceAttheEnd].includes(sIndex) ? true : false, horizontal_align: 'left', vertical_align: 'center', wrap_text: true, fill_color: 'FFFFFF'}}
                // })
              } else if (colKey === 'refData' || colKey === 'sub_grp') {
                continue
              }
              else {
                let value = row[colKey] || 0;
                sum += value;
                obj[colKey] = {value: row[colKey], style: {size: 10, fill_color: [2,5, totalRow].includes(sIndex) ? "C8FFDB" : [balanceAttheEnd].includes(sIndex) ? "DDDDFF" : [3,4].includes(sIndex) ? "FFDDF6"  : "FFFFFF", horizontal_align: 'left', vertical_align: 'center', wrap_text: true}}
                // rowData.push({
                //     [colKey]: {value: row[colKey], style: {size: 10, fill_color: [2,5, totalRow].includes(sIndex) ? "C8FFDB" : [balanceAttheEnd].includes(sIndex) ? "DDDDFF" : [3,4].includes(sIndex) ? "FFDDF6"  : "FFFFFF", horizontal_align: 'left', vertical_align: 'center', wrap_text: true}}
                // })
              }
            }
            // rowData.push({
            //     total: {value: sum, style: {size: 10, bold: true, horizontal_align: 'left', vertical_align: 'center', fill_color: [2,5, totalRow].includes(sIndex) ? "C8FFDB" : [balanceAttheEnd].includes(sIndex) ? "DDDDFF" : [3,4].includes(sIndex) ? "FFDDF6" : "FFFFFF"}}
            // })
            obj['total'] = {value: sum, style: {size: 10, bold: true, horizontal_align: 'left', vertical_align: 'center', fill_color: [2,5, totalRow].includes(sIndex) ? "C8FFDB" : [balanceAttheEnd].includes(sIndex) ? "DDDDFF" : [3,4].includes(sIndex) ? "FFDDF6" : "FFFFFF"}}
            rowData.push(obj);
            return rowData;
          });
          rows.unshift({
        row_header: {value: key === "previous_row_header"
        ? "Previous Year"
        : "Current Year", style: {size: 10, width: 20, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Share application money pending allotment': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Equity component of compounded financial instruments': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Capital reserve': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Securities premium': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Other reserves': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Retained Earnings': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Debt instruments through Other Comprehensive Income': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Equity Instruments through Other Comprehensive Income': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Effective portion of Cash Flow Hedges': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Revaluation Surplus': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Exchange differences on translating the financial statements of a foreign operation': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'Money received against share warrants': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
        'total': {value: null, style: {size: 10, width: 10, bold: true, fill_color: '99FFBB', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}}
        })
        rows.unshift({
            row_header: {value: 'Row Header', style: {size: 10, width: 20, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Share application money pending allotment': {value: 'Share application money pending allotment', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Equity component of compounded financial instruments': {value: 'Equity component of compounded financial instruments', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Capital reserve': {value: 'Capital reserve', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Securities premium': {value: 'Securities premium', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Other reserves': {value: 'Other reserves', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Retained Earnings': {value: 'Retained Earnings', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Debt instruments through Other Comprehensive Income': {value: 'Debt instruments through Other Comprehensive Income', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Equity Instruments through Other Comprehensive Income': {value: 'Equity Instruments through Other Comprehensive Income', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Effective portion of Cash Flow Hedges': {value: 'Effective portion of Cash Flow Hedges', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Revaluation Surplus': {value: 'Revaluation Surplus', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Exchange differences on translating the financial statements of a foreign operation': {value: 'Exchange differences on translating the financial statements of a foreign operation', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'Money received against share warrants': {value: 'Money received against share warrants', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
            'total': {value: 'Total', style: {size: 10, width: 10, bold: true, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}}
          })

          output.push(rows.flat());
        }
      }
  
      return output;
    } catch (err) {
        return [
            {
              Error: "Error",
              Description: err.message,
            },
        ]
    }
  };



  export function pyTtransformNotesData(input) {
    try {
      const output = [];
  
      input.forEach((item, index) => {
        // Process fs_grp data
        let rowData = []
        rowData.push({
            particular: {value: item?.data[0]?.sub_grp[0]?.fs_grp || 'Undefined FSGrp', style: {size: 10, width: 40, bold: true, fill_color: 'FFFFFF'}},
            cy: {value: null, style: {size: 10, width: 20, bold: true, fill_color: 'FFFFFF'}},
            py: {value: null, style: {size: 10, width: 20, bold: true, fill_color: 'FFFFFF'}}

        })
        rowData.push({
            particular: {value: `NOTE No. ${item?.notes_no}`, style: {size: 10, width: 40, bold: true, fill_color: 'FFFFFF'}},
            cy: {value: null, style: {size: 10, width: 20, bold: true, fill_color: 'FFFFFF'}},
            py: {value: null, style: {size: 10, width: 20, bold: true, fill_color: 'FFFFFF'}}
        })
        rowData.push({
            particular: {value: item?.notes_grp || "", style: {size: 10, width: 40, bold: true, fill_color: 'FFFFFF'}},
            cy: {value: null, style: {size: 10, width: 20, bold: true, fill_color: 'FFFFFF'}},
            py: {value: null, style: {size: 10, width: 20, bold: true, fill_color: 'FFFFFF'}}
        })
        let subGroupDataCollection = [];
        item?.data[0]?.sub_grp.forEach((subGroup, index2) => {
          const subGroupData = [];
          subGroupData.push({
            particular: {value: subGroup?.sub_grp || "", style: {size: 10, width: 40, bold: true, fill_color: 'FFFFFF', wrap_text: true}},
            cy: {value: subGroup.cy_amt || 0, style: {size: 10, width: 15, bold: true, horizontal_align: 'right', vertical_align: 'center', fill_color: 'FFFFFF', }},
            py: {value: subGroup.py_amt || 0, style: {size: 10, width: 15, bold: true, horizontal_align: 'right', vertical_align: 'center', fill_color: 'FFFFFF', }}
          })
          if (subGroup?.disclosures?.length > 0) {
            subGroup.disclosures.forEach((disclosure) => {
              const disclosureValues = {
                particular: {value: disclosure.disclosures, style: {size: 10, width: 40, wrap_text: true, fill_color: 'FFFFFF'}},
                cy: {value: disclosure.cy_amt || 0, style: {size: 10, width: 15, bold: true, horizontal_align: 'right', vertical_align: 'center', fill_color: 'FFFFFF'}},
                py: {value: disclosure.py_amt || 0, style: {size: 10, width: 15, bold: true, horizontal_align: 'right', vertical_align: 'center', fill_color: 'FFFFFF'}}
              }
              subGroupData.push(disclosureValues);
            });
            subGroupData.push({
                particular: {value: "Closing Gross Carrying Amount", style: {size: 10, width: 40, bold: true, wrap_text: true, fill_color: 'FFFFFF'}},
                cy: {value: 0, style: {size: 10, width: 15, bold: true, horizontal_align: 'right', vertical_align: 'center', fill_color: 'FFFFFF'}},
                py: {value: 0, style: {size: 10, width: 15, bold: true, horizontal_align: 'right', vertical_align: 'center', fill_color: 'FFFFFF'}} 
            })
            subGroupData.push({
                particular: {value: "Net Difference", style: {size: 10, width: 40, bold: true, color: '696f79', wrap_text: true, fill_color: 'FFFFFF'}},
                cy: {value: 0, style: {size: 10, width: 15, bold: true, horizontal_align: 'right', vertical_align: 'center', fill_color: 'FFFFFF'}},
                py: {value: 0, style: {size: 10, width: 15, bold: true, horizontal_align: 'right', vertical_align: 'center', fill_color: 'FFFFFF'}} 
            })
          }
          if (
            item?.data[0]?.sub_grp?.length - 1 === index2 &&
            subGroup?.disclosures?.length === 0
          ) {
            // Calculate total amount
            const totalCyAmt = item?.data[0]?.sub_grp.reduce(
              (acc, subGroup) => acc + subGroup.cy_amt,
              0
            );
            const totalPyAmt = item?.data[0]?.sub_grp.reduce(
              (acc, subGroup) => acc + subGroup.py_amt,
              0
            );
            subGroupData.push({
                particular: {value: "Total Amount", style: {size: 10, width: 40, bold: true, fill_color: 'F0DBC8', horizontal_align: 'left', vertical_align: 'center', wrap_text: true}},
                cy: {value: totalCyAmt || 0, style: {size: 10, width: 15, bold: true, fill_color: 'F0DBC8', horizontal_align: 'right', vertical_align: 'center'}},
                py: {value: totalPyAmt || 0, style: {size: 10, width: 15, bold: true, fill_color: 'F0DBC8', horizontal_align: 'right', vertical_align: 'center'}} 
            })
            // const totalAmountRow = [
            //   {
            //     value: "Total amount",
            //     style: {
            //       font: { sz: "10", bold: true },
            //       fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
            //       alignment: { horizontal: "left", vertical: "top" },
            //     },
            //   },
            //   {
            //     value: totalCyAmt || 0,
            //     style: {
            //       font: { sz: "10", bold: true },
            //       fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
            //       alignment: { horizontal: "right", vertical: "top" },
            //     },
            //   },
            //   {
            //     value: totalPyAmt || 0,
            //     style: {
            //       font: { sz: "10", bold: true },
            //       fill: { patternType: "solid", fgColor: { rgb: "F0DBC8" } },
            //       alignment: { horizontal: "right", vertical: "top" },
            //     },
            //   },
            // ];
            // subGroupData.push(totalAmountRow);
          }
          subGroupDataCollection.push(subGroupData);
        });
        subGroupDataCollection.unshift({
            particular: {value: 'Particular', style: {size: 10, width: 40, fill_color: '808080', horizontal_align: 'left', vertical_align: 'center'}},
            cy: {value: 'Current Year', style: {size: 10, width: 15, bold: true, fill_color: '808080', horizontal_align: 'right', vertical_align: 'center'}},
            py: {value: 'Previous Year', style: {size: 10, width: 15, bold: true, fill_color: '808080', horizontal_align: 'right', vertical_align: 'center'}}
        })
        rowData = [...rowData,subGroupDataCollection.flat()]
        output.push(rowData.flat())
      });
  
      return output;
    } catch (err) {
        return [
            {
              Error: "Error",
              Description: err.message,
            },
        ]
    }
  }