import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import ChildModal from "./addNewCompany";
import Typography from "@mui/material/Typography";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../../../WorkSpace/SignUp2.css";
import AddNewCompany from "./addNewCompany";

const AddAccount = (props) => {
  const {
    open2,
    handleClose2,
    handleChangeData,
    handleApproverChange,
    findData,
    searchTerm,
    multipleCompany,
    handleChangeDataSubmit,
    submitCompany,
    handleCloseModal,
    open,
    handleOpen,
    handleClose,
    checkContinueBotton
  } = props;
 
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };
  
  return (
    <>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="wp-modal-box wp-modal-box-add-company">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h5 className="wp-add-comp-modal-header">
              Add companies to your account
            </h5>
          </Typography>
          <p className="wp-add-comp-modal-p">
            Start adding all the companies that you want to use latter in your
            future workspace <AddIcon
                style={{
                  height: 25,
                  width: 25,
                  color: "#03565A",
                  float: "right",
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              />
          </p>
          <TextField
            className="wp-add-comp-modal-search"
            id="search"
            type="search"
            placeholder="Type a company name or search"
            onChange={(e) => handleChangeData(e.target.value, "search")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {searchTerm.length > 0 ?
          findData === true ? (
            <div className="wp-comp-search-no-reslt-msg">
              "{searchTerm}" already exist.
            </div>
          ) : (
            <div className="wp-comp-search-no-reslt-msg">
              "{searchTerm}" Does not exist.
            </div>
          ):""}
          <p className="wp-comp-added-p">You’ve added companies so far -</p>
          <Stack direction="row" spacing={1}>
            {multipleCompany.slice(-5).map((data) => {
              return (
                <>
                  <Chip
                  className="font-sans"
                    label={data}
                    onClick={handleClick}
                    // onDelete={handleDelete}
                    // deleteIcon={<CloseIcon />}
                  />
                </>
              );
            })}
            {findData === true ? 
            <Chip
              className="font-sans"
              label={searchTerm}
            />:""}
          </Stack>
          <div className="blankspace30"></div>
          {/* <Button className="wp-btn-fill">Save this list</Button>
          &nbsp; &nbsp; */}
          <Button className="wp-btn-no-fill font-inter" onClick={handleClose2}>Cancel</Button>
          {/* <Button >Open Child Modal</Button> */}
        </Box>
      </Modal>

      <AddNewCompany open={open} handleClose={handleClose} handleChangeDataSubmit={handleChangeDataSubmit} submitCompany={submitCompany} handleCloseModal={handleCloseModal} searchTerm={searchTerm} handleApproverChange={handleApproverChange} />
    </>
  );
};
export default AddAccount;
