/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import "../../../WorkSpace/SignUp2.css";
import { useHistory } from "react-router";

const RegisterModal = (props) => {
  let history = useHistory();
  let permissions = JSON.parse(localStorage.getItem('permission'));
  const { activePlandata } = props;
  console.log(activePlandata, "activePlandata----")

  console.log(permissions?.companyData[0]?.components[0]?.permission?.create, "chck");
  const { registerModalOpen, handleRegisterModalClose, continueStep } = props;
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const Chnageradiofunction = () => {
    const links = {
      a: () => {
        if (permissions?.companyData[0]?.components[0]?.permission?.create !== false || permissions === null) {
          continueStep();
        }
      },
      b: () => document.getElementById("linkB").click(),
      c: () => document.getElementById("linkC").click(),
      d: () => document.getElementById("linkD").click(),
      e: () => document.getElementById("linkE").click(),
      f: () => document.getElementById("linkF").click(),
      g: () => document.getElementById("linkG").click(),
    };

    links[selectedValue]?.();
  };

  return (
    <>
      <Modal
        open={registerModalOpen}
        onClose={handleRegisterModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="wp-modal-box wp-modal-box-select-ir">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h5
              className="wp-add-comp-modal-header font-sans"
              style={{ display: "inline-block" }}
            >
              Select Register Type
            </h5>
            <div

              className="wp-modal-box-select-ir-closebtn"
              onClick={handleRegisterModalClose}
              style={{ cursor: "pointer" }}>
              <span style={{ cursor: "pointer" }} className="font-sans">Close</span>

            </div>
          </Typography>
          <p className="wp-add-comp-modal-p">
            Select from the registers to prepare reporting and consolidating
            financial statements
          </p>
          <div className="row">
            {activePlandata.includes('Investment') ?
              (<div className="col-4">
                <Card
                  className={`register-modal-card ${selectedValue === "a" ? "register-modal-card-active" : ""
                    }`}
                >
                  <CardContent>
                    <Radio
                      checked={selectedValue === "a"}
                      onChange={handleChange}
                      value="a"
                      disabled={permissions?.companyData[0]?.components[0]?.permission?.create === false}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      sx={{
                        color: "#03565A",
                        '&.Mui-checked': {
                          color: "#03565A",
                        },
                      }}
                    />
                    <Typography
                      className="wp-comp-added-p font-sans"
                      style={{ paddingTop: "12px", paddingBottom: "20px" }}
                    >
                      Investment
                    </Typography>
                  </CardContent>
                </Card>
              </div>) 
               :
               (<div className="col-4">
                <Card
                  className={`register-modal-card ${selectedValue === "a" ? "register-modal-card-active" : ""
                    }`}
                >
                  <CardContent>
                    <Radio
                      checked={selectedValue === "a"}
                      onChange={handleChange}
                      value="a"
                      disabled
                      // disabled={permissions?.companyData[0]?.components[0]?.permission?.create === false}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      sx={{
                        color: "#03565A",
                        '&.Mui-checked': {
                          color: "#03565A",
                        },
                      }}
                    />
                    <Typography
                      className="wp-comp-added-p font-sans"
                      style={{ paddingTop: "12px", paddingBottom: "20px" }}
                    >
                      Investment
                    </Typography>
                  </CardContent>
                </Card>
              </div>)}

            {activePlandata.includes('Loans') ? (
              <div className="col-4">
              <Card
                className={`register-modal-card ${selectedValue === "b" ? "register-modal-card-active" : ""
                  }`}
              >
                <CardContent>
                  <Radio
                    checked={selectedValue === "b"}
                    onChange={handleChange}
                    value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-l abel": "B" }}
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Loans
                  </Typography>
                </CardContent>
              </Card>
            </div>
            ) : 
              (<div className="col-4">
              <Card
                className={`register-modal-card ${selectedValue === "b" ? "register-modal-card-active" : ""
                  }`}
              >
                <CardContent>
                  <Radio
                    checked={selectedValue === "b"}
                    onChange={handleChange}
                    value="b"
                    name="radio-buttons"
                    disabled
                    inputProps={{ "aria-l abel": "B" }}
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Loans
                  </Typography>
                </CardContent>
              </Card>
            </div>) }
            
           {activePlandata.includes('Purchases')?(<div className="col-4">
              <Card className={`register-modal-card ${selectedValue === "c" ? "register-modal-card-active" : ""
                  }`}>
                <CardContent>
                  <Radio
                    checked={selectedValue === "c"}
                    onChange={handleChange}
                    value="c"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "C" }}
                    
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Purchase
                  </Typography>
                </CardContent>
              </Card>
            </div>):(<div className="col-4">
              <Card className="register-modal-card">
                <CardContent>
                  <Radio
                    checked={selectedValue === "c"}
                    onChange={handleChange}
                    value="c"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "C" }}
                    disabled
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Purchase
                  </Typography>
                </CardContent>
              </Card>
            </div>)}
            
            {activePlandata.includes('Leases') ?(<div className="col-4" style={{ marginTop: "20px" }}>
              <Card
                className={`register-modal-card ${selectedValue === "d" ? "register-modal-card-active" : ""
                  }`}
              >
                <CardContent>
                  <Radio
                    checked={selectedValue === "d"}
                    onChange={handleChange}
                    value="d"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "D" }}
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Lease
                  </Typography>
                </CardContent>
              </Card>
            </div>):
            (<div className="col-4" style={{ marginTop: "20px" }}>
              <Card
                className={`register-modal-card ${selectedValue === "d" ? "register-modal-card-active" : ""
                  }`}
              >
                <CardContent>
                  <Radio
                    checked={selectedValue === "d"}
                    onChange={handleChange}
                    value="d"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "D" }}
                    disabled
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Lease
                  </Typography>
                </CardContent>
              </Card>
            </div>)}
            
           {activePlandata.includes('Fixed Assets') ? (
            <div className="col-4" style={{ marginTop: "20px" }}>
            <Card
              className={`register-modal-card ${selectedValue === "e" ? "register-modal-card-active" : ""
                }`}
            >
              <CardContent>
                <Radio
                  checked={selectedValue === "e"}
                  onChange={handleChange}
                  value="e"
                  name="radio-buttons"
                  inputProps={{ "aria-l abel": "E" }}
                  sx={{
                    color: "#03565A",
                    '&.Mui-checked': {
                      color: "#03565A",
                    },
                  }}
                />
                <Typography
                  className="wp-comp-added-p font-sans "
                  style={{ paddingTop: "12px", paddingBottom: "20px" }}
                >
                  FAR
                </Typography>
              </CardContent>
            </Card>
          </div>
            ) : (
              <div className="col-4" style={{ marginTop: "20px" }}>
              <Card className="register-modal-card">
                <CardContent>
                  <Radio
                    checked={selectedValue === "e"}
                    onChange={handleChange}
                    value="e"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "E" }}
                    disabled
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    FAR
                  </Typography>
                </CardContent>
              </Card>
            </div>)}
            
           {activePlandata.includes('Receivables') ? (<div className="col-4" style={{ marginTop: "20px" }}>
              <Card className={`register-modal-card ${selectedValue === "f" ? "register-modal-card-active" : ""
                }`}>
                <CardContent>
                  <Radio
                    checked={selectedValue === "f"}
                    onChange={handleChange}
                    value="f"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "F" }}
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Receivable
                  </Typography>
                </CardContent>
              </Card>
            </div>) : (<div className="col-4" style={{ marginTop: "20px" }}>
              <Card className={`register-modal-card ${selectedValue === "f" ? "register-modal-card-active" : ""
                }`}>
                <CardContent>
                  <Radio
                    checked={selectedValue === "f"}
                    onChange={handleChange}
                    value="f"
                    name="radio-buttons"
                    disabled
                    inputProps={{ "aria-label": "F" }}
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Receivable
                  </Typography>
                </CardContent>
              </Card>
            </div>) }
            
         {activePlandata.includes('Payables') ? (<div className="col-4" style={{ marginTop: "20px" }}>
              <Card className={`register-modal-card ${selectedValue === "g" ? "register-modal-card-active" : ""
                }`}>
                <CardContent>
                  <Radio
                    checked={selectedValue === "g"}
                    onChange={handleChange}
                    value="g"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "G" }}
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Payable
                  </Typography>
                </CardContent>
              </Card>
            </div>): (<div className="col-4" style={{ marginTop: "20px" }}>
              <Card className={`register-modal-card ${selectedValue === "g" ? "register-modal-card-active" : ""
                }`}>
                <CardContent>
                  <Radio
                    checked={selectedValue === "g"}
                    onChange={handleChange}
                    value="g"
                    name="radio-buttons"
                    disabled
                    inputProps={{ "aria-label": "G" }}
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Payable
                  </Typography>
                </CardContent>
              </Card>
            </div>)}
            {activePlandata.includes('Sales') ? (<div className="col-4" style={{ marginTop: "20px" }}>
              <Card className={`register-modal-card ${selectedValue === "h" ? "register-modal-card-active" : ""
                }`}>
                <CardContent>
                  <Radio
                    checked={selectedValue === "h"}
                    onChange={handleChange}
                    value="h"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "H" }}
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Sales
                  </Typography>
                </CardContent>
              </Card>
            </div>): (<div className="col-4" style={{ marginTop: "20px" }}>
              <Card className={`register-modal-card ${selectedValue === "h" ? "register-modal-card-active" : ""
                }`}>
                <CardContent>
                  <Radio
                    checked={selectedValue === "h"}
                    onChange={handleChange}
                    value="h"
                    name="radio-buttons"
                    disabled
                    inputProps={{ "aria-label": "H" }}
                    sx={{
                      color: "#03565A",
                      '&.Mui-checked': {
                        color: "#03565A",
                      },
                    }}
                  />
                  <Typography
                    className="wp-comp-added-p font-sans "
                    style={{ paddingTop: "12px", paddingBottom: "20px" }}
                  >
                    Sales
                  </Typography>
                </CardContent>
              </Card>
            </div>)}
            
          </div>

          <div className="blankspace20"></div>
          <div className="row">
            <div className="col-12 text-center">
              <a id="linkA" href="#" style={{ display: "none" }}></a>
              <a
                id="linkB"
                href="https://loan.test.myfinalyst.com"
                rel="noreferrer"
              ></a>
              <a
                id="linkD"
                href="https://lease.test.myfinalyst.com"
                rel="noreferrer"
              ></a>
              <a
                id="linkF"
                href="https://receivable.test.myfinalyst.com"
                rel="noreferrer"
              >
              </a>
              <a
                id="linkG"
                href="https://payable.test.myfinalyst.com"
                rel="noreferrer"
              >
              </a>
              <button
                className="wp-btn-fill"
                onClick={() => {
                  Chnageradiofunction();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default RegisterModal;
