import axios from 'axios';
const auth = localStorage.getItem("auth_token");

const headers = {
    "x-auth-token": auth,
};

export const getOneBookClosure = async (id, data) => { 
    return axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosure/get?id=${id}`, data);
}
export const createBookClosure = async (data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosure/create`, data);
}
export const addCompany = async (id, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosure/addCompany?id=${id}`, data);
}
export const deleteBookClosure = async (id) => {
    return await axios.delete(`${process.env.REACT_APP_FSCP_URL}bookClosure/delete?id=${id}`);
}

export const getBookClosureByCompanyIds = async (companyIds) => {
    return await axios.get(`api/v1/workspace/get-book-closure-company?companyIds=${companyIds}`, { headers })
}
export const getApproverUserOptions = async (companyId) => {
    return await axios.get(`api/v1/workspace/get-book-closure-user?companyId=${companyId}`, { headers })
}

export const addTask = async (data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/add`, data);
}
export const updateTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/update?id=${taskId}`, data);
}
export const getOneTask = async (taskId) => { 
    return axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/get?id=${taskId}`);
}
export const deleteTask = async (taskId) => {
    return await axios.delete(`${process.env.REACT_APP_FSCP_URL}bookClosure/delete?id=${taskId}`);
}

export const getLibraryList = async (bookClosureId, companyID) => { 
    return axios.get(`${process.env.REACT_APP_FSCP_URL}bookClosureLibrary/get?id=${bookClosureId}&companyID=${companyID}`);
}
export const importTasksFromLibrary = async (bookClosureId, data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/import?id=${bookClosureId}`, data);
}
export const addTaskInLibrary = async (bookClosureId, data) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosureLibrary/add`, data);
}
export const updateTaskInLibrary = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureLibrary/update?id=${taskId}`, data);
}
export const deleteTaskInLibrary = async (taskId) => {
    return await axios.delete(`${process.env.REACT_APP_FSCP_URL}bookClosureLibrary/delete?id=${taskId}`);
}

// ? Submit task, when the preparer wants to submit an "open" or "returned" task.
export const submitTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/submit?id=${taskId}`, data);
}
// ? Save and submit task, when the preparer wants to save and submit a new task.
export const saveAndSubmitTask = async ( data ) => {
    return await axios.post(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/saveAndSubmit`, data);
}
// ? Approve task, when approver wants to approve "submitted" task.
export const approveTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/approve?id=${taskId}`, data);
}
// ? Return task, when approver wants to return "submitted" task.
export const returnTask = async (taskId, data) => {
    return await axios.patch(`${process.env.REACT_APP_FSCP_URL}bookClosureTask/return?id=${taskId}`, data);
}