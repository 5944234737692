
import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './notes.css'
import {useDispatch,useSelector} from 'react-redux'
import * as actionTypes from '../../redux/actionTypes'

import Note4 from './Note4'
import Note4B from './Note4B'
import Note3 from './Note3'
import Notetype3 from './Notetype3'
import NotesData from './NotesData'
import Note8 from './Note8'
import NotePayble from './Notepayble'
import Pattern6 from './Pattern6';
import Pattern3 from './Pattern3';
import Pattern5 from './Pattern5';
import Pattern5b from './Pattern5b';
import Pattern7 from './Pattern7';
import NoteLeases from './NoteLeases';
import NoteEquiteShareCaptial from './NoteEquiteShareCaptial';
import { fetchTTBSData } from '../../redux/actions/TemplateTabs/actionBS';
import { fetchTTPNLData } from '../../redux/actions/TemplateTabs/actionPNL';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: '50vh',

    
  },
  tabs: {
    maxHeight:'100vh',
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop:'3rem',
    paddingBottom:'3rem',
    background:'linear-gradient(45deg, transparent, #E5EEEE)',    
    color:'Black',
    fontSize:'1rem',
    width: '260px',
  },
  tab: {
      padding: '12px 12px',
      overflow: 'hidden',
      position: 'relative',
      fontSize: '5px',
      boxSizing: 'border-box',
      minHeight: '55px',
      textAlign: 'center',
      flexShrink: 0,
      fontWeight: "500",
      letterSpacing: '0.02857em',
      textTransform: 'none',
  },
  TabPanel: {
    width:'100%'
  },
}));

const heading = {
  width:"100%",
  height:'50px',
  backgroundColor:'#E5EEEE',
  position:'sticky',
  top:'80px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  // border:'1px solid teal'

}

const headingText = {
  fontSize:'1.2 rem',
  fontWeight:'700'

}

export default function VerticalTabs(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [math, setMath] = useState('');
  const [noteData, setNoteData] = useState([]);
  const NoteNo = useSelector(initialState => initialState.reducer.blNoteNo) 
  const pnlNoteNo = useSelector((initialState) => initialState.PNLReducer.notePnlNo);

  const tb_id = localStorage.getItem("tb_id");
  const project_id = localStorage.getItem("project_id");
  const { isSuccess, response, error_message, loading, set_payload } = useSelector((state) => state.TempTabPNLReducer)
  const rows = response?.pnl_statement ?? [];
  const fsRows = Object.keys(rows);

  const handleChange = (event, newValue) => {
    const noteNo = noteData.find((note, index) => index === newValue)?.notes_index || 0;
    dispatch({ type: "NOTE_PNL_NUMBER", payload: noteNo });
    setValue(newValue);
  };

  useEffect(() => {
    if(noteData.length > 0){
      const noteInd = noteData.findIndex(note => note.notes_index === pnlNoteNo);
      setValue(noteInd >= 0 ? noteInd : 0)
    }
  }, [pnlNoteNo, noteData])

  const fetchPnlSheetData = async () => {
    if(!isSuccess || set_payload !== JSON.stringify({project_id, tb_id})){
      dispatch(fetchTTPNLData(project_id, tb_id))
    }
  }

  useEffect(() => {
    (async () => {
      await fetchPnlSheetData();
    })()
  }, [])

  useEffect(() => {
    setValue(NoteNo)
  }, [NoteNo])

  const sortingNotes = (notesData) => {
    const output = notesData.reduce((result, currentItem) => {
        const existingItem = result.find(item => item.notes_grp === currentItem.notes_grp && item.notes_no === currentItem.notes_no);
      
        if (existingItem) {
          if (!Array.isArray(existingItem.fs_grp)) {
            existingItem.fs_grp = [existingItem.fs_grp];
          }
      
          if (!existingItem.fs_grp.includes(currentItem.fs_grp)) {
            existingItem.fs_grp.push(currentItem.fs_grp);
          }
        } else {
          result.push({ ...currentItem });
        }
      
        return result;
      }, []);
    return output;
  }

  useEffect(()=> {
    const notes_ = Object.keys(rows).map((key, index)=>rows[key]
    .map((notes)=>{ return {
      fs_grp: key,
      notes_grp: notes.notes_grp,
      notes_no: notes.notes_no,
      notes_index: notes.notes_index
    }
    })).flat()
    const resultNotes = sortingNotes(notes_);
    setNoteData(resultNotes );
  },[rows])



  return (
    <div className={classes.root}>
    <Tabs
    orientation="vertical"
    variant="scrollable"
    value={value}
    onChange={handleChange}
    aria-label="Vertical tabs example"
    className={`${classes.tabs} bsnotetab`}
    TabIndicatorProps={{ style: { backgroundColor: '#03565A',width:"3px" } }}
  >
    {noteData.map((note, key) => (
      <Tab className={`${classes.tab} font-sans`} label={'Note - ' + note?.notes_grp} key={key} {...a11yProps(0)} />
    ))}
  </Tabs>
        {noteData.map((note, key)=>(
          <TabPanel  className={classes.TabPanel} value={value} index={key} key={key}>
            <div style={heading}>
              <p className="font-sans" style={headingText}>{'Note ' + note?.notes_index + ' - ' + note?.notes_grp}</p>

            </div>
            <NotesData
             setShowCreateInput={props.setShowCreateInput}  
             note='3' 
             FinalStr='SET_NOTE3' 
             NoteStr='note3' 
             setSid={props.setSid} 
             setNid={props.setNid} 
             setNoteNum={props.setNoteNum}
             notes_no={note.notes_no}
             fs_grp={note.fs_grp}
            />
          </TabPanel>
        ))}
    </div>
  );
}