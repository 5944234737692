import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const input = {
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    height: "2.2rem", padding: "0.3rem", fontWeight: "500", margin: "0"
}
const shadow = {
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
}

const CommonTextEditor = ({ value, onChange, data, subOnChange, tabIx, label }) => {
    return (
        <>
            <div>
                <CKEditor
                className="font-sans"
                    editor={ClassicEditor}
                    data={value}
                    onReady={(editor) => {
                        console.log("CKEditor5 React Component is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        onChange(data)
                        // console.log({ event, editor, data });
                    }}
                />
                {
                    data && data.length > 0 && data.map((sub, subIx) => (
                        <div>
                        {label === "Hedging activities and derivatives" && subIx === 1 &&
                            <div className='ml-3 d-flex flex-column'>
                                <b>Derivatives designated as hedging instruments</b>
                                <b>Cash flow hedges</b>
                            </div>
                        }
                        <div key={subIx} style={shadow} className={`mx-3 my-2 p-1 pb-2`}>
                            <div className='d-flex justify-content-between my-2 mx-2' style={{ gap: "1rem" }}>
                                {sub?.text1 && <p style={input} className='w-50' >{sub?.text1}</p>}
                                {sub?.text2 && <p style={input} className='w-50' >{sub?.text2}</p>}
                            </div>
                            <CKEditor
                            className="font-sans"
                                editor={ClassicEditor}
                                data={sub?.message}
                                onReady={(editor) => {
                                    console.log("CKEditor5 React Component is ready to use!", editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    subOnChange(data, subIx, tabIx)
                                }}
                            />
                        </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default CommonTextEditor