// import Reacct from 'react'
import { Navbar } from '../../Components/Navbar'
import { useHistory } from 'react-router'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import "./finalisefs.css"
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import Preview from './Preview';
import ExportExcel from './exportExcel';

// import "./finalisefs.css";


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const FinaliseFs = () => {
    return (
        <>
            <div>
                <Navbar text="Financial Year: 2021 - 2022" />
                <div className='px-5'>
                    <div className="row">
                        <div className="col-md-8">
                            <button
                                className="rounded back-btn btn btn-border-none pl-0 mt-4"
                            >
                                <div className="d-flex  custom-font-600 custom-common-theme-text-color">
                                    <ArrowBackIcon className="mr-1" /> Back
                                </div>
                            </button>
                        </div>
                        <div className="col-md-4">
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginLeft: "150px" }}>
                                <ExportExcel />
                                <div className='d-flex align-items-center'>
                                    <span><PictureAsPdfIcon className='mt-3' style={{ width: "24px", height: "24px" }} /></span>
                                    <p className="mb-0 ml-2 font-size-16" style={{ color: "#343434" }}>Export as XL</p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div><h6 className='font-weight-bold' style={{ lineHeight: "28px" }}>Finalise your Financial Statements</h6></div>
                            <div style={{ height: "100%", border: "2px solid #C3C8D3", borderRadius: "15px" }}>
                                <div className='d-flex mt-4 ml-3' style={{ gap: "10px" }}>
                                    <div style={{ display: "flex", flexDirection: "column", width: "250px" }}>
                                        <label htmlFor="name">Units <span style={{ color: "red" }}>*</span></label>
                                        <TextField id="name" variant="outlined" />
                                    </div>
                                    <div>
                                        <div style={{ display: "flex", flexDirection: "column", width: "250px" }}>
                                            <label htmlFor="name">Decimals <span style={{ color: "red" }}>*</span></label>
                                            <TextField id="name" variant="outlined" />
                                        </div>
                                    </div>
                                </div>
                                <div className='p-3'>


                                    <Card style={{ borderRadius: "10px" }}>
                                        <div style={{ backgroundColor: "#E5EEEE", height: "40px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", }}>
                                            <h6 className='p-2 ml-3'>Header</h6>
                                        </div>
                                        <CardContent>
                                            <div>
                                                <Typography className='font-size-16 custom-font-600' sx={{ fontSize: 16, lineHeight: "20px", color: "#000000" }} gutterBottom>
                                                    Add Header to:
                                                </Typography>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>Balance Sheet</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>P & L</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>SOCIE</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>Cashflow</h6>
                                                </div>
                                                <div  className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>All pages</h6>
                                                </div>

                                            </div>
                                            <div className='mt-2 ' >
                                                <TextField id="name" placeholder='Hello World' variant="outlined" className='w-100 finalise-textcolor rounded' />
                                            </div>
                                            <div className=' d-flex' style={{ gap: "10px" }}>
                                                <div className='mt-2 rounded d-flex align-items-center justify-content-center ' style={{ height: "55px", width: "180px", backgroundColor: "#F8F8F8", borderRadius: "10px" }}>
                                                    <div className='d-flex align-items-center'>
                                                        <DescriptionIcon />
                                                        <p className="ml-2 font-size-16 custom-font-500">Demo.Png</p>
                                                    </div>
                                                    <CloseIcon className='custom-redcolor ml-1 mt-1' style={{ width: "15px", height: "15px", }} />
                                                </div>
                                                <div className='mt-2 d-flex align-items-center justify-content-center ' style={{ height: "55px", width: "180px", backgroundColor: "#F8F8F8", border: "1px dashed #C3C8D3", borderRadius: "10px" }}>
                                                    <div className='d-flex align-items-center'>
                                                        <ImageIcon style={{ color: "#717171" }} />
                                                        <p className="ml-2 font-size-16 custom-font-500">Add image</p>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='mt-3'>
                                                <Typography className='font-size-16 custom-font-600' sx={{lineHeight: "20px", color: "#000000" }} gutterBottom>
                                                    Add 2nd Header to:
                                                </Typography>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>Balance Sheet</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>P & L</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>SOCIE</h6>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>Cashflow</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>All pages</h6>
                                                </div>

                                            </div>
                                            <div className='mt-2 ' >
                                                <TextField id="name" variant="outlined" placeholder='Enter header text' className='w-100 finalise-textcolor rounded' />
                                            </div>
                                            <div>
                                                <div className='mt-3 d-flex align-items-center justify-content-center w-100 ' style={{ height: "90px", backgroundColor: "#F8F8F8", border: "1px dashed #C3C8D3", borderRadius: "10px" }}>
                                                    <div className='d-flex align-items-center'>
                                                        <ImageIcon style={{ color: "#717171" }} />
                                                        <p className="ml-2 font-size-16 custom-font-500">Add image</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>



                                </div>
                                <div className='p-3'>


                                    <Card style={{ borderRadius: "15px" }}>
                                        <div style={{ backgroundColor: "#E5EEEE", height: "40px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", }}>
                                            <h6 className='p-2 ml-3'>Footer</h6>
                                        </div>
                                        <CardContent>
                                            <div>
                                                <Typography className='font-size-16 custom-font-600' sx={{ lineHeight: "20px", color: "#000000" }} gutterBottom>
                                                    Add Header to:
                                                </Typography>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>Balance Sheet</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>P & L</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>SOCIE</h6>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>Cashflow</h6>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        {...label}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: '#03565A ', // Color when checked
                                                            },
                                                        }}
                                                    />
                                                    <h6 className='mr-2 mt-2'>All pages</h6>
                                                </div>

                                            </div>
                                            <div className='mt-2 ' >
                                                <TextField id="name" placeholder='Hello World' variant="outlined" className='w-100 finalise-textcolor rounded' />
                                            </div>
                                            <div>

                                                <div className='mt-3 d-flex align-items-center justify-content-center w-100 ' style={{ height: "90px", backgroundColor: "#F8F8F8", border: "1px dashed #C3C8D3", borderRadius: "10px" }}>
                                                    <div className='d-flex align-items-center'>
                                                        <ImageIcon style={{ color: "#717171" }} />
                                                        <p className="ml-2 font-size-16 custom-font-500">Add image</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                                <div className='px-3'>
                                    <h6 className='custom-font-600 font-size-16'>Export pages</h6>
                                </div>
                                <div className='px-2'>
                                    <div className='w-75 d-flex justify-content-between' >
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox
                                                    {...label}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#03565A', // Color when checked
                                                        },
                                                    }}
                                                />
                                                <h6 className='mr-2 mt-2'>Export all</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-6 mt-2">
                            <div><h6 className='custom-font-600 font-size-16'>Preview</h6></div>
                            <div style={{  border: "2px solid #C3C8D3", borderRadius:"12px" }}>
                                <Preview />
                            </div>
                        </div>
                    </div>
                    

                </div>

                <div className='mt-5 mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                <Button  className='custom-common-theme-bg-color' style={{width:"150px" ,height:"50px",color:"white"}}>Save</Button>
              </div>
              


            </div>
        </>
    )
}

export default FinaliseFs