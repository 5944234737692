import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import * as actionTypes from "../../redux/actionTypes";
import { screenStyle, headStyle, headerStyle, containerStyle, paraStyle } from "../Styles/switchModeStyle";
import switchModeSvg from "../../assets/switchMode.svg";


const SwitchMode = (props) => {
  let history = useHistory();
  const project_id = localStorage.getItem("project_id");
  const [click, setClick] = useState("");
  function handleClose(e) {
    if (e.target.classList.contains("screenDiv")) {
      props.close(false);
    }
  }
  function handleSelect(mode) {
    console.log("hey clicked ");
    if (mode === "1") {
      console.log(props.currentMode, "hey clicked ");
      if (props.currentMode === "1") {
        props.close(false);
      }
      history.push(`/Conventional/${project_id}`);
    }
    if (mode === "2") {
      if (props.currentMode === "2") {
        props.close(false);
      }
      history.push(`/templateV2/${project_id}`);
    }
  }

  return (
    <motion.div
      style={screenStyle}
      className="screenDiv"
      onClick={handleClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div
        style={containerStyle}
        className="col-md-10 col-sm-10"
        initial={{ y: "-5vh" }}
        animate={{ y: 0 }}
      >
        <div style={headStyle}>
          <div style={{ paddingTop: "0", marginLeft: "30%" }}>
            <h2 style={headerStyle}>{props.name}</h2>
            <p style={paraStyle}>
              This feature allows you to switch to a more intutive method of
              mapping{" "}
            </p>
          </div>
          <div style={{ paddingTop: "13px", marginLeft: "auto" }}>
            <h5
              style={{
                color: "var(--clr-accent)",
                fontSize: "1rem",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => {
                props.close(false);
              }}
            >
              Close
            </h5>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "5rem 0",
          }}
        >
          <div className="cMode">
            <motion.div
              className={click === "1" ? "modeImg-1_active" : "modeImg-1"}
              onClick={() => handleSelect("1")}
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.1 },
              }}
            ></motion.div>
            <div className="modeContent">
              <h1>Conventional Mode</h1>
              <p>
                This mode allows you to assign individual ledgers
                <br />
                to respective financial statements, and their notes
              </p>
            </div>
          </div>
          <div className="tMode">
            <motion.div
              className={click === "2" ? "modeImg-2_active" : "modeImg-2"}
              onClick={() => handleSelect("2")}
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.1 },
              }}
            ></motion.div>
            <div className="modeContent">
              <h1>Template Mode</h1>
              <p>
                This mode allows you to assign one or more <br />
                ledgers to a given FS template, and all you have to
                <br />
                do is drag and drop the ledgers.
              </p>
            </div>
          </div>
        </div>
        <div></div>
      </motion.div>
    </motion.div>
  );
};

export default SwitchMode;
