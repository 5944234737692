import React from "react";
import { Avatar, Badge, Box, Button, Modal } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "85%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #03565A",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const PushNotification = ({
  openModal,
  handleCloseModal,
  notifications,
  userName,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div>
              <Badge badgeContent={notifications?.length} color="error">
                <h2>Notifications</h2>
              </Badge>
              {notifications?.length > 0 &&
                notifications.slice(0, 4).map((notification) => (
                  <div>
                    <div className="d-flex flex-row p-2 align-items-center">
                      <div>
                        <Avatar className="custom-common-theme-bg-color">
                          {userName(
                            notification.sender_id === user._id
                              ? notification.sender_id
                              : notification.sender_id
                          ).charAt(0)}
                        </Avatar>
                      </div>
                      <div className="ml-2">
                        {notification?.sender_id === user._id
                          ? "You"
                          : userName(notification?.sender_id)}{" "}
                        {notification?.type} for {notification?.description}
                      </div>
                    </div>
                    <span className="d-flex flex-row-reverse font-size-12 font-weight-bold custom-common-theme-text-color">
                      {moment(notification.createdAt).fromNow()}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="d-flex flex-row-reverse mt-4">
            <Button
              className="custom-common-theme-bg-color text-white text-capitalize w-100"
              disabled={user.isAdmin==="Yes"}
              onClick={() => {
                history.push("/Notification");
                handleCloseModal();
              }}
            >
              View All
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PushNotification;
