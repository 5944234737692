import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { socket } from "../../../services/socket/socket";
import { useEffect } from "react";
import {
  UpdatePreset,
  getPrepareConsole,
  getProjects,
  getfsData,
} from "../../../Console/Component/services/apiHandler";
import { useState } from "react";
import AssociateBrowseModal from "../../../Console/Component/modal/associateModal";
import { useDispatch, useSelector } from "react-redux";
import { setAllChecksPreset } from "../../../redux/actions/actionPreset";
import { fetchPrepareConsoleData } from "../../../redux/actions/actionPrepareConsole";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchSubsidiaryConsoleData } from "../../../redux/actions/actionSubsidiaryData";

const style_v2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1400,
  height: 670,
  bgcolor: "background.paper",
  outline: "none",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const SubsidiarySelectModal = (props) => {
  const {
    isopen,
    handleIsClose,
    value,
    handleChange,
    workspaceData,
    prepareConsoleId,
    presetIdData,
    CompanyName,
  } = props;
  const [openassociate, setOpenAssociate] = useState(false);
  const [associateId, setAssociateId] = useState("");
  const [consoldata, setConsolData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [allcheckdata, setAllcheckData] = useState([]);
  const [selectCheckData, setSelectCheckData] = useState([]);
  const [selectCheckDataAssociate, setSelectCheckDataAssociate] = useState([]);
  const [tableData, SetInvestmentRegister] = useState([]);
  const [selectedProject, setSelectProject] = useState("");
  const [fieldOfIr, setFieldOfIr] = useState("");
  const subsidiaryData = useSelector((state) => state.SubsidiaryConsoleReducer);

  const prepareConsoleData = useSelector(
    (state) => state.PrepareConsoleReducer
  );

  const { prepareConsole } = useSelector((state) => state.miscellaneousReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    // fetchPreConsoleData(prepareConsoleId);
    getCompanyData();
    if (prepareConsole?.link_company && !subsidiaryData.isSuccess) {
      dispatch(fetchSubsidiaryConsoleData(prepareConsole.link_company));
    }

    if (presetIdData) {
      dispatch(fetchPrepareConsoleData(prepareConsole._id));
    }
    fetchInvestCompany();
  }, [prepareConsoleId, presetIdData]);

  const fetchInvestCompany = async () => {
    try {
      const id = prepareConsole?.link_company;
      const res = await getfsData(id);
      if (res.status === 200) {
        SetInvestmentRegister(res?.data?.getCompanyData);
      }
    } catch (err) {
      console.log(err.response);
    }
  };
  const browseConsol = (project_id, field) => {
    setAssociateId(project_id);
    setOpenAssociate(true);
    setFieldOfIr(field);
  };
  const handleClose = () => {
    setOpenAssociate(false);
  };
  const fetchPreConsoleData = async () => {
    try {
      let response = await getPrepareConsole(prepareConsoleId);
      setConsolData(response.data);
    } catch (err) {
      console.log("err", err.response);
    }
  };
  const uploadPresetToSubsidiary = (project_id, prepare_console_id, field) => {
    if (field === "Subsidiary Company") {
      const objIndex =
        prepareConsoleData?.response?.getOnePreCompany[0]?.subsidiary_upload_preset?.findIndex(
          (data) => data.prepare_console_id === prepare_console_id
        );
      if (objIndex === -1) {
        prepareConsoleData?.response?.getOnePreCompany[0]?.subsidiary_upload_preset.push(
          {
            project_id: project_id,
            prepare_console_id: prepare_console_id,
          }
        );
      } else {
        prepareConsoleData.response.getOnePreCompany[0].subsidiary_upload_preset[
          objIndex
        ]["project_id"] = project_id;
      }
      setConsolData(prepareConsoleData?.response?.getOnePreCompany[0]);
    } else {
      const objIndex =
      prepareConsoleData?.response?.getOnePreCompany[0]?.associate_upload_preset?.findIndex(
        (data) => data.prepare_console_id === prepare_console_id
      );
    if (objIndex === -1) {
      prepareConsoleData?.response?.getOnePreCompany[0]?.associate_upload_preset.push(
        {
          project_id: project_id,
          prepare_console_id: prepare_console_id,
        }
      );
    } else {
      prepareConsoleData.response.getOnePreCompany[0].associate_upload_preset[
        objIndex
      ]["project_id"] = project_id;
    }
    setConsolData(prepareConsoleData?.response?.getOnePreCompany[0]);
    }
  };
  const getCompanyData = async () => {
    try {
      const response = await getProjects();
      setProjectsData(response.data.project);
    } catch (err) {
      console.log("err", err.response);
    }
  };
  const getProjectName = (p_id) => {
    try {
      if (!p_id) return "";
      const nameOfType = projectsData.filter((adj) => adj._id === p_id);
      const result = nameOfType[0]?.project_name
        ? nameOfType[0]?.project_name
        : "";
      return result;
    } catch (err) {
      console.log("err", err.message);
    }
  };
  const newGetProjectName = (subsidiary_id, getId = false) => {
    try {
      const subsidiary_upload_preset =
        prepareConsoleData?.response?.getOnePreCompany[0]
          ?.subsidiary_upload_preset;
      let projectData = subsidiary_upload_preset?.find(
        (obj) => obj?.prepare_console_id === subsidiary_id
      );
      if (getId) {
        return projectData?.project_id;
      }
      return getProjectName(projectData?.project_id);
    } catch (err) {
      console.log("err", err.message);
    }
  };

  const consolidateChange = (e, data, project_id,field) => {
    if (field === "Subsidiary Company") {
      if (e.target.checked) {
        let dataValue = {
          all_data: data,
          project_id: project_id,
        };
        setAllcheckData([...allcheckdata, dataValue]);
  
        let checkData = {
          Ir_id: data._id,
          project_id: project_id,
        };
        setSelectCheckData([...selectCheckData, checkData]);
      } else {
        setAllcheckData(
          allcheckdata.filter((item) => item.project_id !== project_id)
        );
        setSelectCheckData(
          selectCheckData.filter((item) => item.Ir_id !== data._id)
        );
      }
    } else {
      if (e.target.checked) {
        let checkData = {
          Ir_id: data._id,
          project_id: project_id,
        };
        setSelectCheckDataAssociate([...selectCheckDataAssociate, checkData]);
      } else {
        setSelectCheckDataAssociate(
          selectCheckDataAssociate.filter((item) => item.Ir_id !== data._id)
        );
      }
    }
    
  };

  const saveModalCheck = async () => {
    let data = {
      Consolidate: selectCheckData,
      AssociateConsolidate:selectCheckDataAssociate
    };
    const res = await UpdatePreset(data, prepareConsole._id);
    if (res.status === 200) {
      socket.emit("request-prepare-console", {
        prepare_console_id: prepareConsole.link_company,
      });
      dispatch(setAllChecksPreset(selectCheckData));
      handleIsClose();
    }
    console.log(data);
  };

  const checkConsolidated = (ir_id) => {
    try {
      const exist =
        prepareConsoleData?.response?.getOnePreCompany[0]?.consolidation.find(
          (consol) => consol.Ir_id === ir_id
        );
      if (exist) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log("error", err.message);
      return false;
    }
  };

  const checkConsolidatedAssociate = (ir_id)=>{
    try {
      const exist =
        prepareConsoleData?.response?.getOnePreCompany[0]?.AssociateConsolidate.find(
          (consol) => consol.Ir_id === ir_id
        );
      if (exist) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log("error", err.message);
      return false;
    }
  }

  return (
    <>
      <Modal
        open={isopen}
        onClose={handleIsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style_v2}>
          <div className="d-flex justify-content-between">
            <div>
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "600",
                }}
              >
                {" "}
                Investees
              </div>
            </div>
            <div className="d-flex" onClick={handleIsClose}>
              <div className="mr-4 mt-1" style={{ fontSize: "18px",cursor:"pointer" }}>
                Cancel
              </div>
              <div>
                <Button
                  variant="contained"
                  style={{
                    width: "150px",
                    height: "40px",
                    backgroundColor: "#03565A",
                    color: "white",
                    borderRadius: "5px",
                    textTransform: "none",
                  }}
                  onClick={saveModalCheck}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
          <div className="w-50" style={{ fontSize: "12px" }}>
            Upload the presets of subsidiary companies to prepare your
            consolidated financial statement
          </div>
          <div
            style={{
              width: "100%",
              height: "50px",
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginTop: "15px",
              }}
            >
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  sx={{
                    "& button": { borderRadius: 2 },
                    "& button": {
                      border: "1px solid black",
                      borderRadius: "2px",
                    },
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textTransform: "none",
                      width: "200px",
                    }}
                    label="Subsidiary companies"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textTransform: "none",
                      marginLeft: "10px",
                      width: "200px",
                    }}
                    label="Joint Ventures"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{
                      color: "black",
                      fontWeight: "600",
                      textTransform: "none",
                      marginLeft: "10px",
                      width: "200px",
                    }}
                    label="Investments"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel
                value={value}
                style={{ marginTop: "10px" }}
                index={0}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>INVEST REGISTER NAME</TableCell>
                        <TableCell align="left">INVESTMENT VALUE</TableCell>
                        {/* <TableCell align="center">
                          INVESTMENT VALUE(PY)
                        </TableCell> */}
                        <TableCell align="center">HOLDING % </TableCell>
                        <TableCell align="center">
                          UPLOADING COMPANY PRESET
                        </TableCell>
                        <TableCell align="center">VALIDATION CHECK</TableCell>
                        <TableCell align="center">ADD TO PARENT FS</TableCell>
                      </TableRow>
                    </TableHead>
                    {subsidiaryData.loading && !subsidiaryData.response ? (
                      ""
                    ) : subsidiaryData?.response?.getSubsidiaryData?.length >
                      0 ? (
                      <TableBody>
                        <>
                          {subsidiaryData?.response?.getSubsidiaryData
                            ?.filter(
                              (name) =>
                                name.final_holdings > "50" &&
                                name.nature_of_relationship ===
                                  "Subsidiary Company"
                            )
                            .map((subsiary, key) => {
                              const { _id } = subsiary;
                              return (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                    >
                                      {CompanyName(subsiary?.company)}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="left"
                                    >
                                      {subsiary?.value_of_investment}
                                    </TableCell>
                                    {/* <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      {prepareConsoleId}
                                    </TableCell> */}
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      {subsiary?.final_holdings}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      <Button
                                        style={{
                                          width: "150px",
                                          textTransform: "none",
                                          backgroundColor: "white",
                                          border: "2px solid #03565A",
                                          color: "black",
                                          height: "40px",
                                          borderRadius: "10px",
                                        }}
                                        variant="contained"
                                        onClick={() =>
                                          browseConsol(
                                            subsiary._id,
                                            "Subsidiary Company"
                                          )
                                        }
                                      >
                                        Upload Preset
                                      </Button>
                                      <p>{newGetProjectName(subsiary._id)}</p>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      {newGetProjectName(subsiary._id) ? (
                                        <i
                                          class="fa fa-check-square"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "30px",
                                            color: "green",
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          class="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "30px",
                                            color: "red",
                                          }}
                                        ></i>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      <CheckBoxComp
                                        label={"label"}
                                        consolidated={checkConsolidated(
                                          subsiary._id
                                        )}
                                        consolidateChange={consolidateChange}
                                        filteredName={subsiary}
                                        newGetProjectName={newGetProjectName}
                                        field={"Subsidiary Company"}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                        </>
                      </TableBody>
                    ) : (
                      <CircularProgress />
                    )}
                  </Table>
                </TableContainer>
              </CustomTabPanel>
              <CustomTabPanel
                value={value}
                style={{ marginTop: "10px" }}
                index={1}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>INVEST REGISTER NAME</TableCell>
                        <TableCell align="left">INVESTMENT VALUE</TableCell>
                        <TableCell align="center">HOLDING % </TableCell>
                        <TableCell align="center">
                          UPLOADING COMPANY PRESET
                        </TableCell>
                        <TableCell align="center">VALIDATION CHECK</TableCell>
                        <TableCell align="center">ADD TO PARENT FS</TableCell>
                      </TableRow>
                    </TableHead>
                    {subsidiaryData.loading && !subsidiaryData.response ? (
                      ""
                    ) : subsidiaryData?.response?.getSubsidiaryData?.length >
                      0 ? (
                      <TableBody>
                        <>
                          {subsidiaryData?.response?.getSubsidiaryData
                            ?.filter(
                              (name) =>
                                name.final_holdings <= "50" &&
                                name.final_holdings >= "20" &&
                                name.nature_of_relationship ===
                                  "Associate Company"
                            )
                            .map((subsiary, key) => {
                              const { _id } = subsiary;
                              return (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                    >
                                      {CompanyName(subsiary?.company)}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="left"
                                    >
                                      {subsiary?.value_of_investment}
                                    </TableCell>
                                    {/* <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      {prepareConsoleId}
                                    </TableCell> */}
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      {subsiary?.final_holdings}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      <Button
                                        style={{
                                          width: "150px",
                                          textTransform: "none",
                                          backgroundColor: "white",
                                          border: "2px solid #03565A",
                                          color: "black",
                                          height: "40px",
                                          borderRadius: "10px",
                                        }}
                                        variant="contained"
                                        onClick={() =>
                                          browseConsol(
                                            subsiary._id,
                                            "Associate Company"
                                          )
                                        }
                                      >
                                        Upload Preset
                                      </Button>
                                      <p>{newGetProjectName(subsiary._id)}</p>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      {newGetProjectName(subsiary._id) ? (
                                        <i
                                          class="fa fa-check-square"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "30px",
                                            color: "green",
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          class="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "30px",
                                            color: "red",
                                          }}
                                        ></i>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      <CheckBoxComp
                                        label={"label"}
                                        consolidated={checkConsolidatedAssociate(
                                          subsiary._id
                                        )}
                                        consolidateChange={consolidateChange}
                                        filteredName={subsiary}
                                        newGetProjectName={newGetProjectName}
                                        field={"Associate Company"}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                        </>
                      </TableBody>
                    ) : (
                      <CircularProgress />
                    )}
                  </Table>
                </TableContainer>
              </CustomTabPanel>
              <CustomTabPanel
                value={value}
                style={{ marginTop: "10px" }}
                index={2}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>INVEST REGISTER NAME</TableCell>
                        <TableCell align="left">INVESTMENT VALUE</TableCell>
                        <TableCell align="center">HOLDING % </TableCell>
                        <TableCell align="center">
                          UPLOADING COMPANY PRESET
                        </TableCell>
                        <TableCell align="center">VALIDATION CHECK</TableCell>
                        <TableCell align="center">ADD TO PARENT FS</TableCell>
                      </TableRow>
                    </TableHead>
                    {subsidiaryData.loading && !subsidiaryData.response ? (
                      ""
                    ) : subsidiaryData?.response?.getSubsidiaryData?.length >
                      0 ? (
                      <TableBody>
                        <>
                          {subsidiaryData?.response?.getSubsidiaryData
                            ?.filter(
                              (name) =>
                                name.final_holdings < "20" &&
                                name.nature_of_relationship === "Investment"
                            )
                            .map((subsiary, key) => {
                              const { _id } = subsiary;
                              return (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                    >
                                      {CompanyName(subsiary?.company)}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="left"
                                    >
                                      {subsiary?.value_of_investment}
                                    </TableCell>
                                    {/* <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      {prepareConsoleId}
                                    </TableCell> */}
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      {subsiary?.final_holdings}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      <Button
                                        style={{
                                          width: "150px",
                                          textTransform: "none",
                                          backgroundColor: "white",
                                          border: "2px solid #03565A",
                                          color: "black",
                                          height: "40px",
                                          borderRadius: "10px",
                                        }}
                                        variant="contained"
                                        onClick={() =>
                                          browseConsol(
                                            subsiary._id,
                                            "Investment"
                                          )
                                        }
                                      >
                                        Upload Preset
                                      </Button>
                                      <p>{newGetProjectName(subsiary._id)}</p>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      {newGetProjectName(subsiary._id) ? (
                                        <i
                                          class="fa fa-check-square"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "30px",
                                            color: "green",
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          class="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "30px",
                                            color: "red",
                                          }}
                                        ></i>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      align="center"
                                    >
                                      <CheckBoxComp
                                        label={"label"}
                                        consolidated={checkConsolidated(
                                          subsiary._id
                                        )}
                                        consolidateChange={consolidateChange}
                                        filteredName={subsiary}
                                        newGetProjectName={newGetProjectName}
                                        field={"Investment"}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                        </>
                      </TableBody>
                    ) : (
                      <CircularProgress />
                    )}
                  </Table>
                </TableContainer>
              </CustomTabPanel>
            </Box>
          </div>
        </Box>
      </Modal>

      <AssociateBrowseModal
        handleClose={handleClose}
        open={openassociate}
        selectedAssociateId={associateId}
        setPresetHandler={uploadPresetToSubsidiary}
        projectsData={projectsData}
        prepareConsoleId={presetIdData}
        setSelectProject={setSelectProject}
        selectedProject={selectedProject}
        nature={fieldOfIr}
        workspaceData={workspaceData}
        consoldata={consoldata}
      />
    </>
  );
};

export default SubsidiarySelectModal;

const CheckBoxComp = (props) => {
  const {
    label,
    consolidated,
    consolidateChange,
    filteredName,
    newGetProjectName,
    field
  } = props;

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(consolidated);
  }, [consolidated]);

  const handleChange = (event,field) => {
    setChecked(event.target.checked);
    consolidateChange(
      event,
      filteredName,
      newGetProjectName(filteredName._id, true),
      field
    );
  };

  return <Checkbox {...label} checked={checked} onChange={(e)=>handleChange(e,field)}/>;
};
