import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveIcon from "@mui/icons-material/Remove";
import "../../../WorkSpace/font.css";
import { Link, useHistory, useLocation } from "react-router-dom";
// import Header from "./header";
import { Navbar } from "../../../Components/Navbar";

// img
// import logo from "../assets/images/logo/Logo.svg";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import {
  getProjectDetailById,
  getSubGrpFs,
  editRegister,
} from "../../../Console/Component/services/apiHandler";
// import EditInvestment from "./editInvestment/edit_investment";
// import DilutionModal from "./modal/dilutionModal";
import { useState } from "react";
import { useEffect } from "react";
import EditInvestmentRegister from "./EditInvestRegister";
import { GetAllCompanyData } from "../ApiServices/apiHandler";
import InvestAdjustModal from "../../../Console/Component/InvestAdjustmentModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const InvestmentRegisterv2 = (props) => {
  const auth = localStorage.getItem("auth_token");
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const [browseOpen, setBrowseOpen] = useState(false);
  const [optionentry, setOptionEntry] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [eqityshare, setEquityShare] = useState([]);
  const [otherequityshare, setOtherEquityShare] = useState("");
  const [otherreservesurplus, setOtherReserveSurplus] = useState("");
  const [othereserve, setOtherReserve] = useState("");
  const [setfinalsum, setFinalSum] = useState("");
  const [totaldata, setDataTotal] = useState("");
  const [interest, setInterest] = useState("");
  const [entryopenModal, setEntryOpen] = useState(false);
  const [natureinvest, setNatureInvest] = useState("");
  const [editconsoleentry, setEditConsoleEntry] = useState("");
  const [additinalData, setAdditinalData] = useState([]);
  const [data, setData] = useState("");
  const [openconsoleentry, setOpenConsoleEntry] = useState(false);
  const [browseopendata, setBrowseOpenData] = useState(false);
  const [consoleadditional, setConsoleAdditional] = useState("");
  const [datacondition, setDataCondition] = useState("");
  const [itemid, setItemID] = useState("");
  const [hidediv, setHideDiv] = useState(true);
  const [finalnoofshar, setFinalNoOfShare] = useState("");
  const [finalinvestment, setFinalInvestment] = useState("");
  const [noofdevest, setNoOfDevest] = useState("");
  const [projectsData, setProjectsData] = useState([]);
  const [browsproject, setSelectedBrowsProject] = useState("");
  const [otherValue, setOtherData] = useState("");
  const [allCompany, setAllCompany] = useState([]);
  const [investvalue, setInvestValue] = useState("");
  const [totaleqity, setTotalEquity] = useState(eqityshare * -1);
  const [finalconsoleentry, setFinalConsoleEntry] = useState({});
  const [dilutiondata, setDilutionData] = useState({
    date_of_divest: "",
    no_of_share: "",
    sale: "",
  });
  const [updateadditinaldata, setUpdateAdditionalData] = useState({
    investment_date: "",
    company: "",
    investment_value: "",
    shares_bought: "",
    tnositc: "",
    holding: "",
    non_controlling_interest: "",
    browse_control_dateFS: "",
    donotdoc: "",
    wticacct: "",
  });
  const [registerdata, setRegisterDAta] = useState({
    investment_date: "",
    company: "",
    investment_value: "",
    shares_bought: "",
    tnositc: "",
    holding: "",
    non_controlling_interest: "",
    browse_control_dateFS: "",
    donotdoc: "",
    wticacct: "",
    other_items: "",
  });
  const [dateofinvest, setDateOfInvest] = useState(
    registerdata.date_Of_investment
  );
  let no_of_share = Number(registerdata?.no_of_shares) + finalnoofshar;
  console.log(no_of_share);
  let final_investment =
    Number(registerdata?.value_of_investment) + finalinvestment;
  let dataValue = (Number(no_of_share) / Number(final_investment)) * noofdevest;
  const [name, setName] = useState("");
  let history = useHistory();
  const [state, setstate] = useState([{ column: "", select: "" }]);
  let a = location.pathname;
  let b = a.split("/");
  let holding =
    (Number(registerdata?.no_of_shares) /
      Number(registerdata?.total_no_shares)) *
    100;
  let notInterest = registerdata?.total_no_shares - registerdata?.no_of_shares;
  console.log(browsproject, "90989878");
  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setBrowseOpen(false);
    setEntryOpen(false);
    setOpenConsoleEntry(false);
  };

  useEffect(() => {
    getRegister();
    fetchProject();
    fetchAdditionalData();
    getCompany();
    editDataValue();
    companyData();
  }, []);

  const editDataValue = async () => {
    try {
      const res = await editRegister(b[2]);
      setOtherData(res.data.editOtherData[0].console_entry[0]);
    } catch (error) {
      console.log(error, "error");
      return 0;
    }
  };
  const getRegister = async () => {
    const res = await editRegister(b[2]);
    if (res.status === 200) {
      setEditConsoleEntry(res.data.editOtherData[0].console_entry[0]);
      setRegisterDAta(res.data.editOtherData[0]);
      getCompany(res.data.editOtherData[0].c_id);
      getProjectName(res.data.editOtherData[0].browse_control_dateFS);
    }
  };

  function getCompany(id) {
    let headers = {
      "x-auth-token": auth,
    };
    axios
      .get(`api/v1/company/get-onecomapny/${id}`, {
        headers,
      })
      .then((response) => {
        setName(response.data.getOneCompany[0]);
      });
  }

  const handleChange = (e, field) => {
    // if (e.target.value === "Subsidiary Company") {
    //   setNatureInvest("Subsidiary Company");
    // } else if (e.target.value === "Associate Company") {
    //   setNatureInvest("Associate Company");
    // } else {
    //   setNatureInvest("Investment");
    // }
    setRegisterDAta({ ...registerdata, [field]: e.target.value });
    if (field === "no_of_bought") {
      let data = Number(e.target.value);
      setData(data);
      if (totaldata) {
        let holding = (Number(data) / totaldata) * 100;
        let noOfInterest = 100 - holding;
        setDataTotal(Number(holding));
        setInterest(noOfInterest);
        if (holding > 50) {
          setNatureInvest("Subsidiary Company");
        } else if (holding <= 50 && holding >= 20) {
          setNatureInvest("Associate Company");
        } else {
          setNatureInvest("Investment");
        }
      }
    }
    if (field === "total_share") {
      let data2 = Number(e.target.value);
      let holding = (data / data2) * 100;
      let noOfInterest = 100 - holding;
      setDataTotal(holding);
      setInterest(noOfInterest);
      if (holding > 50) {
        setNatureInvest("Subsidiary Company");
      } else if (holding <= 50 && holding >= 20) {
        setNatureInvest("Associate Company");
      } else {
        setNatureInvest("Investment");
      }
    }
    if (field === "voi") {
      let investvalue = Number(e.target.value);
      setInvestValue(investvalue);
    }
    if (field === "doi") {
      setDateOfInvest(e.target.value);
    }
  };

  const handleNew = () => {
    setstate((oldArray) => [...oldArray, { column: "", select: "" }]);
  };
  function handleDelete(i) {
    const temp = state;
    const newarr = temp.filter((val, ix) => ix !== i);
    setstate(newarr);
    if (newarr.length == 0) {
    }
  }
  const companyData = async () => {
    try {
      const res = await GetAllCompanyData();
      if (res.status === 200) {
        setAllCompany(res.data.getAllCompanyData);
        console.log(allCompany,'+++++++++')
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const handleSubmit = () => {
    let headers = {
      "x-auth-token": auth,
    };
    const data = {
      investment_date: registerdata.doi,
      company: registerdata.company_name,
      investment_value: registerdata.voi,
      shares_bought: registerdata.no_of_bought,
      tnositc: registerdata.total_share,
      holding: totaldata,
      final_holdings: totaldata,
      non_controlling_interest: notInterest,
      donotdoc: registerdata.doi,
      nature_of_relationship: natureinvest,
      wticacct: registerdata.transaction,
      add_other_details: state,
      console_other_details: setfinalsum,
      browse_control_dateFS: selectedOption,
    };
    axios
      .post(`api/v1/company-data/update-company-data/${b[2]}`, data, {
        headers,
      })
      .then((response) => {
        console.log(response.data, "dat1212121212");
        getRegister();
        history.goBack();
      });
  };

  function browseFs() {
    setBrowseOpen(true);
  }
  function browseFsData() {
    setBrowseOpenData(true);
  }
  function fetchProject() {
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    axios
      .get("/api/v1/project/get-project", { headers })
      .then((res) => {
        console.log(res.data.project, "res");
        setOptionEntry(res.data.project);
        setProjectsData(res.data.project);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  const handleChangeFs = (e, data) => {
    console.log(e.target.value);
    setSelectedOption(e.target.value);
  };

  const handleSubmitFs = () => {
    console.log(selectedOption);

    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    axios
      .get(`/api/v1/grouping/fs/${selectedOption}`, { headers })
      .then((res) => {
        console.log(res.data.fs_grp[2], "res_fdgghhh");

        setOtherEquityShare(res.data.fs_grp[2].notes_grp[1].sub_grp[0].cy_amt);
        setOtherReserveSurplus(
          res.data.fs_grp[2].notes_grp[2].sub_grp[0].cy_amt
        );
        setOtherReserve(res.data.fs_grp[2].notes_grp[3].sub_grp[0].cy_amt);

        setBrowseOpen(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleSave = () => {
    // let data = state.shift();
    console.log(state);
    let sum = 0;
    setOpen1(false);
    const newData = state.map((item, index) => {
      let someData = Number(item.select);
      sum += someData;
    });
    console.log(sum);
    setFinalSum(sum);
  };

  const fetchAdditionalData = () => {
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    axios
      .get(`api/v1/company-data/fetch-additional-data/${b[2]}`, { headers })
      .then((res) => {
        console.log(res.data.fetchAddinalData, "res343434");
        let finaLData = res.data.fetchAddinalData
          .sort((a, b) => a - b)
          .reverse();
        setAdditinalData(finaLData);
        let x = 0;
        let y = 0;
        setConsoleAdditional(res.data.fetchAddinalData[0].console_entry[0]);
        res.data.fetchAddinalData.map((item, index) => {
          x += Number(item.no_of_shares);
          y += Number(item.value_of_investment);
        });
        setFinalNoOfShare(x);
        setFinalInvestment(y);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const hideDiv = () => {
    setHideDiv(false);
  };

  const entryOpen = () => {
    setEntryOpen(true);
  };
  const additionalEntryOpen = (id) => {
    fetchId(id);
  };

  const handleAdditionChange = (e, field) => {
    console.log(e.target.value);
    setUpdateAdditionalData({
      ...updateadditinaldata,
      [field]: e.target.value,
    });
  };

  const handleSubmitAddition = (aid) => {
    let headers = {
      "x-auth-token": auth,
    };
    const dataAdd = {
      investment_date: updateadditinaldata.doi,
      company: updateadditinaldata.company_name,
      investment_value: updateadditinaldata.voi,
      shares_bought: updateadditinaldata.no_of_bought,
      tnositc: updateadditinaldata.total_share,
      holding: totaldata,
      non_controlling_interest: notInterest,
      donotdoc: updateadditinaldata.doi,
      // nature_of_relationship: updateadditinaldata,
      wticacct: updateadditinaldata.transaction,
      add_other_details: "",
    };
    axios
      .post(`api/v1/company-data/update-additional-data/${aid}`, dataAdd, {
        headers,
      })
      .then((response) => {
        getRegister();
      });
  };

  function handleChangeDetails(e, field, i) {
    console.log(e.target.value);

    const newArray = state.map((item, index) => {
      if (i === index) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    console.log(newArray);
    setstate(newArray);
  }

  function fetchId(id) {
    let headers = {
      "x-auth-token": auth,
    };
    axios
      .get(`api/v1/company-data/fetch-additional/${id}`, { headers })
      .then((res) => {
        console.log(res.data, "res");
        setOpenConsoleEntry(true);
        setDataCondition(res.data.fetchAddinal[0]);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  const handleDilutionChange = (e, field) => {
    console.log(e.target.value);
    setDilutionData({ ...dilutiondata, [field]: e.target.value });
    if (field === "no_of_share") {
      setNoOfDevest(e.target.value);
    }
  };

  const handleDelution = () => {
    let headers = {
      "x-auth-token": auth,
    };
    const delution = {
      rid: b[2],
      cid: registerdata.c_id,
      date_of_devestment: dilutiondata.date_of_divest,
      No_shares_divested: dilutiondata.no_of_share,
      sale_value: dilutiondata.sale,
      cost_of_invest: dataValue,
      status: "dilution",
    };
    axios
      .post(`api/v1/company-data/delution-data`, delution, {
        headers,
      })
      .then((response) => {
        console.log(response.data, "dat1212121212");
        setOpen(false);
        fetchAdditionalData();
      });
  };

  const getProjectName = async (p_id) => {
    try {
      const result = await getSubGrpFs(p_id);
      setEquityShare(result?.data.fs_grp[9]?.notes_grp);
      let project = await getProjectDetailById(p_id);
      setSelectedBrowsProject(project?.data?.project?.project_name);
    } catch (error) {
      console.log("error", error.message);
      return "None";
    }
  };

  const CompanyName = (c_id) => {
    try {
      const nameCompany = allCompany.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_name
        ? nameCompany[0]?.company_name
        : "";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  const CompanyCode = (c_id) => {
    try {
      const nameCompany = allCompany.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_code
        ? nameCompany[0]?.company_code
        : "";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  const handleBAck = () => {
    history.push(`/dashboard`);
  };
  return (
    <>
      <div className="pb-5">
        <Navbar />

        <div className="container mt-4">
          <div className="d-flex">
            {/* <p style={{ color: "gray", fontSize: "16px" }} className="fs-inter"> */}
            <p className="fs-inter" style={{ color: "#343434" }}>
              HOME / EDIT IR /&nbsp;
              <span style={{ fontWeight: "bold", color: "#000000" }}>
                {registerdata.ir_name}
              </span>
            </p>
          </div>
          <div className="d-flex">
            <div className="mt-1">
              <h5 className="fs-inter ir-heading">
                Investments for {CompanyName(registerdata.company)} here
              </h5>
            </div>
            <div className="invbtndiv">
              <Button
                style={{
                  border: "2px solid #03565A",
                  color: "#03565A",
                  textTransform: "none",
                  backgroundColor: "white",
                }}
                onClick={() => history.push(`/additionInvestregister/${b[2]}`)}
              >
                <AddIcon />
                Add
              </Button>

              <Button
                style={{
                  border: "2px solid #03565A",
                  marginLeft: "14px",
                  color: "#03565A",
                  textTransform: "none",
                  backgroundColor: "white",
                }}
                onClick={handleOpen}
              >
                <RemoveIcon />
                Dilute
              </Button>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-8">
              {additinalData.length > 0 &&
                additinalData.map((item, i) => (
                  <>
                    {/* <div className="default-according p-4" id="accordion1">
                  <div className="card">
                    <div
                      style={{ backgroundColor: "#03565a" }}
                      className="card-header"
                      id="headingFive"
                    >
                      {item.status === "addition" && (
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link collapsed text-white"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                            data-bs-original-title=""
                            title=""
                          >
                            {" "}
                            Tranch {additinalData.length - i + 1}
                            <br />
                            <span>
                              <b>Date of Investment:</b>{" "}
                              {item.date_Of_investment}
                            </span>{" "}
                            <span className="ps-4">
                              <b>Investment Value:</b>{" "}
                              {item.value_of_investment}
                            </span>
                          </button>
                        </h5>
                      )}
                      {item.status === "dilution" && (
                        <>
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed text-white"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                              data-bs-original-title=""
                              title=""
                            >
                              {" "}
                              Tranch {additinalData.length - i + 1}
                              <br />
                              <span>
                                <b>Date of Dilution:</b>{" "}
                                {item.date_of_devestment}
                              </span>{" "}
                              <span className="ps-4">
                                <b>Investment Value:</b>{" "}
                                {Math.round(item.cost_of_invest)}
                              </span>
                            </button>
                          </h5>
                        </>
                      )}
                    </div>

                    <div
                      className="collapse show"
                      id="collapseFive"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordion1"
                    >
                      <form>
                        {item.status === "addition" && (
                          <>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6 form-group">
                                  <label>Date of Investment</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    defaultValue={item.date_Of_investment}
                                    onChange={(e) =>
                                      handleAdditionChange(e, "doi")
                                    }
                                  />
                                </div>
                                <div className="col-5 offset-1 form-group">
                                  <label>Company Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter company name"
                                    name="company_name"
                                    defaultValue={name.company_name}
                                    onChange={(e) =>
                                      handleAdditionChange(e, "company_name")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row  mt-3">
                                <div className="col-6 form-group">
                                  <label>Company Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={name.company_code}
                                    disabled
                                  />
                                </div>
                                <div className="col-5 offset-1 form-group">
                                  <label>Value of Investment</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.value_of_investment}
                                    onChange={(e) =>
                                      handleAdditionChange(e, "voi")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row  mt-3">
                                <div className="col-6 form-group">
                                  <label>No. of shares bought</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.no_of_shares}
                                    onChange={(e) =>
                                      handleAdditionChange(e, "no_of_bought")
                                    }
                                  />
                                </div>
                                <div className="col-5 offset-1 form-group">
                                  <label>
                                    Total no. of shares in the company
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.total_no_shares}
                                    onChange={(e) =>
                                      handleAdditionChange(e, "total_share")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-6 form-group">
                                  <label>% Holding</label>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={item.holdings}
                                        disabled
                                      />
                                    </div>
                                    {item.holdings > 50 && (
                                      <div
                                        className="col-md-6 text-center"
                                        onClick={() =>
                                          additionalEntryOpen(item._id)
                                        }
                                      >
                                        <input
                                          type="button"
                                          className="btn btn-primary w-100"
                                          value="Create Console Entry"
                                          style={{
                                            background: "rgb(3, 86, 90)",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-5 offset-1 form-group">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Non-controlling interest %</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={100 - item.holdings}
                                        disabled
                                      />
                                    </div>
                                    {item.holdings > 50 && (
                                      <div
                                        className="col-md-6 text-center"
                                        onClick={() => browseFsData()}
                                      >
                                        <input
                                          type="button"
                                          className="btn btn-primary w-100"
                                          value="Browse control date FS"
                                          style={{
                                            background: "rgb(3, 86, 90)",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {item.holdings > 50 && (
                                <div className="row  mt-3">
                                  <div className="col-6 form-group">
                                    <label>
                                      Details of networth on the date of control
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={item.details_networth}
                                      onChange={(e) =>
                                        handleAdditionChange(e, "doi")
                                      }
                                    />
                                  </div>
                                  <div className="col-5 offset-1 form-group">
                                    <label>Add other details </label>
                                    <input
                                      onClick={handleOpen1}
                                      type="button"
                                      className="btn btn-primary ml-2"
                                      data-bs-toggle="modal"
                                      data-bs-target="#Intangible"
                                      value="+"
                                      style={{
                                        background: "rgb(3, 86, 90)",
                                      }}
                                    /></div>
                                </div>
                              )}
                              {item.holdings > 50 && (
                                <div className="row mt-3">
                                  <div className="col-6 form-group">
                                    <label>
                                      Whether transaction is covered as common
                                      control transaction
                                    </label>
                                    <select
                                      className="form-control"
                                      defaultValue={item.transaction_is_covered}
                                      onChange={(e) =>
                                        handleAdditionChange(e, "transaction")
                                      }
                                    >
                                      <option>Yes</option>
                                      <option>No</option>
                                    </select>
                                  </div>
                                  <div className="col-5 offset-1 form-group">
                                    <div class="table-responsive table-bordered">
                                      <table
                                        class="table"
                                        style={{ marginBottom: "0" }}
                                      >
                                        <tbody>
                                          {item.other_items.length > 0 &&
                                            item.other_items.map((element) => (
                                              <tr>
                                                <td>{element.column}</td>
                                                <td>{element.select}</td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        {item.status === "dilution" && (
                          <>
                            <h1>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label>Date of Divestment</label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      defaultValue={item.date_of_devestment}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-5 offset-1 form-group">
                                    <label>No. of shares divested</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={item.No_shares_divested}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label>Sale value</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={item.sale_value}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-5 offset-1 form-group">
                                    <label>Cost of Investment</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter company name"
                                      name="company_name"
                                      defaultValue={item.cost_of_invest}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </h1>
                          </>
                        )}
                        <hr />
                        <div
                          className="card-body"
                          style={{
                            paddingTop: "20px",
                            paddingBottom: "20px",
                          }}
                        >
                          <input
                            type="button"
                            className="btn btn-primary"
                            value="Submit"
                            onClick={() => handleSubmitAddition(item._id)}
                            style={{ background: "rgb(3, 86, 90)" }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div> */}
                    {item.status === "addition" && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{ marginLeft: "22px" }}>
                            <small className="fs-inter ir-con-head">
                              TRANCH <b>{additinalData.length - i + 1}</b>{" "}
                              {item.date_Of_investment}
                            </small>
                            <br />
                            <p className="dm-sans ir-heading2">
                              Investment value:{" "}
                              {Math.round(item.value_of_investment)}
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="row">
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  investee company name
                                  <select
                                    style={{
                                      width: "336px",
                                      marginTop: "12px",
                                      borderRadius: "5px",
                                      height: "59px",
                                    }}
                                    onChange={(e) => handleChange("company", e)}
                                  >
                                    <option>Select...</option>
                                    {allCompany.length > 0 &&
                                      allCompany.map((det) => {
                                        return (
                                          <>
                                            <option
                                              value={det._id}
                                              selected={
                                                registerdata.company === det._id
                                              }
                                            >
                                              {det.company_name}
                                            </option>
                                          </>
                                        );
                                      })}
                                  </select>
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Company code
                                  <input
                                    type="text"
                                    value={CompanyCode(registerdata.company)}
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <label className="ircon-head">
                                  Date of investment
                                  <input
                                    type="date"
                                    className="form-control"
                                    defaultValue={item.date_Of_investment}
                                    onChange={(e) =>
                                      handleAdditionChange(e, "doi")
                                    }
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <label className="ircon-head">
                                  Value of investment
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={item.value_of_investment}
                                  onChange={(e) =>
                                    handleAdditionChange(e, "voi")
                                  }
                                />
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  No of shares brought
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.no_of_shares}
                                    onChange={(e) =>
                                      handleAdditionChange(e, "no_of_bought")
                                    }
                                  />
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Total no shares in the company
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={item.total_no_shares}
                                    onChange={(e) =>
                                      handleAdditionChange(e, "total_share")
                                    }
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Percentage holding
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={item.holdings}
                                    disabled
                                  />
                                </label>
                              </div>
                              <div className="col-md-6">
                                <label className="ircon-head">
                                  Non-controling interest Percentage
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={100 - item.holdings}
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-12">
                                <label className="text-secondary">
                                  Nature of relationship
                                </label>

                                <select
                                  style={{
                                    width: "100%",
                                    height: "59px",
                                    borderRadius: "5px",
                                  }}
                                  defaultValue={natureinvest}
                                  onChange={(e) =>
                                    handleAdditionChange(e, "nature_of_relation")
                                  }
                                >
                                  <option>Select</option>
                                  <option
                                    value="Investment"
                                    selected={natureinvest === "Investment"}
                                  >
                                    Investment
                                  </option>
                                  <option
                                    value="Associate Company"
                                    selected={
                                      natureinvest === "Associate Company"
                                    }
                                  >
                                    Associate Company
                                  </option>
                                  <option
                                    value="Subsidiary Company"
                                    selected={
                                      natureinvest === "Subsidiary Company"
                                    }
                                  >
                                    Subsidiary Company
                                  </option>
                                  <option
                                    value="Joint Venture"
                                    selected={natureinvest === "Joint Venture"}
                                  >
                                    Joint Venture
                                  </option>
                                </select>
                              </div>
                            </div>
                            {/* modal  */}

                            {/* {holding > 50 && (!totaldata || totaldata > 50) ? (
                              <div className="row mt-3">
                                <div
                                  className="col-md-6"
                                  onClick={() => entryOpen()}
                                >
                                  <input
                                    type="button"
                                    className="btn btn-primary1 w-100"
                                    value="Create consol entry"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="button"
                                    className="btn btn-primary1 w-100"
                                    value="Browse control date FS"
                                    onClick={() => browseFs()}
                                  />
                                  <small>
                                    {browsproject ? browsproject : ""}
                                  </small>
                                </div>
                              </div>
                            ) : path[1] === "edit-register" &&
                              totaldata &&
                              totaldata > 50 ? (
                              <div className="row mt-3">
                                <div
                                  className="col-md-6"
                                  onClick={() => entryOpen()}
                                >
                                  <input
                                    type="button"
                                    className="btn btn-primary1 w-100"
                                    value="Create consol entry"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="button"
                                    className="btn btn-primary1 w-100"
                                    value="Browse control date FS"
                                    onClick={() => browseFs()}
                                  />
                                  <small>
                                    {browsproject ? browsproject : ""}
                                  </small>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {holding > 50 && (!totaldata || totaldata > 50) ? (
                              <div className="row  mt-3">
                                <div className="col-md-12 ">
                                  <label className="text-secondary">
                                    Details of networth on the date of control
                                  </label>
                                  <input
                                    defaultValue={registerdata.details_networth}
                                    onChange={(e) => handleChange(e, "doi")}
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-12 mt-3">
                                  <label className="text-secondary">
                                    Add other details &nbsp; &nbsp;
                                  </label>
                                  <AddIcon
                                    onClick={handleOpen1}
                                    data-bs-toggle="modal"
                                    data-bs-target="#Intangible"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                            ) : path[1] === "edit-register" &&
                              totaldata &&
                              totaldata > 50 ? (
                              <div className="row  mt-3">
                                <div className="col-md-12 ">
                                  <label className="text-secondary">
                                    Details of networth on the date of control
                                  </label>
                                  <input
                                    defaultValue={registerdata.details_networth}
                                    onChange={(e) => handleChange(e, "doi")}
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-12 mt-3">
                                  <label className="text-secondary">
                                    Add other details &nbsp; &nbsp;
                                  </label>
                                  <AddIcon
                                    onClick={handleOpen1}
                                    data-bs-toggle="modal"
                                    data-bs-target="#Intangible"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {holding > 50 && (!totaldata || totaldata > 50) ? (
                              <div className="row mt-2">
                                <div className="col-md-12 ">
                                  <label className="text-secondary">
                                    Whether transaction is covered as common
                                    control transaction
                                  </label>
                                  <select
                                    defaultValue={
                                      registerdata.transaction_is_covered
                                    }
                                    onChange={(e) =>
                                      handleChange(e, "transaction")
                                    }
                                    style={{
                                      width: "691px",
                                      marginTop: "12px",
                                      borderRadius: "5px",
                                      height: "59px",
                                    }}
                                  >
                                    <option>Select....</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <div class="table-responsive table-bordered">
                                    <table
                                      class="table"
                                      style={{ marginBottom: "0" }}
                                    >
                                      <tbody>
                                        {registerdata.other_items.length > 0 &&
                                          registerdata.other_items.map(
                                            (item) => (
                                              <>
                                                <tr>
                                                  <td>{item.column}</td>
                                                  <td>{item.select}</td>
                                                </tr>
                                              </>
                                            )
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            ) : path[1] === "edit-register" &&
                              totaldata &&
                              totaldata > 50 ? (
                              <div className="row mt-2">
                                <div className="col-md-12 ">
                                  <label className="text-secondary">
                                    Whether transaction is covered as common
                                    control transaction
                                  </label>
                                  <select
                                    defaultValue={
                                      registerdata.transaction_is_covered
                                    }
                                    onChange={(e) =>
                                      handleChange(e, "transaction")
                                    }
                                    style={{
                                      width: "691px",
                                      marginTop: "12px",
                                      borderRadius: "5px",
                                      height: "59px",
                                    }}
                                  >
                                    <option>Select....</option>
                                    <option
                                      value="yes"
                                      selected={
                                        registerdata.transaction_is_covered ===
                                        "yes"
                                      }
                                    >
                                      Yes
                                    </option>
                                    <option
                                      value="no"
                                      selected={
                                        registerdata.transaction_is_covered ===
                                        "no"
                                      }
                                    >
                                      No
                                    </option>
                                  </select>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <div class="table-responsive table-bordered">
                                    <table
                                      class="table"
                                      style={{ marginBottom: "0" }}
                                    >
                                      <tbody>
                                        {registerdata.other_items.length > 0 &&
                                          registerdata.other_items.map(
                                            (item) => (
                                              <>
                                                <tr>
                                                  <td>{item.column}</td>
                                                  <td>{item.select}</td>
                                                </tr>
                                              </>
                                            )
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )} */}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {item.status === "dilution" && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{ marginLeft: "22px" }}>
                            <small className="fs-inter ir-con-head">
                              TRANCH <b>{additinalData.length - i + 1}</b>{" "}
                              {item.date_of_devestment}
                            </small>
                            <br />
                            <p className="dm-sans ir-heading2">
                              Investment value:{" "}
                              {Math.round(item.cost_of_invest)}
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="row">
                              <div className="col-md-6">
                                <label>Date of Divestment</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  defaultValue={item.date_of_devestment}
                                  disabled
                                />
                              </div>
                              <div className="col-md-6">
                                <label>No. of shares divested</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={item.No_shares_divested}
                                  disabled
                                />
                              </div>

                              <div className="col-md-6">
                                <label>Sale value</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={item.sale_value}
                                  disabled
                                />
                              </div>

                              <div className="col-md-6">
                                <label>Cost of Investment</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter company name"
                                  name="company_name"
                                  defaultValue={item.cost_of_invest}
                                  disabled
                                />
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </>
                ))}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-8">
              <EditInvestmentRegister
                registerdata={registerdata}
                hidediv={hidediv}
                name={name}
                totaldata={totaldata}
                holding={holding}
                interest={interest}
                notInterest={notInterest}
                natureinvest={natureinvest}
                browsproject={browsproject}
                handleOpen1={handleOpen1}
                handleSubmit={handleSubmit}
                hideDiv={hideDiv}
                entryOpen={entryOpen}
                browseFs={browseFs}
                handleChange={handleChange}
                path={b}
              />
            </div>
          </div>
          <hr style={{ width: "731px" }} />

          <div className="mt-4 row">
            <div className="col-md-8">
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <label>
                      <p className="dm-sans ir-remark">Add remarks</p>
                    </label>
                    <input type="text" style={{ height: "110px" }} />
                  </Typography>
                </CardContent>
                {/* <div style={{ color: "black", marginLeft: "20px" }}> */}
                <div
                  className="dm-sans ir-remark"
                  style={{ marginLeft: "20px" }}
                >
                  UploadAttachments
                </div>
                <CardActions>
                  <button
                    style={{
                      backgroundColor: "white",
                      color: "#03565A",
                      textTransform: "none",
                      marginLeft: "12px",
                      width: "220px",
                      height: "50px",
                      border: "2px solid #03565A",
                      borderRadius: "5px",
                      marginTop: "9px",
                    }}
                  >
                    Upload Attachment
                  </button>
                </CardActions>
              </Card>
            </div>
          </div>

          <div
            className="backicon"
            style={{ cursor: "pointer", marginLeft: "0px" }}
            onClick={() => handleBAck()}
          >
            <ArrowBackIcon />
            &nbsp;
            <div>Go Back</div>
          </div>
          <div
            className="nextbtn-v2"
            style={{ marginLeft: "-325px", marginTop: "-42px" }}
          >
            <Button
              style={{
                backgroundColor: "#03565A",
                color: "white",
                border: "2px solid #03565A",
                textTransform: "none",
                borderRadius: "5px",
                height: "54px",
              }}
            >
              <AddIcon />
              Save deatils
            </Button>
            {/* <Button
              style={{
                backgroundColor: "white",
                // color: "black",
                textTransform: "none",

                marginRight: "12px",
                width: "220px",
                height: "50px",
                border: "2px solid #03565A",
                borderRadius: "5px",
                color: "#03565A",
                fontWeight: "bold",
              }}
              onClick={handleSubmit}
            >
              Create Register
            </Button> */}
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="col-12">
            <input
              type="button"
              className="btn btn-defult"
              value="X"
              onClick={handleClose}
              style={{ float: "right" }}
            />
            <div className="col-12">
              <h5>Dilution</h5>
              <div className="form-group mb-1 col-12">
                <label>Date of divestment</label>
                <input
                  type="date"
                  className="form-control"
                  value={dilutiondata.date_of_divest}
                  onChange={(e) => handleDilutionChange(e, "date_of_divest")}
                />
              </div>
              <div className="form-group mb-1 col-12">
                <label>No. of shares divested</label>
                <input
                  type="text"
                  className="form-control"
                  value={dilutiondata.no_of_share}
                  onChange={(e) => handleDilutionChange(e, "no_of_share")}
                />
              </div>
              <div className="form-group mb-1 col-12">
                <label>Sale value</label>
                <input
                  type="text"
                  className="form-control"
                  value={dilutiondata.sale}
                  onChange={(e) => handleDilutionChange(e, "sale")}
                />
              </div>
              <div className="form-group mb-1 col-12">
                <label>Cost of Investment</label>
                <input
                  type="text"
                  className="form-control"
                  value={dataValue}
                  readOnly
                />
              </div>
              <div className="form-group mb-1 col-12">
                <input
                  type="button"
                  className="btn btn-primary1"
                  value="Submit"
                  onClick={() => handleDelution()}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <InvestAdjustModal
        handleClose={handleClose}
        entryopen={entryopenModal}
        eqityshare={eqityshare}
        otherequityshare={otherequityshare}
        investvalue={investvalue}
        totaleqity={totaleqity}
        interest={interest}
        setfinalsum={setfinalsum}
        setFinalConsoleEntry={setFinalConsoleEntry}
        otherreservesurplus={otherreservesurplus}
        othereserve={othereserve}
        setEntryOpen={setEntryOpen}
        dateofinvest={dateofinvest}
      />
    </>
  );
};

export default InvestmentRegisterv2;
