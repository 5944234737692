import { Box, Button, FormControl, MenuItem, Modal, Select } from "@mui/material";
import { React, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { GetUser,addworkspacePermission } from "../ApiServices/apiHandler";
import swal from "sweetalert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ModifyAccess = () => {
  let history = useHistory();
  let { uid,wid } = useParams();
  const user = JSON.parse(localStorage.getItem('user'));
  const modifyAccess_action = localStorage.getItem("modifyAccess");
  const [allUser, setAllUser] = useState([]);
  const [accessStatus, setAccessStatus] = useState({
    editworkspace_name:false,
    addcompanies:false,
    addteam_member:false
  });
  
  useEffect(() => {
    GetAllUser();
  }, []);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleChange = (value, field) => {
    setAccessStatus({...accessStatus,[field]: value} );
  };

  const GetAllUser = async () => {
    const res = await GetUser();
    if (res.status === 200) {
      setAllUser(res.data.fetchUser);
    }
  };
  const addpermission =async()=>{
    const data={
    user_id: uid ,
    workspace_id:wid,
    editworkspace_name:accessStatus.editworkspace_name,
    addcompanies:accessStatus.addcompanies,
    addteam_member:accessStatus.addteam_member
    }
    addworkspacePermission(data)
    .then(response => {
      if (response.status === 200) {
        swal("Success", "Workspace permission given Successfully", "success");
      } else {
        swal("Error", "Failed to give workspace permission", "error");
      }
    })
    .catch(error => {
      swal("Error", "Failed to give workspace permission", "error");
      console.error("Failed to give workspace permission:", error);
    });
  }

  const userName = (u_id) => {
    try {
      const nameUser = allUser.filter((adj) => adj._id === u_id);
      const result = nameUser[0]?.email ? nameUser[0]?.email : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  return (
    <div className="row">
      <div className="col-md-3">
        <div
          className="container d-flex flex-column justify-content-between"
          style={{
            width: "300px",
            height: "95vh",
            backgroundColor: "white",
            marginLeft: "5px",
          }}
        >
          <div
            className="font-weight-bold cursor-pointer justify-content-center"
            style={{ marginTop: "100px", backgroundColor: "#b2edc8" }}
          >
            Workspace Settings
          </div>
          <div
            className="font-weight-bold cursor-pointer fs-4 justify-content-center"
            style={{ marginBottom: "200px" }}
            onClick={() => history.push(`/functionalityacess/${uid}/${wid}`)}
          >
            Functionality Access
          </div>
        </div>
      </div>
      <div className="col-md-9">
        <div className="d-flex flex-row-reverse mr-5 p-3">
          <Button
            className="btn font-inter"
            style={{ backgroundColor: "#03565A", color: "white" }}
            onClick={() => history.push(`/${modifyAccess_action}/${wid}`)}
          >
            Back
          </Button>
        </div>
        <div className="container">
          <div className="row m-5">
            <div className="col-md-2 d-flex justify-content-center align-items-center font-sans">
              MyFinalist ID:
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center font-sans">
              {userName(uid)}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="d-flex flex-row justify-content-between ml-5 mb-3">
            <div className="col-md-6 font-sans font-weight-bold">
              Workspace-Access
            </div>
            <div className="col-md-6 ml-5 font-sans">Access Status</div>
          </div>
          <div className="row ml-5 mb-3">
            <div className="col-md-6 font-sans">Edit Workspace Name</div>
            <div className="col-md-6">
            <FormControl className="w-50">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accessStatus.editworkspace_name}
                disabled={user.responsibilities==="approver"}
                onChange={(e) =>
                 handleChange(e.target.value, "editworkspace_name")
                }
              >
                <MenuItem value= {true} >Granted</MenuItem>
                <MenuItem value={false}>Denied</MenuItem>
              </Select>
            </FormControl>
            </div>
          </div>
          <div className="row ml-5 mb-3">
            <div className="col-md-6">Add Companies</div>
            <div className="col-md-6">
            <FormControl className="w-50">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accessStatus.addcompanies}
                disabled={user.responsibilities==="approver"}
                onChange={(e) =>
                 handleChange(e.target.value, "addcompanies")
                }
              >
                <MenuItem value={true}>Granted</MenuItem>
                <MenuItem value={false}>Denied</MenuItem>
              </Select>
            </FormControl>
            </div>
          </div>
          <div className="row ml-5 mb-3">
            <div className="col-md-6">Add Team Members</div>
            <div className="col-md-6">
            <FormControl className="w-50">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accessStatus.addteam_member}
                disabled={user.responsibilities==="approver"}
                onChange={(e) =>
                 handleChange(e.target.value, "addteam_member")
                }
              >
                <MenuItem value={true}>Granted</MenuItem>
                <MenuItem value={false}>Denied</MenuItem>
              </Select>
            </FormControl>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5 mr-5">
          <Button
            className="btn font-inter w-20"
            style={{ backgroundColor: "#03565A", color: "white" }}
            disabled={user.responsibilities==="approver"}
            onClick={addpermission}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModifyAccess;
