import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'

export function NBFC_48_50({ data, subIndex, HandleChange, dis_index_id, calculateTotal }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});

    return (
        <>
            <thead>
                <tr>
                    <th className='w-auto text-left pl-3 border-top-0 border-bottom-0'>Sr. No.</th>
                    <th className='text-left w-50 pl-3 border-top-0 border-bottom-0' >Particulars</th>
                    <th className='w-auto border-top-0 border-bottom-0' >As at {""}{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' >As at {""} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")} </th>
                </tr>
            </thead>
            <tbody>
            {
                data &&
                data.length > 0 &&
                data.map((subDis, subDisIndex) => (
                    <>
                        <tr>
                            <td className='text-left pl-3 border-top-0 border-bottom-0'> {subDis?.sr_no} </td>
                            <td className='border-top-0 border-bottom-0'>
                                <input
                                    style={{ backgroundColor: "inherit" }}
                                    className="p-0 m-0 w-100 border-0 pl-3 text-left"
                                    type={"text"}
                                    value={formatIndianCurrency(subDis?.header)}
                                    name="header"
                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                />
                            </td>
                            <td className='border-top-0 border-bottom-0'>
                                <input
                                    style={{ backgroundColor: "inherit" }}
                                    className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                    type={"text"}
                                    readOnly={typeof subDis?.cy !== "number"}
                                    value={formatIndianCurrency(subDis?.cy)}
                                    name="cy"
                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                />
                            </td>
                            <td className='border-top-0 border-bottom-0'>
                                <input
                                    style={{ backgroundColor: "inherit" }}
                                    className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                    type={"text"}
                                    readOnly={typeof subDis?.py !== "number"}
                                    value={formatIndianCurrency(subDis?.py)}
                                    name="py"
                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                />
                            </td>
                        </tr>
                    </>
                ))
            }
            <tr>
                <td className='text-dark custom-bg-gray-200'></td>
                <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>Total</td>
                <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"cy")} </td>
                <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"py")} </td>
            </tr>
            </tbody>
        </>
    );
}