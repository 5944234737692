import React from 'react'
import Button from '@mui/material/Button';
import  leftarrow from "../../assets/Image/leftarrow.png"
// import leftarrow from "./../assets/Image/leftarrow.png"
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router";




const BookcluosureNav = () => {
    let history = useHistory();

    const { wid } = useParams();

    return (
        <>
            <div className='custom-common-theme-bg-color' style={{ height: "70px" }}>

                <div className='d-flex justify-content-between px-3 py-2' onClick={() => history.push(`/bookclosure/${wid}`)}>
                    <div className='d-flex align-items-center'>
                        <img src={leftarrow} />
                        <p className='mt-0 mb-0 font-size-24 custom-font-600 text-white ml-1'>Back</p>
                    </div>
                    <div>
                        <Button variant="contained" className='custom-common-theme-bg-color text-capitalize font-size-16 custom-font-600 font-sans ext-white' style={{ border: "1px solid white", width: "200px", height: "50px" }}>View Matrix</Button>
                        <Button variant="outlined" className='bg-white primary-border ml-2 font-size-16 custom-font-600 text-capitalize custom-common-theme-text-color font-sans' style={{ width: "200px", height: "50px" }}>Export to Excel</Button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BookcluosureNav