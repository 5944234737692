import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from "@mui/material";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useState } from "react";
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import ShowLibraryReference from "./ShowLibraryReference";
import { getChecklistData } from "../../services/api/StandAlone/disclosureChecklist";
import LoaderTwo from "../Common/Loader/LoaderTwo";
import { socket } from '../../services/socket/socket';
import "./DisclouserChecklist.css";
import "../../WorkSpace/font.css";
import Loader from "../../helper/loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box p={0}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      minHeight: '50vh',
  },
  tabs: {
      maxHeight: '100vh',
      borderRight: `1px solid ${theme.palette.divider}`,
      background: 'linear-gradient(45deg, transparent, #E5EEEE)',
      color: 'black',
      fontSize: '2rem',
      width: '260px',
  },
  tab: {
      fontSize: '1rem',
      padding: '12px 12px',
      overflow: 'hidden',
      position: 'relative',
      fontSize: '1rem',
      boxSizing: 'border-box',
      minHeight: '55px',
      textAlign: 'center',
      flexShrink: 0,
      fontWeight: "500",
      lineHeight: '1.75',
      letterSpacing: '0.02857em',
      textTransform: 'none',
  },
  TabPanel: {
      width: '100%'
  },
}));

const DisclosuresChecklist = () => {
  const classes = useStyles();
  const project_id = localStorage.getItem('project_id');
  const tb_id = localStorage.getItem("tb_id");
  const dispatch = useDispatch();
  const [checklist, setChecklist] = useState([]);
  const [isShowRef, setIsShowRef] = useState({ isShow: false, data: [] });
  const [loading, setLoading] = useState(false);

  const reduxChecklistData = useSelector((initialState) => initialState.DisclosureChecklistReducer.disclosureChecklistData);
  const reduxNoteIndex = useSelector((initialState) => initialState.DisclosureChecklistReducer.noteIndex);

  useEffect(() => {
    setLoading(true);
    if (reduxChecklistData.length <= 0) {
      getChecklistData(project_id, tb_id).then(response => {
        setLoading(false);
        dispatch({ type: "DISCLOSURE_CHECKLIST_DATA", payload: response?.data?.data });
        setChecklist(response?.data?.data);
      }).catch(error => {
        setLoading(false);
        swal("Error", error, "error");
      });
    } else {
      setChecklist(reduxChecklistData);
      setLoading(false);
    }
  }, [reduxChecklistData]);

  const FilterLibRef = (reference) => {
    setIsShowRef({ ...isShowRef, data: reference, isShow: true });
  };

  const HandleRemarkChange = (e, checklist_index, dis_index) => {
    const {name, value} = e.target;
    const copy_checklist = [...checklist];

    if (
      copy_checklist[checklist_index] &&
      copy_checklist[checklist_index].disclosure &&
      copy_checklist[checklist_index].disclosure[dis_index]
    ) {
      if(name === "value"){
        if(!copy_checklist[checklist_index].disclosure[dis_index]?.isManual) return;
      }
      copy_checklist[checklist_index].disclosure[dis_index][name] = value;
    }

    setChecklist(copy_checklist);

    // Socked code for update and error handeling
    socket.emit("auto-save-disclosure-checklist", {
      project_id: `${project_id}`,
      contents: copy_checklist,
    });
    socket.on('auto-save-disclosure-checklist', (data) => {
      if(!data.success){
        swal("Error", data.message, "error");
      }
    });
  };

  return (
    <>
      {loading ? <Loader /> :
        <div className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={reduxNoteIndex}
            onChange={(event, newValue) => dispatch({ type: "NOTE_INDEX", payload: newValue }) }
            aria-label="Vertical tabs example"
            className={classes.tabs}
            TabIndicatorProps={{ style: { backgroundColor: '#03565A',width:"3px" } }}
          >
            {
              checklist && checklist.length > 0 && checklist.map((item, ix) =>(
                <Tab key={ix} className={`${classes.tab} font-sans`} style={{ color: "black", fontWeight: "600" }} label={item?.name} {...a11yProps(ix)} />
              ))
            }
          </Tabs>

          {
            checklist && checklist.length > 0 && checklist?.map((cat, cx) => (
              <TabPanel key={cx} className={classes.TabPanel} value={reduxNoteIndex} index={cx} style={{maxHeight:"47rem", overflowY:"scroll"}} >
                <table>
                  <thead>
                    <tr>
                      <th className="font-sans">Paragraph reference</th>
                      <th className="font-sans" >Disclosure</th>
                      <th className="font-sans">Yes</th>
                      <th className="font-sans">No</th>
                      <th className="font-sans">NA</th>
                      <th className="font-sans">REMARKS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cat.disclosure.map((dis, dx) => (
                        <tr key={dx} className={dis?.isManual && "bg-warning-100"}>
                          <td className="fs-inter p-table text-left pl-2 cursor-pointer" onClick={() => FilterLibRef(dis)} >{dis?.reference}</td>
                          <td>
                            <h6 className="p-secondtable font-sans">
                              {dis?.notes}
                            </h6>
                          </td>
                          <td>
                            <input 
                              type="checkbox" 
                              checked={dis?.value === "yes"}
                              value="yes" 
                              name="value"
                              onChange={(e) => HandleRemarkChange(e,cx,dx)}
                            />
                          </td>
                          <td>
                            <input 
                              type="checkbox" 
                              checked={dis?.value === "no"}
                              value="no" 
                              name="value"
                              onChange={(e) => HandleRemarkChange(e,cx,dx)}
                            />
                          </td>
                          <td>
                            <input 
                              type="checkbox" 
                              checked={dis?.value === "na"}
                              value="na"
                              name="value"
                              onChange={(e) => HandleRemarkChange(e,cx,dx)}
                            />
                          </td>
                          <td >
                            <input 
                              className={`h-100 border-0 px-1 font-sans ${dis?.isManual && "bg-warning-100"}`}
                              placeholder="add remark"
                              name="remark"
                              value={dis?.remark}
                              onChange={(e) => HandleRemarkChange(e,cx,dx)}
                            />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </TabPanel>
            ))
          }
        </div>
      }
      <ShowLibraryReference isShowRef={isShowRef} setIsShowRef={setIsShowRef} />
      
    </>
  );
};

export default DisclosuresChecklist;
