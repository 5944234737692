import React from 'react'
import Button from "react-bootstrap/Button";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import Table from "react-bootstrap/Table";
import { Card } from '@mui/material'
import { Navbar } from '../../../Components/Navbar';
import { useHistory } from 'react-router';

const ViewLogpage = () => {
    const history = useHistory();
    return (
        <div>
            <Navbar />
            <div className='d-flex '>
                <div className=" container custom-container d-flex flex-column justify-content-between mt-2 h-100 ml-1 mr-1">
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4  m-5" onClick={()=>history.push('/IAM')}>Application Overview Logins</div>
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 m-5">Contact Support</div>
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 m-5" onClick={()=>history.push('/version-updates')}> Version details and Updates </div>
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 m-5"> </div>
                </div>

                <Card className="w-100 ml-3 mt-2 ">
                    <div className='flex-grow-1 mr-1 mt-5'>
                        <p className='ml-1 mt-2'>Logs:</p>
                        <div className=' mr-1 ml-1 mt-5'>
                            <TableContainer>
                                <Table sx={{}} aria-label="caption table">
                                    {/* {users.length > 0 ? ( */}
                                    <>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className="font-sans">
                                                    Admin ID
                                                </TableCell>
                                                <TableCell align="center" className="font-sans">
                                                    User ID
                                                </TableCell>
                                                <TableCell align="center" className="font-sans">
                                                    Modified - Time Stamp
                                                </TableCell>
                                                <TableCell align="center" className="font-sans">
                                                    Access Modified
                                                </TableCell>
                                                <TableCell align="center" className="font-sans">
                                                    Company/ Group
                                                </TableCell>
                                                <TableCell align="center" className="font-sans">
                                                    Remarks
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {users.map((det,index) => {
                                                    return ( */}
                                            <>
                                                <TableRow>
                                                    <TableCell className="font-sans"
                                                        style={{ backgroundColor: "white" }}
                                                        align="left"
                                                    // onClick={() => history.push(`/edit-user/${det._id}`)}
                                                    >
                                                        {/* {index+1} */}
                                                    </TableCell>
                                                    <TableCell className="font-sans"
                                                        style={{ backgroundColor: "white" }}
                                                        align="center"
                                                    >
                                                        {" "}
                                                        {/* {det.email} */}
                                                    </TableCell>
                                                    <TableCell className="font-sans"
                                                        style={{ backgroundColor: "white" }}
                                                        align="center"
                                                    >
                                                        {/* {det.name} */}
                                                    </TableCell>
                                                    <TableCell className="font-sans"
                                                        style={{ backgroundColor: "white" }}
                                                        align="center"
                                                    >
                                                        <span style={{ cursor: 'pointer' }}>View</span>/<span style={{ cursor: 'pointer' }} >Edit</span>
                                                    </TableCell>
                                                    <TableCell
                                                        className="font-sans"
                                                        style={{ backgroundColor: "white" }}
                                                        align="center"
                                                    >
                                                        <span
                                                            style={{ cursor: "pointer", color: 'red' }}
                                                        //   onClick={() => deleteUser(det._id)}
                                                        >
                                                            Delete Id access
                                                        </span>

                                                    </TableCell>
                                                    <TableCell className="font-sans"
                                                        style={{ backgroundColor: "white" }}
                                                        align="center"
                                                    >
                                                        {/* {det.name} */}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                            {/* )})}  */}
                                        </TableBody>
                                    </>
                                    {/* ):""} */}
                                </Table>
                            </TableContainer>

                        </div>
                    </div>
                    <Button
                        style={{
                            backgroundColor: "white",
                            color: "#03565A",
                            border: "2px solid #03565A",
                            textTransform: "none",
                            fontWeight: "500",
                            
                        }}
                        variant="contained"
                        className='ml-1 pl-4 pr-4 mt-4'
                    // onClick={() => history.push("/addyourteam")}
                    >
                        Export Log
                    </Button>
                </Card>
            </div>
        </div>
    )
}

export default ViewLogpage