import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import { addAdjustmentItem, getAdjustmentOptions, addNewOption } from '../../../services/api/StandAlone/cashFlow';
import "../cashFlow.css"
import AddNewAdjustmentModal from './AddNewAdjustmentModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddNewAdjustment = () => {
    let history = useHistory();
    const project_id = localStorage.getItem('project_id');
    const tb_id = localStorage.getItem('tb_id');
    const [open, setOpen] = useState(false)
    const [description, setDescription] = useState('');
    const [headId, setHeadId] = useState('');
    const [adjustmentHeaderData, setAdjustmentHeaderData] = useState([]);
    const [optionData, setOptionData] = useState([]);
    const [workingId, setWorkingId] = useState('');

    useEffect(() => {
        getAdjustmentOptions(project_id, tb_id).then(res => {
            setAdjustmentHeaderData(res.data.data)
            setWorkingId(res.data.workingId)
            setOptionData([...optionData, {
                id: 1,
                headId:1,
                headId1:1,
                subHeadId: 1,
                subHead1Id: 1,
                amount: "",
                amount1: "",
                header: res.data.data,
                header1: res.data.data,
                subHeader: res.data.data[0]?.subHeader,
                subHeader1: res.data.data[0]?.subHeader,
                workingId:'',
                workingId1:"",
                cashflowName:"Continuing operations",
                cashflowName1:"Continuing operations"
            }])
        }).catch(err => {
            swal("Error", err, "error");
        })
    }, []);
    
    const HandleChange = (v, index, key) => {
        if (adjustmentHeaderData.length > 0) {
            let sub = adjustmentHeaderData.find(ele => ele.headId == v)?.subHeader;
            let copy = [...optionData]
            optionData.forEach((ele, ix) => {
                if (ix == index) {
                    if (key === 0) {
                        ele.subHeader = sub
                        ele.workingId = sub[0].workingId ? sub[0].workingId : ""
                        ele.cashflowName = sub[0]?.subHeader
                        ele.headId = v;
                    } else if (key === 2) {
                        if("add_option" == v){
                            setHeadId(ele.headId);
                            setOpen(true);
                            return
                        }
                        let subElement = ele.subHeader?.find(ele => ele.subHeaderId == v);
                        ele.workingId = subElement?.workingId ? subElement?.workingId : ""
                        ele.cashflowName = subElement?.subHeader
                    } else if (key === 1) {
                        ele.subHeader1 = sub
                        ele.workingId1 = sub[0].workingId ? sub[0].workingId : ""
                        ele.cashflowName1 = sub[0]?.subHeader
                        ele.headId1 = v;
                    } else if (key === 3) {
                        if("add_option" == v){
                            setHeadId(ele.headId1);
                            setOpen(true);
                            return
                        }
                        let subElement = ele.subHeader1?.find(ele => ele.subHeaderId == v);
                        ele.workingId1 = subElement.workingId ? subElement.workingId : ""
                        ele.cashflowName1 = subElement?.subHeader
                    } else if (key === 4) {
                        ele.amount = v
                    } else if (key === 5) {
                        ele.amount1 = v
                    }
                }
            })

            setOptionData(copy);
        }
    }

    const AddNew = () => {
        let len = optionData.length + 1
        setOptionData([...optionData, {
            id: len,
            headId: 1,
            headId1:1,
            subHeadId: 1,
            amount: "",
            head1Id: 1,
            subHead1Id: 1,
            amount1: "",
            header: adjustmentHeaderData,
            subHeader: adjustmentHeaderData[0]?.subHeader,
            header1: adjustmentHeaderData,
            subHeader1: adjustmentHeaderData[0]?.subHeader,
            workingId:'',
            workingId1:"",
            cashflowName:"Continuing operations",
            cashflowName1:"Continuing operations"
        }])
    }

    const RemoveItem = (i) => {
        let copy = [...optionData]
        copy.splice(i, 1)
        setOptionData(copy)
    }

    const HandleSave = (index) => {
        let findObj = optionData.find((ele,i) => i === index)
        console.log("find obj 000", findObj)
        let data = {
            description:description,
            amount:findObj.amount,
            amount1:findObj.amount1,
            workingId:findObj.workingId,
            workingId1:findObj.workingId1,
            cashflowName : findObj.cashflowName, 
            cashflowName1 : findObj.cashflowName1, 
            obj:findObj
        }

        addAdjustmentItem(project_id, data).then((res) => {
            console.log(res.data)
            swal("Success",`${res.data.message} go to adjustment log.`, "success");
        }).catch(err => {
            swal("Error", err, "error");
        })

    }

    const AddSubGroup = (value, id) => {
        let data = {
            name:value,
            id:id,
            workingId:workingId + 1,
            description:description
        }
        addNewOption(project_id, data).then(res => {
            setOpen(false);
            setAdjustmentHeaderData(res.data.header);
            let copy = [...optionData]
            copy.forEach((ele,i) => {
                ele.header = res.data.header
                ele.header1 = res.data.header
                ele.subHeader = res.data.header.find(e => e.headId == ele.headId)?.subHeader
                ele.subHeader1 = res.data.header.find(e => e.headId == ele.headId1)?.subHeader
            })
            setOptionData(copy)
        }).catch(err => {
            console.log("error-----", err)
        })
    }

    return (
        <>
            <div>
                <button className='ml-2 mt-3 rounded back-btn' style={{ border:"none", backgroundColor:"inherit" }}
                    onClick={() => {history.push(`/financialstatement/cashflow/working`)}}
                >
                    <div className='d-flex px-3' style={{ color: "#03565a", fontWeight: "600" }}><ArrowBackIcon /> Back</div>
                </button>
                <div className='mx-4'>
                    <p className='text-center border py-1 mt-2' style={{ backgroundColor: "#e1f0fa", fontWeight: "600" }}>Add adjustment </p>
                    <div className='d-flex' style={{ gap: "1rem" }}>
                        <p className='mt-0 mb-0 w-25'>Adjustment description</p>
                        <textarea placeholder='Interest' className='p-2 w-25' onChange={(e) => setDescription(e.target.value)} />
                    </div>

                    {
                        optionData?.length > 0 && optionData.map((ele, ix) => (
                            <div className='d-flex' key={ix}>
                                <div>
                                    <div className='d-flex mt-3' style={{ gap: "1rem" }}>
                                        <p className='mt-0 mb-0 w-50'>Adjustment to be shown under</p>
                                        <select onChange={(e) => HandleChange(e.target.value, ix, 0)} className='p-3 custom-common-theme-bg-color' style={{ color: "white", border: "none", width: "15rem" }}>
                                            {
                                                ele?.header.length > 0 && ele?.header.map((ele, i) => (
                                                    <option className='adj-select' style={{ color: "black", backgroundColor: "white" }} key={i} value={ele?.headId} >{ele?.head}</option>
                                                ))
                                            }
                                        </select>
                                        <select onChange={(e) => HandleChange(e.target.value, ix, 2)} className='p-3 custom-common-theme-bg-color' style={{ color: "white", border: "none", width: "15rem" }}>
                                            {
                                                ele?.subHeader && ele?.subHeader.length > 0 && ele?.subHeader.map((ele, i) => (
                                                    <option style={{ color: "black", backgroundColor: "white" }} key={i} value={ele?.subHeaderId} >{ele?.subHeader}</option>
                                                ))
                                            }
                                            <option style={{ color: "black", backgroundColor: "white" }} value={"add_option"} onClick={() => AddSubGroup} >{"+ Add New"}</option>
                                        </select>
                                        <input placeholder='Amount' className='p-2' onChange={(e) => HandleChange(Number(e.target.value), ix, 4)} />
                                    </div>
                                    <div className='d-flex mt-3' style={{ gap: "1rem" }}>
                                        <p className='mt-0 mb-0 w-50'>Adjustment to be shown under</p>
                                        <select onChange={(e) => HandleChange(e.target.value, ix, 1)} className='p-3 custom-common-theme-bg-color' style={{ color: "white", border: "none", width: "15rem" }}>
                                            {
                                                ele?.header1.length > 0 && ele?.header1.map((ele, i) => (
                                                    <option style={{ color: "black", backgroundColor: "white" }} key={i} value={ele?.headId} >{ele?.head}</option>
                                                ))
                                            }
                                        </select>
                                        <select onChange={(e) => HandleChange(e.target.value, ix, 3)} className='p-3 custom-common-theme-bg-color' style={{ color: "white", border: "none", width: "15rem" }}>
                                            {
                                                ele?.subHeader1 && ele?.subHeader1.length > 0 && ele?.subHeader1.map((ele, i) => (
                                                    <option style={{ color: "black", backgroundColor: "white" }} key={i} value={ele?.subHeaderId} >{ele?.subHeader}</option>
                                                ))
                                            }
                                            <option style={{ color: "black", backgroundColor: "white" }} value={"add_option"} onClick={() => AddSubGroup} >{"+ Add New"}</option>
                                        </select>
                                        <input placeholder='Amount' className='p-2' onChange={(e) => HandleChange(Number(e.target.value), ix, 5)} />
                                    </div>
                                </div>

                                <div className='d-flex align-items-center ml-2'>
                                    <button className='my-2 rounded deleteBtn mt-2' style={{ border: "none", backgroundColor: "white" }}
                                        onClick={() => {HandleSave(ix)}}
                                    >
                                        <div className='px-3 py-1' style={{ color: "#03565a", fontWeight: "600" }}>Save</div>
                                    </button>
                                </div>
                                <div className='d-flex align-items-center ml-2'>
                                    <button className='my-2 rounded deleteBtn mt-2' style={{ border: "none", backgroundColor: "white" }}
                                        onClick={() => RemoveItem(ix)}
                                    >
                                        <div className='px-3 py-1' style={{ color: "#03565a", fontWeight: "600" }}>Remove</div>
                                    </button>
                                </div>
                            </div>
                        ))
                    }

                    <button className='my-2 rounded deleteBtn mt-2' style={{ border: "none", backgroundColor: "white" }}
                        onClick={() => { AddNew() }}
                    >
                        <div className='px-3 py-1' style={{ color: "#03565a", fontWeight: "600" }}>Add New Item +</div>
                    </button>

                    {/* <div className='d-flex mt-3' style={{ gap: "1rem" }}>
                        <p className='mt-0 mb-0 w-25'>Total</p>
                        <input placeholder='Amount' className='p-2' disabled />
                    </div> */}

                </div>
            </div>

            {
                open && <AddNewAdjustmentModal open={open} setOpen={setOpen} HandleSubmit={(value) => AddSubGroup(value, headId)} /> 
            }
        </>
    )
}

export default AddNewAdjustment