import moment from "moment";
import { getAllIR } from "../WorkSpace/Components/ApiServices/apiHandler";
import { useState } from "react";


export const getCyPyData = (projectGroup, note_no) => {
  if (projectGroup) {
    const projectGroupData = [];
    projectGroup.forEach((data, index) => {
      let notesData = data.notes_grp.filter(res=>res.notes_no === note_no)
      if(notesData.length !== 0){
        let total_cy = notesData[0].sub_grp.map(item => {return {
          "fs_grp": data.fs_grp, 
          "notes_grp": notesData[0].notes_grp,
          "notes_no": notesData[0].notes_no,
          "cy_amt": item.cy_amt, 
          "py_amt": item.py_amt, 
          "group":item.sub_grp,
          "headings": notesData[0]?.headings || []
        }
        });
        projectGroupData.push(total_cy)
      }
    })
    return projectGroupData;
  }
   
}


export const getGroupName = (projectGroup, note_no) => {
    try {
      const result = projectGroup.map((data, index) => {
        if(data.notes_grp.findIndex(res => res.notes_no === note_no) !== -1){
          return projectGroup[index].fs_grp;
        }
      })
      return result.filter(data => data !== undefined)
    } catch (error) {
      console.log("err", error)
    }
}

export const getAdjustmentValue = (scenario_data, ledger_name) => {
    try {
        const res = scenario_data.map((data) =>  { return data.adjustment.map(item=>{
            if(item.ledger_name === ledger_name && item.fs_grp === data.brows_fs.fs_grp){
                return Number(item.adjustment_value);
            }else{
                return 0
            }
            })
        });
        const res2 = scenario_data.map((data_2) => { return data_2?.autoPopulate.map(item_2 => {
                if(item_2.ledger_name === ledger_name && item_2.fs_grp === data_2.brows_fs.fs_grp){
                    return Number(item_2.adjustment_value);
                }else{
                    return 0
                }
            })
        })
        if(res.length > 0 && res2.length > 0){
            return res.flat().reduce((num, acc)=> Number(num) + acc, 0) + res2.flat().reduce((num, acc)=> Number(num) + acc, 0);
        }else if(res.length > 0){
            return res.flat().reduce((num, acc)=> Number(num) + acc, 0)
        }else if(res2.length > 0){
            return res2.flat().reduce((num, acc)=> Number(num) + acc, 0)
        }else{
            return 0;
        }
    } catch (error) {
      console.log("err ad", error.message)
      return 0
    }
}

function generateRange(input) {
  const [start, end] = input;
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}

function sumKeyAtIndex(arr, indices, key) {
  const sum = indices.reduce((accumulator, currentIndex) => {
      if (currentIndex >= 0 && currentIndex < arr.length && arr[currentIndex][key] !== undefined) {
        return accumulator + arr[currentIndex][key];
      } else {
        console.log(`Index ${currentIndex} or key "${key}" is invalid.`);
        return accumulator; // Return accumulator unchanged for invalid indices or keys
      }
  }, 0);

  return sum;
}

export const calSubGrpHeading = (subGroups, from, to, type, action) => {
  try {
    const from_res = from.length ? sumKeyAtIndex(subGroups, generateRange(from), type) : 0;
    const to_res = to.length ? sumKeyAtIndex(subGroups, generateRange(to), type) : 0;
    if(action === 'sum'){
      return from_res + to_res;
    }else{
      return from_res - to_res;
    }
  } catch (err) {
    console.log("subString heading err", err.message);
    return "*"
  }
}

export const manualAutoAdjustment = (scenario_result, browsDataRes_other, scenario) => {
    switch(scenario){
      case 1:
        const first_scenario_res = scenario_result.filter((data)=>data.scenario.first_scenario === true);
        const first_result =  getAdjustmentValue(first_scenario_res, browsDataRes_other[0].brows_fs.group);
        return  first_result;
      case 2: 
        const second_scenario_res = scenario_result.filter((data)=>data.scenario.second_scenario === true);
        const second_result = getAdjustmentValue(second_scenario_res, browsDataRes_other[0].brows_fs.group);
        return second_result;
      case 3:
        const third_scenario_res = scenario_result.filter((data)=>data.scenario.first_scenario !== true && data.scenario.second_scenario !== true);
        const third_result = getAdjustmentValue(third_scenario_res, browsDataRes_other[0].brows_fs.group);
        return third_result;
      default:
        return 0
    }
  }

export const getRandomKey = () => {
    const min = 10000;
    const max = 99999;
    const num = Math.floor(Math.random() * (max - min + 1)) + min;
    return num;
}

export const prepareAdjustmentData = (AdjData, note_no) => {
    try {
      if(AdjData?.response?.getConsoleAdjustment){
        const adj_data = AdjData.response.getConsoleAdjustment.map(data=>data.adjustment_other_data).flat().filter(item=>item.notes_no === note_no);
        return adj_data;
      } else{
        return [];
      }
    } catch (error) {
      console.log("err", error)
    }
}

export const prepareAutoPopulationData = (APAData, note_no) => {
    try {
      if(APAData.isSuccess){
        const adj_data = APAData.response.filter(item=>item.note_no === note_no);
        return adj_data;
      } else{
        return [];
      }
    } catch (error) {
      console.log("err", error)
    }
}

export const getScenario = (currentCompany, subsidiaryData, subsidiary_index,FindIr) => {
  try{
    let currentDate = moment(currentCompany.current_financial_year).format('YYYY-04-01');
    let previousDate = moment(currentCompany.current_financial_year).subtract(1, "year").format('YYYY-03-31');
    let previousDate_2nd = moment(currentCompany.current_financial_year).subtract(1, "year").format('YYYY-04-01');
    let data = FindIr(subsidiaryData[subsidiary_index].Ir_id);
    let testDate = moment(data[0]?.date_Of_investment).format('YYYY-MM-DD');
    let py_date = moment(previousDate).subtract(1, "year").format('YYYY-03-01');
    let first_scenario = moment(testDate).isBetween(previousDate,currentDate);
    let second_scenario = moment(testDate).isBetween(py_date,previousDate_2nd);
    return {
      first_scenario: first_scenario,
      second_scenario: second_scenario
    }
  }catch(err){
    return {
      first_scenario: 0,
      second_scenario: 0
    }
    
  }
    
}

export const getNoteNumber = (notes, index) => {
    try{
      let consoleNotes = [];
      Object.keys(notes).forEach((key)=>{
        consoleNotes.push(notes[key])
      })
      const note_no = consoleNotes.flat().find(note => note.index === index);
      if (note_no !== null || note_no !== undefined){
        return note_no.note_number;
      }else{
        return 0
      }
    }catch(err){
      // console.log("error getNoteNumber", index,  err.message)
      return "--"
    }
}

export const getPatternType = (notes, note_no) => {
    try{
        const pattern = Object.keys(notes).map((keynote, index)=>{
            if(notes[keynote].find(item=>item.note_number===note_no) !== undefined){return keynote}
        })
        const final_pattern = pattern.filter(data => data != undefined);
        if(final_pattern.length > 0){
            return final_pattern[0]
        }
    }catch(err){
        return "--"
    }
}

export const groupDataCal = (groupingData, note_no) =>{
    if(groupingData.isSuccess === true){
      const GroupData = groupingData?.response?.data;
      const result = getCyPyData(GroupData?.current_company_grouping?.fs_grp, note_no);
      const projectsGroup = GroupData?.project_ids_grouping.map((data)=> getCyPyData(data.fs_grp, note_no))
      const browsFsDAta = GroupData?.browse_fs_grouping?.map((data)=> getCyPyData(data.fs_grp, note_no))
      return {
        currentProject: result,
        Projects: projectsGroup,
        BrowsFsData: browsFsDAta
      }
    }
  }

export const setNoteNumber = (fsRows, currentProjectBs,) => {
  let noArr = [];
    fsRows.map((row, i) => {
      currentProjectBs[row].map((line) => {
        if (
          noArr.length > 0 &&
          noArr.some(
            (user) =>
              user.notes_grp.toLowerCase() === line.notes_grp.toLowerCase()
          )
        ) {
        } else {
          noArr.push({ notes_grp: line.notes_grp });
        }
      });
    });
  return noArr;
}