import React from "react";
import Navbarv3 from "../../Workspace-v2/Navbarv3";
import Sidbarv2 from "../../Workspace-v2/Sidbarv2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import consolidlogo from "../../../assets/consolidlogo.svg";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
// import "./sidebarv2.css"
import "./../../Workspace-v2/sidebarv2.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Checkbox from "@mui/material/Checkbox";
import Popover from "@mui/material/Popover";
import CircularProgress from "@mui/material/CircularProgress";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from "@mui/material/Modal";
import { Navbar } from "../../../Components/Navbar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  deleteControlFS,
  getAllIR,
  getCompany,
  getOneAccessWorkspacedata,
  getWorkspace,
  saveFsData,
} from "../ApiServices/apiHandler";
import { useState } from "react";
import axios from "axios";
import PreSetModal from "../../../Console/Component/modal/presetModal";
import {
  getCompanybyId,
  getPrepareConsole,
  getProjects,
  getRegisterData,
  getfsData,
} from "../../../Console/Component/services/apiHandler";
import swal from "sweetalert";
import SubsidiarySelectModal from "../Modal/selectFsGrpModal";
import PreSetWorkspaceModal from "../Modal/presetWorkspaceModal";
import CreateFsModal from "../Modal/createFsModal";
import CompareFs from "../Modal/compareFsModal";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrepareConsoleData } from "../../../redux/actions/actionPrepareConsole";
import { selectPrepareConsole } from "../../../redux/actions/actionMiscellaneous";
import { fetchWorkspacePrepareConsoleData } from "../../../redux/actions/actionWorkspacePrepareConsole";
import { useEffect } from "react";
import LoaderTwo from "../../../Components/Common/Loader/LoaderTwo";

// /second modal/
const style_v2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 670,
  bgcolor: "background.paper",
  outline: "none",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};
//

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Consolidation = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fsId, setFSID] = useState("");
  const handleIdClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setFSID(id);
  };

  const handleIdClose = () => {
    setAnchorEl(null);
  };

  const idopen = Boolean(anchorEl);
  const id = idopen ? "simple-popover" : undefined;

  // const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-17'));

  const [open, setOpen] = React.useState(false);
  const [openCompare, setOpenCompare] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClosePreset = () => openPreset(false);
  const handleOpenCompare = () => {
    setOpenCompare(true);
  };
  const auth = localStorage.getItem("auth_token");

  const [isopen, setIsOpen] = React.useState(false);

  const handleIsClose = () => {
    setOpenCompare(false);
    setIsOpen(false);
    setPrepareConsoleID();
  };
  const [oneAccess, setOneAccess] = useState("");
  const [value, setValue] = React.useState(0);
  const [allpreconsoldata, getAllPreConsolCompany] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [allIr, setAllIR] = useState([]);
  const [selectedIr, setSelectedIr] = useState([]);
  const [preset, openPreset] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [company, setCompany] = useState("");
  const [investmentRegister, SetInvestmentRegister] = useState([]);
  const [projectPreset, setProjectPreset] = useState(null);
  const [consoldata, setConsolData] = useState("");
  const [workspaceData, setWorkspace] = useState("");
  const [uploadPresetID, setUploadPresetId] = useState("");
  const [prepareConsoleId, setPrepareConsoleID] = useState("");
  const [fieldModal, setField] = useState("");
  const [presetId, setPresetId] = useState("");
  const [presetIdData, getPreConsole] = useState("");

  const prepareConsole_data = useSelector(
    (state) => state.PrepareConsoleReducer
  );
  const workspacePrepareConsol = useSelector(
    (state) => state.WorkspacePrepareconsolReducer
  );
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (workspacePrepareConsol.isSuccess === false || workspacePrepareConsol.set_payload !== w_id[2]) {
      dispatch(fetchWorkspacePrepareConsoleData(w_id[2]));
    }
  }, [])
  
  React.useEffect(() => {
    getOneAccessWorkspace();
    GetCompany();
    getAllIrData();
    // getPreConsole();
    fetchPreConsole();
    getCompanyData();
    getPrepareConsole();
    fetchWorskSpace();
    if (prepareConsole_data.isSuccess === false || prepareConsole_data.set_payload !== prepareConsoleId) {
      dispatch(fetchPrepareConsoleData(prepareConsoleId));
    }
    
    // fetchInvestCompany();
  }, []);

  const [fsData, setFsData] = useState({
    consol_name: "",
    parent_company: "",
    link_ir: "",
    c_yr: "",
    p_yr: "",
  });

  const GetCompany = async () => {
    const res = await getCompany();
    if (res?.status === 200) {
      setAllCompany(res.data.getCompany);
    }
  };
  const uploadPreset = (field, preset_id) => {
    openPreset(true);
    if (field === "empty") {
      setField(field);
      setPresetId(preset_id);
    }
  };
  const location = useLocation();
  let a = location.pathname;
  let w_id = a.split("/");
  let history = useHistory();
  const getOneAccessWorkspace = async () => {
    const res = await getOneAccessWorkspacedata(w_id[2]);
    if (res.status === 200) {
      setOneAccess(res.data.getOneAccessWorkspace[0]);
    }
  };

  const fetchPreConsole = () => {
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    axios
      .get(`api/v1/prepare-consol/fetch-preConsol-data/${w_id[2]}`, {
        headers,
      })
      .then((response) => {
        getAllPreConsolCompany(response.data.getPreConsolCompany);
      });
  };
  const getCompanyData = async () => {
    try {
      const response = await getProjects();
      setProjectsData(response.data.project);
    } catch (err) {
      console.log("err", err.response);
    }
  };
  const getAllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIR(res.data.fetchIR);
    }
  };
  const handleChangeValue = (e, field) => {
    if (field === "parent_company") {
      const result = allIr.filter(
        (adj) => adj.parent_company_id === e.target.value
      );
      setSelectedIr(result);
    }
    setFsData({ ...fsData, [field]: e.target.value });
  };
  const handleSubmitFs = async () => {
    let data = {
      console_name: fsData.consol_name,
      parent_company: fsData.parent_company,
      workSpaceId: w_id[2],
      link_ir: fsData.link_ir,
      current_yr: workspaceData[0]?.console_cy,
      previous_yr: workspaceData[0]?.console_py,
      precet_id: uploadPresetID,
    };
    const res = await saveFsData(data);
    if (res.status === 200) {
      setOpen(false);
      fetchPreConsole();
      setUploadPresetId();
      dispatch(fetchWorkspacePrepareConsoleData(w_id[2]));
    }
  };
  const getCompanyId = async (company_id) => {
    try {
      const response = await getCompanybyId(company_id);
      setCompany(response.data.getOneCompany[0].company_name);
    } catch (error) {
      console.log("getCompany", error.response);
    }
  };

  const handleIsOpen = (prepare_console) => {
    setIsOpen(true);
    getPreConsole(prepare_console._id);
    setPrepareConsoleID(prepare_console.link_company);
    dispatch(selectPrepareConsole(prepare_console));
  };
  const handleIsError = () => {
    swal("", "Please Upload Preset", "error");
  };
  const fetchWorskSpace = async () => {
    const res = await getWorkspace(w_id[2]);
    if (res.status === 200) {
      setWorkspace(res.data.getworkspace);
    }
  };

  const handleDeleteFs = async (id) => {
    const res = await deleteControlFS(id);
    if (res.status === 200) {
      fetchPreConsole();
      swal("", "Deleted Sucessful", "success");
      handleIdClose();
      dispatch(fetchWorkspacePrepareConsoleData(w_id[2]));
    }
  };
  const CompanyName = (c_id, field) => {
    const result = allCompany.filter((adj) => adj._id === c_id);
    if (field === "parent") {
      return result[0]?.company_name ? result[0]?.company_name : "-";
    } else {
      return result[0]?.company_name ? result[0]?.company_name : "-";
    }
  };
  const LinkIRName = (Ir_id, field) => {
    const result = allIr.filter((adj) => adj.ir_id === Ir_id);
    if (field === "link") {
      return result[0]?.ir_name ? result[0]?.ir_name : "-";
    } else {
      return result[0]?.ir_name ? result[0]?.ir_name : "-";
    }
  };

  const ProjectName = (p_id) => {
    const result = projectsData.filter((adj) => adj._id === p_id);
    return result[0]?.project_name ? result[0]?.project_name : "";
  };

  const handleViewFS = (prepare_id) => {
    history.push(`/view-fs/${prepare_id}`);
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="d-flex">
        <div>
          <Sidbarv2 w_id={w_id} />
        </div>
        <div className="w-100 px-4 mt-2 ">
          <div>
            {workspacePrepareConsol.loading ||
            !workspacePrepareConsol.response.getPreConsolCompany ? (
              <LoaderTwo/>
            ) : workspacePrepareConsol.isSuccess &&
              workspacePrepareConsol?.response?.getPreConsolCompany?.length >
                0 ? (
              <>
              <div className="d-flex justify-content-between mt-4">
                <p className="dm-sans fs-head">Consolidated financial statements</p>
              </div>
                <div
                  style={{
                    height: 80,
                    width: "100%",
                    backgroundColor: "white",
                    marginTop: "10px",
                  }}
                >
                  <div className="my_button">
                    {oneAccess?.access === "View" ? (
                      <Button className="my_button_v1" disabled>
                        <span style={{ marginRight: "10px" }}>
                          <AddIcon />
                        </span>
                        Create consol FS
                      </Button>
                    ) : (
                      <Button onClick={handleOpen} className="my_button_v1">
                        <span style={{ marginRight: "10px" }}>
                          <AddIcon />
                        </span>
                        Create consol FS
                      </Button>
                    )}
                    <Button
                      onClick={handleOpenCompare}
                      className="my_button_v1"
                    >
                      <span style={{ marginRight: "10px" }}>
                        <AddIcon />
                      </span>
                      Compare
                    </Button>
                  </div>
                </div>
                <div style={{}}>
                  <TableContainer component={Paper} className="mb-4 w100">
                    <Table sx={{}} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>CONSOL NAME</TableCell>
                          <TableCell align="center">PARENT COMPANY</TableCell>
                          <TableCell align="center">
                            PARENT COMPANY PRESET
                          </TableCell>
                          <TableCell align="center">INVESTEES</TableCell>
                          <TableCell align="center">INVESTMENT REGISTER</TableCell>
                          <TableCell align="center">VIEW FS</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {workspacePrepareConsol?.response?.getPreConsolCompany?.map(
                          (dat, i) => (
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ backgroundColor: "white" }}
                              >
                                {dat.prepare_consol_company_name}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ backgroundColor: "white" }}
                              >
                                {CompanyName(dat?.parent_company, "parent")}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ backgroundColor: "white" }}
                              >
                                {dat?.upload_preset ? (
                                  ProjectName(dat?.upload_preset)
                                ) : (
                                  <Button
                                    style={{
                                      width: "150px",
                                      textTransform: "none",
                                      backgroundColor: "white",
                                      border: "2px solid #03565A",
                                      color: "black",
                                      height: "40px",
                                      borderRadius: "10px",
                                    }}
                                    variant="contained"
                                    onClick={() =>
                                      uploadPreset("empty", dat._id)
                                    }
                                  >
                                    Upload Preset
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ backgroundColor: "white" }}
                              >
                                <Badge>
                                  Click{}
                                  {dat?.upload_preset ? (
                                    <span>
                                      <DriveFileRenameOutlineIcon
                                        onClick={() => handleIsOpen(dat)}
                                        style={{
                                          color: "#03565A",
                                          marginLeft: "5px",
                                          cursor:"pointer"
                                        }}
                                      />
                                    </span>
                                  ) : (
                                    <span>
                                      <DriveFileRenameOutlineIcon
                                        onClick={() => handleIsError()}
                                        style={{
                                          color: "#03565A",
                                          marginLeft: "5px",
                                        }}
                                      />
                                    </span>
                                  )}
                                </Badge>
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ backgroundColor: "white" }}
                              >
                                {LinkIRName(dat.link_company)}
                              </TableCell>
                              <TableCell
                                style={{ backgroundColor: "white" }}
                                align="center"
                              >
                                <Button
                                  style={{
                                    width: "150px",
                                    textTransform: "none",
                                    backgroundColor: "white",
                                    border: "2px solid #03565A",
                                    color: "black",
                                    height: "40px",
                                    borderRadius: "10px",
                                  }}
                                  variant="contained"
                                  onClick={() => handleViewFS(dat._id)}
                                >
                                  View Fs
                                </Button>
                              </TableCell>
                              <TableCell
                                style={{ backgroundColor: "white" }}
                                align="center"
                              >
                                <MoreVertIcon
                                  onClick={(e) => handleIdClick(e, dat._id)}
                                />
                                <Popover
                                  id={id}
                                  open={idopen}
                                  anchorEl={anchorEl}
                                  onClose={handleIdClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                >
                                  <Typography sx={{ p: 2 }}>
                                   <span
                                    style={{ cursor: "pointer",fontSize: "12px" }}
                                   >Edit</span>  <br />
                                    <span
                                      onClick={() => handleDeleteFs(fsId)}
                                      style={{ cursor: "pointer",fontSize: "12px" }}
                                    >
                                      Delete
                                    </span>
                                  </Typography>
                                </Popover>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-between mt-4">
                <p className="dm-sans fs-head">Consolidated financial statements</p>
              </div>
                <div className="mt-4">
                  <TableContainer
                    style={{ height: "400px" }}
                    className=""
                    component={Paper}
                  >
                    <Table aria-label="caption table">
                      <TableHead>
                        {/* <div
                          className="wpTeampmember"
                          style={{
                            height: "80px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div className="mt-3">
                            <div>
                              <input
                                style={{
                                  width: "200px",
                                  height: "50px",
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                }}
                                type="date"
                              ></input>
                            </div>
                          </div>
                          <div className="mt-3 ml-2">
                            <input
                              style={{
                                width: "200px",
                                height: "50px",
                                border: "1px solid gray",
                                borderRadius: "5px",
                              }}
                              type="date"
                            ></input>
                          </div>
                        </div> */}
                        <TableRow>
                          <TableCell>CONSOL NAME</TableCell>
                          <TableCell align="center">PARENT COMPANY</TableCell>
                          <TableCell align="center">
                            PARENT COMPANY'S PRESET
                          </TableCell>
                          <TableCell align="center">INVESTEES</TableCell>
                          <TableCell align="center">VIEW FS</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody></TableBody>
                    </Table>
                    <div className="d-flex justify-content-center mt-4">
                      <div className="fs_logo_1">
                        <div>
                          <img
                            className="image_fs_1"
                            src={consolidlogo}
                            alt="logo"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                      No consol statements create so far
                    </div>
                    <div className="consol_fs">
                      {oneAccess?.access === "View" ? (
                        <Button
                          className="my_button_v1"
                          variant="contained"
                          disabled
                        >
                          <span>
                            <AddIcon />
                          </span>
                          Create consol FS
                        </Button>
                      ) : (
                        <Button
                          onClick={handleOpen}
                          // className="btn_consol"
                          variant="contained"
                          style={{
                            backgroundColor: "#03565A",
                            color: "white",
                            textTransform: "none",
                            width: "280px",
                            height: "50px",
                          }}
                        >
                          <span>
                            <AddIcon />
                          </span>
                          Create consol FS
                        </Button>
                      )}
                    </div>
                  </TableContainer>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <PreSetWorkspaceModal
        handleClose={handleClosePreset}
        open={preset}
        prepareConsoleId={setUploadPresetId}
        workspaceData={workspaceData}
        // selectedPresetProject={selectedPresetProject?.project_id}
        // setPresetHandler={setSelectedPresetProject}
        projectsData={projectsData}
        fieldModal={fieldModal}
        setOpen={setOpen}
        presetId={presetId}
      />
      <CreateFsModal
        open={open}
        handleClose={handleClose}
        handleChangeValue={handleChangeValue}
        allCompany={allCompany}
        selectedIr={selectedIr}
        uploadPreset={uploadPreset}
        uploadPresetID={uploadPresetID}
        handleSubmitFs={handleSubmitFs}
        workspaceData={workspaceData}
        ProjectName={ProjectName}
      />
      <SubsidiarySelectModal
        isopen={isopen}
        handleIsClose={handleIsClose}
        value={value}
        handleChange={handleChange}
        presetIdData={presetIdData}
        workspaceData={workspaceData}
        prepareConsoleId={prepareConsoleId}
        CompanyName={CompanyName}
      />
      <CompareFs
        open={openCompare}
        handleClose={handleIsClose}
        w_id={w_id}
        allpreconsoldata={allpreconsoldata}
      />
    </div>
  );
};

export default Consolidation;
