import React from 'react'
import Button from '@mui/material/Button';
// import leftarrow from "./../../../assets/Image/leftarrow.png"
import { useHistory,} from "react-router";

// import leftarrow from "../../../../assets/Image/leftarrow.png"
import leftarrow from "../../assets/Image/leftarrow.png"
import { useParams } from "react-router";

export const MatrixNav = () => {

 let history = useHistory();

  const {wid}=useParams();
    return (
        <>
            <div>
                <div className='custom-common-theme-bg-color' style={{ height: "70px" }}>

                    <div className='d-flex  px-3 py-2'>
                        <div className='d-flex align-items-center mt-1' onClick={() => history.push(`/bookclosure/${wid}`)} style={{cursor:"pointer"}}>
                            <img src={leftarrow} />
                            <p className='mt-0 mb-0 font-size-24 custom-font-600 text-white ml-1'>Back</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
