import Navbarv3 from "../../Workspace-v2/Navbarv3";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navbar } from "../../../Components/Navbar";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { GetAllCompany, getCompany } from "../ApiServices/apiHandler";
import { useEffect } from "react";
import { useState } from "react";
import "../../../WorkSpace/font.css";
import "../../../WorkSpace/Workspace2/sidebar.css";
import swal from "sweetalert";
import InvestAdjustModal from "../../../Console/Component/InvestAdjustmentModal";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useHistory, useLocation } from "react-router";
import BrowsFsModal from "../Modal/browsControlFsModal";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";

const InsideWorkspace = (props) => {
  const [openBrowse, setOpenBrowse] = useState(false);
  const handleBrowseopen = () => {
    setOpenBrowse(true);
  };
  const handleCloseBrowse = () => {
    setOpenBrowse(false);
  };
  const [allCompany, setAllCompany] = useState([]);
  const auth = localStorage.getItem("auth_token");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  let history = useHistory();
  const location = useLocation();
  const [company, setName] = useState({});
  let a = location.pathname;
  let b = a.split("/");
  const [allotherdata, setAllOtherData] = useState([]);
  const [data, setData] = useState("");
  const [totaldata, setDataTotal] = useState("");
  const [interest, setInterest] = useState("");
  const [natureinvest, setNatureInvest] = useState("");
  const [entryopen, setEntryOpen] = useState(false);
  const [optionentry, setOptionEntry] = useState("");
  const [browseOpen, setBrowseOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [eqityshare, setEquityShare] = useState([]);
  const [totaleqity, setTotalEquity] = useState(eqityshare * -1);
  const [investvalue, setInvestValue] = useState("");
  const [otherequityshare, setOtherEquityShare] = useState("");
  const [otherreservesurplus, setOtherReserveSurplus] = useState("");
  const [othereserve, setOtherReserve] = useState("");
  const [setfinalsum, setFinalSum] = useState("");
  const [datavalue, setDataValue] = useState("");
  const [finalconsoleentry, setFinalConsoleEntry] = useState({});
  const [getoneProject, setOneProject] = useState("");
  const [otherdetails, setOtherDetails] = useState([]);
  const [dateofinvest, setDateOfInvest] = useState("");
  const [newDetails, setNewDetails] = useState(false);
  const [otherallData, setDataother] = useState("");
  const [state, setstate] = useState([{ column: "", select: "" }]);
  const [detailsdata, setDetailsData] = useState("");
  const [companyCode, setCompanyCode] = useState("");

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    height:200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const style4 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [companydate, setCompanyDate] = useState({
    InvestRegister: "",
    investment_date: "",
    ParentCompany: "",
    company: "",
    investment_value: "",
    shares_bought: "",
    tnositc: "",
    holding: "",
    non_controlling_interest: "",
    browse_control_dateFS: "",
    donotdoc: "",
    wticacct: "",
    // add_other_details:"",
  });
  // for modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 1250,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  //  end modal style
  const close = {
    height: "43px",
    width: "43px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    borderRadius: "6px",
    fontWeight: "normal",
    marginRight: "15px",
  };
  useEffect(() => {
    let headers = {
      "x-auth-token": auth,
    };
    axios
      .get(`api/v1/company/get-onecomapny/${b[2]}`, {
        headers,
      })
      .then((response) => {
        setName(response.data.getOneCompany[0]);
      });
    fetchProject();
    GetCompany();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setEntryOpen(false);
    setBrowseOpen(false);
  };
  const handleCloseData = () => {
    setNewDetails(false);
  };
  const handleChange = (field, e) => {
    if (field === "company") {
      const resultCompany = allCompany.filter((det)=>det._id === e.target.value)
      setCompanyCode(resultCompany[0].company_code);
    }
    if (field === "shares_bought") {
      let data = e.target.value;
      setData(data);
      if (datavalue) {
        let holding = (Number(data) / datavalue) * 100;
        let noOfInterest = 100 - holding;
        setDataTotal(Number(holding));
        setInterest(noOfInterest);
        if (holding > 50) {
          setNatureInvest("Subsidiary Company");
        } else if (holding <= 50 && holding >= 20) {
          setNatureInvest("Associate Company");
        } else {
          setNatureInvest("Investment");
        }
      }
    }
    if (field === "tnositc") {
      let data2 = e.target.value;
      setDataValue(e.target.value);
      let holding = (data / datavalue) * 10; //if error dn 10 to change 100
      let noOfInterest = 100 - holding;
      setDataTotal(Number(holding));
      setInterest(noOfInterest);
      if (holding > 50) {
        setNatureInvest("Subsidiary Company");
      } else if (holding <= 50 && holding >= 20) {
        setNatureInvest("Associate Company");
      } else {
        setNatureInvest("Investment");
      }
    }
    if (field === "investment_value") {
      let investvalue = Number(e.target.value);
      setInvestValue(investvalue);
    }
    // //
    if (field === "investment_date") {
      setDateOfInvest(e.target.value);
    }
    setCompanyDate({ ...companydate, [field]: e.target.value });
  };
  function isEmpty(object) {
    return Object.keys(object).length === 0;
  }
  const handleSubmit = () => {
    if (selectedOption) {
      if (isEmpty(finalconsoleentry)) {
        swal("", "Please Submit Create Console Entry", "warning");
      } else {
        let headers = {
          "x-auth-token": auth,
        };
        const data = {
          cid: b[2],
          InvestRegister: companydate.InvestRegister,
          ParentCompany: companydate.ParentCompany,
          investment_date: companydate.investment_date,
          company: companydate.company,
          investment_value: companydate.investment_value,
          shares_bought: companydate.shares_bought,
          tnositc: companydate.tnositc,
          holding: totaldata,
          non_controlling_interest: interest,
          browse_control_dateFS: selectedOption,
          donotdoc: companydate.donotdoc,
          wticacct: companydate.wticacct,
          nature_of_relationship: companydate.nature_of_relation
            ? companydate.nature_of_relation
            : natureinvest,
          add_other_details: state,
          console_entry: finalconsoleentry,
          final_no_of_shares: companydate.shares_bought,
        };
        axios
          .post("api/v1/company-data/register-company-data", data, { headers })
          .then((res) => {
            history.push(`/dashboard`);
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    } else {
      let headers = {
        "x-auth-token": auth,
      };
      const data = {
        cid: b[2],
        InvestRegister: companydate.InvestRegister,
        ParentCompany: companydate.ParentCompany,
        investment_date: companydate.investment_date,
        company: companydate.company,
        investment_value: companydate.investment_value,
        shares_bought: companydate.shares_bought,
        tnositc: companydate.tnositc,
        holding: totaldata,
        non_controlling_interest: interest,
        browse_control_dateFS: selectedOption,
        donotdoc: companydate.donotdoc,
        wticacct: companydate.wticacct,
        nature_of_relationship: companydate.nature_of_relation
          ? companydate.nature_of_relation
          : natureinvest,
        add_other_details: state,
        console_entry: finalconsoleentry,
        final_no_of_shares: companydate.shares_bought,
      };
      axios
        .post("api/v1/company-data/register-company-data", data, { headers })
        .then((res) => {
          history.push(`/dashboard`);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
    return "0";
  };

  const entryOpen = () => {
    if (selectedOption) {
      setEntryOpen(true);
    } else {
      swal("", "Please Upload Browse control date FS", "error");
    }
  };

  function fetchProject() {
    let headers = {
      "x-auth-token": auth,
    };
    axios
      .get("/api/v1/project/get-project", { headers })
      .then((res) => {
        setOptionEntry(res.data.project);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  function browseFs() {
    setBrowseOpen(true);
  }
  const handleChangeFs = (e, data) => {
    setSelectedOption(e.target.value);
  };
  const handleSubmitFs = () => {
    let headers = {
      "x-auth-token": auth,
    };
    axios
      .get(`/api/v1/grouping/fs/${selectedOption}`, { headers })
      .then((res) => {
        setEquityShare(res.data.fs_grp[9].notes_grp);
        findProject(selectedOption);
        setBrowseOpen(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  function handleDelete(i) {
    const temp = state;
    const newarr = temp.filter((val, ix) => ix !== i);
    setstate(newarr);
    if (newarr.length == 0) {
    }
  }

  function handleNew() {
    setstate((oldArray) => [...oldArray, { column: "", select: "" }]);
  }

  function handleChangeDetails(e, field, i) {
    if (e.target.value === "Add_new") {
      setNewDetails(true);
    } else {
      const newArray = state.map((item, index) => {
        if (i === index) {
          return { ...item, [e.target.name]: e.target.value };
        } else {
          return item;
        }
      });

      setstate(newArray);
    }
  }

  const handleSave = () => {
    let sum = 0;
    setOpen(false);
    const newData = state.map((item, index) => {
      let someData = Number(item.select);
      sum += someData;
    });
    setFinalSum(sum);
  };

  function findProject(project_id) {
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    axios
      .get(`api/v1/project/get-project-details/${project_id}`, {
        headers,
      })
      .then((response) => {
        setOneProject(response.data);
      });
  }
  const newData = (e) => {
    setDataother(e.target.value);
  };

  const saveData = () => {
    var dataAll = [...allotherdata, otherallData];
    setAllOtherData(dataAll);
    handleCloseData();
  };

  const GetCompany = async () => {
    const res = await getCompany();
    if (res.status === 200) {
      setAllCompany(res.data.getCompany);
    }
  };

  const handleBack =()=>{
    history.push(`/dashboard`)
  }

  return (
    <>
      <div className="pb-2">
        <Navbar />

        <div className="container mt-4">
          <div className="d-flex">
            <p className="fs-inter">
              HOME / INVESTMENT / REGISTERS /&nbsp;
              <span style={{ fontWeight: "bold", color: "#000000" }}>
                CREATE REGISTER
              </span>
            </p>
          </div>
          <div>
            <h5 className="dbwcardhead">Create Investment Register</h5>
          </div>
          <div className="row mt-4">
            <div className="col-md-4">
              <label className="dm-sans iw-label">
                Name of investment register
                <input
                  type="text"
                  name="register"
                  placeholder="Enter investment register"
                  onChange={(e) => handleChange("InvestRegister", e)}
                />
              </label>
            </div>
            <div className="col-md-4">
              {/* <input type="text" name="register" placeholder="register007" /> */}
              <label className="dm-sans iw-label">Parent company</label>
              <select
                style={{
                  minWidth: "350px",
                  minHeight: "54px",
                  marginTop: "-1px",
                  borderRadius: "5px",
                }}
                onChange={(e) => handleChange("ParentCompany", e)}
              >
                <option>Select...</option>
                {allCompany.length > 0 &&
                  allCompany.map((det) => {
                    return (
                      <>
                        <option value={det._id}>{det.company_name}</option>
                      </>
                    );
                  })}
              </select>
            </div>
          </div>
          <hr style={{ width: "731px" }} />
          <div className="row">
            <div className="col-md-8">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <div className="d-flex justify-content-between">
                        &nbsp;&nbsp; Investment
                      <div>
                        <DriveFileRenameOutlineIcon
                          style={{ marginLeft: "523px" }}
                        />
                        <DeleteIcon
                          style={{ color: "red", marginLeft: "5px" }}
                        />
                      </div>
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="text-secondary">
                          Investee company name
                          <select
                            style={{
                              width: "336px",
                              marginTop: "12px",
                              borderRadius: "5px",
                              height: "59px",
                            }}
                            onChange={(e) => handleChange("company", e)}
                          >
                            <option>Select...</option>
                            {allCompany.length > 0 &&
                              allCompany.map((det) => {
                                return (
                                  <>
                                    <option value={det._id}>
                                      {det.company_name}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </label>
                      </div>
                      <div className="col-md-6 text-secondary">
                        <label>
                          Company code
                          <input type="text" placeholder="" value={companyCode}/>
                        </label>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label className="text-secondary">
                          Date of investment
                          <input
                            onChange={(e) => handleChange("investment_date", e)}
                            type="date"
                            className="form-control"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label className="text-secondary">
                          Value of investment
                          <input
                            type="text"
                            name=""
                            placeholder=""
                            style={{ width: "698px" }}
                            onChange={(e) =>
                              handleChange("investment_value", e)
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label className="text-secondary">
                          No of shares brought
                          <input
                            type="text"
                            onChange={(e) => handleChange("shares_bought", e)}
                          />
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="text-secondary">
                          Total no shares in the company
                          <input
                            type="text"
                            onChange={(e) => handleChange("tnositc", e)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label className="text-secondary">
                          Percentage holding
                          <input
                            type="text"
                            placeholder="%"
                            // onChange={(e) => handleChange("holding", e)}
                            value={totaldata.toLocaleString("en-IN")}
                            disabled
                          />
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="text-secondary">
                          Non-controling interest
                          <input
                            type="text"
                            placeholder="Add non-controlling interest"
                            onChange={(e) =>
                              handleChange("non_controlling_interest", e)
                            }
                            value={interest.toLocaleString("en-IN")}
                            disabled
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label className="text-secondary">
                          Nature of relationship
                        </label>

                        <select
                          style={{
                            width: "100%",
                            height: "59px",
                            borderRadius: "5px",
                          }}
                        >
                          <option>Select</option>
                          <option
                            value="Investment"
                            selected={natureinvest === "Investment"}
                          >
                            Investment
                          </option>
                          <option
                            value="Associate Company"
                            selected={natureinvest === "Associate Company"}
                          >
                            Associate Company
                          </option>
                          <option
                            value="Subsidiary Company"
                            selected={natureinvest === "Subsidiary Company"}
                          >
                            Subsidiary Company
                          </option>
                          <option
                            value="Joint Venture"
                            selected={natureinvest === "Joint Venture"}
                          >
                            Joint Venture
                          </option>
                        </select>
                      </div>
                    </div>
                    {/* modal  */}
                    <Modal
                      open={openBrowse}
                      onClose={handleCloseBrowse}
                      aria-labelledby="child-modal-title"
                      aria-describedby="child-modal-description"
                    >
                      <Box sx={{ ...style, width: 500 }}>
                        <h2 id="child-modal-title">Text in a child modal</h2>
                        <p id="child-modal-description">
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit.
                        </p>
                        <Button onClick={handleCloseBrowse}>Close</Button>
                      </Box>
                    </Modal>
                    {/* end modal */}
                    {totaldata > 50 && (
                      <div className="row mt-3">
                        <div className="col-md-6" onClick={() => entryOpen()}>
                          <input
                            type="button"
                            className="btn btn-primary1 w-100"
                            value="Create consol entry"
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="button"
                            className="btn btn-primary1 w-100"
                            value="Browse control date FS"
                            onClick={() => browseFs()}
                          />
                          <small>
                            {getoneProject.project
                              ? getoneProject.project.project_name
                              : ""}
                          </small>
                        </div>
                      </div>
                    )}
                    {totaldata > 50 && (
                      <div className="row  mt-3">
                        <div className="col-md-12 ">
                          <label className="text-secondary">
                            Details of networth on the date of control
                          </label>
                          <input
                            onChange={(e) => handleChange("donotdoc", e)}
                            type="text"
                          />
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="text-secondary">
                            Add other details &nbsp; &nbsp;
                          </label>
                          <AddIcon
                            onClick={handleOpen}
                            data-bs-toggle="modal"
                            data-bs-target="#Intangible"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    )}
                    {totaldata > 50 && (
                      <div className="row mt-2">
                        <div className="col-md-12 ">
                          <label className="text-secondary">
                            Whether transaction is covered as common control
                            transaction
                          </label>
                          <select
                            onChange={(e) => handleChange("wticacct", e)}
                            style={{
                              width: "691px",
                              marginTop: "12px",
                              borderRadius: "5px",
                              height: "59px",
                            }}
                          >
                            <option>Select....</option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>

                        <div className="col-md-12 mt-3">
                          <div class="table-responsive table-bordered">
                            <table class="table" style={{ marginBottom: "0" }}>
                              <tbody>
                                {state.length > 0 &&
                                  state.map((item, v) => (
                                    <tr>
                                      <td>{item.column}</td>
                                      <td>{item.select}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <hr style={{ width: "731px" }} />
          <div className="mt-4 row">
            <div className="col-md-8">
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <label
                      className="text-secondary"
                      style={{ fontSize: "16px" }}
                    >
                      Add remarks
                    </label>
                    <input type="text" style={{ height: "110px" }} />
                  </Typography>
                </CardContent>
                <div
                  style={{ color: "black", marginLeft: "18px" }}
                  className="text-secondary"
                >
                  Upload attachments
                </div>
                <CardActions>
                  {/* <button
                    style={{
                      backgroundColor: "white",
                      color: "#03565A",
                      textTransform: "none",
                      marginLeft: "12px",
                      width: "220px",
                      height: "50px",
                      border: "2px solid #03565A",
                    }}
                  >
                    Upload Attachment
                  </button> */}
                  <Button
                    style={{
                      backgroundColor: "white",
                      // color: "black",
                      textTransform: "none",
                      marginLeft: "12px",
                      width: "220px",
                      height: "50px",
                      border: "2px solid #03565A",
                      borderRadius: "5px",
                      color: "#03565A",
                      fontWeight: "bold",
                    }}
                  >
                    Upload attachment
                  </Button>
                </CardActions>
              </Card>
            </div>
          </div>
          <div
            className="backicon"
            style={{ cursor: "pointer", marginLeft: "0px" }}
            onClick={handleBack}
          >
            <ArrowBackIcon />
            &nbsp;
            <div>Go Back</div>
          </div>
          <div
            className="nextbtn-v2"
            style={{ marginLeft: "-325px", marginTop: "-42px" }}
          >
            {/* <button
              style={{
                backgroundColor: "white",
                color: "#03565A",
                textTransform: "none",
                marginLeft: "12px",
                width: "220px",
                height: "50px",
                border: "2px solid #03565A",
              }}
            >
              Create Investment
            </button> */}
            {/* <Button
              style={{
                backgroundColor: "white",
                // color: "black",
                textTransform: "none",

                marginRight: "12px",
                width: "220px",
                height: "50px",
                border: "2px solid #03565A",
                borderRadius: "5px",
                color: "#03565A",
                fontWeight: "bold",
              }}
            >
              <AddIcon />
              Create Investment
            </Button>
            <Button
              style={{
                backgroundColor: "#03565A",
                color: "white",
                marginLeft: "12px",
              }}
            >
              <AddIcon />
              Save details
            </Button> */}
            <Button
              style={{
                backgroundColor: "white",
                // color: "black",
                textTransform: "none",

                marginRight: "12px",
                width: "220px",
                height: "50px",
                border: "2px solid #03565A",
                borderRadius: "5px",
                color: "#03565A",
                fontWeight: "bold",
              }}
              onClick={handleSubmit}
            >
              Save details
            </Button>
          </div>
        </div>
      </div>

      <InvestAdjustModal
        handleClose={handleClose}
        entryopen={entryopen}
        eqityshare={eqityshare}
        otherequityshare={otherequityshare}
        investvalue={investvalue}
        totaleqity={totaleqity}
        interest={interest}
        setfinalsum={setfinalsum}
        setFinalConsoleEntry={setFinalConsoleEntry}
        otherreservesurplus={otherreservesurplus}
        othereserve={othereserve}
        setEntryOpen={setEntryOpen}
        dateofinvest={dateofinvest}
      />
      <Modal
        open={browseOpen}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style2}>
          <div className="col-12">
            {/* <button type="button" onClick={handleClose} class="btn-close float-end"></button> */}
            <div className="col-md-12 form-group">
              <label>Browse control date FS</label>
              <select
                className="form-control mt-2"
                onChange={(e) => handleChangeFs(e)}
                value={selectedOption}
              >
                <option>select</option>
                {optionentry.length > 0 &&
                  optionentry.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.project_name}
                    </option>
                  ))}
                ;
              </select>
            </div>
            <input
              type="button"
              className="btn btn-primary1 float-end"
              value="Submit"
              onClick={handleSubmitFs}
            />
          </div>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <div className="col-12">
              <button
                type="button"
                onClick={handleClose}
                class="btn-close float-end"
              ></button>
              <div className="mb-3">
                <div className="row">
                  {state.map((val, i) => {
                    return (
                      <>
                        <div className="col-md-6 mt-3">
                          <select
                            className="form-control"
                            name="column"
                            onChange={(e) =>
                              handleChangeDetails(e, "select", i)
                            }
                            style={{ border: "1px" }}
                          >
                            <option>Select</option>
                            <option value="Brand_trademarks">
                              Brands/trademarks
                            </option>
                            {allotherdata.map((data, i) => (
                              <option key={i} value={data}>
                                {data}
                              </option>
                            ))}
                            <option value="Add_new">Add New</option>
                          </select>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            name="select"
                            className="form-control"
                            onChange={(e) => handleChangeDetails(e, "input", i)}
                          />
                        </div>
                        <div
                          style={close}
                          onClick={() => {
                            handleDelete(i);
                          }}
                        >
                          <button
                            type="button"
                            class="btn-close float-end"
                            style={{ color: "red" }}
                          >
                            X
                          </button>
                        </div>
                      </>
                    );
                  })}
                  <div className="col-md-2 mt-2">
                    <AddIcon
                      onClick={handleNew}
                      data-bs-toggle="modal"
                      data-bs-target="#Intangible"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
              <input
                type="button"
                className="btn btn-primary1"
                value="Submit"
                onClick={() => {
                  handleSave();
                }}
              />
              {/* <input type="button" className="btn btn-defult" value="Close" onClick={handleClose} /> */}
            </div>
          </Box>
        </>
      </Modal>
      <Modal
        open={newDetails}
        onClose={handleCloseData}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style4}>
          <div className="col-12">
            <h5>Add New Data</h5>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={(e) => newData(e)}
                />
              </div>
              <input
                type="button"
                className="btn btn-primary1"
                value="Submit"
                onClick={saveData}
              />
              <input
                type="button"
                className="btn btn-defult"
                value="Close"
                onClick={handleCloseData}
              />
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default InsideWorkspace;
