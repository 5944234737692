import React from 'react'
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Table } from 'react-bootstrap';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';

const IssuedCapitaltable = () => {
    const [shares, setShares] = React.useState('');

    const handleChangeShares = (event) => {
        setShares(event.target.value);
    };
    const [classification, setClassification] = React.useState('');

    const handleChangeClassification = (event) => {
        setClassification(event.target.value);
    };
    return (
        <>
            <div>
                <div className='mt-2 w-100'>
                    <Card className='w-100 radius-8px' style={{ border: "2px solid #D6D6D6" }}>
                        <div style={{ overflowX: 'auto' }}>
                            <Table style={{ minWidth: '1600px' }}>
                                <thead style={{ backgroundColor: "#F4F6F6" }}>
                                    <tr>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter' style={{ width: '11%' }}>Date</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter' style={{ width: '11%' }}>Class of shares</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter' style={{ width: '11%' }}>Classification</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter' style={{ width: '11%' }}>Number of shares</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter' style={{ width: '12%' }}>Face value per share</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter' style={{ width: '11%' }}>Authorized Capital</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter' style={{ width: '11%' }}>Remarks</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter' style={{ width: '11%' }}>Attached file</th>
                                        <th className='text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter' style={{ width: '11%' }}>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ backgroundColor: "white" }}>
                                        <td className='text-left font-size-12 custom-font-600'>
                                            <div className='mt-2'>
                                                <input
                                                    style={{ height: "48px" }}
                                                    type="date"
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-left font-size-12 custom-font-600'>
                                            <FormControl className="w-100 noBorder mt-1">
                                                <Select
                                                    value={shares}
                                                    onChange={handleChangeShares}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                            textAlign: "start",
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        <em className="text-secondary text-start">Select shares</em>
                                                    </MenuItem>
                                                    <MenuItem value={10} >CCPS</MenuItem>
                                                    <MenuItem value={20} >Class A</MenuItem>
                                                    <MenuItem value={30} >Class B</MenuItem>
                                                    <MenuItem value={40} >Class C</MenuItem>
                                                    <MenuItem value={50} >Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </td>
                                        <td className='text-left font-size-12 custom-font-600'>
                                            <FormControl className='w-100 mt-1'>
                                                <Select
                                                    value={classification}
                                                    onChange={handleChangeClassification}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    sx={{
                                                        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        <em className='text-secondary'>Select Classification</em>
                                                    </MenuItem>
                                                    <MenuItem value={10}>Equity</MenuItem>
                                                    <MenuItem value={20}>Liability</MenuItem>
                                                    <MenuItem value={30}>Compound Financial<br/> Instrument</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </td>
                                        <td className='text-left font-size-12 custom-font-600'>
                                            <input type='text' placeholder='Type..' style={{ border: "none" }} />
                                        </td>
                                        <td className='text-left font-size-12 custom-font-600'>
                                            <input type='text' placeholder='Type...' style={{ border: "none" }} />
                                        </td>
                                        <td className='text-left font-size-12 custom-font-600'>
                                            <input type='text' placeholder='Type...' style={{ border: "none" }} />
                                        </td>
                                        <td className='text-left font-size-12 custom-font-600'>
                                            <input type='text' placeholder='Type' style={{ border: "none" }} />
                                        </td>
                                        <td className='text-center font-size-12 custom-font-600'>
                                            <div className='d-flex justify-content-center align-items-center mt-3' style={{ gap: "2px" }}>
                                                <UploadFileIcon className='custom-common-theme-text-color' style={{ width: "15px", height: "15px" }} />
                                                <p className='mt-0 mb-0 font-size-12 custom-font-600 custom-common-theme-text-color'>Upload file</p>
                                            </div>
                                        </td>
                                        <td className='text-center font-size-12 custom-font-600'>
                                            <div className='d-flex justify-content-center align-items-center mt-3' style={{ gap: "2px" }}>
                                                <UploadFileIcon style={{ width: "15px", height: "15px" }} className='text-danger' />
                                                <p className='mt-0 mb-0 font-size-12 custom-font-600 text-danger'>Delete</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style={{ backgroundColor: "white" }}>
                                        <td className='text-left font-size-12 custom-font-600' colSpan={9}>
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>
                                                <AddIcon style={{ width: "15px", height: "15px" }} />
                                                <p className='mt-0 mb-0 font-size-12 custom-font-500 text-secondary'>Add New Row</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </div>
            </div>


        </>
    )
}

export default IssuedCapitaltable