import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import swal from "sweetalert";
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import dayjs from "dayjs";
import { toast } from 'react-toastify';

import "./../../WorkSpace/SignUp2.css";
import "./../../Bookclosure/Styles/Bookclosure.css";
import { Navbar } from '../../Components/Navbar';
import Sidbarv2 from '../../WorkSpace/Workspace-v2/Sidbarv2';
import { 
    addTask, 
    approveTask, 
    getApproverUserOptions, 
    getOneBookClosure,
    getOneTask,
    returnTask,
    saveAndSubmitTask,
    submitTask,
    updateTask 
} from '../Services/ApiHandler';
import { getProfile } from '../../services/api/apiHandler';
import BookClosureProgressCard from '../Components/BookClosureProgressCard';
import TaskSVGComponent from '../Components/SvgComponents/BookClosureTask';
import TaskCompletedSvgComponent from '../Components/SvgComponents/TaskCompletedSvgComponent';
import TaskProgressSvgComponent from '../Components/SvgComponents/TaskProgressSvgComponent';
import CheckFieldDialog from '../Components/CheckFieldDialog';
import AddTaskModal from '../Components/AddTaskModal';
import LeaseModal from '../Components/LeaseModal';
import BookClosureTaskList from '../Components/BookClosureTaskList';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, backgroundColor: 'white' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Bookclosure = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { workspaceId } = useParams();
    const a = location.pathname;
    const w_id = a.split("/");

    const { 
        bookClosureId, 
        bookClosureData,
        bookClosureCompanies,
        bookClosureModules
    } = useSelector((state) => state.BookClosureReducer);
    const { 
        userData
    } = useSelector((state) => state.AuthReducer);

    const [value, setValue] = useState(0);
    const [isFieldDialog, setIsFieldDialog] = useState(false);
    const [isAddTaskModal, setIsAddTaskModal] = useState(false);
    const [isTaskAction, setIsTaskAction] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [addTaskForm, setAddTaskForm] = useState({
        taskId: "",
        companyID: "",
        module:"",
        name : "",
        description: "",
        dueDate: "",
        assigneeID: "",
        approverID: "",
        workingsAndRemarks: "",
        selectedAssignId:[],
        selectedApproverId:{},
        status:""
    });
    const [isLeaseTaskModal, setIsLeaseTaskModal] = useState(false);

    useEffect(() => {
        GetUserData();
        getBookClosureData();
        getTaskOptions();
    }, []);

    const GetUserData = () =>{
        if(Object.keys(userData).length ===  0){
            getProfile().then(response => {
                dispatch({ type: "USER_MIDDLEWARE_DATA", payload: response.data?.user?.data ?? {}});
            }).catch(error => {
                swal("Error", error || `User Data Not Found`, 'error');
            })
        }
    }

    const getBookClosureData = (company_id) => {
        const data = company_id ? {
            companies: [company_id],
            // assignees: [],
            // approvers: [userId]
        } : {}
        getOneBookClosure(workspaceId, data).then(response => {
            if(!company_id){
                dispatch({ type: "BOOK_CLOSURE_COMPANIES", payload: response.data.bookClosure?.companyDetails || [] });
                const findCurrentCompany = response.data.bookClosure?.companyDetails[0]
                setCompanyId(findCurrentCompany._id);
                getBookClosureData(findCurrentCompany._id);
            }else{
                dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
            }
        }).catch(error => {
            swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
        })
    }

    const getTaskOptions = () => {
        getApproverUserOptions(workspaceId).then(res => {
            dispatch({ type: "TASK_OPTIONS", payload: res.data || {} });
        }).catch(err => {
            swal("Error", `Error: ${err.response?.data?.error}`, 'error');
        });
    }
    
    const AddTask = () => {
        const findCurrentCompany = bookClosureCompanies.find((ele,i) => i === value);
        const data = {
            module: addTaskForm.module ?? "",
            bookClosureID: bookClosureId,
            companyID: findCurrentCompany._id ?? "",
            name : addTaskForm.name,
            description: addTaskForm.description,
            dueDate: dayjs(addTaskForm.dueDate).format("DD-MM-YYYY"),
            assigneeIDs: addTaskForm.assigneeID,
            approverID: addTaskForm.approverID,
            workingsAndRemarks: addTaskForm.workingsAndRemarks
        }

        if(userData?.responsibilities === "preparer"){
            saveAndSubmitTask(data).then(response => {
                getBookClosureData(companyId);
                handleTaskModalClose();
                toast.success("Task Save and Submitted Successfully.");
            }).catch(error => swal("Error", `Error: ${error.response?.data?.error || "Task Return Failed!"}`, 'error'))
        }else{
            addTask(data).then(response =>{
                toast.success("Task added successfully.");
                getBookClosureData(companyId);
                handleTaskModalClose();
            }).catch(error => {
                swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
            })
        }
    }

    const handleTaskModalClose = () =>  {
        setAddTaskForm(prev => ({...prev, 
            taskId: "",
            module:"",
            companyID: "",
            name : "",
            description: "",
            dueDate: "",
            assigneeID: "",
            approverID: "",
            workingsAndRemarks: "",
            selectedAssignId:[],
            selectedApproverId:{},
        }));
        setIsLeaseTaskModal(false); // Lease view more modal close
        setIsFieldDialog(false); // close select dialog box 
        setIsAddTaskModal(false); // General task view more modal close
        setIsTaskAction(false); // handle save and update button for general task modal
    }

    const ViewTaskMore = (taskId) => {
        setAddTaskForm(prev => ({...prev, taskId }));
        getOneTask(taskId).then((response)=>{
            const taskData = response.data.bookClosureTask;
            if(taskData?.template){
                setIsLeaseTaskModal(true);
            }else{
                setIsTaskAction(true);
                setIsAddTaskModal(true);
            }
            setAddTaskForm(prev => ({...prev, 
                name : taskData.name,
                description: taskData.description,
                dueDate: taskData?.dueDate ?? "",
                workingsAndRemarks:  taskData.workingsAndRemarks ?? "",
                selectedAssignId: taskData?.assigneeData ? taskData.assigneeData.map(ele => ({ value: ele._id, label: ele.name })) : [],
                selectedApproverId: taskData?.approverData ? { value: taskData.approverData._id, label: taskData.approverData.name } : {},
                assigneeID: taskData?.assigneeIDs ?? [],
                approverID: taskData?.approverID ?? {},
                status: taskData?.status
            }));
        }).catch((error)=>{
            swal("Error", `Error: ${error.response?.data?.error}`, 'error');
        })
    }

    const UpdateTask = () => {
        const data = {
            name : addTaskForm.name,
            description: addTaskForm.description,
            dueDate: dayjs(addTaskForm.dueDate).format("DD-MM-YYYY"),
            assigneeIDs: addTaskForm.assigneeID,
            approverID: addTaskForm.approverID,
            workingsAndRemarks: addTaskForm.workingsAndRemarks
        }
        if(userData?.responsibilities === "approver" && addTaskForm.status === "submitted"){
            approveTask(addTaskForm.taskId, {
                workingsAndRemarks: addTaskForm.workingsAndRemarks
            }).then(response => {
                getBookClosureData(companyId);
                handleTaskModalClose();
                toast.success("Task Approved Successfully.");
            }).catch(error => swal("Error", `Error: ${error.response?.data?.error || "Task Approve Failed!"}`, 'error'));

        }else if(userData?.responsibilities === "preparer" && ['open', 'returned'].includes(addTaskForm.status)){
            submitTask(addTaskForm.taskId, {
                workingsAndRemarks: addTaskForm.workingsAndRemarks
            }).then(response => {
                getBookClosureData(companyId);
                handleTaskModalClose();
                toast.success("Task Submitted Successfully.");
            }).catch(error => swal("Error", `Error: ${error.response?.data?.error || "Task Submit Failed!"}`, 'error'));
        }else{
            updateTask(addTaskForm.taskId, data).then(response => {
                getBookClosureData(companyId);
                handleTaskModalClose();
                toast.success("Task Saved Successfully.");
            }).catch(error => swal("Error", `Error: ${error.response?.data?.error}`, 'error') );
        }
    }

    const ReturnTask = () => {
        if(userData?.responsibilities === "approver" && addTaskForm.status === "submitted"){
            returnTask(addTaskForm.taskId, {
                workingsAndRemarks: addTaskForm.workingsAndRemarks
            }).then(response => {
                getBookClosureData(companyId);
                handleTaskModalClose();
                toast.success("Task Returned Successfully.");
            }).catch(error => swal("Error", `Error: ${error.response?.data?.error || "Task Return Failed!"}`, 'error'))
        }
    }

    return (
        <>
            <div>
                <Navbar />
                <div className="d-flex">
                    <Sidbarv2 w_id={w_id} />
                    <div className="px-4 w-100 mt-3">
                        <p className="mt-0 mb-3 font-size-18 ml-2 font-weight-bold ">Book closure</p>
                        <div className='radius-10px bg-secondary-100'>
                            <Tabs
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                    const findCurrentCompany = bookClosureCompanies.find((ele,i) => i === newValue);
                                    setCompanyId(findCurrentCompany._id);
                                    getBookClosureData(findCurrentCompany._id);
                                }}
                                aria-label="tabs example"
                                sx={{ backgroundColor: 'transparent' }}
                            >
                                {
                                    bookClosureCompanies && bookClosureCompanies.length > 0 &&
                                    bookClosureCompanies.map((company, index) => (
                                        <Tab
                                            key={company._id}
                                            label={company?.company_name}
                                            className={`text-capitalize font-size-12 font-weight-bold ${value === index ? 'bg-white text-dark' : 'bg-secondary-100 text-secondary'} `}
                                            sx={{
                                                borderTopLeftRadius: index === 0 ? '10px' : 0,
                                                opacity: 1,
                                            }}
                                        />
                                    ))
                                }
                            </Tabs>

                            <Card className='w-100'>
                                {
                                    bookClosureCompanies && bookClosureCompanies.length > 0 &&
                                    bookClosureCompanies.map((company, ix) => (
                                        <CustomTabPanel value={value} key={ix} index={ix}>
                                            <div className='px-3'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <p className='font-size-18 font-weight-bold font-sans'> {company?.company_name} </p>
                                                    <div className="d-inline-flex">
                                                        <Button 
                                                            variant="contained" 
                                                            className='font-size-16 custom-font-500 text-capitalize custom-common-theme-bg-color font-sans radius-6px import-btn text-white'
                                                            onClick={() => history.push(`/bookclosure-library/${workspaceId}/${companyId}`)}
                                                        >
                                                            Import from library
                                                        </Button>
                                                        <Button className='ml-3 font-size-16 custom-font-500 text-capitalize primary-border custom-common-theme-text-color font-sans radius-6px import-btn' variant="outlined" onClick={() => history.push(`/import-preset/${w_id[2]}`)}>Import Preset</Button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <BookClosureProgressCard
                                                        icon={<TaskSVGComponent color={"#573E20"} height={24} width={24} />}
                                                        name={"Open task"}
                                                        noOfTask={bookClosureData?.openTasks}
                                                    />
                                                    <BookClosureProgressCard
                                                        icon={<TaskProgressSvgComponent color={"#573E20"} height={24} width={24} />}
                                                        name={"Task in Progress"}
                                                        noOfTask={bookClosureData?.tasksPendingApproval}
                                                    />
                                                    <BookClosureProgressCard
                                                        icon={<TaskCompletedSvgComponent color={"#573E20"} height={24} width={24} />}
                                                        name={"Completed"}
                                                        noOfTask={`${bookClosureData?.completedTasksPercentage}%`}
                                                    />
                                                </div>
                                                <div className="mt-3 d-flex align-items-center justify-content-between">
                                                    <p className='font-size-16 font-weight-bold font-sans'>Book Closure Checklist</p>
                                                    <BookClosureButton
                                                        className={"px-4"}
                                                        onClick={() => history.push(`/upload-tb/${w_id[2]}`)}
                                                    >
                                                        Upload TB
                                                    </BookClosureButton>
                                                </div>
                                                <Card className='w-100 mt-3 pb-3' style={{ backgroundColor: "#FDFEFE", border: "1px solid #EAEAEA", borderRadius: "20px" }}>
                                                    <div className="d-flex justify-content-end mt-3 mr-3 main-button">
                                                        <BookClosureButton
                                                            className={"px-4"}
                                                            onClick={() => history.push(`/bookclosure-library/${w_id[2]}`)}
                                                        >
                                                            View FSCP Posting
                                                        </BookClosureButton>
                                                        <BookClosureButton
                                                            className={"px-4 ml-3"}
                                                            onClick={() => history.push(`/bookclosure-table/${w_id[2]}`)}
                                                        >
                                                            View my task / All task
                                                        </BookClosureButton>
                                                        <BookClosureButton
                                                            className={"px-4 ml-3"}
                                                            onClick={() => history.push(`/matrix/${w_id[2]}`)}
                                                        >
                                                            Add Column
                                                        </BookClosureButton>
                                                    </div>
                                                    <div className='mt-3'>
                                                        {
                                                            bookClosureModules && bookClosureModules.length>0 ?
                                                            bookClosureModules.map((module,index, bookClosureArray)=>(
                                                                <BookClosureTaskList 
                                                                    key={index} 
                                                                    module={module} 
                                                                    ViewTaskMore={ViewTaskMore}
                                                                    setAddTaskForm={setAddTaskForm}
                                                                    setIsFieldDialog={setIsFieldDialog}
                                                                    bookClosureArray={bookClosureArray}
                                                                />
                                                            )) : 
                                                            <BookClosureTaskList 
                                                                module={{
                                                                    module:"lease",
                                                                    tasks: []
                                                                }}  
                                                                ViewTaskMore={ViewTaskMore}
                                                                setAddTaskForm={setAddTaskForm}
                                                                setIsFieldDialog={setIsFieldDialog}
                                                                bookClosureArray={bookClosureModules}
                                                            />
                                                        }
                                                    </div>
                                                </Card>
                                            </div>
                                        </CustomTabPanel>
                                    ))
                                }
                            </Card>
                        </div>
                    </div>

                </div>
            </div>

            <CheckFieldDialog
                isFieldDialog={isFieldDialog}
                setIsFieldDialog={setIsFieldDialog}
                setIsAddTaskModal={setIsAddTaskModal}
                onClick={
                    () => history.push(`/bookclosure-library/${workspaceId}/${companyId}`)
                }
            />
            <AddTaskModal
                isAddTaskModal={isAddTaskModal}
                setAddTaskForm={setAddTaskForm}
                addTaskForm={addTaskForm}
                AddTask={() => {
                    isTaskAction ? UpdateTask() : AddTask()
                }}
                handleTaskModalClose={handleTaskModalClose}
                ReturnTask={ReturnTask}
                isTaskAction={isTaskAction}
                userData={userData}
            />
            <LeaseModal
                isLeaseTaskModal={isLeaseTaskModal}
                handleTaskModalClose={handleTaskModalClose}
                UpdateTask={() => {
                    UpdateTask();
                }}
                SaveTask={() => {
                    if(userData?.responsibilities === "approver"){
                        ReturnTask();
                    }else{  }
                }}
                addTaskForm={addTaskForm}
                setAddTaskForm={setAddTaskForm}
                userData={userData}
            />
        </>
    );
};

export default Bookclosure;

export const BookClosureButton = ({ className, onClick, children, ...props }) => {
    return (
        <button
            className={`font-size-16 custom-font-500 radius-8px shadow border-0 custom-common-theme-text-color font-sans radius-6px custom-bg-white-150 py-2 ${className}`}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
};
