import React, { useEffect, useState } from 'react'
import { Typography, Box, Table, TableBody, MoreVertIcon, TableCell, TableRow, TableHead, TableContainer, Popover } from '@mui/material'
import { Navbar } from '../../../Components/Navbar'
import { GetLogs } from '../ApiServices/apiHandler'
import moment from 'moment'
import { createlogs } from '../../../helper/logs'
import { Button } from 'react-bootstrap'
import axios from 'axios'

const getToken = ()=>{
  return localStorage.getItem("auth_token");
}
const headers = {
  "x-auth-token": getToken(),
};


const ViewLog = () => {
  const [logData, setLogData] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [hasMore, setHasMore] = useState(true);


 
    // createlogs('65d5ef5d516fd4342485d9d3','wallaaastik','65d5ef5d516fd4342485d9d3','happening','waytorunClick')   
  


  useEffect(() => {
    fetchLogs();
  }, [page])  

  const fetchLogs = async () => {
    try {
      const response = await axios.get(`/api/v1/rolePermission/logs?page=${page}&limit=${limit}`,{headers});
      if (response.status === 200) {
        // Append the newly fetched log data to the existing logData state
        const newlogdata = response.data.permissionLog
        console.log(response,'responseess',newlogdata)
        setLogData(prevLogData => [...prevLogData, ...newlogdata]);

        if(newlogdata.length < limit){
         setHasMore(false)
        }
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
    }

           

  };


  const fetchMoreData = () => {
    // Increment the batch index to fetch the next batch of data
    setPage(prevPage => prevPage + 1);
  };



  return (
    <>
      <Navbar />
      <div className=" h-100 w-100 px-5 mt-4">
        <Box>
          <TableContainer>
            <Table sx={{}} aria-label="caption table">
              <>

                <TableHead style={{position:'sticky',top:-1,zIndex:1, backgroundColor:'#f5f5f5'}}>
                  <TableRow>
                    <TableCell align="left" className="font-sans">
                      Modified Date
                    </TableCell>
                    <TableCell align="center" className="font-sans">
                      Username
                    </TableCell>
                    <TableCell align="center" className="font-sans">
                      Field
                    </TableCell>
                    <TableCell align="center" className="font-sans">
                      Changes
                    </TableCell>
                    <TableCell align="center" className="font-sans">
                      Remark
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {logData?.map((data,index) => {
                      return (
                         <TableRow key={index}>
                      <TableCell className="font-sans"
                        style={{ backgroundColor: "white" }}
                        align="left"
                      >
                           {moment(data.updatedAt).format('DD-MM-YYYY')}<br></br>
                           <sub>{moment(data.updatedAt).fromNow()}</sub>
                           
                      </TableCell>
                      <TableCell className="font-sans"
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        {data?.user_id?.name}
                      </TableCell>

                      <TableCell className="font-sans"
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                       {data.field}
                      </TableCell>

                      <TableCell className="font-sans"
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                      {data.change}
                      </TableCell>
                      <TableCell className="font-sans"
                        style={{ backgroundColor: "white" }}
                        align="center"
                      >
                        {data.remark}
                      </TableCell>

                    </TableRow>
                      )
                    })}
                   
                  </>
                </TableBody>
              </>

            </Table>
          </TableContainer>
          {hasMore?<button style={{border:'none',color:'#03565A',float:'right',marginRight:'3px'}} onClick={fetchMoreData}>Load more...</button>:''}
          

        </Box>
      </div>

    </>
  )
}

export default ViewLog