import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import nodata from "../../../assets/nodata.jpg";
import { getTicket } from '../ApiServices/apiHandler';
import { getProfile } from '../../../services/api/apiHandler'
import { useHistory } from 'react-router';
import moment from 'moment';
import { Navbar } from '../../../Components/Navbar';
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";

const SupportPage = () => {

    const history = useHistory();
    const [ticket, setTicket] = useState([]);
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulating an asynchronous operation
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer); // Cleanup function to clear the timeout
    }, []);

    useEffect(() => {
        const fetch = async () => {
            await getProfileId();
            await Ticketdata();
        }

        fetch()
    }, [userId]);

    const getProfileId = async (id) => {
        try {
            const response = await getProfile();
            console.log(response.data.user.data._id)
            if (response.status === 200) {
                setUserId(response.data.user.data._id);
            } // Return the data from the response
        } catch (error) {
            console.error('Error fetching profile:', error);
            throw error; // Rethrow the error to be handled by the caller
        }
    }

    const Ticketdata = async () => {
        try {
            const user_id = userId
            const res = await getTicket(user_id)
            console.log("Response--------------------:", res);
            if (res.status === 200) {
                setTicket(res.data.data);
            } else {
                console.error("Invalid response:", res);
            }
        } catch (error) {
            console.error("Error fetching ticket data:", error);
        }
    };

    return (

        <>
            <div>
                <Navbar />
            </div>
            <div className='px-2'>
                {loading ? (<div className='d-flex justify-content-center align-items-center vh-100'>
                    <CircularProgress />
                </div>)
                    : ticket.length === 0 ?
                        (

                            <div className="d-flex flex-column justify-content-center align-items-center vh-70">
                                <img src={nodata} className="img-fluid" style={{ maxWidth: "300px" }} alt="No Data" />
                                <p className='text-secondary fs-4'>You have no ticket data</p>
                                <Button
                                    style={{
                                        backgroundColor: "#03565A",
                                        color: "white",
                                        padding: "2px 90px",
                                        fontSize: "1rem"
                                    }}
                                    onClick={() => history.push(`/support-newticket`)}
                                >
                                    ADD NEW TICKET
                                </Button>
                            </div>

                        ) : (

                            <div className='p-5 mt-2' >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant='h5'>Tickets</Typography>
                                    <Button variant="contained" style={{ backgroundColor: "#03565A", }} onClick={() => history.push(`/support-newticket`)}>ADD NEW TICKET</Button>

                                </div>

                                <TableContainer className='mt-4' component={Paper}>
                                    <Table>
                                        <TableHead sx={{ backgroundColor: '#DDDDDD79' }}>
                                            <TableRow>
                                                <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Subject</TableCell>
                                                <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Priority</TableCell>
                                                <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Status</TableCell>
                                                <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Created AT</TableCell>
                                                <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Updated AT</TableCell>


                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ticket.map((data, index) => (
                                                <TableRow key={data} style={{ cursor: "pointer" }} onClick={() => history.push(`/support-viewticket/${data._id}`)} >
                                                    <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{data.subject}</TableCell>
                                                    <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{data.priority}</TableCell>
                                                    <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{data.status}</TableCell>
                                                    <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{moment(data.createdAt).format("DD/MM/YYYY")}</TableCell>
                                                    <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>{moment(data.updatedAt).format('DD/MM/YYYY')}</TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )
                }



            </div>
        </>


    );
};

export default SupportPage;
