import React from 'react'
import Button from "react-bootstrap/Button";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import Table from "react-bootstrap/Table";
import { Card } from '@mui/material'
import { Navbar } from '../../../Components/Navbar';
import { useHistory } from 'react-router';
import LogoWhite from "../../../assets/navlogo.png"

const Version = () => {
    const history = useHistory();
    return (
        <div>
            <Navbar />
            <div className='d-flex '>
                <div className=" container custom-container d-flex flex-column justify-content-between mt-2 h-100 ml-1 mr-1 ">
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4  m-5" onClick={() => history.push('/IAM')}>Application Overview Logins</div>
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 m-5"onClick={()=> history.push('/supports')}>Contact Support</div>
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 m-5"> Version details and Updates </div>
                    <div className="custom-link ml-4 font-weight-bold cursor-pointer fs-4 m-5"> </div>
                </div>

                <Card className="w-100 ml-3 mt-2">
                    <div className='mt-5  d-flex justify-content-between'>
                        <div className='mt-5 ml-3'>
                            <p className='text-decoration-underline'><b>Version details</b></p>
                            <h6 className='mt-4'>Version Name: <span className='ml-4'>MyFinalyst V1</span></h6>
                            <h6 className='mt-4'>Version Release:  <span className='ml-4'>DD/MM/YY</span></h6>
                            <h6 className='mt-4'>Whats New:<a className='ml-4'>Link takes to Myfinalyst page</a></h6>
                            <h6 className='mt-4'>About Myfinalyst: <a className='ml-4'>Link takes to Myfinalyst page</a></h6>
                            <h6 className='mt-4'>Check for updates: <a className='ml-4'>Link takes to Myfinalyst page</a></h6>
                            <h6 className='mt-4'>Read terms and legal: <a className='ml-4'>Link takes to Myfinalyst page</a></h6>

                        </div>
                        <div className='mt-5 mr-2'>
                            <img
                                src={LogoWhite}
                                style={{ height: "250px",marginTop:'15px',backgroundColor:'#03565A'}}
                                alt="logo"
                            //   onClick={() => history.push("/dashboard")}
                            />
                        </div>
                    </div>

                </Card>
            </div>
        </div>
    )
}

export default Version