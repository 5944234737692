import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'


export function NBFC_32p14_32p21({ data, subIndex, HandleChange,calculateTotal, dis_index_id }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});

    return (
        <>
            <thead>
                <tr>
                    <th className='text-left w-75 pl-3 border-top-0 border-bottom-0' >Particulars</th>
                    <th className='w-auto border-top-0 border-bottom-0' > Year ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' > Year ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                </tr>
            </thead>
            <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            <tr>
                                <td className='border-top-0 border-bottom-0'>
                                    <input style={{ backgroundColor: "inherit" }} 
                                       className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                        type={"text"}
                                        value={subDis?.header}
                                        name="header"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input style={{ backgroundColor: "inherit" }} className="w-100 border-0 pr-3 text-right"
                                        type={"text"}
                                        readOnly={typeof subDis?.cy !== "number"}
                                        value={formatIndianCurrency(subDis?.cy)}
                                        name="cy"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input style={{ backgroundColor: "inherit" }} className="w-100 border-0 pr-3 text-right"
                                        type={"text"}
                                        readOnly={typeof subDis?.py !== "number"}
                                        value={formatIndianCurrency(subDis?.py)}
                                        name="py"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                            </tr>
                            {
                                (dis_index_id === 1 && subDis.sub_dis_index_id === 3) && (
                                    <tr>
                                        <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>Total</td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"cy", {start:1, end:3})} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"py", {start:1, end:3})} </td>
                                    </tr>
                                )
                            }

                            {
                                (dis_index_id === 1 && subDis.sub_dis_index_id === 6) && (
                                    <tr>
                                        <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>Total</td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"cy", {start:4, end:6})} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"py", {start:4, end:6})} </td>
                                    </tr>
                                )
                            }

                            {
                                (dis_index_id === 1 && subDis.sub_dis_index_id === 9) && (
                                    <tr>
                                        <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>Total</td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"cy", {start:7, end:9})} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"py", {start:7, end:9})} </td>
                                    </tr>
                                )
                            }

                           {
                                (dis_index_id === 1 && subDis.sub_dis_index_id === 12) && (
                                    <tr>
                                        <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>Total</td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"cy", {start:10, end:12})} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {calculateTotal(data,"py", {start:10, end:12})} </td>
                                    </tr>
                                )
                            }
                        </>
                    ))
                }
            </tbody>
        </>
    );
}