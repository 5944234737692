// import React from 'react'
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Card from '@mui/material/Card';
import Folder from "../../assets/Image/memory.png"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskIcon from '@mui/icons-material/Task';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { useParams } from "react-router";
import "./../../Bookclosure/Styles/Bookclosure.css"
// import Tbc from "../Tbc"
import Tbc from './Tbc';




const styleModal = {
  position: 'absolute',
  top: '27%',
  left: '89%',
  transform: 'translate(-50%, -50%)',
  width: 200,
  bgcolor: 'background.paper',
  border: 'none ',
  borderRadius: 2,
  // boxShadow: 24,
  p: 2,

};
const columnModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  backgroundColor: "#F8F8F8",
  border: 'none',
  borderRadius: 2,
  // boxShadow: 24,
  p: 2,
}


const UploadTbV2 = () => {
  const history = useHistory();
  const {wid}=useParams();


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // 

  const [openModal, setOpenModal] = React.useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const handleSaveAndContinue = () => {
    // Redirect to Tbc component
    history.push('/tbc');
  };

  return (
    <>
      <div className='px-5 py-5'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            <span><ArrowBackIcon className='custom-common-theme-text-color' /></span>
            <p className='custom-common-theme-text-color font-size-16 custom-font-600 mt-0 mb-0 font-sans'  onClick={() => history.push(`/bookclosure/${wid}`)} style={{cursor:"pointer"}}>Back</p>
          </div>
          <div className='d-flex align-items-center'>
            <span >
              <HelpOutlineIcon onClick={handleOpen} style={{ color: "#03565A", cursor: 'pointer' }} />
            </span>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>
                <div>
                  <p className='mt-0 mb-0 font-size-18 font-sans'>Upload Guide</p>
                  <div className='d-flex align-items-center mt-3'>
                    <div className='d-flex justify-content-center align-items-center rounded-circle custom-common-theme-bg-color' style={{ width: '21px', height: '21px' }}>
                      <span className='font-size-12 custom-font-500 text-white font-sans'>1</span>
                    </div>
                    <p className='ml-2 mb-0 mt-0 font-size-16 custom-font-500 font-sans'>Upload TB</p>
                  </div>
                  <div className='d-flex align-items-center mt-3'>
                    <div className='d-flex justify-content-center align-items-center rounded-circle custom-common-theme-bg-color' style={{ width: '21px', height: '21px' }}>
                      <span className='font-size-12 custom-font-500 text-white font-sans'>2</span>
                    </div>
                    <p className='ml-2 mb-0 mt-0 font-size-16 custom-font-500 font-sans'>Map Columns</p>
                  </div>
                  <div className='d-flex align-items-center mt-3'>
                    <div className='d-flex justify-content-center align-items-center rounded-circle custom-common-theme-bg-color font-sans' style={{ width: '21px', height: '21px' }}>
                      <span className='font-size-12 custom-font-500 text-white font-sans'>3</span>
                    </div>
                    <p className='ml-2 mb-0 mt-0 font-size-16 custom-font-500 font-sans'>Confirm grouping</p>
                  </div>
                  <div>
                    <Button className='w-100 mt-3 custom-common-theme-bg-color text-white text-capitalize font-sans' >Okay</Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
        <div>
          <p className='mt-4 mb-0 font-size-24 text-dark custom-font-600 font-sans'>Upload Trail Balances</p>
          <div className='mt-4 p-4'>
            <ul style={{ listStyleType: "disc" }}>
              <li className='text-secondary custom-font-500 mb-2 font-sans'>File you upload must contain Ledger code, ledger name, and current year amount</li>
              <li className='text-secondary custom-font-500 mb-2 font-sans'>Please ensure Column header is on the first row</li>
              <li className='text-secondary custom-font-500 mb-2 font-sans'>Remove sub-totals in between or at the end</li>
            </ul>
          </div>
          <div>
            <Card className='w-75 radius-10px'>
              <div className='ml-3 mt-3 mb-3 mr-3 d-flex justify-content-center align-items-center radius-8px' style={{ border: "1px dashed black", height: "276px" }}>
                <div>
                  <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex justify-content-center align-items-center rounded-circle' style={{ backgroundColor: '#FFE3BF', width: '100px', height: '100px' }}>
                      {/* <span><FolderIcon style={{ color: "#573E20" }} /></span> */}
                      <img src={Folder} alt="Bokcloser Vector" style={{ width: "45px", height: "35px" }} />
                    </div>
                    <div className='mt-2'><p className='mt-0 mb-0 custom-font-600 font-size-16 font-sans'>Upload files here to prepare financial statements</p></div>
                    <div className='mt-2'><p className='mt-0 mb-0 custom-font-500 font-size-12 text-secondary font-sans'><span><ErrorOutlineIcon /></span> File must contain Ledger Code, Ledger Name, Net Balance for Current Year</p></div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {/*  */}
          <div className='mt-3'>
            <Card className='w-75 radius-8px'>
              <div className="d-flex align-items-center justify-content-between p-3">
                <div className="d-flex align-items-center">
                  <div className="d-flex justify-content-center align-items-center rounded-circle" style={{ width: '45px', height: '45px', backgroundColor: '#F8F8F8' }}>
                    <span><DeleteIcon style={{ color: '#573E20', width: "24px", height: "24px" }} /></span>
                  </div>
                  <div className="d-flex align-items-center ml-2 rounded" style={{ backgroundColor: '#F8F8F8', width: "200px", height: "40px" }}>
                    <span className='ml-2'><TaskIcon style={{ width: "24px", height: "24px" }} /></span>
                    <p className='mt-0 mb-0 ml-2 font-size-16 custom-font-500 font-inter'>P NBFC LTD-TB.xlsx</p>
                  </div>
                </div>
                <div className='select-btn'>
                  <Button onClick={handleModalOpen} className='custom-common-theme-text-color  text-capitalize font-size-16 custom-font-600 radius-6px font-sans select-colum' >Select columns</Button>
                  <Modal
                    open={openModal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={columnModal}>
                      <div className='p-2'>
                        <div>
                          <p className='mt-0 mb-0 font-size-24 custom-font-600 font-sans'>Map your Trial Balance</p>
                          <p className=' font-size-16 custom-font-500 text-secondary font-sans'>Map column headers to the below tags </p>
                        </div>
                        <div>
                          <Card className='radius-8px' style={{ backgroundColor: "white" }}>
                            <div className="container">
                              <div className="row mb-3 mt-3 " style={{ width: "69%" }}>
                                <div className="col-md-6">
                                  <InputLabel className='font-sans' htmlFor="outlined-basic ">Ledger code<span style={{ color: "red" }}>*</span></InputLabel>
                                  <TextField className="w-100" id="outlined-basic" variant="outlined" />
                                </div>
                                <div className="col-md-6">
                                  <InputLabel className='font-sans' htmlFor="outlined-basic">Ledger Name<span style={{ color: "red" }}>*</span></InputLabel>
                                  <TextField className="w-100" id="outlined-basic" variant="outlined" />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-md-4">
                                  <InputLabel className='font-sans' htmlFor="outlined-basic">Period End Balance<span style={{ color: "red" }}>*</span></InputLabel>
                                  <TextField className="w-100" id="outlined-basic" variant="outlined" />
                                </div>
                                <div className="col-md-4">
                                  <InputLabel className='font-sans' htmlFor="outlined-basic">Period Beginning Balance<span style={{ color: "red" }}>*</span></InputLabel>
                                  <TextField className="w-100" id="outlined-basic" variant="outlined" />
                                </div>
                                <div className="col-md-4">
                                  <InputLabel className='font-sans' htmlFor="outlined-basic">Amount in<span style={{ color: "red" }}>*</span></InputLabel>
                                  <TextField className="w-100" id="outlined-basic" variant="outlined" />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 mb-3">
                                  <InputLabel className='font-sans' htmlFor="outlined-basic">Import Mapping from</InputLabel>
                                  <TextField className="w-100" id="outlined-basic" variant="outlined" />
                                </div>
                              </div>
                            </div>
                          </Card>
                          <div className='d-flex mt-3 exit-btnv1' style={{ gap: "15px" }}>
                            <Button className='primary-border text-capitalize custom-common-theme-text-color custom-font-600 font-size-16 radius-6px font-sans btn-exit 'onClick={handleModalClose}>Exit</Button>
                            <Button className='custom-common-theme-bg-color text-white text-capitalize custom-font-600 font-size-16 radius-6px font-sans btn-exit'>Confirm</Button>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>
            </Card>
            <div className="d-flex justify-content-center w-75 mt-3 save-btn">
              <Button
                className='text-capitalize custom-common-theme-bg-color font-size-16 custom-font-600 text-white radius-6px font-sans save-btnv2'
                // style={{ width: "200px", height: "50px" }}
                variant="contained"
                onClick={handleSaveAndContinue} 
              >
                Save and Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UploadTbV2