import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'


export function NBFC43p3({data, subIndex, HandleChange, dis_index_id}) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});

    console.log("data----------", data);
    return (
        <>
              <thead>
                  <tr>
                    <td rowSpan={2} className='text-left w-50 pl-3 border-top-0 border-bottom-0'>Nature of Assets</td>
                    <td colSpan={2}  className='w-auto border-top-0 border-bottom-0'>As at {""}{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</td>
                   <td colSpan={2} className='w-auto border-top-0 border-bottom-0'>As at {""} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</td>
                  </tr>

                  <tr>
                    <td className='w-auto border-top-0 border-bottom-0'> Carrying amount of sold assets</td>
                    <td className='w-auto border-top-0 border-bottom-0'> Carrying amount of associated liabilities</td>
                    <td className='w-auto border-top-0 border-bottom-0'>Carrying amount of sold assets</td>
                    <td className='w-auto border-top-0 border-bottom-0'> Carrying amount of associated liabilities-</td>
                  </tr>
                        
              </thead>

              <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            <tr>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{backgroundColor:"inherit"}}
                                        className="p-0 m-0 w-100 border-0 pl-3 text-left"
                                        type={"text"}
                                        value={subDis?.header}
                                        name="header"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{backgroundColor:"inherit"}}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.cy_carrying_amount_sold !== "number"}
                                        value={formatIndianCurrency(subDis?.cy_carrying_amount_sold)}
                                        name="cy_carrying_amount_sold"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input
                                        style={{backgroundColor:"inherit"}}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.cy_carrying_amount_associated !== "number"}
                                        value={formatIndianCurrency(subDis?.cy_carrying_amount_associated)}
                                        name="cy_carrying_amount_associated"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input 
                                        style={{backgroundColor:"inherit"}}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.py_carrying_amount_sold !== "number"}
                                        value={formatIndianCurrency(subDis?.py_carrying_amount_sold)}
                                        name="py_carrying_amount_sold"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input 
                                        style={{backgroundColor:"inherit"}}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.py_carrying_amount_associated !== "number"}
                                        value={formatIndianCurrency(subDis?.py_carrying_amount_associated)}
                                        name="py_carrying_amount_associated"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                            </tr>
                        </>
                    ))
                }
               
            </tbody>
        </>
    );
}