import React from "react";
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'

export function NBFC_33({ data, subIndex,HandleChange, dis_index_id, calculateTotal }) {
  const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});


  return (
    <>
      <thead>
        <tr>
          <th className="text-left pl-3 border-top-0 border-bottom-0" style={{minWidth:"130px"}}>Particulars</th>
          <th className="w- border-top-0 border-bottom-0">Trade & Other receivable days past due </th>
          <th className="w-auto border-top-0 border-bottom-0">0-90 days</th>
          <th className="w-auto border-top-0 border-bottom-0">91-180 days </th>
          <th className="w-auto border-top-0 border-bottom-0">181-360 days</th>
          <th className="w-auto border-top-0 border-bottom-0">more than 360 days</th>
          <th className="w-auto border-top-0 border-bottom-0">Total</th>
        </tr>
      </thead>

      <tbody>
        {data &&
          data.length > 0 &&
          data.map((subDis, subDisIndex) => (
            <tr key={subDis.sub_dis_index_id}>
              <td className="text-left pl-3 border-top-0 border-bottom-0">
              {
                (subDis.sub_dis_index_id === 1 || subDis.sub_dis_index_id === 5) ? (
                  <strong>ECL rate</strong>
                ) : (
                  [2, 3, 4].includes(subDis.sub_dis_index_id) ? (
                    `As at ${moment(reduxProjectData.current_year).format("DD MMMM YYYY")}`
                  ) : (
                    `As at ${moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}`
                  )
                )
              }
              
              </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pl-3 text-left"
                  type={"text"}
                  value={subDis?.header}
                  name="header"
                  onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                />
              </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  value={subDis["0_90_days"]}
                  name="0_90_days"
                  onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                />
              </td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  value={subDis["91_180_days"]}
                  name="91_180_days"
                  onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                /></td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  value={subDis["181_360_days"]}
                  name="181_360_days"
                  onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                /></td>
              <td className="border-top-0 border-bottom-0">
                <input
                  style={{ backgroundColor: "inherit" }}
                  className="p-0 m-0 w-100 border-0 pr-3 text-right"
                  type={"text"}
                  value={subDis["more_than_360_days"]}
                  name="more_than_360_days"
                  onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                />
              </td>
              <td className="border-top-0 border-bottom-0 text-right pr-3">
                {
                  calculateTotal(data, "dis_58", { type: "type_3", index: subDisIndex })
                }
              </td>
            </tr>
          ))
        }
      </tbody>
    </>
  );
}
