import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../services/socket/socket";
import * as actionTypes from "../../redux/actionTypes";
import { Spinner } from "react-bootstrap";
import { motion } from "framer-motion";
import { Tooltip } from "@mui/material";

import Note1 from "./Note1";

import axios from "axios";

const inputDiv = {
  height: "100%",
  width: "100%",
};
const input = {
  height: "100%",
  width: "100%",
  border: "none",
  textAlign: "end",
  background: "transparent",
};

const level3 = {
  color: "black",
  fontSize: "18px",
  fontWeight: "500",
  borderTop: "none",
};

const disInp = {
  height: "100%",
  width: "100%",
  backgroundColor: "#EBEBEB",
  outline: "none",
  border: "none",
  textAlign: "end",
}

const Note8 = (props) => {
  // console.log("props========", props)
  const finalStr = props.FinalStr;
  const NoteStr = props.NoteStr;
  const dispatch = useDispatch();
  const [note, setNote] = useState([]);
  const [ageingTable, setAgeingTable] = useState({
    cy:[],
    py:[]
  })
  const [total, setTotal] = useState({
    sub_py:0,
    sub_cy:0,
    check_cy:0,
    check_Py:0
  })
  const [dummy, setDummy] = useState(props.note4);
  const [nid, setNld] = useState("");
  const [noteOpen, setNoteOpen] = useState(false);
  const TB_ID = useSelector((initialState) => initialState.reducer.tb_id) || localStorage.getItem('tb_id');
  const Note = useSelector(
    (initialState) => initialState.reducerNotes[NoteStr]
  );
  const project_id = localStorage.getItem("project_id");
  useEffect(() => {
    const auth = localStorage.getItem("auth_token");
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    if(props.note && !["13(b)", "6(b)", '6(a)', '14','13(c)'].includes(props.note) ){
      const fd = new FormData();
      fd.append("notes_no", props.note);
      axios
        .post(`api/v1/notes/gcn/${project_id}/${TB_ID}`, fd, { headers })
        .then((res) => {
          setNld(res.data.notes.nlid);
          setNote(res.data.notes.data[0].sub_grp);
          dispatch({
            type: actionTypes[finalStr],
            payload: res.data.notes.data[0].sub_grp,
          });
        })
        .catch((err) => {
          // console.log(err.response)
          // if(err.response.data){
          //   alert(err.response.data.error)
          // }
        });
    }else{
      if ( props.data && Object.keys(props.data).length > 0 ) {
        // console.log("props.data==kanha======",props.data)
        setNote(props?.data?.sub_grp);
        if(props.data?.ageing_table && Object.keys(props.data.ageing_table).length > 0){
          setAgeingTable({...ageingTable,cy:props.data?.ageing_table?.ageing_cy, py:props.data?.ageing_table?.ageing_py})
        }
        if(props?.nid){
          setNld(props?.nid)
        }
      }
    }
    return () => {
      // dispatch({type: actionTypes.SET_NOTE4,payload:note})
    };
  }, []);

  const HandleDisChange = (value, index, key, filed ) => {
    // console.log("data=============",{value:value, ind:index, key:key, filed:filed})
    let copy = [...props.noteData]
    let noteNo = props?.note
    // console.log("copy========", copy)

    let copyData = [];
    if(props.check === "non_current"){
      if(filed === "ageing_cy"){
        copyData = [...copy[0]?.ageing_table?.ageing_cy]
      }else if(filed === "ageing_py"){
        copyData = [...copy[0]?.ageing_table?.ageing_py]
      }
    }else{
      if(filed === "ageing_cy"){
        copyData = [...copy[1]?.ageing_table?.ageing_cy]
      }else if(filed === "ageing_py"){
        copyData = [...copy[1]?.ageing_table?.ageing_py]
      }
    }
    
    // console.log("copyData========", copyData)

    if(copyData.length > 0){
      copyData.forEach((ele, ind) => {
        if(ind === index) {
          if(noteNo === '6(b)' && key === "less_then_6_month"){
            ele.less_then_6_month = value
          }
          if(noteNo === '6(b)'){
            if(key === "year_6_to_1"){
              ele.year_6_to_1 = value
            }
          }else{
            if(key === "less_then_1_year"){
              ele.less_then_1_year = value
            }
          }
          if(key === "year_1_2"){
            ele.year_1_2 = value
          }
          if(key === "year_2_3"){
            ele.year_2_3 = value
          }
          if(key === "more_than_3_year"){
            ele.more_than_3_year = value
          }
        }
      })
    }

    if(props.check === "non_current"){
      setAgeingTable({...ageingTable,cy:copy[0]?.ageing_table?.ageing_cy, py:copy[0]?.ageing_table?.ageing_py})
    }else{
      setAgeingTable({...ageingTable,cy:copy[1]?.ageing_table?.ageing_cy, py:copy[1]?.ageing_table?.ageing_py})
    }

    // console.log("updated copy =====", copy)

    socket.emit("save-update-notes", {
      project_id: `${project_id}`,
      tb_id: `${TB_ID}`,
      nlid: `${nid}`,
      note_no: props.note,
      contents: copy,
    });
  }
  const TotalAmount = (data) => {
    let count = 0
    if(data){
      data.forEach((ele) => {
        count += ADD(ele)
      })
    }
    return count ? count.toLocaleString("en-IN") : 0
  }
  const ADD = (data) => {
    // console.log("adddddd==", data)
    let noteNo = props?.note;
    let total;
    if(noteNo === '6(b)'){
      total = Number(data?.less_then_6_month) + Number(data?.year_6_to_1) + Number(data?.year_1_2) + Number(data?.year_2_3) + Number(data?.more_than_3_year)
    }else{
      total = Number(data?.less_then_1_year) + Number(data?.year_1_2) + Number(data?.year_2_3) + Number(data?.more_than_3_year)
    }
    return total;
  }

  useEffect(() => {
    if(note.length > 0){

      let sub_py = note.reduce(
        (totalpy, i) => totalpy + Number(i.sub_grp.py_amt),
        0
      );
      let sub_cy = note.reduce(
        (totalCy, i) => totalCy + Number(i.sub_grp.cy_amt),
        0
      );
      let check_cy;
      let check_py;

      if(props.note && props.note === '6(b)'){
        
        let copyNote = [...note]
        check_py = (sub_py - Number(copyNote[4].sub_grp.py_amt))
        check_cy = (sub_cy - Number(copyNote[4].sub_grp.cy_amt))
        console.log("check ==",check_py,check_cy )
      }else{
        check_cy = sub_cy
        check_py = sub_py
      }

      setTotal({...total, 
        check_cy:check_cy, 
        check_Py:check_py, 
        sub_cy:sub_cy, 
        sub_py:sub_py
      });
    }
  }, [note])
  

  if ((note == null) | (note == [])) {
    return (
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="grow" size="sm" />
        <Spinner animation="grow" />
        <Spinner animation="grow" size="sm" />
      </div>
    );
  }

  return (
    <div>
      <table>
        <thead>
          <tr style={{ backgroundColor: "#A2C0C2" }}>
            <th
              style={{
                width: "40%",
                height: "90px",
                textAlign: "left",
                paddingLeft: "2rem",
              }}
            >
              Particulars
            </th>
            <th style={{ width: "10%" }}>As at March 31, 2021</th>
            <th style={{ width: "10%" }}>As at March 31, 2020</th>
            <th
              style={{ width: "25%", textAlign: "left", paddingLeft: "3rem" }}
            >
              Guidances
            </th>
          </tr>
        </thead>

        {note.map((sub, i) => {
          const py = sub.disclosures.reduce(
            (totalPy, i) => totalPy + Number(i.py_amt),
            0
          );
          const cy = sub.disclosures.reduce(
            (totalCy, i) => totalCy + Number(i.cy_amt),
            0
          );
          return (
            <tbody key={i}>
              <tr style={level3}>
                <td
                  style={{
                    width: "40%",
                    height: "70px",
                    textAlign: "left",
                    paddingLeft: "2rem",
                    backgroundColor: "#EBEBEB",
                  }}
                >
                  {sub.sub_grp.sub_grp}
                </td>
                <td
                  style={{
                    width: "10%",
                    textAlign: "end",
                    paddingRight: "1rem",
                    backgroundColor: "#EBEBEB",
                  }}
                >
                  {sub.sub_grp.cy_amt.toLocaleString("en-IN")}
                </td>
                <td
                  style={{
                    width: "10%",
                    textAlign: "end",
                    paddingRight: "1rem",
                    backgroundColor: "#EBEBEB",
                  }}
                >
                  {sub.sub_grp.py_amt.toLocaleString("en-IN")}
                </td>
                <td
                  style={{
                    width: "25%",
                    textAlign: "right",
                    paddingLeft: "3rem",
                    backgroundColor: "#EBEBEB",
                  }}
                ></td>
              </tr>
            </tbody>
          );
        })}
        <tr
          style={{
            height: "50px",
            backgroundColor: " #F0DBC8",
            padding: "10px 0 0 2rem",
            alignContent: "center",
            fontSize: "1.1rem",
            fontWeight: "600",
          }}
        >
          <td
            style={{
              width: "40%",
              height: "33px",
              textAlign: "left",
              paddingLeft: "2rem",
            }}
          >
            {" "}
            Total amount
          </td>
          <td style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}>
            {total.sub_cy.toLocaleString("en-IN")}
          </td>
          <td style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}>
            {total.sub_py.toLocaleString("en-IN")}
          </td>
          <td
            style={{ width: "25%", textAlign: "left", paddingLeft: "3rem" }}
          ></td>
        </tr>
      </table>
      <br />
      {
        ["6(b)", '13(c)'].includes(props.note) && (
        <table>
          <thead>
            <tr style={{ backgroundColor: "#A2C0C2" }}>
              <th
                style={{
                  width: "40%",
                  height: "90px",
                  textAlign: "left",
                  paddingLeft: "2rem",
                }}
              >
                Particulars
              </th>
              {
                props.note === "6(b)" && (
                  <th style={{ width: "10%" }}>Less than 6 months</th>
                )
              }
              <th style={{ width: "10%" }}>{props.note === "6(b)" ? "6 months - 1 year" : "Less than 1 years"}</th>
              <th style={{ width: "10%" }}>1-2 year</th>
              <th style={{ width: "10%" }}>2-3 year</th>
              <th style={{ width: "10%" }}>more than 3 year</th>
              <th
                style={{ width: "25%", textAlign: "center"}}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={level3}>
              <td
                style={{
                  width: "40%",
                  height: "70px",
                  textAlign: "left",
                  paddingLeft: "2rem",
                  backgroundColor: "#EBEBEB",
                  color:"black",
                  fontWeight:"600"
                }}
              >
                Current Year
              </td>
              {props.note === "6(b)" && (
                <td
                  style={{
                    width: "10%",
                    textAlign: "end",
                    paddingRight: "1rem",
                    backgroundColor: "#EBEBEB",
                  }}
                ></td>
              )}
              <td
                style={{
                  width: "10%",
                  textAlign: "end",
                  paddingRight: "1rem",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
              <td
                style={{
                  width: "10%",
                  textAlign: "end",
                  paddingRight: "1rem",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
              <td
                style={{
                  width: "10%",
                  textAlign: "end",
                  paddingRight: "1rem",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
              <td
                style={{
                  width: "10%",
                  textAlign: "end",
                  paddingRight: "1rem",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
              <td
                style={{
                  width: "25%",
                  textAlign: "center",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
            </tr>
            {
              ageingTable.cy.length > 0 && ageingTable.cy.map((ele,i) => (
                <tr style={level3}>
                  <td
                    style={{
                      width: "40%",
                      height: "70px",
                      textAlign: "left",
                      paddingLeft: "2rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    {ele?.header}
                  </td>
                  {props.note === "6(b)" && (
                    <td
                      style={{
                        width: "10%",
                        backgroundColor: "#EBEBEB",
                      }}
                    >
                      <input 
                        style={disInp} 
                        type={"number"} 
                        value={ele?.less_then_6_month} 
                        onChange={(e) => HandleDisChange(e.target.value, i, "less_then_6_month", "ageing_cy") } 
                      />
                    </td>
                  )}
                  <td
                    style={{
                      width: "10%",
                      textAlign: "end",
                      paddingRight: "1rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    <input 
                      style={disInp} 
                      type={"number"} 
                      value={props.note === "6(b)" ? ele?.year_6_to_1 : ele?.less_then_1_year} 
                      onChange={(e) => HandleDisChange(e.target.value, i,props.note === "6(b)" ? "year_6_to_1" : "less_then_1_year", "ageing_cy") } 
                    />
                  </td>
                  <td
                    style={{
                      width: "10%",
                      textAlign: "end",
                      paddingRight: "1rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    <input 
                      style={disInp} 
                      type={"number"} 
                      value={ele?.year_1_2} 
                      onChange={(e) => HandleDisChange(e.target.value, i, "year_1_2", "ageing_cy") } 
                    />
                  </td>
                  <td
                    style={{
                      width: "10%",
                      textAlign: "end",
                      paddingRight: "1rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    <input 
                      style={disInp} 
                      type={"number"} 
                      value={ele?.year_2_3} 
                      onChange={(e) => HandleDisChange(e.target.value, i, "year_2_3", "ageing_cy") } 
                    />
                  </td>
                  <td
                    style={{
                      width: "10%",
                      textAlign: "end",
                      paddingRight: "1rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    <input 
                      style={disInp} 
                      type={"number"} 
                      value={ele?.more_than_3_year} 
                      onChange={(e) => HandleDisChange(e.target.value, i, "more_than_3_year", "ageing_cy") } 
                    />
                  </td>
                  <td
                    style={{
                      width: "25%",
                      textAlign: "center",
                      backgroundColor: "#EBEBEB",
                    }}
                  >{ADD(ele)}</td>
                </tr>
              ))
            }
            <tr
            style={{
              height: "50px",
              backgroundColor: " #F0DBC8",
              padding: "10px 0 0 2rem",
              alignContent: "center",
              fontSize: "1.1rem",
              fontWeight: "600",
            }}
          >
              <td
                style={{
                  width: "40%",
                  height: "33px",
                  textAlign: "left",
                  paddingLeft: "2rem",
                }}
              >
                {" "}
                Total amount
              </td>
              {props.note === "6(b)" && (
                <td
                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
              )}
              <td
                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
              ></td>
              <td
                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
              ></td>
              <td
                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
              ></td>
              <td
                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
              ></td>
              <td
                style={{ width: "25%", textAlign: "center"}}
              >
                {TotalAmount(ageingTable.cy.length > 0 && ageingTable.cy)}
                <span style={{marginLeft:"0.6rem"}}>
                  {
                    TotalAmount(ageingTable.cy.length > 0 && ageingTable.cy) != total.check_cy.toLocaleString("en-IN") &&
                    (
                      <Tooltip title="The value is not matching with third level template mode." placement="top">
                        <i
                          style={{
                            cursor:"pointer",
                            color: "darkorange",
                            transform: "scale(1.2)",
                          }}
                          className="fas fa-info-circle"
                        />
                      </Tooltip>
                    )
                  }
                </span>
              </td>
            </tr>

            <tr style={level3}>
              <td
                style={{
                  width: "40%",
                  height: "70px",
                  textAlign: "left",
                  paddingLeft: "2rem",
                  backgroundColor: "#EBEBEB",
                  color:"black",
                  fontWeight:"600"
                }}
              >
                Previous Year
              </td>
              {props.note === "6(b)" && (
                <td
                  style={{
                    width: "10%",
                    textAlign: "end",
                    paddingRight: "1rem",
                    backgroundColor: "#EBEBEB",
                  }}
                ></td>
              )}
              <td
                style={{
                  width: "10%",
                  textAlign: "end",
                  paddingRight: "1rem",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
              <td
                style={{
                  width: "10%",
                  textAlign: "end",
                  paddingRight: "1rem",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
              <td
                style={{
                  width: "10%",
                  textAlign: "end",
                  paddingRight: "1rem",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
              <td
                style={{
                  width: "10%",
                  textAlign: "end",
                  paddingRight: "1rem",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
              <td
                style={{
                  width: "25%",
                  textAlign: "center",
                  backgroundColor: "#EBEBEB",
                }}
              ></td>
            </tr>
            {
              ageingTable.py.length > 0 && ageingTable.py.map((ele,i) => (
                <tr style={level3}>
                  <td
                    style={{
                      width: "40%",
                      height: "70px",
                      textAlign: "left",
                      paddingLeft: "2rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    {ele?.header}
                  </td>
                  {props.note === "6(b)" && (
                    <td
                      style={{
                        width: "10%",
                        backgroundColor: "#EBEBEB",
                      }}
                    >
                      <input 
                        style={disInp} 
                        type={"number"} 
                        value={ele?.less_then_6_month} 
                        onChange={(e) => HandleDisChange(e.target.value, i, "less_then_6_month", "ageing_py") } 
                      />
                    </td>
                  )}
                  <td
                    style={{
                      width: "10%",
                      textAlign: "end",
                      paddingRight: "1rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    <input 
                      style={disInp} 
                      type={"number"} 
                      value={props.note === "6(b)" ? ele?.year_6_to_1 : ele?.less_then_1_year} 
                      onChange={(e) => HandleDisChange(e.target.value, i, props.note === "6(b)" ? "year_6_to_1" : "less_then_1_year", "ageing_py") } 
                    />
                  </td>
                  <td
                    style={{
                      width: "10%",
                      textAlign: "end",
                      paddingRight: "1rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    <input 
                      style={disInp} 
                      type={"number"} 
                      value={ele?.year_1_2} 
                      onChange={(e) => HandleDisChange(e.target.value, i,  "year_1_2", "ageing_py") } 
                    />
                  </td>
                  <td
                    style={{
                      width: "10%",
                      textAlign: "end",
                      paddingRight: "1rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    <input 
                      style={disInp} 
                      type={"number"} 
                      value={ele?.year_2_3} 
                      onChange={(e) => HandleDisChange(e.target.value, i,  "year_2_3", "ageing_py") } 
                    />
                  </td>
                  <td
                    style={{
                      width: "10%",
                      textAlign: "end",
                      paddingRight: "1rem",
                      backgroundColor: "#EBEBEB",
                    }}
                  >
                    <input 
                      style={disInp} 
                      type={"number"} 
                      value={ele?.more_than_3_year} 
                      onChange={(e) => HandleDisChange(e.target.value, i,  "more_than_3_year", "ageing_py") } 
                    />
                  </td>
                  <td
                    style={{
                      width: "25%",
                      textAlign: "center",
                      backgroundColor: "#EBEBEB",
                    }}
                  >{ADD(ele)}</td>
                </tr>
              ))
            }
            <tr
            style={{
              height: "50px",
              backgroundColor: " #F0DBC8",
              padding: "10px 0 0 2rem",
              alignContent: "center",
              fontSize: "1.1rem",
              fontWeight: "600",
            }}
          >
              <td
                style={{
                  width: "40%",
                  height: "33px",
                  textAlign: "left",
                  paddingLeft: "2rem",
                }}
              >
                {" "}
                Total amount
              </td>
              {props.note === "6(b)" && (
                <td
                  style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
              )}
              <td
                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
              ></td>
              <td
                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
              ></td>
              <td
                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
              ></td>
              <td
                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
              ></td>
              <td
                style={{ width: "25%", textAlign: "center" }}
              >{TotalAmount(ageingTable.py.length > 0 && ageingTable.py)}
                <span style={{marginLeft:"0.6rem"}}>
                  {
                    TotalAmount(ageingTable.py.length > 0 && ageingTable.py).toLocaleString("en-IN") != total.check_Py.toLocaleString("en-IN") &&
                    (
                      <Tooltip title="The value is not matching with third level template mode." placement="top">
                        <i
                          style={{
                            cursor:"pointer",
                            color: "darkorange",
                            transform: "scale(1.2)",
                          }}
                          className="fas fa-info-circle"
                        />
                      </Tooltip>
                    )
                  }
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          height: "50px",
          border: "2px solid #e8e8e8",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "1rem",
            color: "grey",
          }}
        >
          <p>Notes here</p>
        </div>
        <div
          onClick={(e) => {
            setNoteOpen(!noteOpen);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "3rem",
            background: "#e8e8e8",
            marginLeft: "auto",
            marginRight: "2rem",
            cursor: "pointer",
          }}
        >
          <i className="fas fa-pencil-alt" /> {/* <img src/> */}
        </div>
      </div>
      {noteOpen && (
        <motion.div
          style={{
            marginBottom: "1rem",
            padding: "0rem",
            backgroundColor: "white",
            border: "2px solid #e8e8e8",
            borderTop: "none",
          }}
        >
          {/* <h1>note 3  </h1> */}
          <Note1 color="grey" />
        </motion.div>
      )}
      {/* <div >
          Net Carrying Amount</div> */}
    </div>
  );
};

export default Note8;
