import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import "../Disclosure.css"
import TableManagement from './TableManagement';
import { financialInstrumentUpdate } from '../../../services/api/StandAlone/disclosuresTabAPI';
import swal from 'sweetalert';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    border:"1.3px",
    // boxShadow: 24,
    p: 2,
    borderRadius: "0.3rem",

    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
};

const RestoreFinancialModal = ({deletedItemData, setIsDeleted, isDeleted, handleSelect, HandleChange, setFinancialData, setDeletedItemData }) => {
    const project_id = localStorage.getItem('project_id');

    const HandleRestore = (i, onchangeProp, element, key) => {
        console.log("HandleRestore=====", {i:i, onchangeProp:onchangeProp, element:element, key:key})
        let updateData = {
            keyIndex: i,
            item: element,
            prop: onchangeProp,
            key : key
        }
        financialInstrumentUpdate(`api/v1/disclosure/restore-financial-instruments/${project_id}`, updateData).then(res => {
            console.log("response delete ----", res)
            if (res.success) {
                setFinancialData(res?.data?.data);
                setDeletedItemData(res?.data?.deletedItem);
                swal("Restored successfully!", {
                    icon: "success",
                }).then(() => {setIsDeleted(false)});
            }
        }).catch(err => {
            swal("", err.message, "error");
            setIsDeleted(false);
        })
    }

    return (
        <>
        <Modal
            open={isDeleted}
            onClose={() => setIsDeleted(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Box sx={{ ...style, width: "100%" }}>
                    <div id="parent-modal-title" className='d-flex justify-content-between'>
                        <h2 className='financial-restore-modal' >Restore Financial Instruments Data</h2>
                        <div onClick={() => setIsDeleted(false)} className='d-flex justify-content-center align-items-center' style={{width:"3rem", cursor:"pointer"}}><CloseIcon className='financialInstrument-deleteIconHover' /></div>
                    </div>
                    <div id="parent-modal-description" style={{overflowY:"scroll", height:"43rem"}} >
                        <table>
                            <thead>
                                <tr className='custom-common-theme-bg-color'>
                                    <th style={{ width: "35%" }}></th>
                                    <th style={{ width: "9%" }} >Note</th>
                                    <th style={{ width: "9%" }} >Level 1</th>
                                    <th style={{ width: "9%" }} >Level 2</th>
                                    <th style={{ width: "9%" }} >Level 3</th>
                                    <th style={{ width: "9%" }} >Total</th>
                                    <th style={{ width: "14%" }} >Fair value hierarchy</th>
                                    <th style={{ width: "6%" }} ></th>
                                </tr>
                            </thead>
                        </table>
                        {   
                            deletedItemData?.current_year && <TableManagement isDeleted={isDeleted} handleSelect={handleSelect} HandleDelete={HandleRestore} onChangeYT={"cy"} HandleChange={HandleChange} table={1} tableData={deletedItemData?.current_year} title={"Financial assets and liabilities - Fair value hierarchy (Current Year)"} />
                        }

                        {   
                            deletedItemData?.prev_year && <TableManagement isDeleted={isDeleted} handleSelect={handleSelect} HandleDelete={HandleRestore} onChangeYT={"py"} HandleChange={HandleChange} table={1} tableData={deletedItemData?.prev_year} title={"Financial assets and liabilities - Fair value hierarchy (Previous Year)"} />
                        }

                        <p className='mt-3 custom-common-theme-text-color custom-font-500 pl-2'>Fair value of financial assets and liabilities measured at amortised cost</p>
                        <table>
                            <thead>
                                <tr className='custom-common-theme-bg-color'>
                                    <th style={{ width: "35%" }}></th>
                                    <th style={{}} colSpan={3}>As at period end of Preset</th>
                                    <th style={{}} colSpan={3} >As at comparative period end</th>
                                    <th style={{ width: "6%" }} ></th>
                                </tr>
                                <tr className='custom-common-theme-bg-color'>
                                    <th style={{ width: "35%" }} ></th>
                                    <th style={{ width: "9%" }} >Carrying amount</th>
                                    <th style={{ width: "9%" }} >Fair value</th>
                                    <th style={{ width: "9%" }} >Set Fair value same as cost</th>
                                    <th style={{ width: "9%" }} >Carrying amount</th>
                                    <th style={{ width: "9%" }} >Fair value</th>
                                    <th style={{ width: "14%" }} >Set Fair value same as cost</th>
                                    <th style={{ width: "6%" }} ></th>
                                </tr>
                            </thead>
                        </table>
                        {
                            deletedItemData?.financialTableII && <TableManagement isDeleted={isDeleted} HandleDelete={HandleRestore} onChangeYT={"table3"} HandleChange={HandleChange} table={2} tableData={deletedItemData?.financialTableII} title={""} />
                        }
                    </div>
                </Box>
            </Box>
        </Modal>
        </>
    )
}

export default RestoreFinancialModal

