const presetType = {
    BOOK_cLOSURE_DATA: "BOOK_cLOSURE_DATA",
    BOOK_CLOSURE_COMPANIES: "BOOK_CLOSURE_COMPANIES",
    TASK_OPTIONS: "TASK_OPTIONS",
    BOOK_cLOSURE_CLEANUP: "BOOK_cLOSURE_CLEANUP",
}

const Initial_state = {
    bookClosureData: {},
    bookClosureId: "",
    bookClosureCompanies : [],
    bookClosureModules: [],

    assignOptions:[],
    approveOptions:[]
};

const BookClosureReducer = (state=Initial_state, action) => {
    switch(action.type){
        case presetType.BOOK_cLOSURE_DATA:
            return {
                ...state,
                bookClosureData: action.payload,
                // bookClosureCompanies: action.payload.companyDetails || [],
                bookClosureModules: action.payload.modules || [],
                bookClosureId: action.payload._id || "",
            }
        case presetType.BOOK_CLOSURE_COMPANIES:
            return {
                ...state,
                bookClosureCompanies: action.payload,
            }
        case presetType.TASK_OPTIONS:
            return {
                ...state,
                assignOptions: action.payload.assign || [],
                approveOptions: action.payload.approver || []
            }
        case presetType.BOOK_cLOSURE_CLEANUP:
            return {
                ...Initial_state,
                bookClosureData: {},
                bookClosureId: "",
                bookClosureCompanies: []
            }
        default:
            return state;
    }
};

export default BookClosureReducer;
