import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import swal from "sweetalert";
import {
  CreateIr,
  IrRegister,
} from "../../../Console/Component/services/apiHandler";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Navbar } from "../../../Components/Navbar";
import { getCompany } from "../ApiServices/apiHandler";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdditionalSubsidiariData from "../../../Console/Component/modal/additionalSubsidiaryModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 950,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style4 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const close = {
  height: "43px",
  width: "43px",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItem: "center",
  borderRadius: "6px",
  fontWeight: "normal",
  marginRight: "15px",
};

function AdditionInvestRegister() {
  const location = useLocation();
  let a = location.pathname;
  let b = a.split("/");
  const auth = localStorage.getItem("auth_token");
  let history = useHistory();
  const [data, setData] = useState("");
  const [totaldata, setDataTotal] = useState("");
  const [interest, setInterest] = useState("");
  const [investvalue, setInvestValue] = useState("");
  const [natureinvest, setNatureInvest] = useState("");
  const [comapnyId, setCompanyId] = useState("");
  const [openconsoleentry, setOpenConsoleEntry] = useState(false);
  const [holddata, SetHoldData] = useState("");
  const [newDetails, setNewDetails] = useState(false);
  const [browseOpen, setBrowseOpen] = useState(false);
  const [optionentry, setOptionEntry] = useState("");
  const [eqitysharedata, setEquityShareData] = useState("");
  const [otherequitysharedata, setOtherEquityShareData] = useState("");
  const [otherreservesurplusdata, setOtherReserveSurplusData] = useState("");
  const [othereservedata, setOtherReserveData] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [setfinalsumdata, setFinalSumData] = useState("");
  const [investcalculate, setInvestCalculate] = useState("");
  const [valuationcalculate, setVlauationCalculate] = useState("");
  const [calculationtwo, setCalculationTwo] = useState("");
  const [calculatefour, setCalculationfour] = useState("");
  const [thirddata, setThirdData] = useState({});
  const [additionalfinaldata, setAdditionalFinalData] = useState({});
  const [opendata, setOpenData] = useState(false);
  const [finalsharedata, setFinalData] = useState("");
  const [setcompanyData, setCompanyData] = useState("");
  const [datavalue, setDataValue] = useState("");
  const [eqityshare, setEquityShare] = useState([]);
  const [otherallData, setDataother] = useState("");
  const [allotherdata, setAllOtherData] = useState([]);
  const [finalconsoleentry, setFinalConsoleEntry] = useState({});
  const [allCompany, setAllCompany] = useState([]);

  const [companydate, setCompanyDate] = useState({
    investment_date: "",
    company: "",
    investment_value: "",
    shares_bought: "",
    tnositc: "",
    holding: "",
    non_controlling_interest: "",
    browse_control_dateFS: "",
    donotdoc: "",
    wticacct: "",
    // add_other_details:"",
  });

  const [statedata, setstate] = useState([{ column: "", select: "" }]);

  useEffect(() => {
    retriveRegisterData();
    fetchProject();
    getCompanyData();
    GetCompany();
  }, []);
  const GetCompany = async () => {
    const res = await getCompany();
    if (res.status === 200) {
      setAllCompany(res.data.getCompany);
    }
  };

  function handleDelete(i) {
    const temp = statedata;
    const newarr = temp.filter((val, ix) => ix !== i);
    setstate(newarr);
    if (newarr.length == 0) {
    }
  }
  const newData = (e) => {
    setDataother(e.target.value);
  };
  function handleNew() {
    setstate((oldArray) => [...oldArray, { column: "", select: "" }]);
    // dispatch({ type: actionTypes.SET_FILTER, payload: [] });
  }
  const handleClose = () => {
    setOpenConsoleEntry(false);
    setBrowseOpen(false);
    setOpenData(false);
  };
  const handleSave = () => {
    let sum = 0;
    setOpenData(false);
    const newData = statedata.map((item, index) => {
      let someData = Number(item.select);
      sum += someData;
    });
    console.log(sum);
    setFinalSumData(sum);
  };
  const retriveRegisterData = () => {
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    axios
      .get(`/api/v1/company-data/fetch-company-data/${b[2]}`, { headers })
      .then((res) => {
        console.log(res.data.getCompanyData[0], "res++++++++++");
        setCompanyId(res.data.getCompanyData[0]);
        getCompanyData(res.data.getCompanyData[0].c_id);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  function getCompanyData(id) {
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    axios
      .get(`/api/v1/company/get-onecomapny/${id}`, { headers })
      .then((res) => {
        console.log(res.data.getOneCompany[0], "777777777777777777");
        setCompanyData(res.data.getOneCompany[0]);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  const handleChangeData = (field, e) => {
    console.log(comapnyId, "gsdsghsghsgh");
    // console.log(field);
    if (field === "shares_bought") {
      let data = Number(e.target.value) + Number(comapnyId.final_no_of_shares);
      setData(data);
      setDataValue(Number(e.target.value));
      setFinalData(data);
    }
    if (field === "tnositc") {
      let data2 = Number(e.target.value);
      console.log(data);
      let holding = (data / data2) * 100;
      SetHoldData(holding);
      let updateHolding = Number(comapnyId.final_holdings) + Number(holding);
      let noOfInterest = 100 - holding;
      setDataTotal(updateHolding);
      setInterest(noOfInterest);

      if (holding > 50) {
        setNatureInvest("Subsidiary Company");
      } else if (holding <= 50 && holding >= 20) {
        setNatureInvest("Associate Company");
      } else {
        setNatureInvest("Investment");
      }
    }
    if (field === "investment_value") {
      let investvalue = Number(e.target.value);
      setInvestValue(investvalue);
    }
    // // //
    setCompanyDate({ ...companydate, [field]: e.target.value });
  };
  function isEmpty(object) {
    return Object.keys(object).length === 0;
  }
  const handleSubmit = async () => {
    if (selectedOption) {
      if (isEmpty(finalconsoleentry)) {
        swal("", "Please Submit Create Console Entry", "warning");
      } else {
        let headers = {
          "x-auth-token": auth,
        };
        const data = {
          rid: b[2],
          cid: comapnyId.c_id,
          investment_date: companydate.investment_date,
          company: companydate.company,
          investment_value: companydate.investment_value,
          shares_bought: companydate.shares_bought,
          tnositc: companydate.tnositc,
          holding: holddata,
          non_controlling_interest: interest,
          browse_control_dateFS: selectedOption,
          donotdoc: companydate.donotdoc,
          wticacct: companydate.wticacct,
          nature_of_relationship: natureinvest,
          add_other_details: statedata,
          console_entry: finalconsoleentry,
          console_entry_third_stage: thirddata ? thirddata : "",
          final_no_of_shares: finalsharedata,
          status: "addition",
        };
        axios
          .post(`api/v1/company-data/save-additional-data/${b[2]}`, data, {
            headers,
          })
          .then(async (res) => {
            const result = await CreateIr(data);
            if (result.status === 200) {
              history.push(`/investmentregister/${b[2]}`);
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    } else {
      let headers = {
        "x-auth-token": auth,
      };
      const data = {
        rid: b[2],
        cid: comapnyId.c_id,
        investment_date: companydate.investment_date,
        company: companydate.company,
        investment_value: companydate.investment_value,
        shares_bought: companydate.shares_bought,
        tnositc: companydate.tnositc,
        holding: holddata,
        non_controlling_interest: interest,
        browse_control_dateFS: companydate.browse_control_dateFS,
        donotdoc: companydate.donotdoc,
        wticacct: companydate.wticacct,
        nature_of_relationship: natureinvest,
        add_other_details: statedata,
        console_entry: finalconsoleentry,
        console_entry_third_stage: thirddata ? thirddata : "",
        final_no_of_shares: finalsharedata,
        status: "addition",
      };
      axios
        .post(`api/v1/company-data/save-additional-data/${b[2]}`, data, {
          headers,
        })
        .then(async (res) => {
          const result = await IrRegister(data);
          history.push(`/edit-register/${b[2]}`);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  const additionalEntryOpen = () => {
    if (selectedOption) {
      setOpenConsoleEntry(true);
    } else {
      swal("", "Please Upload Browse control date FS", "error");
    }
  };

  const handleInvestChnage = (e, field) => {
    console.log(e.target.value);
    console.log(field);
    if (field === "calculation1") {
      console.log(e.target.value);
      setInvestCalculate(Number(e.target.value));
      // let investtotal = Number(investcalculate) * Number(calculationtwo);
      // setTotalInvest(investtotal);
    }
    if (field === "calculation2") {
      console.log("jhgf");
      setCalculationTwo(Number(e.target.value));
      // let investtotal = Number(investcalculate) * Number(calculationtwo);
      // setTotalInvest(investtotal);
    }
  };

  function browseFs() {
    setBrowseOpen(true);
  }

  function fetchProject() {
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    axios
      .get("/api/v1/project/get-project", { headers })
      .then((res) => {
        setOptionEntry(res.data.project);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  const handleSubmitFs = () => {
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    axios
      .get(`/api/v1/grouping/fs/${selectedOption}`, { headers })
      .then((res) => {
        setEquityShareData(res.data.fs_grp[2].notes_grp[0].sub_grp[0].cy_amt);
        setOtherEquityShareData(
          res.data.fs_grp[2].notes_grp[1].sub_grp[0].cy_amt
        );
        setOtherReserveSurplusData(
          res.data.fs_grp[2].notes_grp[2].sub_grp[0].cy_amt
        );
        setOtherReserveData(res.data.fs_grp[2].notes_grp[3].sub_grp[0].cy_amt);
        setEquityShare(res.data.fs_grp[9].notes_grp);
        setBrowseOpen(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChangeFs2 = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleOpen = () => {
    setOpenData(true);
  };
  function handleChangeDetails(e, field, i) {
    // console.log(e.target.value);
    if (e.target.value === "Add_new") {
      setNewDetails(true);
    } else {
      const newArray = statedata.map((item, index) => {
        if (i === index) {
          return { ...item, [e.target.name]: e.target.value };
        } else {
          return item;
        }
      });

      setstate(newArray);
    }
  }

  const handleCloseData = () => {
    setNewDetails(false);
  };

  const handleValuationChnage = (e, field) => {
    if (field === "calculation3") {
      setVlauationCalculate(Number(e.target.value));
    }
    if (field === "calculation4") {
      console.log("jhgf");
      setCalculationfour(Number(e.target.value));
    }
  };

  const handleAdditionalData = () => {
    let additionalData = {};

    setAdditionalFinalData(additionalData);
    setOpenConsoleEntry(false);
  };

  const handleAdditional = () => {
    let thirdData = {
      Investment: investvalue,
      Non_controlling_interest: interest,
    };
    setThirdData(thirdData);
    setFinalConsoleEntry(thirdData);
    setOpenConsoleEntry(false);
  };

  const saveData = () => {
    var dataAll = [...allotherdata, otherallData];
    setAllOtherData(dataAll);
    handleCloseData();
  };

  const ProjectName = (p_id)=>{
    try {
      const nameCompany = optionentry.filter((adj) => adj._id === p_id);
      const result = nameCompany[0]?.project_name
        ? nameCompany[0]?.project_name
        : "";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  }

  const handleBack =()=>{
    history.push(`/investmentregister/${b[2]}`);
  }

  return (
    <div className="pb-2">
      <Navbar />
      <div className="container mt-4">
        <div className="d-flex">
          <p className="fs-inter" style={{ color: "#343434" }}>
            HOME / INVESTMENT /&nbsp;
            <span style={{ fontWeight: "bold", color: "#000000" }}>
              ADDTION REGISTER
            </span>
          </p>
        </div>
        <div>
          <h5 className="fs-inter ir-heading">Addition Investment Register</h5>
        </div>
        <div className="row mt-4">
          <div className="col-md-4">
            <label className="dm-sans iw-label">
              Name of investment register
              <input
                type="text"
                name="register"
                placeholder="Enter Invest Register"
                disabled
              />
            </label>
          </div>
          <div className="col-md-4">
            <label className="dm-sans iw-label">Parent company</label>
            <select
              style={{
                minWidth: "350px",
                minHeight: "54px",
                marginTop: "-1px",
                borderRadius: "5px",
              }}
              disabled
            >
              <option>Select...</option>
              {allCompany.length > 0 &&
                allCompany.map((det) => {
                  return (
                    <>
                      <option value={det._id}>{det.company_name}</option>
                    </>
                  );
                })}
            </select>
          </div>
        </div>
        <hr style={{ width: "731px" }} />
        <div className="row">
          <div className="col-md-8">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="d-flex justify-content-between">
                    <div className="font-weight-bold">
                      &nbsp;&nbsp;Addition Investment
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="text-secondary">
                        Investee company name
                        <select
                          style={{
                            width: "336px",
                            marginTop: "12px",
                            borderRadius: "5px",
                            height: "59px",
                          }}
                          onChange={(e) => handleChangeData("company", e)}
                          value={
                            comapnyId.company
                              ? comapnyId.company
                              : setcompanyData.company_name
                          }
                        >
                          <option>Select...</option>
                          {allCompany.length > 0 &&
                            allCompany.map((det) => {
                              return (
                                <>
                                  <option value={det._id}>
                                    {det.company_name}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div className="col-md-6 text-secondary">
                      <label>
                        Company code
                        <input type="text" placeholder="Code." />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label className="text-secondary">
                        Date of investment
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) =>
                            handleChangeData("investment_date", e)
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label className="text-secondary">
                        Value of investment
                        <input
                          type="text"
                          name=""
                          placeholder=""
                          style={{ width: "698px" }}
                          onChange={(e) =>
                            handleChangeData("investment_value", e)
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label className="text-secondary">
                        No of shares brought
                        <input
                          type="text"
                          onChange={(e) => handleChangeData("shares_bought", e)}
                        />
                      </label>
                      {datavalue && (
                        <small style={{ color: "grey" }}>
                          {comapnyId.final_no_of_shares} + {datavalue} = {data}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="text-secondary">
                        Total no shares in the company
                        <input
                          type="text"
                          onChange={(e) => handleChangeData("tnositc", e)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label className="text-secondary">
                        Percentage holding
                        <input
                          type="text"
                          placeholder="%"
                          onChange={(e) => handleChangeData("holding", e)}
                          value={totaldata.toLocaleString("en-IN")}
                          disabled
                        />
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="text-secondary">
                        Non-controling interest
                        <input
                          type="text"
                          onChange={(e) =>
                            handleChangeData("non_controlling_interest", e)
                          }
                          placeholder="Add non-controlling interest"
                          value={interest.toLocaleString("en-IN")}
                          disabled
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label className="text-secondary">
                        Nature of relationship
                      </label>

                      <select
                        style={{
                          width: "100%",
                          height: "59px",
                          borderRadius: "5px",
                        }}
                      >
                        <option>Select</option>
                        <option
                          value="Investment"
                          selected={natureinvest === "Investment"}
                        >
                          Investment
                        </option>
                        <option
                          value="Associate Company"
                          selected={natureinvest === "Associate Company"}
                        >
                          Associate Company
                        </option>
                        <option
                          value="Subsidiary Company"
                          selected={natureinvest === "Subsidiary Company"}
                        >
                          Subsidiary Company
                        </option>
                      </select>
                    </div>
                  </div>
                  {/* modal  */}

                  {totaldata > 50 && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <input
                          type="button"
                          className="btn btn-primary1 w-100"
                          value="Create consol entry"
                          onClick={() => additionalEntryOpen()}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="button"
                          className="btn btn-primary1 w-100"
                          value="Browse control date FS"
                          onClick={() => browseFs()}
                        />
                        <small>
                          {ProjectName(selectedOption)}
                        </small>
                      </div>
                    </div>
                  )}
                  {totaldata > 50 && (
                    <div className="row  mt-3">
                      <div className="col-md-12 ">
                        <label className="text-secondary">
                          Details of networth on the date of control
                        </label>
                        <input
                          onChange={(e) => handleChangeData("donotdoc", e)}
                          type="text"
                        />
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="text-secondary">
                          Add other details &nbsp; &nbsp;
                        </label>
                        <AddIcon
                          onClick={handleOpen}
                          data-bs-toggle="modal"
                          data-bs-target="#Intangible"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  )}
                  {totaldata > 50 && (
                    <div className="row mt-2">
                      <div className="col-md-12 ">
                        <label className="text-secondary">
                          Whether transaction is covered as common control
                          transaction
                        </label>
                        <select
                          onChange={(e) => handleChangeData("wticacct", e)}
                          style={{
                            width: "691px",
                            marginTop: "12px",
                            borderRadius: "5px",
                            height: "59px",
                          }}
                        >
                          <option>Select....</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>

                      <div className="col-md-12 mt-3">
                        <div class="table-responsive table-bordered">
                          <table class="table" style={{ marginBottom: "0" }}>
                            <tbody>
                              {statedata.length > 0 &&
                                statedata.map((item, v) => (
                                  <tr>
                                    <td>{item.column}</td>
                                    <td>{item.select}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <hr style={{ width: "731px" }} />
        <div className="mt-4 row">
          <div className="col-md-8">
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  <label
                    className="text-secondary"
                    style={{ fontSize: "16px" }}
                  >
                    Add remarks
                  </label>
                  <input type="text" style={{ height: "110px" }} />
                </Typography>
              </CardContent>
              <div
                style={{ color: "black", marginLeft: "18px" }}
                className="text-secondary"
              >
                Upload attachments
              </div>
              <CardActions>
                <Button
                  style={{
                    backgroundColor: "white",
                    // color: "black",
                    textTransform: "none",
                    marginLeft: "12px",
                    width: "220px",
                    height: "50px",
                    border: "2px solid #03565A",
                    borderRadius: "5px",
                    color: "#03565A",
                    fontWeight: "bold",
                  }}
                >
                  Upload attachment
                </Button>
              </CardActions>
            </Card>
          </div>
        </div>
        <div
          className="backicon"
          style={{ cursor: "pointer", marginLeft: "0px" }}
          onClick={()=>handleBack()}
        >
          <ArrowBackIcon
          
           />
          &nbsp;
          <div>Go Back</div>
        </div>
        <div
          className="nextbtn-v2"
          style={{ marginLeft: "-325px", marginTop: "-42px" }}
        >
          <Button
            style={{
              backgroundColor: "white",
              // color: "black",
              textTransform: "none",

              marginRight: "12px",
              width: "220px",
              height: "50px",
              border: "2px solid #03565A",
              borderRadius: "5px",
              color: "#03565A",
              fontWeight: "bold",
            }}
            onClick={handleSubmit}
          >
            Create Register
          </Button>
        </div>
      </div>
      <Modal
        open={browseOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <div className="col-12">
            {/* <button type="button" onClick={handleClose} class="btn-close float-end"></button> */}
            <div className="col-md-12 form-group">
              <label>Browse control date FS</label>
              <select
                className="form-control mt-2"
                onChange={(e) => handleChangeFs2(e)}
                value={selectedOption}
              >
                <option>select</option>
                {optionentry.length > 0 &&
                  optionentry.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.project_name}
                    </option>
                  ))}
                ;
              </select>
            </div>
            <input
              type="button"
              className="btn btn-primary1 float-end"
              value="Submit"
              onClick={handleSubmitFs}
            />
          </div>
        </Box>
      </Modal>

      <Modal
        open={opendata}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <div className="col-12">
              <button
                type="button"
                onClick={handleClose}
                class="btn-close float-end"
              ></button>
              <div className="mb-3">
                <div className="row">
                  {statedata.map((val, i) => {
                    return (
                      <>
                        <div className="col-md-6 mt-3">
                          <select
                            className="form-control"
                            name="column"
                            onChange={(e) =>
                              handleChangeDetails(e, "select", i)
                            }
                            style={{ border: "1px" }}
                          >
                            <option>Select</option>
                            <option value="Brand_trademarks">
                              Brands/trademarks
                            </option>
                            {allotherdata.map((data, i) => (
                              <option key={i} value={data}>
                                {data}
                              </option>
                            ))}
                            <option value="Add_new">Add New</option>
                          </select>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            name="select"
                            className="form-control"
                            onChange={(e) => handleChangeDetails(e, "input", i)}
                          />
                        </div>
                        <div
                          style={close}
                          onClick={() => {
                            handleDelete(i);
                          }}
                        >
                          <button
                            type="button"
                            class="btn-close float-end"
                            style={{ color: "red" }}
                          >
                            X
                          </button>
                        </div>
                      </>
                    );
                  })}
                  <div className="col-md-2 mt-2">
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#Intangible"
                      onClick={() => {
                        handleNew();
                      }}
                      style={{ background: "rgb(3, 86, 90)" }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <input
                type="button"
                className="btn btn-primary"
                style={{ background: "rgb(3, 86, 90)" }}
                value="Submit"
                onClick={() => {
                  handleSave();
                }}
              />
              {/* <input type="button" className="btn btn-defult" value="Close" onClick={handleClose} /> */}
            </div>
          </Box>
        </>
      </Modal>

      <Modal
        open={newDetails}
        onClose={handleCloseData}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style4}>
          <div className="col-12">
            <h5>Add New Data</h5>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={(e) => newData(e)}
                />
              </div>
              <input
                type="button"
                className="btn btn-primary1"
                value="Submit"
                onClick={saveData}
              />
              <input
                type="button"
                className="btn btn-defult"
                value="Close"
                onClick={handleCloseData}
              />
            </form>
          </div>
        </Box>
      </Modal>
      <AdditionalSubsidiariData
        openconsoleentry={openconsoleentry}
        handleClose={handleClose}
        comapnyId={comapnyId}
        handleInvestChnage={handleInvestChnage}
        investcalculate={investcalculate}
        calculationtwo={calculationtwo}
        handleValuationChnage={handleValuationChnage}
        valuationcalculate={valuationcalculate}
        calculatefour={calculatefour}
        eqitysharedata={eqitysharedata}
        otherequitysharedata={otherequitysharedata}
        otherreservesurplusdata={otherreservesurplusdata}
        othereservedata={othereservedata}
        investvalue={investvalue}
        interest={interest}
        setfinalsumdata={setfinalsumdata}
        handleAdditionalData={handleAdditionalData}
        handleAdditional={handleAdditional}
        eqityshare={eqityshare}
        datavalue={datavalue}
        setFinalConsoleEntry={setFinalConsoleEntry}
        setOpenConsoleEntry={setOpenConsoleEntry}
      />
    </div>
  );
}

export default AdditionInvestRegister;
