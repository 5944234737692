import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {NBFC_8p1, NBFC_12_17, NBFC_26_30, NBFC_31, NBFC_31p3, NBFC_32_32p5, NBFC_32p6, NBFC_32p7_32p13,
    NBFC_32p14_32p21, NBFC_32p22_32p37, NBFC_33, NBFC_34, NBFC_35, NBFC_35p1, NBFC_37_41, NBFC_42_43, 
    NBFC_44, NBFC_45_46, NBFC_46p1_46p2, NBFC_46p3_47, NBFC_48_50, NBFC35p1of2, NBFC36, NBFC43p3
} from '../../../Components/Disclosures/NBFC/index';
import { socket } from '../../../services/socket/socket';
import swal from 'sweetalert';
import CkEditor from '../../Common/CkEditor';
import { convertStrToNumber } from '../../../helper/ConvertValue';

const NBFCDisclosureNotes = ({tabIx}) => {
    const project_id = localStorage.getItem('project_id');
    const reduxDisclosureFilterData = useSelector((initialState) => initialState.DisclosureReducer.filterDisclosureData);

    const [subData, setSubData] = useState();

    useEffect(() => {
        if (Object.keys(reduxDisclosureFilterData).length > 0) {
            setSubData(reduxDisclosureFilterData.disclosure_data);
        }
    }, []);

    const HandleChange = (event, subIndex, subDisIndex, isArrayAvl=null, extraIndex) => {
        try {
            const copy = [...reduxDisclosureFilterData.disclosure_data];
            if(isArrayAvl){
                copy[subIndex].sub_disclosure[subDisIndex][event.target.name] = Array.isArray(copy[subIndex].sub_disclosure[subDisIndex][event.target.name]) ?
                copy[subIndex].sub_disclosure[subDisIndex][event.target.name].map((value, index) =>
                    index === extraIndex ? event.target.value : value
                    )
                : event.target.value;
            }else{
                if(subDisIndex >= 0 || ('headerName' === subDisIndex || 'footer' === subDisIndex)){
                    if('headerName' === subDisIndex || 'footer' === subDisIndex){
                        copy[subIndex][subDisIndex] =  event;
                    }else{
                        copy[subIndex].sub_disclosure[subDisIndex][event.target.name] = 
                            ["header", "header_2", "cy_header", "py_header"].includes(event.target.name) ? 
                            event.target.value : 
                            extraIndex === "lease" ? event.target.value : convertStrToNumber(event.target.value);
                    }
                }else{
                    copy[subIndex][event.target.name] =  event.target.value;
                }
            }
            setSubData(copy);

            // Socked code for update and error handeling
            socket.emit("auto-save-nbfc-disclosure", {
                project_id: `${project_id}`,
                disclosureId:reduxDisclosureFilterData?.disclosureId,
                contents: copy,
            });
        } catch (error) {
            swal("Error", (error || "Update value failed !"), "error")
        }
    }
    
    socket.on('auto-save-nbfc-disclosure', (data) => {
        if(!data.success){
            swal("Error", data.message, "error")
        }
    });

    const fetchTable = (tabIx, data, subIndex, dis_index_id, from_financial_statement, originalArray) => {
        switch (tabIx) {
            case 0:
                return <NBFC_8p1 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 1:
                return <NBFC_12_17 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 2:
                return <NBFC_26_30 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} from_financial_statement={from_financial_statement}/>
            case 3:
                return <NBFC_31 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 4:
                return <NBFC_31p3 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 5:
                return <NBFC_32_32p5 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} calculateTotal={calculateTotal}/>
            case 6:
                return <NBFC_32p6 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} calculateTotal={calculateTotal} originalArray={originalArray}/>
            case 7:
                return <NBFC_32p7_32p13 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} calculateTotal={calculateTotal} />
            case 8:
                return <NBFC_32p14_32p21 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} calculateTotal={calculateTotal}/>
            case 9:
                return <NBFC_32p22_32p37 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} calculateTotal={calculateTotal}/>
            case 10:
                return <NBFC_33 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} calculateTotal={calculateTotal}/>
            case 11:
                return <NBFC_34 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 12:
                return <NBFC_35 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 13:
                return <NBFC_35p1 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} calculateTotal={calculateTotal} />
            case 14:
                return <NBFC35p1of2 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            // case 15:
            //     return <NBFC36 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 16:
                return <NBFC_37_41 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} calculateTotal={calculateTotal} />
            // case 17:
            //     return <NBFC_42_43 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 18:
                return <NBFC43p3 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 19:
                return <NBFC_44 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 20:
                return <NBFC_45_46 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 21:
                return <NBFC_46p1_46p2 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            // case 22:
            //     return <NBFC_46p3_47 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} />
            case 25:
                return <NBFC_48_50 data={data} subIndex={subIndex} HandleChange={HandleChange} dis_index_id={dis_index_id} calculateTotal={calculateTotal} />
            default:
                return <></>
        }
    }

    const calculateTotal = (data, key, indexes=null) => {
        const copy = [...data];
        if(indexes){
            if(indexes?.type && indexes?.type === "type_3"){
                const obj_keys = [
                    "1_to_7days",
                    "8_to_14days",
                    "15_to_30days",
                    "over_1month",
                    "over_1year",
                    "over_2months",
                    "over_3months",
                    "over_3years",
                    "over_5years",
                    "over_6months",
                ];
                const obj_keys_57_2 = ["1_day_to_30_days", "1_months_to_2_months", "2_months_to_3_months", "3_months_to_6_months", "6_months_to_1_years", "1_years_to_3years", "3_years_to_5years", "over_5years"]
                const obj_keys_57_3 = ["pledged_as_collateral", "Legally_restricted_assets", "encumbered_other", "available_as_collateral", "unencumbered_other"];

                const allKeys = key === "dis_54_cy" ? ["cy_within_1_year","cy_after_1_year"] : 
                    key === "dis_54_py" ? ["py_within_1_year","py_after_1_year"] : 
                    key === "dis_57_1"? ["retail", "structured_finance_corporate_finance", "financial_services"] :
                    key === "dis_57_2" ? obj_keys_57_2 :
                    key === "dis_57_3"? obj_keys_57_3 : 
                    key === "dis_55_cy"? ["distribution_cy", "retail_finance_cy", "wholesale_finance_cy"] : 
                    key === "dis_55_py"? ["distribution_py", "retail_finance_py", "wholesale_finance_py"] : 
                    key === "dis_58" ? ["0_90_days", "91_180_days", "181_360_days", "more_than_360_days"] : obj_keys;
                    
                return allKeys.reduce((total, obj_key) => total + data[indexes?.index]?.[obj_key] || 0, 0);
            }else{
                return copy
                    ?.slice(indexes.start, indexes.end)
                    .reduce((total, item) => total + (typeof item[key] === "number" ? item[key] : 0), 0);
            }
        }
        return copy.reduce((total, item) => total + (typeof item[key] === "number" ? item[key] : 0), 0);
    };

    return (
        <>
            {
                subData && subData.length > 0 && subData.map((sub, subIndex, originalArray) => (
                    <div className='nbfc-box-shadow' key={subIndex} >
                        {
                            sub?.isMainHeading && (
                                <input
                                    className='w-100 border-0 pl-3 p-2 custom-bg-warning custom-font-600 font-sans'
                                    name='mainHeading'
                                    value={sub?.mainHeading}
                                    onChange={(event) => HandleChange(event, subIndex)}
                                />
                            )
                        }
                        {
                            sub?.isHeading && (
                                <input
                                    className='w-100 border-0 pl-3 p-2 custom-common-theme-text-color font-sans custom-bg-warning'
                                    name='heading'
                                    value={sub?.heading}
                                    onChange={(event) => HandleChange(event, subIndex)}
                                />
                            )
                        }
                        {
                            sub?.isHeaderName && <CkEditor value={sub?.headerName} onChange={(data) => {
                                HandleChange(data, subIndex, 'headerName')
                            }} />
                        }
                        {
                            sub?.sub_disclosure && sub?.sub_disclosure.length > 0 && (
                                <table>
                                    {fetchTable(tabIx, sub?.sub_disclosure, subIndex, sub?.dis_index_id, sub?.from_financial_statement, originalArray )}
                                </table>
                            )
                        }
                        {
                            sub?.isFooter && <CkEditor value={sub?.footer} onChange={(data) => {
                                HandleChange(data, subIndex, 'footer')
                            }} />
                        }
                    </div>
                ))
            }
        </>
    )
}

export default NBFCDisclosureNotes;