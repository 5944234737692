import React from "react";
import Sidebar from "../../Workspace2/Sidebar";
import "../../Workspace2/sidebar.css";
import Button from "@mui/material/Button";
// import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Box, InputLabel, Modal} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import Loader from "../../../helper/loader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useLocation } from "react-router";
import {
  CreateAccess,
  GetAccess,
  GetUser,
  UpdateStepStatus,
  getMeber,
  getChildUser,
  getworkspaceteamMember,
  getMemberbyPreparerId,
  addworkspaceteamMember,
  deleteworkspaceteamMember,
  getWorkspace
} from "../ApiServices/apiHandler";
import { useEffect } from "react";
import { useState } from "react";
import "../../../WorkSpace/font.css";
import WorkspaceHeader from "./WorkspaceHeader";
import swal from "sweetalert";
import { socket } from '../../../services/socket/socket';

const stylemodal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

const InviteteamMember = (props) => {
  const location = useLocation();
  let a = location.pathname;
  let w_id = a.split("/");
  let history = useHistory();
  const [alluser, setAllUSer] = useState([]);
  const [allmember, setAllmember] = useState([]);
  // const[user,setUser]=useState([]);
  // const [selectedUsers,setSelectedUsers]=useState([]);
  const [userId, setUserId] = useState({});
  const [userAccess, setUserAcces] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModal1, setOpenModal1] = React.useState(false);
  const [openModal2, setOpenModal2] = React.useState(false);
  const [approvalId,setApprovalId]=useState([]);
  const [approverId,setApproverId]=useState({});
  const [reason,setReason]=useState('');
  const [teamMember,setTeamMember]=useState({});
  const [workspace,setWorkspace]=useState({});

  const users = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    getUser();
    usersData();
    getAllAccess();
    getApprovalIds();
    getworkspaceteamMembers();
    getWorkspaceDetails();
    if(w_id.includes('inviteteammember')){
      localStorage.setItem("modifyAccess", 'inviteteammember');
    }
  }, []);

 
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal1 = () => setOpenModal1(true);
  const handleCloseModal1 = () => setOpenModal1(false);
  const handleOpenModal2 = () => setOpenModal2(true);
  const handleCloseModal2 = () => setOpenModal2(false);

  const getUser = async () => {
    const res = await GetUser();
    if (res.status === 200) {
      setAllUSer(res.data.fetchUser);
    }
  };

  const getAllAccess = async () => {
    const result = await GetAccess();
    if (result.status === 200) {
      console.log(result, "eeeee");
      setUserAcces(result.data.getAccess);
    }
  };

  const getworkspaceteamMembers = () => {
    const workspace_id = w_id[2];
    getworkspaceteamMember(workspace_id)
      .then((result) => {
        if (result.status === 200) {
          setTeamMember(result.data.response?.user_ids);
        }
      })
      .catch(error => {
        swal("Error",  "Get Workspace Team Failed", "error")
      });
  };
  
  const getWorkspaceDetails = async () => {
    const res = await getWorkspace(w_id[2]);
    if (res.status === 200) {
      setWorkspace(res.data?.getworkspace[0])
    }
  }


  const nextStep =async () => {
    try {
      let data={}
      if(users.responsibilities==="preparer"){
       data = {
        steps:"step6",
        has_published:"draft",
        status:"waiting",
        approver_id:approverId,
        cancel_reason:""
      };
    }else{
      data = {
        steps:"step6",
        has_published:"published",
      };
    }
      const res = await UpdateStepStatus(w_id[2],data);
      if (res.status === 200) {
          setOpenModal(false);
          getWorkspaceDetails();
          if(users.isAdmin==="Yes"){
          history.push(`/overview/${w_id[2]}`);
          }
          if(users.responsibilities==="preparer"){
            socket.emit("add-notification", {
              receiver_id: approverId,
              description: "creation of workspace",
              type:"sent approval",
              path:"/Notification"
            });
            socket.emit('get-notification');
           }
          }
        }
     catch (error) {
      console.log("error", error.message);
      return 0;
    }
  };
  const skipNext =async () => {
    
        history.push(`/overview/${w_id[2]}`);
  };

  const handleAdd = () => {
    const datas = {
      workspace_id:w_id[2],
      user_id:userId
      };
      addworkspaceteamMember(datas).then(res => { 
      setUserId('');
      getworkspaceteamMembers();
      if(res.data.response.user_exists===true){
        swal("ERROR","Team member Already exists","error");
      }else{
        swal("Success","Team Added Sucessfully","success");
      }
      
    }).catch(error => {
      swal("Error", error.response.data.error || "Workspace Team creation Failed", "error")
    });
  };

  const handleRemove = (u_id) => {
    const datas = {
      workspace_id:w_id[2],
      user_id:u_id
      };
      deleteworkspaceteamMember(datas).then(res => { 
      swal("Success","Team member Removed Sucessfully","success");
      getworkspaceteamMembers();
    }).catch(error => {
      swal("Error", error.response.data.error || "Workspace Team creation Failed", "error")
    });
  };
  
  const usersData = async () => {
    if (users.responsibilities==="preparer"){
      const parent_id = users.parent_id;
      const res = await getMemberbyPreparerId(parent_id);
      setAllmember(res.data.mergedData);
    }else{
    const res = await getMeber();
    setAllmember(res.data.fetchMember);
    }
  };
  
  const handleChange = (event,value) => {
    const newValue = event.target.value;
    setUserId(newValue);
  }

  const handleApproverChange = (event) => {
    const newValue = event.target.value;
    setApproverId(newValue);
  };
  const handleApprove = async()=>{
    const data = {
      status:"approved",
      cancel_reason:"",
      has_published:"published",
      steps:"step6",
      approver_id:workspace.approver_id,
    };
    const response = await  UpdateStepStatus(w_id[2],data);
    if(response.status === 200){
      const datas = {
        workspace_id:w_id[2],
        user_id:workspace.approver_id
        };
        addworkspaceteamMember(datas).then(res => { 
        swal("Success","Team Added Sucessfully","success");
        getworkspaceteamMembers();
      }).catch(error => {
        swal("Error", error.response.data.error, "error")
      });
      swal("Success","Approved Successfully", "success")
      getWorkspaceDetails();
      socket.emit("add-notification", {
        receiver_id:workspace.user_id ,
        description: "workspace",
        type:"approved",
        path:"/Notification"
      });
      socket.emit('get-notification');  
    }
  }
  const handleCancel = async()=>{
    const data = {
      status:"cancelled",
      cancel_reason:reason,
      has_published:"draft",
      steps:"step6",
      approver_id:workspace.approver_id,
    };
    const response = await UpdateStepStatus(w_id[2],data);
    if(response.status === 200){
      setOpenModal1(false);
      setReason("");
      swal("Warning","Cancelled Successfully", "warning")
      getWorkspaceDetails();
      socket.emit("add-notification", {
        receiver_id: workspace.user_id,
        description: "workspace",
        type:"Cancelled",
        path:"/Notification"
      });
      socket.emit('get-notification');
    }
  }
  const getApprovalIds = async()=>{
    const parent_id = users.parent_id;
    const workspace_id =w_id[2];
      getChildUser(parent_id,workspace_id)
        .then(response => {
          setApprovalId(response.data.result);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }

  return (
    <>

      <div className="row mr-0 ml-0">
        <div className="col-3 bg-white">
            <Sidebar activePage="6" id={w_id[2]}/>
        </div>
        <div className="col-9 main-workspace-content">
          <WorkspaceHeader currentStep="6" title="Invite your team members" />
          <div className="row mt-3">
            <div className="row mt-2 w-100">
              <p className=" col-sm itmlab font-sans text-center">User ID:</p>
              <div className="col-sm">
                {/* <input
                  style={{
                    height: "50px",
                    outline: "none",
                    border: "none",
                  }}
                  type="text"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                /> */}
                {/* <Button
                  className="font-sans"
                    style={{
                      width: "200px",
                      backgroundColor: "#03565A",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                  Send invite
                </Button> */}
                 <FormControl fullWidth>
                <Select
                 labelId="demo-simple-select-label"
                 id="demo-simple-select"
                 value={userId|| ''}
                onChange={handleChange}
                >
                  {allmember && allmember.length>0 && allmember.map((data)=>
                  <MenuItem
                   key={data._id} 
                   value={data._id}
                  >
                    {data.email}({data.responsibilities?data.responsibilities: "admin"})
                  </MenuItem>
                )}
                </Select>
                </FormControl>
              </div>
              <div className="col-sm">
                <div className="ml-5">
                  <Button
                    style={{
                      color: "black",
                      textTransform: "none",
                      marginTop: "17px",
                    }}
                    onClick={handleAdd}
                  >
                    <AddIcon />
                    <span className="font-sans">Add into Workspace</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* <hr className="hr" /> */}
          {/* <div className="row mt-3">
            <div className="col-10 mt-2">
            <div className="drop_down">
        {alluser.length > 0 ?
          (alluser.map((user) => {
            const atIndex = user?.email.indexOf('@');
            const nameBeforeAt = user?.email.slice(0, atIndex);
            return (
              <>
                <div className="drop_down1">
                  <div className="dropp_down">
                    <div>
                      <div className="d-flex">
                        <div className="circle-v22">A</div>
                        <div style={{ marginLeft: "15px" }}>
                          <div style={{fontWeight:"bold"}}>{nameBeforeAt?nameBeforeAt:"Uknown"}</div>
                          <div>{user?.email}</div>
                        </div>
                      </div>
                    </div>
                    
                    <SelectUser
                      w_id={w_id}
                      user={user}
                      userAccess={userAccess}
                    />
                  </div>
                </div>
              </>
            );
          })):(<div style={{marginBottom:'100px'}}><Loader/></div>)}
            </div>
            </div>
          </div> */}
          <div className="mt-4">
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#b5b3b3" }}>
                    <TableCell style={{border:"none"}}align="center" className="font-sans">S.No</TableCell>
                    <TableCell style={{border:"none"}} align="center"className="font-sans">Name</TableCell>
                    <TableCell style={{border:"none"}} align="center"className="font-sans">Company</TableCell>
                    <TableCell style={{border:"none"}} align="center"className="font-sans">Actions</TableCell>
                    <TableCell style={{border:"none"}} align="center"className="font-sans">ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {teamMember && teamMember.length>0  && teamMember.map((data,index)=>
                  <TableRow>
                    <TableCell component="th" scope="row" style={{border:"none"}}>{index+1}</TableCell>
                    <TableCell align="center" style={{border:"none"}}className="font-sans">{data.name?data.name:"Unknown"}</TableCell>
                    <TableCell align="center" style={{border:"none"}}>Not Listed</TableCell>
                    <TableCell align="center" style={{border:"none"}}>
                      <div className="d-flex flex-row justify-content-center">
                      <div style={{color:"#03565a" ,cursor:"pointer"}} 
                       onClick={
                        data.responsibilities === "approver" ? () => history.push(`/functionalityacess/${data._id}/${w_id[2]}`) : () => history.push(`/modifyacess/${data._id}/${w_id[2]}`)
                      }>
                      <MiscellaneousServicesIcon/> Modify access
                      </div>
                      <div style={{color:"#03565a",cursor:"pointer"}} className="ml-3"  onClick={
                        users.responsibilities === "approver" ? null:
                        () => handleRemove(data._id)}>
                      <DoDisturbOnIcon/>Remove user
                      </div>
                      </div>
                    </TableCell>
                    <TableCell align="center" style={{border:"none"}}className="font-sans">{data.email}</TableCell>
                  </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="row mt-5 mb-5">
            <div className="col-md-6">
              <div
                className="backicon"
                onClick={() => history.push(`/importPreset/${w_id[2]}`)}
                style={{ cursor: "pointer" }}
              >
                <ArrowBackIcon />
                <div className="font-sans">Back to the Previous step</div>
              </div>
            </div>
            <div className="col-md-6">
            {users.responsibilities === "preparer"  && users?.unrestrictedaccess===false  ? (
                    <div  className="nextbtn-v2 text-right">
                    <Button
                      className="datebtn font-sans"
                      style={{
                        backgroundColor: "#03565A",
                        color: "white",
                        textTransform: "none",
                      }}
                      onClick={handleOpenModal2} 
                    >
                      Remark
                    </Button>
                    &nbsp;
                    <Button
                      className="datebtn font-sans"
                      style={{
                        backgroundColor: "#03565A",
                        color: "white",
                        textTransform: "none",
                      }}
                      disabled={workspace.status === "waiting"}
                      onClick={() =>{
                         if(workspace.status ===  "start"){
                          handleOpenModal()
                         }else if(workspace.status === "cancelled" ){
                          handleOpenModal()
                        }else{
                          history.push(`/overview/${w_id[2]}`);
                        }
                      }}
                    >
                      {workspace.status==="start" ? "Send for Approval" :(workspace.status === "waiting" ? "Already Sent for approval": workspace.status === "approved" ?"Next Step":"Update")}
                    </Button>
                    </div>
                  ) : users.responsibilities === "approver" ? (
                    <div  className="nextbtn-v2 text-right">
                    {workspace.status ==="approved" ? "":
                    <Button
                      className="datebtn font-sans"
                      style={{
                        backgroundColor: "#03565A",
                        color: "white",
                        textTransform: "none",
                      }}
                      disabled={workspace.status === "cancelled"}
                      onClick={handleOpenModal1}
                    >
                       {workspace.status ==="cancelled" ? "Cancelled": "Return"}
                    </Button>}
                    &nbsp;
                    {workspace.status ==="cancelled" ? "":
                    <Button
                      className="datebtn font-sans"
                      style={{
                        backgroundColor: "#03565A",
                        color: "white",
                        textTransform: "none",
                      }}
                      disabled={workspace.status === "approved"}
                      onClick={ handleApprove}
                    >
                      {workspace.status === "approved"?"Approved":"Approve"}
                    </Button>}
                    </div>
                  ) : (
              <div className="nextbtn-v2 text-right">
                <Button
                  className="datebtn font-sans"
                  style={{
                    border: "2px solid #03565A",
                    color: "#03565A",
                    textTransform: "none",
                  }}
                  onClick={skipNext}
                >
                  Skip for now
                </Button>
                &nbsp;
                <Button
                  className="datebtn font-sans"
                  style={{
                    backgroundColor: "#03565A",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={nextStep}
                >
                  Get started
                </Button>
              </div>
              )}
            </div>
          </div>
          <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={stylemodal}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={approverId|| ''}
                  onChange={handleApproverChange}
                >
                {approvalId && approvalId.length>0&&approvalId.map((data)=>
                  <MenuItem key={data._id} value={data._id}>{data.name}</MenuItem>
                )}
                </Select>
                </FormControl>
                <div className="d-flex flex-row justify-content-center mt-3 ">
                <Button style={{
                    backgroundColor: "#03565a",
                    color: "white",
                    textTransform: "none",
                    margin:"10px",
                    width:"50%"
                  }}
                  className="font-sans"
                  onClick={handleCloseModal}
                  >
                    Return
                </Button>
                <Button
                  style={{
                    backgroundColor: "#03565a",
                    color: "white",
                    textTransform: "none",
                    width:"50%",
                    height:"36px",
                    marginTop:"10px"
                  }}
                  onClick={nextStep}
                  disabled={!approverId}
                  className="font-sans"
                >
                  Send for approval
                </Button>
             </div>
              </Box>
            </Modal>
          <Modal
              open={openModal1}
              onClose={handleCloseModal1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={stylemodal}>
                  <div>
                    <label className="font-sans">Cancel Reason:</label>
                    <textarea 
                    className="w-100"
                    value={reason}
                    onChange={(event)=>setReason(event.target.value)}
                    />
                  </div>
                  <div className="d-flex  justify-content-center mt-3 ">
                    <Button
                      style={{
                      backgroundColor: "#03565a",
                      color: "white",
                      textTransform: "none",
                      width:"50%",
                      marginTop:"10px"
                      }}
                      className="font-sans"
                      onClick={()=>handleCancel()}
                    >
                      Submit
                    </Button>
                </div>
              </Box>
           </Modal>
          <Modal
              open={openModal2}
              onClose={handleCloseModal2}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={stylemodal}>
              <div className="d-flex flex-row-reverse font-sans" onClick={handleCloseModal2} style={{cursor:"pointer"}}>Close</div>
                  <div style={{maxHeight:"150px",overflowY:"auto"}}className="mt-4">
                    <p className="font-sans">{workspace.cancel_reason}</p>
                  </div>
              </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default InviteteamMember;

const SelectUser = (props) => {
  const { w_id, user, userAccess } = props;
  const [value, setValue] = useState("");
  const handleChange = async (event, a_id, email) => {
    setValue(event.target.value);

    let data = {
      access_user_id: a_id,
      email: email,
      access: event.target.value,
      workspace_id: w_id[2],
    };

    const res = await CreateAccess(data);
    if (res.status === 200) {
      console.log(res.data);
    }
  };
  // console.log(userAccess,"userAccessuserAccessuserAccess",user._id);
  // console.log(userAccess.filter((det)=>det.access_user_id === user._id),"0000000000000000000",user._id);
  return (
    <>
      <FormControl sx={{ minWidth: 160 }}>
        <Select
          value={value}
          onChange={(e) => handleChange(e, user._id, user.email)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="">
            <em className="font-sans">Selected..</em>
          </MenuItem>
          <MenuItem
          className="font-sans"
            value={"Edit"}
            // selected={userAccess.access === "Edit"}
          >
            Edit Access
          </MenuItem>
          <MenuItem
          className="font-sans"
            value={"Audit"}
            // selected={userAccess.access === "Audit"}
          >
            Audit Access
          </MenuItem>
          <MenuItem
          className="font-sans"
            value={"View"}
            // selected={userAccess.access === "View"}
          >
            View Access
          </MenuItem>
        </Select>
        {userAccess.user_id}
      </FormControl>
    </>
  );
};
